import Page from 'components/Page';
import React,{Fragment} from 'react';
import { Button, ButtonGroup, Card, Input, Label,CardHeader,Tooltip } from 'reactstrap';
import { withApollo,Mutation } from 'react-apollo'
import ToggleButton from 'react-toggle-button'
import gql from 'graphql-tag';
import { MdEdit,MdDelete,MdAddCircle } from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import Moment from 'moment'
import confirm from 'components/Confirmation';

export const ARTICLE_QUERY = gql`
query Article($page:Int,$pageSize:Int,$sorted: [SortedInput],$filtered: [FilteredInput]){
    response:articles(page:$page,pageSize:$pageSize,sorted:$sorted,filtered:$filtered){
      message
      code
      page
      pages
      data{
        _id
        name
        title
        category
        createdTime
        thumb
        shortDesc
        disable
      }
    }
  }
    
`
export const ARTICLE_DELETE = gql`

    mutation ARTICLE($_id:String){
        deleteArticle(_id:$_id){
          code
          message
        }
      }

    
`
class ArticlePage extends React.Component {

    state = {
        data: [],
        pages: 1,
        loading: true,
        tooltipOpen:{},
        category:""
    }
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);  
        this.state.category=props.match.params.category;
    }
    componentDidMount(){
        this.state.category=this.props.match.params.category;
        this.fetchData({page:0,pageSize:10,filtered:[],sorted:[]});
    }
    componentWillReceiveProps(nextProps){
        if (nextProps.match.params.category !== this.props.match.params.category) {
            this.state.category=nextProps.match.params.category;
            this.fetchData({page:0,pageSize:10,filtered:[],sorted:[]});
        }
    }
    fetchData = async (state, instance) => {

        this.setState({ loading: true });
        const { filtered, pageSize, sorted, page } = state;
        try {
            const result = await this.props.client.query({
                query: ARTICLE_QUERY,
                variables: { filtered, pageSize, sorted, page },
            })
            authCheck(result);
            this.setState({ data: result.data.response.data, pages: result.data.response.pages, loading: false });
        } catch (err) {
            authCheck(err);
        }
    }
    onEdit=(row)=>{            
        this.props.history.push("/article-edit/"+row._id);
    }
    onDelete=(row,mutation)=>{
        confirm(`Bạn có chắc chắn muốn xóa bài viết '${row.name}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }
    render = () => {
        const { data, loading, pages,tooltipOpen } = this.state;
        return (
            <Page title="Quản lý bài viết"
                breadcrumbs={[{ name: 'Quản lý bài viết', active: true }]}
                className="CustomerPage">
                <Card>
                    <CardHeader className="bg-green">
                        <Button color="primary" onClick={e => { this.props.history.push("/article-create") }}><MdAddCircle /> Tạo bài viết</Button>
                    </CardHeader>
                    <ReactTable
                        manual
                        noDataText="Không có dữ liệu"   
                        sortable={false}   
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={5}
                        className="-striped -highlight"                       
                        columns={[
                            {
                                Header: "#",
                                width:50,
                                className:"text-center",
                                filterable:false,
                                Cell:row=><span>
                                    {row.index+1}
                                </span>
                            },
                            {
                                Header: "Tên",
                                accessor: "name"
                            },
                            {
                                Header: "Ảnh Thumb",
                                maxWidth:100,
                                accessor: "thumb",
                                filterable: false,
                                Cell:row=>(
                                    <div>
                                    <img width="50" height="50" alt={row.original.title} src={row.value}/>
                                    </div>
                                ),
                            },
                            {
                                Header: "Tiêu đề",
                                accessor: "title",
                                filterable:false
                            },
                            {
                                Header: "Loại",
                                accessor: "category",
                                filterable:false,
                                Filter:({filter,onChange})=>
                                        <Input
                                            type="select" onChange={e=>onChange(e.target.value)} bsSize="sm"
                                            value={filter?filter.value:""}
                                        >
                                            <option value="">Tất cả</option>
                                            <option value="news">Tin tức</option>
                                            <option value="tutorial">Hướng dẫn</option>
                                            <option value="system_notice">Thông báo</option>
                                        </Input>,
                                Cell:row=>(
                                    
                                        <span>{row.value=="news"?"Tin tức":(row.value=="tutorial")?"Hướng dẫn":"Thông báo"}</span>
                                    
                                )
                            },
                            {
                                Header: "Mô tả ngắn",
                                filterable:false,
                                accessor: "shortDesc",    
                                Cell:row=><div className="ellipsisCol">
                                    {row.value}
                                </div>     
                            },
                            {
                                Header: "Trạng thái",
                                accessor: "disable",
                                width:80,
                                filterable:false,
                                Cell:row=>(
                                    <ToggleButton
                                    disabled
                                    value={ row.value }
                                    />
                                )
                            },
                            {
                                Header: "Thời gian tạo",
                                accessor: "createdTime",
                                filterable:false,
                                Filter: ({ filter, onChange }) =>
                                    <Input bsSize="sm" type="date" placeholder="Chọn ngày" onChange={e => onChange(e.target.value)} />,
                                Cell: row => (<Label>{Moment(row.value).format('DD/MM/YYYY')}</Label>)
                            },
                            {
                                Header: "Tác vụ",
                                maxWidth: 100,
                                filterable: false,
                                sortable: false,
                                className:"text-center",
                                Cell: row=>(
                                    <ButtonGroup style={{fontSize:20}}>
                                        <MdEdit id={"edit"+row.original._id} onClick={()=>this.onEdit(row.original)} className="text-warning m-1"/>
                                        <Tooltip placement="bottom" isOpen={tooltipOpen['edit'+row.original._id]} autohide={false} target={"edit"+row.original._id} toggle={e=>{tooltipOpen['edit'+row.original._id]=!tooltipOpen['edit'+row.original._id]; this.setState({tooltipOpen})}}>
                                            Sửa
                                        </Tooltip>
                                        <Mutation mutation={ARTICLE_DELETE}
                                            variables={{_id:row.original._id}}
                                            onCompleted={(result)=>{
                                                this.fetchData({page:0,pageSize:10,filtered:[],sorted:[]});
                                            }}
                                        >
                                            {mutation=>
                                                <Fragment>
                                                    <MdDelete id={"delete"+row.original._id} className="text-danger m-1" onClick={()=>this.onDelete(row.original,mutation)}/>
                                                    <Tooltip placement="bottom" isOpen={tooltipOpen['delete'+row.original._id]} autohide={false} target={"delete"+row.original._id} toggle={e=>{tooltipOpen['delete'+row.original._id]=!tooltipOpen['delete'+row.original._id]; this.setState({tooltipOpen})}}>
                                                        Xóa bài viết
                                                    </Tooltip>
                                                </Fragment>
                                            }
                                        </Mutation>
                                    </ButtonGroup>
                                )
                            }
                        ]}
                        
                    >

                    </ReactTable>
                </Card>

            </Page>
        )
    }
}

export default withApollo(ArticlePage);