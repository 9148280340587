import Page from 'components/Page';
import React, { Fragment } from 'react';
import { Button, ButtonGroup, Card, Input, Label, Tooltip } from 'reactstrap';
import { withApollo, Mutation } from 'react-apollo'
import confirm from 'components/Confirmation';
import { GetLocalToken } from 'companyConstants'
import { MdCall, MdEdit } from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import Moment from 'moment'
import { CALLOUT, FETCH_CUSTOMERS } from 'AdminCustomer/GqlQuery';


class NewCustomer extends React.Component {

    state = {
        data: [],
        pages: 1,
        page: 0,
        loading: true,
        tooltipOpen: {}
    }
    constructor() {
        super();
        this.fetchData = this.fetchData.bind(this);
    }
    onEdit = (row) => {
        this.props.history.push("/customer-edit/" + row._id);
    }

    onDelete = (row, mutation) => {
        confirm(`Bạn có chắc chắn muốn xóa lịch khám với khách hàng '${row.customer.fullName}'?`, { title: "Xóa bản ghi" }).then(
            (result) => {
                mutation();
            },
            (result) => {
                // `cancel` callback

            }
        )
    }
    onCreate = () => {
        this.props.history.push("/customer-edit");
    }
    fetchData = async (state, instance) => {
        const token = GetLocalToken();
        const{page,pageSize}=state;
        try {
            var menus = token.account.menus;
            var valid = menus.filter((item) => {
                return item.path == this.props.location.pathname
            })
            if (!valid || valid.length == 0) {
                valid = menus.filter((item) => {
                    return item.path !== "#";
                })
                if (valid && valid.length > 0) {
                    this.props.history.push(valid[0].path);
                } else {
                    //console.log("not valid");
                    this.props.history.push("/login");
                }
            }else{
                this.setState({ loading: true });

                //const { filtered, pageSize, sorted, page } = state;
                var filtered = [{ id: "createdTime", value: Moment().format("DD/MM/YYYY") }];
                var sorted = [{ id: "createdTime", desc: true }];
                try {
                    const result = await this.props.client.query({
                        query: FETCH_CUSTOMERS,
                        variables: { filtered, sorted,pageSize,page },
                    })
                    authCheck(result);
                    this.setState({ data: result.data.customers.data, pages: result.data.customers.pages, page: result.data.customers.page, loading: false });
                } catch (err) {
                    authCheck(err);
                }
            }
        } catch (err) {
            console.log(err);
        }
        
    }
    render = () => {
        const { data, loading, pages, page, tooltipOpen } = this.state;
        return (
            <Page title="Khách hàng mới"
                breadcrumbs={[{ name: 'Khách hàng mới', active: true }]}
                className="NewCustomer">
                <Card>
                    <ReactTable
                        noDataText="Không có dữ liệu"
                        manual
                        data={data}
                        pages={this.state.pages}
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable={false}
                        showPagination={true}
                        className="-striped -highlight"
                        sortable={false}
                        columns={[
                            {
                                Header: "#",
                                width: 50,
                                className: "text-center",
                                filterable: false,
                                Cell: row => <span>
                                    {row.index + 1 + (page * 10)}
                                </span>
                            },
                            {
                                Header: "Số điện thoại",
                                accessor: "phoneNumber"
                            },
                            {
                                Header: "Họ và Tên",
                                accessor: "fullName"
                            },
                            {
                                Header: "Mã bệnh nhân",
                                accessor: "patientId"
                            },
                            {
                                Header: "Ngày sinh",
                                accessor: "birthDay",
                                Filter: ({ filter, onChange }) =>
                                    <Input bsSize="sm" type="date" placeholder="Chọn ngày" onChange={e => onChange(e.target.value)} />,
                                Cell: row => (<Label>{Moment(row.value).format('DD/MM/YYYY')}</Label>)
                            },
                            {
                                Header: "Địa chỉ",
                                accessor: "address"
                            },
                            {
                                Header: "Đặt khám",
                                accessor: "appointments",
                                className: "text-center",
                                width: 90,
                                filterable: false
                            },
                            {
                                Header: "Đã khám",
                                accessor: "servedAppointments",
                                className: "text-center",
                                width: 90,
                                filterable: false
                            },
                            {
                                Header: "Tác vụ",
                                maxWidth: 100,
                                className: "text-center",
                                filterable: false,
                                sortable: false,
                                Cell: row => (
                                    <ButtonGroup style={{ fontSize: 18 }}>
                                        <MdEdit id={'edit' + row.original._id} className="text-grey m-1" onClick={() => this.onEdit(row.original)} />
                                        <Tooltip placement="bottom" isOpen={tooltipOpen['edit' + row.original._id]} autohide={false} target={"edit" + row.original._id} toggle={e => { tooltipOpen['edit' + row.original._id] = !tooltipOpen['edit' + row.original._id]; this.setState({ tooltipOpen }) }}>
                                            Sửa
                                        </Tooltip>
                                        <Mutation
                                            mutation={CALLOUT}
                                            variables={{ phoneNumber: row.original.phoneNumber }}
                                        >
                                            {mutation => <Fragment>
                                                <MdCall onClick={e => {
                                                    console.log("click call");
                                                    confirm("Cuộc gọi tới số " + row.original.phoneNumber + " đang được thực hiện, vui lòng chờ trong ít giây", { hideCancel: true })
                                                    mutation();
                                                }} id={"call" + row.original._id} className="text-green m-1" />
                                                <Tooltip placement="bottom" isOpen={tooltipOpen['call' + row.original._id]} autohide={false} target={"call" + row.original._id} toggle={e => { tooltipOpen['call' + row.original._id] = !tooltipOpen['call' + row.original._id]; this.setState({ tooltipOpen }) }}>
                                                    Gọi tới số {row.original.phoneNumber}
                                                </Tooltip>

                                            </Fragment>}
                                        </Mutation>
                                    </ButtonGroup>
                                )
                            }
                        ]}

                    >

                    </ReactTable>
                </Card>

            </Page>
        )
    }
}

export default withApollo(NewCustomer);