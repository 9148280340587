import Page from 'components/Page';
import React from 'react';
import { Button,CardBody,Input,Col,Label,Form,FormGroup} from 'reactstrap';
import gql from 'graphql-tag';
import { Query } from 'react-apollo'
import {MdArrowBack,MdAddCircle} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
export const FEED_QUERY=gql`
{
    me{
        data{
            _id
            claims{
                type
                value
            }
            info{
                createdTime
                email
                phoneNumber
            }
            userName
            isAgent
            extension
            name
            
            leaders{
                name
            }
            agents{
                name
            }
        }
    }
  }
`

class ProfilePage extends React.Component{


    render=()=>{
        
        return(
            <Page title="Thông tin tài khoản"
                breadcrumbs={[{ name: 'Thông tin tài khoản', active: true }]}            
                className="ProfilePage"> 
                <CardBody>
                    <Query
                        query={FEED_QUERY}>
                        {
                            ({loading,error,data})=>{
                                if (error){
                                    authCheck(error);
                                    //return <div>Có lỗi load dữ liệu</div>
                                    data={};
                                }
                                   
                                if(data.me){
                                    const {leaders,info,agents,groups,claims,userName,name,extension,_id}=data.me.data;
                                    return (
                                    <Form className="m-4">
                                        
                                        <FormGroup row>
                                            <Label md={3} sm={5} className="text-right">Tài khoản:</Label>
                                            <Col md={6} sm={7}>
                                                <Input value={userName} type="text" disabled={true}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label md={3} sm={5} className="text-right">Họ và tên:</Label>
                                            <Col md={6} sm={7}>
                                                <Input value={name} type="text" disabled={true}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label md={3} sm={5} className="text-right">Số điện thoại:</Label>
                                            <Col md={6} sm={7}>
                                                <Input value={info.phoneNumber} type="text" disabled={true}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label md={3} sm={5} className="text-right">Số voip:</Label>
                                            <Col md={6} sm={7}>
                                                <Input value={extension} type="text" disabled={true}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label md={3} sm={5} className="text-right">Email:</Label>
                                            <Col md={6} sm={7}>
                                                <Input value={info.email} type="text" disabled={true}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label md={3} sm={5} className="text-right">Quản lý phòng/ban:</Label>
                                            <Col md={6} sm={7}>
                                                <Input value={'Lãnh đạo: '+leaders.map((l,index)=>{return l.name+','})+'   Nhân viên: '+agents.map((a,index)=>{return a.name+','})} type="text" disabled={true}/>
                                            </Col>
                                        </FormGroup>
                                        
                                        <FormGroup row>
                                            <Label md={3} sm={5} className="text-right">Cài đặt hiển thị thông báo từ hệ thống:</Label>
                                            <Col md={6} sm={7}>
                                                <Label style={{paddingLeft:"1rem"}}>
                                                    <Input type="checkbox" />
                                                </Label>
                                                
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label md={3} sm={5} className="text-right">Cài đặt thông báo về thông tin được ủy quyền:</Label>
                                            <Col md={6} sm={7}>
                                                <Label style={{paddingLeft:"1rem"}}>
                                                    <Input type="checkbox" />
                                                </Label>
                                                
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label md={3} sm={5}></Label>
                                            <Col md={6} sm={7}>
                                                <Button color="default"><MdArrowBack/> Quay lại</Button>{"   "}
                                                <Button color="success"><MdAddCircle/> Xác nhận</Button>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                )
                                }else
                                return <div>Không thể load thông tin tài khoản</div>;
                                
                            }
                        }

                    </Query>
                    
                </CardBody>
            </Page>
        )
    }
}


export default ProfilePage;