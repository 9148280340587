import { Content } from './index';
import React from 'react';
import backgroundImage from 'assets/img/bg/login-bg.jpg';

const EmptyLayout = ({ children, ...restProps }) => (
  <main  {...restProps}>
    
      {children}
    </main>
);

export default EmptyLayout;
