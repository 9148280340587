export const AUTH_TOKEN = 'admin-auth-token'
export const GetLocalToken=()=>{
    const tokenStr = localStorage.getItem(AUTH_TOKEN);
    
    try{
      if(tokenStr){
        const token = JSON.parse(tokenStr);        
        return token;
      }
    }catch(err){
        console.log("err="+JSON.stringify(err));
       SignOut();
    }
}
export const UpdateLocalToken=(token)=>{
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
}
export const SignOut = ()=>{
    console.log("signout from adminConstant");
    localStorage.setItem(AUTH_TOKEN,undefined);
    window.location.href='/login';
}
