import Page from "components/Page";
import React from "react";
import { Button, Input, Row, Col, Label, FormGroup } from "reactstrap";
import { withApollo, Query } from "react-apollo";
import { MdSearch, MdImportExport, MdPrint } from "react-icons/lib/md";
import gql from "graphql-tag";
import authCheck from "utils/authCheck";
import Moment from "moment";
import ReactTable from "react-table";
import {FETCH_DEPARTMENT} from 'AdminCustomer/GqlQuery';
export const QUERY_REPORT = gql`
  query Report($startDate: String, $endDate: String, $departmentId: String) {
    appointmentGeneralDaily(
      startDate: $startDate
      endDate: $endDate
      departmentId: $departmentId
    ) {
      data{
        appointmentDate
        cancelAmount
        totalAmount
        completeAmount
        approveAmount
        pendingAmount
      }
    }
  }
`;

class AppointmentGeneralReportPage extends React.Component {
  state = {
    departments: [],
    startDate: Moment().add('days',-30),
    endDate: new Date(),
    departmentId: "",
    data:[]
  };

  componentDidMount(){
    //fetch deparmtnet    
      this.props.client.query({
          query:FETCH_DEPARTMENT
      }).then(result=>{
          authCheck(result);
          this.setState({departments:result.data.departments.data})
      })
  }
  fetchData=async (isInit)=>{
    const { startDate,endDate, departmentId } = this.state;
    this.props.client.query({
        query:QUERY_REPORT,
        variables:{startDate:Moment(startDate).format("DD/MM/YYYY"), endDate:Moment(endDate).format("DD/MM/YYYY"), departmentId }
      
    }).then(result=>{
        authCheck(result);
        this.setState({data:result.data.appointmentGeneralDaily.data})
    })
  }
  render = () => {
    const { startDate, endDate, departmentId,data } = this.state;
    return (
      
            <Page
              title="Báo cáo tổng hợp đặt khám"
              breadcrumbs={[
                { name: "Báo cáo tổng hợp đặt khám", active: true }
              ]}
              className="AppointmentReportPage"
            >
              <Row style={{ padding: "1rem" }}>
                <Col md={4} sm={6} xs={12}>
                  <FormGroup>
                    <Label>Ngày bắt đầu</Label>
                    <Input
                      value={Moment(startDate).format("YYYY-MM-DD")}
                      type="date"
                      bsSize="sm"
                      onChange={e =>
                        this.setState({ startDate: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={4} sm={6} xs={12}>
                  <FormGroup>
                    <Label>Ngày kết thúc</Label>
                    <Input
                      value={Moment(endDate).format("YYYY-MM-DD")}
                      type="date"
                      bsSize="sm"
                      onChange={e => this.setState({ endDate: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} sm={6} xs={12}>
                  <FormGroup>
                    <Label>Lọc theo phòng/ban</Label>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={departmentId}
                      onChange={e => {
                        this.setState({ departmentId: e.target.value });
                      }}
                    >
                      <option value="">Tất cả phòng/ban</option>
                      {this.state.departments.map((item, i) => {
                        return (
                          <option key={i} value={item._id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <center>
                <Button className="mr-4" size="lg" color="green" onClick={e=>this.fetchData(false)}><MdSearch/> Tìm kiếm</Button>
                <Button className="mr-4 text-white" size="lg" color="dark"><MdImportExport/> Xuất Excel</Button>
                <Button className="mr-4 text-white" size="lg" color="dark"><MdPrint/> In báo cáo</Button>
                                
              </center>

              <ReactTable
                noDataText="Không có dữ liệu"
                className="mt-3"
                sortable={false}
                data={data}
                filterable={false}
                pageSize={data.length}
                columns={[
                  {
                    Header: "#",
                    width: 80,
                    className: "text-center",
                    Cell:row=><span>
                      {row.index+1}
                    </span>
                  },
                  {
                    Header: "Ngày",
                    className: "text-center",
                    accessor: "appointmentDate",
                    Cell:row=>(
                      <div>
                        {Moment(row.value).format("DD-MM-YYYY")}
                      </div>
                    )
                  },
                  {
                    Header: "Tổng đặt khám",
                    className: "text-center",
                    accessor: "totalAmount",
                    style:{fontWeight:"bold"}
                  },
                  {
                    Header: "Đã khám",
                    className: "text-center text-green",
                    accessor: "completeAmount"
                  },
                  {
                    Header: "Không khám",
                    className: "text-center text-danger",
                    accessor: "cancelAmount"
                  },
                  {
                    Header: "Chờ khám",
                    className: "text-center text-primary",
                    accessor: "approveAmount"
                  },
                  {
                    Header: "Chưa xác nhận",
                    className: "text-center",
                    accessor: "pendingAmount"
                  }
                ]}
              />
            </Page>
         
    );
  };
}

export default withApollo(AppointmentGeneralReportPage);
