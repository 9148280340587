import React from "react";
import logo from 'styles/images/logo_phusan.png'
import { ValidFormDate } from "utils/dateHelper";
import { GetLocalToken } from 'companyConstants'
import Moment from 'moment'

const MYTD = ({ className, colspan, rowspan, children, style }) => <td className={className} colspan={colspan} rowspan={rowspan} style={{ padding: "2px 10px", fontSize: 15, ...style }}>
    {children}
</td>
const P = ({ className, children, style }) => <p className={className} style={{ fontSize: 12, ...style }}>
    {children}
</p>

const PrintNips = ({
    mabn,
    nghe_nghiep,
    sodienthoai,
    chungtoc,
    chieu_cao,
    can_nang,
    ho_va_ten,
    ngaysinh,
    diachi1,
    hutthuoc,
    uongruou,
    para,
    Khoang_sang_sau_gay,
    phuong_phap_thu_thai,
    ngay_dau_tien_ky_kinh_cuoi,
    kinh_deu,
    vongkinh,
    ngay_sieu_am,
    bacsy_chi_dinh,
    tiensu_banthan,
    tiensu_gia_dinh,
    tuan_thai,
    ngay_thai,

    luong_mau_nips,
    nhom_mau,
    so_thai,
    ma_xet_nghiem_nips,
    ngay_lay_mau_nips,
    nguoi_lay_mau_nips,
    donvi_lay_mau_nips,
    bacsy_chi_dinh_nips,
    kqxn_sang_loc_cu,
    css,
    objRef
}) => (

        <div className="body" ref={objRef}>

            <div className="content" id="nips">
                <div class="img_logo" style={{ width: 100, marginRight: 20 }}> <img src={logo} alt="logo hospital" style={{ width: 120 }} /> </div>
                <div style={{ flex: 1 }}>
                    <P style={{ marginBottom: 0 }}><b style={{ fontSize: 20, color: "#478F20" }}>BỆNH VIỆN PHỤ SẢN HÀ NỘI</b><br />
                        <b>
                            TRUNG TÂM SÀNG LỌC, CHUẨN ĐOÁN TRƯỚC SINH VÀ SƠ SINH
                        </b>
                    </P>
                    <P> Địa chỉ: Số 929 - Đường La Thành - Ngọc Khánh - Ba Đình - Hà Nội<br />
                        Hotline: 0243 7757 346
                    </P>
                </div>
                <div style={{ width: "100%" }}>
                    <P style={{ textAlign: "center", fontSize: 20, color: "#478F20", fontWeight: "bold" }}>HỒ SƠ XÉT NGHIỆM SÀNG LỌC NIPS</P>
                </div>
                <div class="form_info" style={{ paddingTop: 0 }}>
                    <P className="header">I. THÔNG TIN THAI PHỤ</P>
                    <table>
                        <tr style={{ border: "none" }}>
                            <th style={{ width: "40%" }}></th>
                            <th></th>
                        </tr>
                        <tr>
                            <MYTD>Mã bệnh nhân: <b>{mabn}</b></MYTD>
                            <MYTD>Mã xét nghiệm: <b>{ma_xet_nghiem_nips}</b></MYTD>
                        </tr>
                        <tr>
                            <MYTD>Họ và tên:<b>{ho_va_ten}</b></MYTD>
                            <MYTD>
                                <table className="table-inline">
                                    <tr>
                                        <MYTD>Ngày sinh:<b>{ValidFormDate(ngaysinh)}</b></MYTD>
                                        <MYTD>Dân tộc:<b>{chungtoc}</b></MYTD>
                                    </tr>
                                </table>
                            </MYTD>

                        </tr>
                        <tr >
                            <MYTD>Địa chỉ:<b> {diachi1}</b></MYTD>
                            <MYTD>Số ĐT:<b>{sodienthoai}</b></MYTD>
                        </tr>
                        <tr>
                            <MYTD colspan="2">
                                <table className="table-inline">
                                    <tbody>
                                        <tr>
                                            <MYTD>Cân nặng: <b>{can_nang < 0 ? "........" : can_nang} kg</b></MYTD>
                                            <MYTD>Chiều cao:<b>{chieu_cao < 0 ? "........" : chieu_cao} cm</b></MYTD>
                                            <MYTD>Nhóm máu:<b>{nhom_mau}</b></MYTD>
                                        </tr>
                                    </tbody>
                                </table>
                            </MYTD>

                        </tr>

                        <tr>
                            <MYTD rowspan="2">PARA:<b>{para}</b></MYTD>
                            <MYTD>
                                <table className="table-inline">
                                    <tbody>
                                        <tr>

                                            <MYTD style={{ width: 100 }}>Số thai:</MYTD>
                                            <MYTD>
                                                <label class="frm_check"> Đơn thai <span className={so_thai == 1 ? "checked" : ""} />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </MYTD>
                                            <MYTD>
                                                <label class="frm_check"> Đa thai <span className={so_thai > 1 ? "checked" : ""} />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </MYTD>

                                        </tr>
                                    </tbody>
                                </table>
                            </MYTD>
                        </tr>
                        <tr>
                            <MYTD>
                                Tuổi thai: <b>{tuan_thai < 0 ? "......." : tuan_thai}</b> tuần <b>{ngay_thai < 0 ? "......." : ngay_thai}</b> ngày
                            </MYTD>
                        </tr>
                        <tr>
                            <MYTD colspan="2">
                                Tiền sử mang thai bất thường: {tiensu_banthan}
                            </MYTD>
                        </tr>
                        <tr>
                            <MYTD>4. Thai lần này:</MYTD>
                            <MYTD>
                                <table className="table-inline">
                                    <tbody>
                                        <tr >
                                            <MYTD>
                                                <label class="frm_check"><span className={phuong_phap_thu_thai == 1 ? "checked" : ""} />
                                                    <span class="checkmark"></span>
                                                    Tự nhiên
                                            </label>
                                            </MYTD>
                                            <MYTD>
                                                <label class="frm_check"><span className={phuong_phap_thu_thai == 3 ? "checked" : ""} />
                                                    <span class="checkmark"></span>
                                                    IVF
                                            </label>
                                            </MYTD>
                                            <MYTD>
                                                <label class="frm_check"><span className={phuong_phap_thu_thai == 2 ? "checked" : ""} />
                                                    <span class="checkmark"></span>
                                                    IUI
                                            </label>
                                            </MYTD>
                                        </tr>
                                    </tbody>
                                </table>
                            </MYTD>
                        </tr>
                        <tr>
                            <MYTD colspan="2" style={{ height: 65, verticalAlign: "top" }}>
                                Kết quả xét nghiệm sàn lọc đã có: {kqxn_sang_loc_cu}
                            </MYTD>
                        </tr>
                        <tr>
                            <MYTD colspan="2">
                                Ngày lấy mẫu: {ValidFormDate(ngay_lay_mau_nips)}
                            </MYTD>
                        </tr>
                        <tr>
                            <MYTD colspan="2">
                                <table className="table-inline">
                                    <tbody>
                                        <tr>
                                            <MYTD>
                                                Đơn vị lấy mẫu: {donvi_lay_mau_nips}
                                            </MYTD>
                                            <MYTD>
                                                Lượng máu: {luong_mau_nips < 0 ? "......." : luong_mau_nips} {donvi_lay_mau_nips}
                                            </MYTD>
                                            <MYTD>
                                                Người lấy mẫu:{nguoi_lay_mau_nips}
                                            </MYTD>
                                        </tr>
                                    </tbody>
                                </table>
                            </MYTD>

                        </tr>
                        <tr>
                            <MYTD colspan="2">
                                Bác sỹ chỉ định:{bacsy_chi_dinh_nips}
                            </MYTD>
                        </tr>
                    </table>
                </div>

                <div class="form_info">
                    <P className="header">TƯ VẤN SÀNG LỌC</P>

                    <P><b>1.Mục đích xét nghiệm và phương pháp thực hiện</b></P>
                    <P>
                        Xét nghiệm sàng lọc trước sinh không xâm lấn(NIPS) được thực hiện tại Trung tâm Sàng lọc, Chẩn đoán trước sinh và sơ sinh – Bệnh viện Phụ Sản Hà Nội. Kết quả được phân tích bởi công ty YOURGENE Bioscience.
                    </P>
                    <P>
                        Xét nghiệm được thiết kế và thực hiện với mục đích sàng lọc các bất thường của 22 cặp nhiễm sắc thể thường (NST) thường và cặp NST giới tính. Trong đó có các hội chứng bất thường NST hay gặp như: Hội chứng Patau( 3 NST số 13) Hội chứng Edwards ( 3 NST số 18) Hội chứng Down( 3 NST 21) Hội chứng Turner ( 1 NST X) Hội chứng Klinefelter.....
                    </P>
                    <P>
                        Xét nghiệm được thực hiện theo trình sau:
                    </P>
                    <P style={{ paddingLeft: 20 }}>
                        1.	Thu 10ml máu toàn phần của thai phụ mang thai ≥ 10 tuần vào ống chuyên dụng ( ống Streck)
                    </P>
                    <P style={{ paddingLeft: 20 }}>
                        2.	DNA tự do ngoài tế bào ( cell-free DNA) được tách từ máu thai phụ
                    </P>
                    <P style={{ paddingLeft: 20 }}>
                        3.	DNA được giải trình tự , kết quả được phân tích bằng thuật toán tin sinh chuyên dụng.
                    </P>

                    <P><b>
                        2.Khuyến cáo khi thực hiện xét nghiệm
                    </b>
                    </P>
                    <P>
                        Việc thực hiện xét nghiệm này cần được tư vấn kỹ lưỡng từ bác sỹ và chuyên gia tư vấn di truyền. Xét nghiệm này được khuyến khích thực hiện trong các trường hợp sau:
                    </P>
                    <P style={{ paddingLeft: 20 }}>
                        1.	Thai phụ cảm thấy không an tâm về các nguy cơ rủi ro của các xét nghiệm chẩn đoán trước sinh xâm lấn;
                    </P>
                    <P style={{ paddingLeft: 20 }}>
                        2.	Thai phụ có kết quả siêu âm nghi ngờ thai nhi mắc các bất thường nhiễm sắc thể;
                    </P>
                    <P style={{ paddingLeft: 20 }}>
                        3.	Thai phụ thuộc nhóm cao tuổi hoặc có nguy cơ cao mang thai mắc hội chứng Down
                    </P>
                    <P>
                        Xét nghiệm này không tiết lộ thông tin về giới tính của thai nhi.
                    </P>
                    <P>
                        Mặc dù tỷ lệ phát hiện cao, nhưng xét nghiệm NIPS cũng như các xét nghiệm sàng lọc không xâm lấn khác sử dụng DNA tự do ngoài tế bào vẫn là một xét nghiệm sàng lọc. Xét nghiệm chẩn đoán như làm nhiễm sắc thể đồ từ dịch ối hoặc sinh thiết gai rau được khuyến cáo thực hiện khi kết quả dương tính được trả lời với xét nghiệm NIPS. Vì vậy, kết quả của xét nghiệm NIPS sẽ cung cấp thông tin để các bác sĩ đưa ra các tư vấn tiếp theo.
                    </P>
                    <P>
                        Sự chính xác và chất lượng kết quả của xét nghiệm có thể bị ảnh hưởng bởi việc lấy máu không đúng kỹ thuật, các điều kiện bảo quản và vận chuyển không đúng quy định.
                    </P>
                    <P>
                        Sự chính xác và chất lượng kết quả của xét nghiệm có thể bị ảnh hưởng bởi các hoạt động điều trị bao gồm: Truyền máu không phải máu của chính thai phụ, các phẫu thuật cấy ghép, các trị liệu sử dụng tế bào gốc trong vòng 30 ngày tính đến thời điểm lấy máu.
                    </P>
                    <P>
                        <b>
                            3.Xét nghiệm này không phù hợp với các trường hợp sau.
                        </b>
                    </P>
                    <P style={{ paddingLeft: 20 }}>
                        - Thai phụ mang đa thai không cùng trứng</P>
                    <P>- Thai phụ được chẩn đoán mang dị bội nhiễm sắc thể</P>
                    <P>- Thai phụ được truyền máu không phải là máu của chính thai phụ, hoặc đã thực hiện phẫu thuật cấy ghép, trị liệu sử dụng tế bào gốc hay cho trứng.                    </P>
                    <P>
                        <b>4.Giới hạn của xét nghiệm</b>
                    </P>
                    <P>
                        Xét nghiệm NIPS không phát hiện các bất thường nhiễm sắc thể như đảo đoạn, chuyển đoạn, khảm dòng tế bào, vv.....Vì vậy, kết quả xét nghiệm âm tính không đồng nghĩa với việc thai nhi sẽ không mang các bệnh di truyền.
                    </P>

                    <P>
                        <b>
                            5.Quyền riêng tư và bảo mật thông tin
                        </b>
                    </P>
                    <P>
                        Bệnh viện Phụ sản Hà Nội tô trọng quyền riêng tư và bảo mật thông tin cá nhân của bệnh nhân. Các thông tin trên phiếu này được thu thập chỉ để sử dụng phục vụ việc thực hiện xét nghiệm này và sẽ không được sử dụng với bất kỳ mục đích nào khác ngoài mục đích thực hiện xét nghiệm NIPS.
                    </P>

                    <P><b>Cam kết của bệnh nhân:  Tôi đã được nghe tư vấn và hiểu hết về xét nghiệm sàng lọc này. Tôi xin cam đoan những thông tin trên là chính xác</b></P>
                    <table class="table_text">
                        <tbody>
                        <tr>
                            <MYTD colspan="4">
                                <p style={{float:"right",fontStyle:"italic"}}>                                    
                                    {Moment().format("HH [giờ] mm [phút],  [ngày] DD [tháng] MM [năm] YYYY")}
                                </p>
                            </MYTD>
                        </tr>
                        <tr>
                            <MYTD style={{ textAlign: "center" }}>Bệnh nhân ký tên</MYTD>
                            <td></td>
                            <td></td>
                            <MYTD style={{ textAlign: "center" }}>Người lập phiếu</MYTD>
                        </tr>

                        <tr>
                            <MYTD></MYTD>
                            <td></td><td></td>
                            <MYTD style={{ textAlign: "center" }}><strong>{GetLocalToken().fullName}</strong></MYTD>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

    )
export default PrintNips;