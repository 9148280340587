import Page from "Admin/Components/Page";
import React, { Fragment } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Label,
  Form,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  FormGroup
} from "reactstrap";
import { withApollo } from "react-apollo";
import { MdDelete, MdAddCircle, MdEdit } from "react-icons/lib/md";
import gql from "graphql-tag";
import { Mutation, Query } from "react-apollo";
import ReactTable from "react-table";
import Autosuggest from "react-autosuggest";
import ToggleButton from "react-toggle-button";
import authCheck from "utils/authCheck";

export const QUERY_COMPANY = gql`
  {
    companies {
      data {
        name
        domain
      }
    }
  }
`;
export const QUERY_DETAIL = gql`
  query AdminQuery($_id: String!) {
    trunk(_id: $_id) {
      _id
      dialoutPlan {
        prepend
        prefix
        pattern
        seq
      }
      disable
      maxChans
      name
      outCid
      tech
      provider
      userContext
      templates {
        _id
        name
        type
      }
      details {
        keyword
        data
        flags
      }
      company {
        name
        domain
      }
    }
  }
`;
export const QUERY_Template = gql`
  query AdminQuery(
    $filtered: [FilteredInput]
    $sorted: [SortedInput]
    $page: Int
    $pageSize: Int
  ) {
    templates(
      page: $page
      pageSize: $pageSize
      filtered: $filtered
      sorted: $sorted
    ) {
      data {
        _id
        name
        type
        description
        flags
      }
    }
  }
`;
export const CREATE_QUERY = gql`
  mutation AdminMutation($trunk: TrunkInputType!) {
    createTrunk(trunk: $trunk) {
      _id
    }
  }
`;
export const UPDATE_QUERY = gql`
  mutation AdminMutation($trunk: TrunkInputType!) {
    updateTrunk(trunk: $trunk) {
      _id
    }
  }
`;
export const QUERY_ANNOUNCEMENT = gql`
  query AdminQuery($filtered: [FilteredInput]) {
    announcements(filtered: $filtered, pageSize: 0) {
      data {
        _id
        name
      }
    }
  }
`;

const getSuggestionValue = suggestion => suggestion.name;
// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div className="account-select">
    {suggestion.name + " < " + suggestion.type + " >"}
  </div>
);

class TrunkDetailPage extends React.Component {
  state = {
    _id: "",
    dialoutPlan: [],
    disable: false,
    maxChans: 0,
    templates: [],
    name: "",
    outCid: "",
    tech: "PJSIP",
    company: {},
    provider: "",
    userContext: "",
    details: [
      { keyword: "aors" },
      { keyword: "transport", data: "0.0.0.0-udp" },
      { keyword: "aor_contact" },
      { keyword: "authentication", data: "outbound" },
      { keyword: "client_uri" },
      { keyword: "codecs", data: "ulaw,alaw" },
      { keyword: "username" },
      { keyword: "secret" },
      { keyword: "from_user" },
      { keyword: "from_domain" },
      { keyword: "dtmfmode", data: "auto" },
      { keyword: "match" },
      { keyword: "sip_server" },
      { keyword: "sip_server_port" },
      { keyword: "server_uri" },
      { keyword: "registration", data: "send" },
      { keyword: "rewrite_contact", data: "yes" },
      { keyword: "retry_interval", data: "60" },
      { keyword: "qualify_freequency", data: "60" },
      { keyword: "max_retries", data: "10" }
    ],
    allTemplates: [],
    templateSuggests: [],
    tmpTemplate: "",
    isEdit: false,
    editKeyword: "",
    editData: "",
    editFlags: 0,
    editPrepend: "",
    editPrefix: "",
    editSeq: 0,
    editPattern: ""
  };
  back = () => {
    this.props.history.push("/trunk");
  };
  componentDidMount() {
    try {
      this.props.client
        .query({
          query: QUERY_Template,
          variables: {
            pageSize: 100,
            filtered: [{ id: "type", value: "trunk" }]
          }
        })
        .then(result => {
          this.setState({
            allTemplates: result.data.templates.data,
            templateSuggests: result.data.templates.data
          });
        });
      const _id = this.props.match.params._id;
      if (_id) {
        this.setState({ isEdit: true, _id: _id });
        //fetch detail
        this.props.client
          .query({
            query: QUERY_DETAIL,
            variables: { _id: _id }
          })
          .then(result => {
            authCheck(result);
            this.setState(result.data.trunk);
          });
      }
    } catch (err) {
      authCheck(err);
    }
  }
  onTemplateSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    var { templates } = this.state;
    templates = templates.filter(item => {
      return item.type !== suggestion.type;
    });
    templates.push(suggestion);
    this.setState({
      tmpTemplate: "",
      templates
    });
  };
  onTemplatesClearRequested = () => {
    this.setState({
      templateSuggests: []
    });
  };
  onTemplatesFetchRequested = ({ value }) => {
    this.setState({
      templateSuggests: this.getTemplateSuggestions(value)
    });
  };
  onTemplateSearchChange = (event, { newValue }) => {
    this.setState({
      tmpTemplate: newValue
    });
  };
  getTemplateSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const { allTemplates } = this.state;

    return inputLength === 0
      ? []
      : allTemplates.filter(
          tpl =>
            tpl.name.toLowerCase().indexOf(inputValue) >= 0 ||
            tpl.type.toLowerCase().indexOf(inputValue) >= 0
        );
  };
  render = () => {
    const {
      _id,
      name,
      outCid,
      provider,
      userContext,
      company,
      dialoutPlan,
      templates,
      disable,
      maxChans,
      details,
      tech,
      editPrepend,
      editPrefix,
      editSeq,
      editPattern,
      editData,
      editFlags,
      editKeyword,
      tmpTemplate,
      isEdit,
      templateSuggests
    } = this.state;

    // Autosuggest will pass through all these props to the input.
    const templateInputProps = {
      placeholder: "Nhập tên template muốn thêm",
      value: tmpTemplate,
      onChange: this.onTemplateSearchChange
    };
    return (
      <Page
        title="Chi tiết Template"
        breadcrumbs={[{ name: "Chi tiết Template", active: true }]}
        className="template"
      >
        <Row>
          <Col md={12}>
            <Mutation
              mutation={isEdit ? UPDATE_QUERY : CREATE_QUERY}
              variables={{
                trunk: {
                  _id,
                  name,
                  outCid,
                  provider,
                  userContext,
                  disable,
                  maxChans,
                  dialoutPlan,
                  details,
                  tech,
                  company: company ? company.domain : "",
                  templates: templates.map(item => {
                    return item._id;
                  })
                }
              }}
              onCompleted={result => {
                this.back();
              }}
            >
              {mutation => (
                <Button color="primary" onClick={mutation}>
                  Cập nhật
                </Button>
              )}
            </Mutation>{" "}
            <Button color="link" onClick={this.back}>
              Hủy
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12} sm={12}>
            <Card>
              <CardHeader className="bg-dark text-white">
                Thông tin cơ bản
              </CardHeader>
              <CardBody>
                <Form className="m-3">
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Trunk Name:
                    </Label>
                    <Col md={9} sm={8}>
                      <Input
                        disabled={isEdit}
                        value={name}
                        onChange={e => {
                          var nameValid = e.target.value.trim().toLowerCase();
                          this.setState({ name: nameValid });
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Số gọi ra:
                    </Label>
                    <Col md={9} sm={8}>
                      <Input
                        value={outCid}
                        onChange={e => {
                          this.setState({ outCid: e.target.value });
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Số kênh:
                    </Label>
                    <Col md={9} sm={8}>
                      <Input
                        type="number"
                        value={maxChans}
                        onChange={e => {
                          this.setState({ maxChans: e.target.value });
                        }}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Nhà cung cấp:
                    </Label>
                    <Col md={9} sm={8}>
                      <Input
                        value={provider}
                        onChange={e => {
                          this.setState({ provider: e.target.value });
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Kỹ thuật:
                    </Label>
                    <Col md={9} sm={8}>
                      <Input
                        type="select"
                        value={tech}
                        onChange={e => {
                          this.setState({ tech: e.target.value });
                        }}
                      >
                        <option value="PJSIP">PJSIP</option>
                        <option value="SIP">SIP</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Chọn template:
                    </Label>
                    <Col md={9} sm={8}>
                      <Autosuggest
                        suggestions={templateSuggests}
                        onSuggestionsFetchRequested={
                          this.onTemplatesFetchRequested
                        }
                        onSuggestionsClearRequested={
                          this.onTemplatesClearRequested
                        }
                        onSuggestionSelected={this.onTemplateSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={templateInputProps}
                      />
                      <Input
                        type="select"
                        name="selectMulti"
                        multiple
                        onDoubleClick={e => {
                          var newTemplates = templates.filter(item => {
                            return item._id !== e.target.value;
                          });
                          this.setState({ templates: newTemplates });
                        }}
                      >
                        {templates.map((tpl, index) => (
                          <option key={tpl._id} value={tpl._id}>
                            {tpl.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Công ty:
                    </Label>
                    <Col md={9} sm={8}>
                      <Input
                        type="select"
                        value={company ? company.domain : ""}
                        onChange={e => {
                          this.setState({
                            company: { domain: e.target.value }
                          });
                        }}
                      >
                        <Query query={QUERY_COMPANY}>
                          {({ loading, error, data }) => {
                            if (error) {
                              console.log(error);
                              //authCheck(error);
                            }
                            if (!data.companies) return "";
                            return (
                              <Fragment>
                                <option value="">Chọn Công Ty</option>
                                {data.companies.data.map((com, index) => (
                                  <option key={index} value={com.domain}>
                                    {com.name}
                                  </option>
                                ))}
                              </Fragment>
                            );
                          }}
                        </Query>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Disable:
                    </Label>
                    <Col md={9} sm={8}>
                      <div className="mt-2">
                        <ToggleButton
                          value={disable}
                          onToggle={value => {
                            this.setState({ disable: !disable });
                          }}
                        />
                      </div>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="bg-dark text-white">
                Cấu hình gọi ra
              </CardHeader>
              <CardBody>
                <Row className="mt-2">
                  <Col md={2} sm={6} className="pr-1">
                    <Input
                      value={editPrepend}
                      onChange={e => {
                        this.setState({ editPrepend: e.target.value });
                      }}
                      placeholder="prepend"
                      bsSize="sm"
                    />
                  </Col>
                  <Col md={2} sm={6} className="pl-1 pr-1">
                    <Input
                      value={editPrefix}
                      onChange={e => {
                        this.setState({ editPrefix: e.target.value });
                      }}
                      placeholder="prefix"
                      bsSize="sm"
                    />
                  </Col>
                  <Col md={5} sm={6} className="pl-1 pr-1">
                    <Input
                      value={editPattern}
                      onChange={e => {
                        this.setState({ editPattern: e.target.value });
                      }}
                      placeholder="pattern"
                      bsSize="sm"
                    />
                  </Col>
                  <Col md={2} sm={4} className="pl-1 pr-1">
                    <Input
                      value={editSeq}
                      placeholder="seq"
                      onChange={e => {
                        this.setState({ editSeq: e.target.value });
                      }}
                      type="number"
                      bsSize="sm"
                    />
                  </Col>
                  <Col md={1} sm={2} className="pl-1 pr-1">
                    <Button
                      color="primary"
                      onClick={e => {
                        dialoutPlan.push({
                          prepend: editPrepend,
                          prefix: editPrefix,
                          pattern: editPattern,
                          seq: editSeq
                        });
                        this.setState({
                          dialoutPlan,
                          editPrepend: "",
                          editPrefix: "",
                          editPattern: "",
                          editSeq: editSeq + 1
                        });
                      }}
                    >
                      <MdAddCircle />
                    </Button>
                  </Col>
                </Row>
                {dialoutPlan.map((item, index) => (
                  <Row key={index}>
                    <Col md={2} sm={6} className="pr-1">
                      <Input
                        value={item.prepend}
                        placeholder="prepend"
                        bsSize="sm"
                        onChange={e => {
                          this.setState({ dialoutPlan });
                        }}
                      />
                    </Col>
                    <Col md={2} sm={6} className="pl-1 pr-1">
                      <Input
                        value={item.prefix}
                        placeholder="prefix"
                        bsSize="sm"
                        onChange={e => {
                          this.setState({ dialoutPlan });
                        }}
                      />
                    </Col>
                    <Col md={5} sm={6} className="pl-1 pr-1">
                      <Input
                        value={item.pattern}
                        placeholder="pattern"
                        bsSize="sm"
                        onChange={e => {
                          this.setState({ dialoutPlan });
                        }}
                      />
                    </Col>
                    <Col md={2} sm={4} className="pl-1 pr-1">
                      <Input
                        value={item.seq}
                        placeholder="seq"
                        type="number"
                        bsSize="sm"
                        onChange={e => {
                          this.setState({ dialoutPlan });
                        }}
                      />
                    </Col>
                    <Col md={1} sm={2} className="pl-1 pr-1">
                      <Button
                        onClick={e => {
                          dialoutPlan.splice(index, 1);
                          this.setState({ dialoutPlan });
                        }}
                      >
                        <MdDelete />
                      </Button>
                    </Col>
                  </Row>
                ))}
              </CardBody>
            </Card>
          </Col>
          <Col md={6} xs={12} sm={12}>
            <Card>
              <CardHeader className="bg-dark text-white">
                Cấu hình bổ xung
              </CardHeader>
              <CardBody>
                <Form className="mt-2">
                  <FormGroup row>
                    <Col md={4}>
                      <Input
                        value={editKeyword}
                        onChange={e => {
                          this.setState({ editKeyword: e.target.value });
                        }}
                        bsSize="sm"
                        placeholder="keyword"
                      />
                    </Col>
                    <Col md={4}>
                      <Input
                        value={editData}
                        bsSize="sm"
                        onChange={e => {
                          this.setState({ editData: e.target.value });
                        }}
                        placeholder="value"
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        value={editFlags}
                        bsSize="sm"
                        onChange={e => {
                          this.setState({ editFlags: e.target.value });
                        }}
                        type="number"
                        placeholder="flags"
                      />
                    </Col>
                    <Col md={2}>
                      <Button
                        color="primary"
                        onClick={e => {
                          var newDetails = details.filter(function(
                            item,
                            index,
                            arr
                          ) {
                            return item.keyword !== editKeyword;
                          });
                          newDetails.splice(0, 0, {
                            keyword: editKeyword,
                            data: editData,
                            flags: editFlags
                          });
                          this.setState({
                            details: newDetails,
                            editData: "",
                            editFlags: 0,
                            editKeyword: ""
                          });
                        }}
                      >
                        <MdAddCircle /> Thêm
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
                <ReactTable
                  noDataText="Data is Empty"
                  data={details}
                  showPagination={false}
                  pageSize={details ? details.length : 1}
                  defaultSorted={[
                    {
                      id: "keyword",
                      desc: false
                    }
                  ]}
                  columns={[
                    {
                      Header: "keyword",
                      accessor: "keyword"
                    },
                    {
                      Header: "data",
                      accessor: "data"
                    },
                    {
                      Header: "flags",
                      accessor: "flags",
                      width: 80
                    },
                    {
                      Header: "",
                      width: 90,
                      Cell: row => (
                        <ButtonGroup>
                          <Button
                            color="primary"
                            onClick={e => {
                              this.setState({
                                editData: row.original.data,
                                editKeyword: row.original.keyword,
                                editFlags: row.original.flags
                              });
                            }}
                          >
                            <MdEdit />
                          </Button>{" "}
                          <Button
                            color="warning"
                            onClick={e => {
                              var newDetails = details.filter(function(
                                item,
                                index,
                                arr
                              ) {
                                return index !== row.index;
                              });
                              this.setState({ details: newDetails });
                            }}
                          >
                            <MdDelete />
                          </Button>
                        </ButtonGroup>
                      )
                    }
                  ]}
                />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>Cấu hình gọi vào</CardHeader>
              <CardBody />
            </Card>
          </Col>
        </Row>
      </Page>
    );
  };
}

export default withApollo(TrunkDetailPage);
