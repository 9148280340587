import Page from 'components/Page';
import React from 'react';
import { Button,ButtonGroup} from 'reactstrap';
import { withApollo } from 'react-apollo'
import {MdFileDownload,MdPlayCircleFilled,MdPause} from 'react-icons/lib/md';
import gql from 'graphql-tag';
import authCheck from 'utils/authCheck';
import Moment, { duration } from 'moment';
import { CounterWidget } from 'components/Widget';
import ReactTable from "react-table";
import {GetLocalToken,apiHost} from 'companyConstants'
import { filter } from 'async';

export const FEED_DEPARTMENT=gql`
    query Department{
        departments{
            _id            
            name
          }
    }
`
export const CdrQuery = gql`
query CDR($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
    cdrs(page:$page,pageSize:$pageSize,sorted:$sorted,filtered:$filtered){
      code
      message
      page
      pages
      data{
       _id
        state
        receiver
        agent{name}
        sender
        startTime
        ringTime
        upTime
        isOutbound
        did
        duration
        endTime
        endReason
        hangupTime
        outboundCallerId
      }
    }
  }
`;

class InboundReportServed extends React.Component{
    state={
        departments :[], 
        selectedMonth:new Date(),  
        data: [],
        page: 1,
        loading: true      
    }
    componentDidMount(){
        this.fetchData(true);
    }
    fetchData=async (state, instance)=>{
        this.setState({ loading: true });
        const {filtered,pageSize,sorted,page} = state;
        filtered.push({id:"isOutbound",value:"false"});
        filtered.push({id:"state",value:"true"});
        sorted.push({id:"startTime",desc:true})
        try{
            const result = await this.props.client.query({
                query: CdrQuery,
                variables: { filtered,pageSize,sorted,page },
                
            })
        
            this.setState({data:result.data.cdrs.data,pages:result.data.cdrs.pages,loading:false});
        }catch(err){
           authCheck(err);
        }
    }
    download=(id)=>{
        console.log("download id:"+id);
        window.open("http://"+apiHost+"/downloadrecord/"+id,"_blank")
    }
    render=()=>{
        const {data,loading,pages}=this.state;
        return (
            <Page
                title="QUẢN LÝ CUỘC GỌI ĐƯỢC PHỤC VỤ"
                breadcrumbs={[{ name: 'QUẢN LÝ CUỘC GỌI ĐƯỢC PHỤC VỤ', active: true }]}            
                className="ReportCallMonitorPage">                
                    <ReactTable
                        noDataText="Không có dữ liệu"
                        className="mt-3"
                        columns={[                            
                            {
                                Header:"Điện thoại cá nhân",
                                className:"text-center",
                                accessor:"sender"                                
                            },
                            {
                                Header:"Điện thoại viên",
                                className:"text-center",
                                Cell:row=><span>
                                    {row.original.agent?row.original.agent.name:""} ({row.original.receiver})
                                </span>
                            },
                            
                            {
                                Header:"Ngày",
                                className:"text-center",
                                accessor:"startTime",
                                Cell:row=><span>
                                    {Moment(row.value).format("DD/MM/YYYY")}                                           
                                </span>
                            },
                            {
                                Header:"Thời điểm kết nối",
                                className:"text-center",
                                accessor:"startTime",
                                Cell:row=><span>
                                    {Moment(row.value).format("HH:mm:ss DD/MM/YYYY")}                                           
                                </span>
                            },
                            {
                                Header:"Thời điểm nhấc máy",
                                className:"text-center",
                                accessor:"upTime",
                                Cell:row=><span>
                                    {Moment(row.value).format("HH:mm:ss DD/MM/YYYY")}                                           
                                </span>
                            },
                            {
                                Header:"Thời điểm gác máy",
                                className:"text-center",
                                accessor:"hangupTime",
                                Cell:row=><span>
                                    {Moment(row.value).format("HH:mm:ss DD/MM/YYYY")}                                           
                                </span>
                            },
                            {
                                Header:"Thời gian chờ",
                                className:"text-center",
                                accessor:"ringTime",
                                Cell:row=><span>
                                     {Moment("1900-01-01").add(Moment(row.original.upTime).diff(row.value,'seconds'),'seconds').format("HH:mm:ss")}   

                                </span>
                            },
                            {
                                Header:"Thời gian đàm thoại",
                                className:"text-center",
                                accessor:"duration",
                                Cell:row=><span>
                                     {Moment("1900-01-01").add(row.value,'seconds').format("HH:mm:ss")}   

                                </span>
                            },
                            {
                                Header:"Tác vụ",
                                className:"text-center",
                                accessor:"_id",
                                Cell:row=><ButtonGroup>
                                <Button ><MdPlayCircleFilled/></Button>
                                <Button onClick={e=>this.download(row.value)}><MdFileDownload/></Button>
                            </ButtonGroup>
                            },
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={10}
                        
                    >

                    </ReactTable>
            </Page>
        )
    }

}

export default withApollo(InboundReportServed);