import Page from 'components/Page';
import React from 'react';
import {Badge,Card,CardBody} from 'reactstrap';


import gql from 'graphql-tag';
import { Query } from 'react-apollo'
import {MdCheckBoxOutlineBlank,MdCheckCircle,MdClose} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import Moment from 'moment'

export const FEED_QUERY=gql`
    query GroupAgent{        
        groupAgents{
        _id,description,name,sipAccounts,sipAccountDetails{_id displayName},status,updatedTime,createdTime
        }
    }
    
`
export const FEED_SIPACCOUNT=gql`            
    query{
        sipAccounts{
            userName,displayName
        }
    }
    
`
export const CREATE_QUERY=gql`
    mutation Mutation($groupAgent:GroupAgentInput!){
        createGroupAgent(groupagent:$groupAgent){
        _id,createdTime,description,name,status,updatedTime
        }
    }
`
export const UPDATE_QUERY=gql`
    mutation Mutation($groupAgent:GroupAgentInput!){
        updateGroupAgent(groupagent:$groupAgent){
        _id,createdTime,description,name,status,updatedTime
        }
    }
`
export const DELTE_QUERY=gql`
    mutation Mutation($_id:String!){
        deleteGroupAgent(_id:$_id){
            _id
        }
    }
`
class GroupAgentPage extends React.Component{
    state={
        
    }
    render=()=>{
        
        return (
            <Page title="Nhóm Agent"
                breadcrumbs={[{ name: 'Nhóm Agent', active: true }]}            
                className="ExtensionPage">     
                <Card>
                    
                    <CardBody>
                        <Query query={FEED_QUERY}>
                        {
                            ({loading,error,data})=>{
                                
                                if (error){
                                    authCheck(error);
                                    //return <div>Có lỗi load dữ liệu</div>
                                    data=[];
                                } 
                                    
                                return (                           
                                        
                                    <ReactTable
                                        noDataText="Không có dữ liệu"
                                        data={data.groupAgents}
                                        columns={[                                    
                                            {
                                                Header:"Tên",
                                                accessor:"name"
                                            },
                                            {
                                                Header:"Tài khoản SIP",
                                                accessor:"sipAccountDetails",
                                                Cell:row=>(
                                                    <div>
                                                        {row.value===null?"":(
                                                            row.value.map((item,index)=><span style={{paddingRight:10}} key={index}><Badge color="green">{item._id}</Badge>{" <"+item.displayName+">"}</span>)
                                                        )}
                                                    </div>
                                                )
                                            },
                                            {
                                                Header:"T.Thái",
                                                accessor:"status",
                                                width:70,
                                                Cell:row=>(
                                                    <div style={{fontSize:18}}>
                                                    {row.value==="waiting"?<MdCheckBoxOutlineBlank className="text-green"/>:
                                                    row.value==="complete"?<MdCheckCircle className="text-green"/>:<MdClose className="text-danger"/>   } 
                                                    </div>
                                                )
                                            },
                                            {
                                                Header:"Cập nhật",
                                                accessor:"updatedTime",
                                                width:130,
                                                Cell:row=>(
                                                    <div>
                                                        <Badge className="text-white">{Moment(row.value).format("hh:mm")}</Badge>{" "}
                                                        {Moment(row.value).format("DD/MM/YYYY")}                                          
                                                    </div>
                                                )
                                            }

                                        ]}
                                        
                                        defaultPageSize={5}
                                        defaultSorted={[
                                            {
                                                id: "updatedTime",
                                                desc: true
                                            }
                                        ]}
                                        loading={loading} 
                                        className="-striped -highlight"
                                    />
                                )
                            }
                        }
                        </Query>
                    </CardBody>
                </Card>
                
            </Page>
        )
    }

}

export default GroupAgentPage;
