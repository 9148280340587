import React from 'react';
import { SignOut } from 'companyConstants'
import bn from 'utils/bemnames';
import { GetLocalToken } from 'companyConstants'
import { withApollo } from "react-apollo";
import Autosuggest from 'react-autosuggest';

import {
  Navbar,
  // NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  ListGroup,
  ListGroupItem,
  Button,
} from 'reactstrap';

import {
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
  MdClearAll,
  MdExitToApp,
  MdHome
} from 'react-icons/lib/md';

import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';

import withBadge from 'hocs/withBadge';
import logo200Image from 'assets/img/logo/logo-telehub.png';
import SourceLink from 'components/SourceLink';
import { notificationsData } from 'demos/header';
import { FETCH_CUSTOMER } from 'AdminCustomer/GqlQuery';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);


class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    searchKey: "",
    suggests: [],
    customers: []
  };
  token = {}
  constructor(props) {
    super();
    this.token = GetLocalToken();
    if (!this.token) {
      window.location.href = '/login';
    }
  }
   getSuggestionValue = suggestion => suggestion.fullName;
   renderSuggestion = suggestion => (
  <div className="account-select" onClick={e=>{document.location.href='/customer-edit/'+suggestion._id}}>
    {suggestion.fullName} ({suggestion.phoneNumber})
  </div>
);
  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };
  onSearchChange = (event, { newValue }) => {    
    this.setState({
      searchKey: newValue
    });
  };
 
  onFetchRequested = ({ value }) => {
    this.props.client.query({
      query: FETCH_CUSTOMER,
      variables: { filtered: [{ id: "key", value }], page: 0, pages: 10 }
    }).then(result => {
      if (result.data.customers !== null) {
        this.setState({
          suggests: result.data.customers.data
        });
      }
    })

  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onClearRequested = () => {
    this.setState({
      suggests: []
    });
  };
  onSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    const { customers } = this.state;
    customers.push(suggestion);
    this.setState({
      searchKey: ''
    });
  }
  render() {
    const { isNotificationConfirmed, searchKey, suggests } = this.state;
    const inputProps = {
      placeholder: 'Tên hoặc SĐT khách hàng',
      value: searchKey,
      onChange: this.onSearchChange
    };
    return (
      <Navbar light expand className={bem.b('bg-green')}>
        <Nav navbar className="mr-2">
          <Button color="link" style={{ color: "white" }} onClick={this.handleSidebarControlButton}>
            <MdClearAll size={35} />
          </Button>
        </Nav>
        <Nav navbar>
          <SourceLink className="navbar-brand d-flex" href="/">

            <span className="text-white" style={{ margin: "3px auto 5px" }}>
              <MdHome style={{ fontSize: 25, marginTop: -5, marginRight: 5 }} />BỆNH VIỆN PHỤ SẢN HÀ NỘI
              </span>
          </SourceLink>
        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          <div style={{ position:"absolute",width:280,right:80,top:18 }}>
            <Autosuggest
              suggestions={suggests}
              onSuggestionsFetchRequested={this.onFetchRequested}
              onSuggestionsClearRequested={this.onClearRequested}
              onSuggestionSelected={this.onSelected}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              inputProps={inputProps}
            />
          </div>

          <NavItem>

            <NavLink id="Popover2">
              <Avatar
                onClick={this.toggleUserCardPopover}
                className="can-click"
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}>
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title={this.token.account.info.fullName}
                  subtitle={this.token.account.info.email}
                  text=""
                  className="border-light">
                  <ListGroup flush>
                    <ListGroupItem onClick={e => { document.location.href = "/profile" }} tag="button" action className="border-light">
                      <MdPersonPin /> Hồ sơ
                    </ListGroupItem>                   
                    <ListGroupItem onClick={e => { SignOut() }} tag="button" action className="border-light">
                      <MdExitToApp /> Đăng xuất
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}
export default withApollo(Header);

