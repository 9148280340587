import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout,LoginLayout } from './Layout';

import LoginPage from './Pages/LoginPage';
// pages
import DashboardPage from './Pages/DashboardPage';
import GroupAgentPage from './Pages/GroupAgentPage';
import DepartmentPage from './Pages/Department/DepartmentPage';
import DepartmentEditPage from './Pages/Department/DepartmentEditPage';
import AppointmentPage from './Pages/Appointment/AppointmentPage';
import AppointmentCreatePage from './Pages/Appointment/AppointmentCreatePage';
import AppointmentReportPage from './Pages/Appointment/AppointmentReportPage';
import AppointmentGeneralReportPage from './Pages/Appointment/AppointmentGeneralReportPage'
import AppointmentReportDetailPage from './Pages/Appointment/AppointmentReportDetailPage'
import ReportGeneralPage from './Pages/Report/ReportGeneralPage'
import ReportCallMonitorPage from './Pages/Report/ReportCallMonitorPage'
import CustomerPage from './Pages/Customer/CustomerPage';
import CustomerEditPage from './Pages/Customer/CustomerEditPage';
import NewCustomer from './Pages/Customer/NewCustomer';
import RingGroupPage from './Pages/RingGroup/RingGroupPage';
import HotNewsPage from './Pages/HotNews/HotNewsPage';
import ArticlePage from './Pages/Article/ArticlePage';
import InboundPage from './Pages/InboundPage';
import ProfilePage from './Pages/ProfilePage';
import SettingPage from './Pages/SettingPage';
import IvrPage from './Pages/Ivr/IvrPage'
import CallEventPage from './Pages/CallEventPage';
import MenuPage from './Pages/Menu/MenuPage';
import MenuEditPage from './Pages/Menu/MenuEditPage';
import AccountPage from './Pages/Account/AccountPage';
import AccountEditPage from './Pages/Account/AccountEditPage';
import SipAccountPage from './Pages/SipAccount/SipAccountPage';
import HotNewsEditPage from './Pages/HotNews/HotNewsEditPage';
import ArticleEditPage from './Pages/Article/ArticleEditPage';
import InboundReportByAgent from './Pages/Report/InboundReportByAgent';
import InboundReportByQueue from './Pages/Report/InboundReportByQueue';
import OutboundReportByAgent from './Pages/Report/OutboundReportByAgent';
import InboundReportServedPage from './Pages/Report/InboundReportServedPage';
import OutboundReportDetail from './Pages/Report/OutboundReportDetail';
import TestComponent from './Pages/TestComponent'


//import TestUploadFile from './Pages/Test/TestUploadFile';
import React from 'react';
import componentQueries from 'react-component-queries';
import { Redirect, Switch,BrowserRouter } from 'react-router-dom';
import 'styles/reduction.css';
import "react-table/react-table.css";
import "react-datepicker/dist/react-datepicker.css"
import "react-tabs/style/react-tabs.css";
import "pretty-checkbox/src/pretty-checkbox.scss"
import "react-month-picker/css/month-picker.css"
import { ApolloProvider } from 'react-apollo'
import {client} from '../index';
//import { BrowserRouter } from 'react-router-dom'
import Moment from 'moment'
import 'moment/locale/vi'
import BranchPage from './Pages/Branch/BranchPage';
import BranchEditPage from './Pages/Branch/BranchEditPage';
import DiagnosisPage from './Pages/Diagnosis/DiagnosisPage';
import DiagnosisDetail from './Pages/Diagnosis/DiagnosisDetail';
import DiagnosisPrint from './Pages/Diagnosis/DiagnosisPrint';
import PhieuTraKQXN from './Pages/LIMS/PhieuTraKQXN';
import kqxn1 from './Pages/Kqxn/kqxn1';
import kqxn2 from './Pages/Kqxn/kqxn2';
import kqxn from './Pages/Kqxn/kqxn';
import Lims from './Pages/LIMS/Lims';
import LimsDetail from './Pages/LIMS/LimsDetail'
import NstMau from './Pages/LIMS/NstMau';
import NstMauDetail from './Pages/LIMS/NstMauDetail';
const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};
class App extends React.Component {
  constructor(){
    Moment.locale("vi");    
    super();
    console.log("load AppCompany");
  }
  
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
      <ApolloProvider client={client}>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/login"
              layout={LoginLayout}
              component={props => (
                <LoginPage {...props} authState={STATE_LOGIN} />
              )}
            />
            <LayoutRoute
              exact
              path="/diagnosis-print"
              layout={EmptyLayout}
              component={DiagnosisPrint}
            />
            <LayoutRoute
              exact
              path="/kqxn1"
              layout={MainLayout}
              component={kqxn1}
            />
            <LayoutRoute
              exact
              path="/lims"
              layout={MainLayout}
              component={Lims}
            />
           <LayoutRoute
              exact
              path="/nstmau"
              layout={MainLayout}
              component={NstMau}
            />
            <LayoutRoute
              exact
              path="/nstmau-detail"
              layout={MainLayout}
              component={NstMauDetail}
            />
            <LayoutRoute
              exact
              path="/nstmau-detail/:_id"
              layout={MainLayout}
              component={NstMauDetail}
            />
            <LayoutRoute
              exact
              path="/phieu-tra-kqxn"
              layout={MainLayout}
              component={PhieuTraKQXN}
            />
            <LayoutRoute
              exact
              path="/phieu-tra-kqxn/:mabn"
              layout={MainLayout}
              component={PhieuTraKQXN}
            />
            <LayoutRoute
              exact
              path="/lims-detail"
              layout={MainLayout}
              component={LimsDetail}
            />
            <LayoutRoute
              exact
              path="/lims-detail/:_id"
              layout={MainLayout}
              component={LimsDetail}
            />
            <LayoutRoute
              exact
              path="/kqxn"
              layout={MainLayout}
              component={kqxn}
            />
           <LayoutRoute
              exact
              path="/menu"
              layout={MainLayout}
              component={MenuPage}
            />
            <LayoutRoute
              exact
              path="/menu-edit"
              layout={MainLayout}
              component={MenuEditPage}
            />
            <LayoutRoute
              exact
              path="/menu-edit/:_id"
              layout={MainLayout}
              component={MenuEditPage}
            />
            <LayoutRoute
              exact
              path="/department"
              layout={MainLayout}
              component={DepartmentPage}
            />
            <LayoutRoute
              exact
              path="/kqxn2"
              layout={MainLayout}
              component={kqxn2}
            />
            <LayoutRoute
              exact
              path="/inbound-report-by-agent"
              layout={MainLayout}
              component={InboundReportByAgent}
            />
            <LayoutRoute
              exact
              path="/outbound-report-by-agent"
              layout={MainLayout}
              component={OutboundReportByAgent}
            />
            <LayoutRoute
              exact
              path="/outbound-report-detail"
              layout={MainLayout}
              component={OutboundReportDetail}
            />
            <LayoutRoute
              exact
              path="/inbound-report-by-queue"
              layout={MainLayout}
              component={InboundReportByQueue}
            />
             <LayoutRoute
              exact
              path="/inbound-report-served"
              layout={MainLayout}
              component={InboundReportServedPage}
            />
            <LayoutRoute
              exact
              path="/hotnews"
              layout={MainLayout}
              component={HotNewsPage}
            />
            <LayoutRoute
              exact
              path="/hotnews-edit"
              layout={MainLayout}
              component={HotNewsEditPage}
            />
            <LayoutRoute
              exact
              path="/hotnews-edit/:_id"
              layout={MainLayout}
              component={HotNewsEditPage}
            />
            <LayoutRoute
              exact
              path="/article"
              layout={MainLayout}
              component={ArticlePage}
            />
            <LayoutRoute
              exact
              path="/article/:category"
              layout={MainLayout}
              component={ArticlePage}
            />
            <LayoutRoute
              exact
              path="/article-create"
              layout={MainLayout}
              component={ArticleEditPage}
            />
            <LayoutRoute
              exact
              path="/article-edit/:_id"
              layout={MainLayout}
              component={ArticleEditPage}
            />
            <LayoutRoute
              exact
              path="/branch"
              layout={MainLayout}
              component={BranchPage}
            />
            <LayoutRoute
              exact
              path="/branch-edit"
              layout={MainLayout}
              component={BranchEditPage}
            />
            <LayoutRoute
              exact
              path="/branch-edit/:_id"
              layout={MainLayout}
              component={BranchEditPage}
            />
            <LayoutRoute
              exact
              path="/ringgroup"
              layout={MainLayout}
              component={RingGroupPage}
            />
            <LayoutRoute
              exact
              path="/diagnosis"
              layout={MainLayout}
              component={DiagnosisPage}
            />
            <LayoutRoute
              exact
              path="/diagnosis-detail"
              layout={MainLayout}
              component={DiagnosisDetail}
            />
             <LayoutRoute
              exact
              path="/diagnosis-detail/:_id"
              layout={MainLayout}
              component={DiagnosisDetail}
            />
            <LayoutRoute
              exact
              path="/ivr"
              layout={MainLayout}
              component={IvrPage}
            />
            <LayoutRoute
              exact
              path="/sip-account"
              layout={MainLayout}
              component={SipAccountPage}
            />
            <LayoutRoute
              exact
              path="/account"
              layout={MainLayout}
              component={AccountPage}
            />
             <LayoutRoute
              exact
              path="/account-edit"
              layout={MainLayout}
              component={AccountEditPage}
            />
             <LayoutRoute
              exact
              path="/account-edit/:_id"
              layout={MainLayout}
              component={AccountEditPage}
            />
            <LayoutRoute
              exact
              path="/call-event"
              layout={MainLayout}
              component={CallEventPage}
            />
             <LayoutRoute
              exact
              path="/department-edit/:_id"
              layout={MainLayout}
              component={DepartmentEditPage}
            />
             <LayoutRoute
              exact
              path="/department-edit"
              layout={MainLayout}
              component={DepartmentEditPage}
            />
            <LayoutRoute
              exact
              path="/appointment"
              layout={MainLayout}
              component={AppointmentPage}
            />
            <LayoutRoute
              exact
              path="/report-call-monitor"
              layout={MainLayout}
              component={ReportCallMonitorPage}
            />
             <LayoutRoute
              exact
              path="/profile"
              layout={MainLayout}
              component={ProfilePage}
            />
             <LayoutRoute
              exact
              path="/inbound"
              layout={MainLayout}
              component={InboundPage}
            />
            <LayoutRoute
              exact
              path="/setting"
              layout={MainLayout}
              component={SettingPage}
            />
            <LayoutRoute
              exact
              path="/appointment-create"
              layout={MainLayout}
              component={AppointmentCreatePage}
            />
            <LayoutRoute
              exact
              path="/appointment-report"
              layout={MainLayout}
              component={AppointmentReportPage}
            />
            <LayoutRoute
              exact
              path="/appointment-general-report"
              layout={MainLayout}
              component={AppointmentGeneralReportPage}
            />
            <LayoutRoute
              exact
              path="/appointment-detail-report"
              layout={MainLayout}
              component={AppointmentReportDetailPage}
            />
            <LayoutRoute
              exact
              path="/appointment-create/:phoneNumber/:type/:callid"
              layout={MainLayout}
              component={AppointmentCreatePage}
            />
            <LayoutRoute
              exact
              path='/customer'
              layout={MainLayout}
              component={CustomerPage}
            />
            <LayoutRoute
              exact
              path='/test'
              layout={MainLayout}
              component={TestComponent}
            />
            <LayoutRoute
              exact
              path='/'
              layout={MainLayout}
              component={NewCustomer}
            />
            <LayoutRoute
              exact
              path='/customer-edit'
              layout={MainLayout}
              component={CustomerEditPage}
            />
            <LayoutRoute
              exact
              path='/customer-edit/:_id'
              layout={MainLayout}
              component={CustomerEditPage}
            />
            <LayoutRoute
              exact
              path='/groupagent'
              layout={MainLayout}
              component={GroupAgentPage}
            />             
            <LayoutRoute
              exact
              path="/dashboard"
              layout={MainLayout}
              component={DashboardPage}
            />
            <LayoutRoute
              exact
              path="/report-general"
              layout={MainLayout}
              component={ReportGeneralPage}
            />
            
            
            <Redirect to="/" />
          </Switch>
        </GAListener>
        </ApolloProvider>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
