import Page from 'Admin/Components/Page';
import React from 'react';
import { Button,Input,Row,Col,Label,Form,Card,CardHeader,CardBody,ButtonGroup ,FormGroup} from 'reactstrap';
import { withApollo } from 'react-apollo'
import {MdDelete,MdAddCircle,MdEdit} from 'react-icons/lib/md';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo'
import ReactTable from "react-table";
import authCheck from 'utils/authCheck';

export const QUERY_DETAIL = gql`
    query AdminQuery($_id: String){
        template(_id:$_id){
            message
            code
            data{
                _id
                description
                name
                type
                details{
                data
                keyword
                flags
                }
            }
        }
    }
`
export const MUTATION_CREATE= gql`
    mutation AdminMutation($template: TemplateInputType!){
        createTemplate(template:$template){
            message
            code
            data{_id}
        }
    }
`
export const MUTATION_EDIT = gql`
mutation AdminMutation($template: TemplateInputType!){
    updateTemplate(template:$template){
        message
        code
        data{_id}
    }
}
`

class TemplateDetailPage extends React.Component{
    constructor(){
        super();
        this.state={
            name:"",
            description:"",
            type:"pjsip-endpoint",
            details:[],
            editKeyword:"",
            editData:"",
            editFlags:0
        };
    }
    componentDidMount(){
        const _id = this.props.match.params._id;
        if(_id&&_id.length>0){
            this.props.client.query(
                {
                    query:QUERY_DETAIL,
                    variables:{_id:_id}
                }
            ).then(result=>{
                authCheck(result);
                if(result.data.template!==null){
                    this.setState(result.data.template.data);
                }
            });
        }
    }

    onCancel=()=>{
        this.props.history.push("/template");
    }

    render=()=>{
        const{_id,name,description,details,type,editData,editFlags,editKeyword}=this.state;
        
        return(
            <Page title="Chi tiết Template"
            breadcrumbs={[{ name: 'Chi tiết Template', active: true }]}            
            className="template"> 
                <Row>
                <Col md={12}>
                    <Mutation
                        mutation= {(_id===undefined||_id.length===0)?MUTATION_CREATE:MUTATION_EDIT}
                        variables={{template:{_id,name,description,type,details}}}
                        onCompleted={(result)=>{this.onCancel()}}
                    >
                        {mutation=><Button color="primary" onClick={mutation}>Cập nhật thông tin</Button>}
                    </Mutation>{" "}<Button color="link" onClick={this.onCancel}>Hủy</Button>
                </Col>
                
                </Row>
                
                <Row>
                    <Col md={6} xs={12} sm={12}>
                        <Card>
                            <CardHeader className="bg-dark text-white">Thông tin cơ bản</CardHeader>
                            <CardBody>
                                <Form className="m-3">
                                    <FormGroup row>
                                        <Label md={2} sm={4} xs={6} className="text-right">Tên:</Label>
                                        <Col md={10} sm={8} xs={6}>
                                            <Input type="text" value={name} onChange={e=>{var validateName=e.target.value.trim() ;this.setState({name:validateName})}} bsSize="sm"/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label md={2} sm={4} xs={6} className="text-right">Loại:</Label>
                                        <Col md={10} sm={8} xs={6}>
                                            <Input type="select" value={type} bsSize="sm" onChange={e=>{this.setState({type:e.target.value})}}>                                                
                                                <option value="pjsip-endpoint">PJSIP Endpoint</option>
                                                <option value="pjsip-aor">PJSIP Aor</option>
                                                <option value="identify">Identify</option>
                                                <option value="pjsip-auth">PJSIP Auth</option>
                                                <option value="pjsip-trunk-endpoint">PJSIP Trunk Endpoint</option>
                                                <option value="pjsip-trunk-aor">PJSIP Trunk Aor</option>
                                                <option value="pjsip-trunk-auth">PJSIP Trunk Auth</option>
                                                <option value="pjsip-trunk-registration">PJSIP Trunk Registration</option>
                                                <option value="extension">Extension</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label md={2} sm={4} xs={6} className="text-right">Mô tả:</Label>
                                        <Col md={10} sm={8} xs={6}>
                                            <Input type="textarea" value={description} bsSize="sm" onChange={e=>{this.setState({description:e.target.value})}}/>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                        
                    </Col>
                    <Col md={6} xs={12} sm={12}>
                        <Card>
                            <CardHeader className="bg-dark text-white">Thuộc tính</CardHeader>
                            <CardBody>                                
                                <Form className="mt-2">                                    
                                    <FormGroup row>
                                        <Col md={4}>
                                            <Input value={editKeyword} onChange={(e)=>{this.setState({editKeyword:e.target.value})}} bsSize="sm" placeholder="keyword"/>
                                        </Col>
                                        <Col md={4}>
                                            <Input value={editData} bsSize="sm" onChange={e=>{this.setState({editData:e.target.value})}} placeholder="value"/>
                                        </Col>
                                        <Col md={2}>
                                            <Input value={editFlags} bsSize="sm" onChange={e=>{this.setState({editFlags:e.target.value})}} type="number" placeholder="flags"/>
                                        </Col>
                                        <Col md={2}>
                                            <Button color="primary" onClick={e=>{
                                                var newDetails = details.filter(function(item,index,arr){
                                                    return item.keyword!==editKeyword
                                                });
                                                newDetails.push({keyword:editKeyword,data:editData,flags:editFlags});this.setState({details:newDetails,editData:"",editFlags:0,editKeyword:""}) 
                                            }}><MdAddCircle/> Thêm</Button>
                                        </Col>
                                    </FormGroup>
                                </Form>
                                <ReactTable
                                    noDataText="Data is Empty"
                                    data={details}
                                    showPagination={false}
                                    pageSize={details?details.length:1}
                                    defaultSorted={[
                                                {
                                                    id: "keyword",
                                                    desc: false
                                                }
                                            ]}
                                    columns={[
                                        {
                                           Header:"keyword",
                                           accessor:"keyword"
                                        },
                                        {
                                           Header:"data",
                                           accessor:"data"
                                        },
                                        {
                                           Header:"flags",
                                           accessor:"flags",
                                           width:80
                                        },
                                        {
                                            Header:"",
                                            width:90,
                                            Cell:(row)=>(<ButtonGroup>
                                                <Button onClick={e=>{this.setState({editData:row.original.data,editKeyword:row.original.keyword,editFlags:row.original.flags})}}><MdEdit/></Button>{" "}
                                                <Button onClick={
                                                    e=>{
                                                        var newDetails=details.filter(function(item,index,arr){return index!==row.index});
                                                        this.setState({details:newDetails});
                                                    }
                                                }><MdDelete/></Button>
                                            </ButtonGroup>)
                                        }
                                    ]}
                                />                                
                               
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>
        )
    }
}
export default withApollo(TemplateDetailPage);