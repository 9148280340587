import Page from 'components/Page';
import React from 'react';
import { Button,Row,Col,Card,CardHeader,CardBody,Label,Input,Form,FormGroup } from 'reactstrap';
import ToggleButton from 'react-toggle-button'
import gql from 'graphql-tag';
import { Mutation} from 'react-apollo'
import {MdDateRange,MdDelete,MdAddCircle} from 'react-icons/lib/md';
import Autosuggest from 'react-autosuggest';
import { WithContext as ReactTags } from 'react-tag-input';
import { withApollo } from 'react-apollo'
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import {GetLocalToken} from 'companyConstants'
import authCheck from '../../../utils/authCheck';

const KeyCodes = {
    comma: 188,
    enter: 13,
  };
   
const delimiters = [KeyCodes.comma, KeyCodes.enter];
 
export const DEPARTMENT_DETAIL=gql`
    query Department($_id:String!){
        department(_id:$_id){
            data{
                _id
                agents{
                    _id
                    name
                }
                leaders{
                    _id
                    name
                }
                createdTime
                description
                name
                groupAgentId
                status
                workTimeGroups{
                    daysOfWeek
                    daysOfYear
                    isStopDay
                    maxProcess
                    priority
                    repeatYearly
                    repeatWeekly
                    startDate
                    timeRanges
                }
            }
        }
    }
`
export const ACCOUNT_FETCH=gql`
  query Account{
      accounts{
          data{
            _id
            name
            userName
            
          }
      }
  }
`
export const GROUPAGENT_FETCH=gql`
  query GroupAgent{
    groupAgents{
        data{
            _id
            name
        }
    }
  }
`
export const CREATE_QUERY=gql`
    mutation Mutation($department:DepartmentInput!){
        createDepartment(department:$department){
           data{ _id}
        }
    }
`
export const UPDATE_QUERY=gql`
mutation Mutation($department:DepartmentInput!){
    updateDepartment(department:$department){
        data{_id}
    }
}
`
const getSuggestionValue = suggestion => suggestion.name;
// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div className="account-select">
      {suggestion.name}
    </div>
  );
class DepartmentEditPage extends React.Component{
    constructor(props){
        super(props);
        this.datePickers=[];
        this.state = {            
            timeSuggestions: [],
             tmpAgent:'',
             tmpLeader:'',
             agents:[],
             leaders:[],
             accounts:[],
             accountSuggests:[],
             workTimeGroups:[],
             groupAgents:[],
             name:'',
             description:'',
             groupAgentId:'',
             _id:'',
             isEdit:false
        };
        this.handleDelete = this.handleTimeDelete.bind(this);
        this.handleAddition = this.handleTimeAddition.bind(this);
        this.handleDrag = this.handleTimeDrag.bind(this);
        var token = GetLocalToken();
        const {setting}=token;
        console.log("setting="+JSON.stringify(setting));
        this.state.timeSuggestions=setting.workTimes.map((item)=>{return {id:item,text:item}});  
    }

    componentDidMount(){       
        this.props.client.query({
            query: ACCOUNT_FETCH                
        }).then(result=>{            
            this.setState({accounts:result.data.accounts.data})
        })
        this.props.client.query(
            {query:GROUPAGENT_FETCH}
        ).then(result=>{
            this.setState({groupAgents:result.data.groupAgents.data});
        })
        const _id = this.props.match.params._id;
        if(_id){
            this.setState({isEdit:true,_id:_id});
            //fetch detail
            this.props.client.query({
                query:DEPARTMENT_DETAIL,
                variables:{_id:_id},
                
            }).then(result=>{
                authCheck(result);
                const department=result.data.department.data;
                const {workTimeGroups} = department;
                workTimeGroups.forEach(function(workTime){
                    
                    workTime.timeRanges=workTime.timeRanges.map((time,index)=>{return {id:time,text:time}});
                    workTime.daysOfYear = workTime.daysOfYear.map((day,index)=>{return {id:day,text:day}});                    
                    
                });
                this.setState(department);
            });
        }
    }
    handleTimeDelete(i,workTime) {        
        workTime.timeRanges=workTime.timeRanges.filter((time,index)=>index!==i);
        this.setState({change:true});
    }
    handleDaysOfYearDelete(i,workTime){
        workTime.daysOfYear = workTime.daysOfYear.filter((time,index)=>index!==i);
        this.setState({change:true});
    }
    
    getAccountSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const {accounts}=this.state;
        
        return inputLength === 0 ? [] : accounts.filter(acc =>
          acc.name.toLowerCase().slice(0, inputLength) === inputValue
          ||acc.userName.toLowerCase().slice(0,inputLength)===inputValue
        );
      };
    handleTimeAddition(tag,workTime) {
        workTime.timeRanges= [...workTime.timeRanges,tag];
        this.setState({change:true});
    }
    onLeaderSearchChange = (event, { newValue }) => {
        this.setState({
            tmpLeader: newValue
        });
    };
    onAgentSearchChange = (event, { newValue }) => {
        this.setState({
            tmpAgent: newValue
        });
    };
     
      // Autosuggest will call this function every time you need to update suggestions.
      // You already implemented this logic above, so just use it.
    onLeadersFetchRequested = ({ value }) => {
        this.setState({
            accountSuggests: this.getAccountSuggestions(value)
        });
    };
    
    // Autosuggest will call this function every time you need to clear suggestions.
    onLeadersClearRequested = () => {        
        this.setState({
            accountSuggests: []
        });
    };
    onLeaderSelected=(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method })=>{
        const {leaders} = this.state;
        leaders.push(suggestion);
        this.setState({
            tmpLeader: ''
        });
    }
    onDeleteWorkTime=(i)=>{
        const {workTimeGroups} = this.state;
        workTimeGroups.splice(i,1);
        this.setState({change:true});
    }
    onAgentSelected=(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method })=>{
        const {agents} = this.state;
        agents.push(suggestion);
        this.setState({
            tmpAgent: ''
        });
    }
    handleTimeDrag(tag, currPos, newPos,workTime) {
        // const tags = [...this.state.tags];
        const tags =workTime.timeRanges;
        const newTags = tags.slice();
 
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
 
        // re-render
        //this.setState({ timeRanges: newTags });
        workTime.timeRanges = newTags;
        this.setState({change:true});
    }
    onCancel=()=>{
        this.props.history.push("/department");
    }
    handleRefClick=(refName,event)=>{
        
        this.refs[refName].click();
    }
    render=()=>{
        const {leaders,agents,  accountSuggests,timeSuggestions,tmpLeader,tmpAgent,workTimeGroups,status } = this.state;
        const{_id,name,description,groupAgentId}=this.state;
        // Autosuggest will pass through all these props to the input.
        const leaderInputProps = {
            placeholder: 'Nhập tên tài khoản muốn thêm',
            value:tmpLeader,
            onChange: this.onLeaderSearchChange
        };
        const agentInputProps = {
            placeholder: 'Nhập tên tài khoản muốn thêm',
            value:tmpAgent,
            onChange: this.onAgentSearchChange
        };
        return(
            <Page
                title="Chỉnh sửa Phòng/Ban"
                breadcrumbs={[{ name: 'Chỉnh sửa Phòng/Ban', active: true }]}
                className="TablePage" 
            >
                <Row>
                    <Col md="12" xs="12" sm="12">
                        <Mutation
                            mutation={this.state.isEdit?UPDATE_QUERY: CREATE_QUERY}
                            variables={{department:{name,description,groupAgentId,_id,status,
                                leaders:leaders.map((leader,index)=>{return leader._id;}),
                                agents: agents.map((agent,index)=>{return agent._id}),
                                workTimeGroups:workTimeGroups.map((workTime,index)=>{
                                    return {
                                        startDate:workTime.startDate,
                                        daysOfWeek:workTime.daysOfWeek,
                                        daysOfYear:workTime.daysOfYear.map((item,i)=>{return item.text}),
                                        maxProcess:workTime.maxProcess,
                                        isStopDay:workTime.isStopDay,
                                        priority:workTime.priority,
                                        repeatWeekly:workTime.repeatWeekly,
                                        repeatYearly:workTime.repeatYearly,
                                        timeRanges:workTime.timeRanges.map((item,i)=>{return item.text})
                                    };
                                })
                            }}}
                            onCompleted={(result)=>{
                                this.props.history.push("/department");
                            }}
                        >
                            {
                                mutation=>(
                                    <Button color="warning" onClick={mutation}>Lưu thông tin</Button>
                                )
                            }
                        </Mutation>
                        {" "}
                        <Button onClick={this.onCancel} color="link">Quay lại</Button>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" xs="12" sm="12">
                        <Card>
                            <CardHeader>
                                <h3>Thông tin phòng/ban</h3>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <FormGroup>
                                        <Label>
                                            Tên phòng ban
                                        </Label>
                                        <Input bsSize="sm" value={name} placeholder="Tên phòng" onChange={e=>{this.setState({name:e.target.value})}}/>
                                    </FormGroup>                                    
                                    <FormGroup>
                                        <Label>
                                            Mô tả
                                        </Label>
                                        <Input bsSize="sm" placeholder="Mô tả nhiệm vụ" value={description} onChange={e=>{this.setState({description:e.target.value})}}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>
                                            Tổng đài chăm sóc khách hàng
                                        </Label>
                                        <Input bsSize="sm" type="select" value={groupAgentId} onChange={e=>{this.setState({groupAgentId:e.target.value})}}>
                                            <option value={""} >Lựa chọn tổng đài chăm sóc</option>
                                            {
                                                this.state.groupAgents.map((groupAgent,index)=>(
                                                    <option value={groupAgent._id} key={groupAgent._id}>{groupAgent.name}</option>
                                                ))
                                            }
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>
                                            Cán bộ quản lý
                                        </Label>
                                        <Autosuggest
                                            suggestions={accountSuggests}
                                            onSuggestionsFetchRequested={this.onLeadersFetchRequested}
                                            onSuggestionsClearRequested={this.onLeadersClearRequested}
                                            onSuggestionSelected={this.onLeaderSelected}
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={leaderInputProps}
                                        />
                                        <Input
                                                type="select"
                                                name="selectMulti"
                                                multiple
                                            >
                                            {
                                                leaders.map((leader,index)=>(
                                                    <option onClick={e=>{
                                                        var newleaders = leaders.filter((item,index)=>{
                                                            return item._id!==leader._id;
                                                        })
                                                        this.setState({leaders:newleaders});
                                                    }} key={leader._id}>{leader.name}</option>
                                                ))
                                            }
                                                
                                        </Input>
                                
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>
                                            Tổng đài viên
                                        </Label>
                                        <Autosuggest
                                            suggestions={accountSuggests}
                                            onSuggestionsFetchRequested={this.onLeadersFetchRequested}
                                            onSuggestionsClearRequested={this.onLeadersClearRequested}
                                            onSuggestionSelected={this.onAgentSelected}
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={agentInputProps}
                                        />
                                        <Input
                                                type="select"
                                                name="selectMulti"
                                                multiple
                                            >
                                            {
                                                agents.map((agent,index)=>(
                                                    <option onClick={e=>{
                                                        var newagents = agents.filter((item,index)=>{
                                                            return item._id!==agent._id;
                                                        })
                                                        this.setState({agents:newagents});
                                                    }} key={agent._id}>{agent.name}</option>
                                                ))
                                            }
                                                
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>
                                            Trạng thái
                                        </Label>
                                    <ToggleButton
                                        value={ this.state.status}
                                        onToggle={(value) => {                                            
                                            this.setState({status:!value});
                                        }} />
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6" xs="12" sm="12">
                        <Card>
                            <CardHeader>
                                <h3>Lịch làm việc</h3>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <Button onClick={
                                            ()=>{workTimeGroups.push(
                                                {
                                                    timeRanges:[],
                                                    daysOfYear:[],
                                                    isStopDay:false,
                                                    priority:1,
                                                    repeatWeekly:false,
                                                    repeatYearly:false,
                                                    maxProcess:6,
                                                    daysOfWeek:[false,false,false,false,false,false,false]});
                                                    this.setState({workTimeGroups:workTimeGroups})}}>
                                            <MdAddCircle/> Thêm</Button>
                                    </Col>
                                </Row>                                
                                
                                {
                                    workTimeGroups.map((workTime,index)=>(
                                        <Form key={index}>
                                            <hr/>
                                            <FormGroup row>
                                                <Label md={4}>
                                                    Thời gian làm việc
                                                </Label>
                                                <Col md={8}>
                                                    <ReactTags tags={workTime.timeRanges}
                                                        suggestions={timeSuggestions}
                                                        handleDelete={i=>{this.handleTimeDelete(i,workTime)}}
                                                        handleAddition={value=>{this.handleTimeAddition(value,workTime)}}
                                                        handleDrag={(tag, currPos, newPos)=>{this.handleTimeDrag(tag, currPos, newPos,workTime)}}
                                                        delimiters={delimiters} />  
                                                </Col>                                                                            
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label md={4}>Xử lý tối đa</Label>
                                                <Col md={8}>
                                                    <Input bsSize="sm" type="number" value={workTime.maxProcess} onChange={(e)=>{workTime.maxProcess=e.target.value;this.setState({change:true})}}/>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label md={4}>Ngày bắt đầu</Label>
                                                <Col md={8}>
                                                    <Input bsSize="sm" type="date" value={Moment(workTime.startDate).format("YYYY-MM-DD")} onChange={(e)=>{
                                                        
                                                        workTime.startDate=e.target.value;this.setState({change:true})}}/>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label md={4}>
                                                    Đặt làm ngày nghỉ
                                                </Label>
                                                <Col md={8} className="toggle-switch">
                                                    <ToggleButton
                                                        value={ workTime.isStopDay || false }
                                                        onToggle={(value) => {
                                                            workTime.isStopDay=!workTime.isStopDay;
                                                            this.setState({change:true});
                                                        }} />
                                                </Col>                                                
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label md={4}>
                                                    Lặp các ngày trong tuần
                                                </Label>
                                                <Col md={8} className="toggle-switch">
                                                    <ToggleButton
                                                        value={ workTime.repeatWeekly || false }
                                                        onToggle={(value) => {
                                                            workTime.repeatWeekly=!workTime.repeatWeekly;
                                                            this.setState({change:true});
                                                        }} />
                                                </Col>       
                                            </FormGroup>
                                            {
                                                !workTime.repeatWeekly?"":(
                                                    <div style={{padding:"0 0 15px 60px"}}>
                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input type="checkbox" checked={workTime.daysOfWeek[1]} onChange={e=>{workTime.daysOfWeek[1]=e.target.checked;this.setState({change:true})}}/> Thứ 2
                                                            </Label>                                        
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input type="checkbox" checked={workTime.daysOfWeek[2]} onChange={e=>{workTime.daysOfWeek[2]=e.target.checked;this.setState({change:true})}}/> Thứ 3
                                                            </Label>                                        
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input type="checkbox" checked={workTime.daysOfWeek[3]} onChange={e=>{workTime.daysOfWeek[3]=e.target.checked;this.setState({change:true})}}/> Thứ 4
                                                            </Label>                                        
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input type="checkbox" checked={workTime.daysOfWeek[4]} onChange={e=>{workTime.daysOfWeek[4]=e.target.checked;this.setState({change:true})}}/> Thứ 5
                                                            </Label>                                        
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input type="checkbox" checked={workTime.daysOfWeek[5]} onChange={e=>{workTime.daysOfWeek[5]=e.target.checked;this.setState({change:true})}}/> Thứ 6
                                                            </Label>                                        
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input type="checkbox" checked={workTime.daysOfWeek[6]} onChange={e=>{workTime.daysOfWeek[6]=e.target.checked;this.setState({change:true})}}/> Thứ 7
                                                            </Label>                                        
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input type="checkbox" checked={workTime.daysOfWeek[0]} onChange={e=>{workTime.daysOfWeek[0]=e.target.checked;this.setState({change:true})}}/> Chủ nhật
                                                            </Label>                                        
                                                        </FormGroup>
                                                    </div>
                                                )
                                            }
                                            
                                            <FormGroup row>
                                                <Label md={4}>
                                                    Lặp các ngày trong năm
                                                </Label>
                                                <Col md={8} className="toggle-switch">
                                                    <ToggleButton
                                                        value={ workTime.repeatYearly || false }
                                                        onToggle={(value) => {
                                                            workTime.repeatYearly=!workTime.repeatYearly;
                                                            this.setState({change:true});
                                                        }} />
                                                </Col>       
                                            </FormGroup>
                                            {
                                                !workTime.repeatYearly?"":(
                                                    <FormGroup row>
                                                        <Label md={4} for="exampleDatetime">Chọn ngày</Label>
                                                        <Col md={8}>
                                                            <div style={{margin:"4px"}}>
                                                            <DatePicker 
                                                                selected={this.state.startDate}
                                                                customInput={<Button><MdDateRange/></Button>}
                                                                onChange={e=>{this.setState({startDate:e});
                                                                var datestr=Moment(e).format('DD/MM');
                                                                workTime.daysOfYear.push({id:datestr,text:datestr})}}
                                                            /></div>
                                                            
                                                            <div class="hideTagInput">
                                                                <ReactTags tags={workTime.daysOfYear}                                                            
                                                                    handleDelete={i=>{this.handleDaysOfYearDelete(i,workTime)}}
                                                                    delimiters={delimiters} /> 
                                                            </div>
                                                        </Col>                                                        
                                                    </FormGroup>
                                                )
                                            }       
                                           
                                            <FormGroup row>
                                                <Label md={4}>
                                                Ưu tiên hiển thị
                                                </Label>
                                                <Col md={8}>
                                                    <Input bsSize="sm" type="number" defaultValue="1"/>
                                                </Col>
                                            </FormGroup>
                                            <Button onClick={(e)=>{this.onDeleteWorkTime(index)}}><MdDelete/> Xóa</Button>
                                        </Form>
                                    ))
                                }
                                 
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>
        )
    }
}
export default withApollo(DepartmentEditPage);