import Page from 'components/Page';
import React from 'react';
import { Button,FormGroup,Col,Label,Input,Row} from 'reactstrap';
import { withApollo,Query } from 'react-apollo'
import {MdSearch,MdImportExport} from 'react-icons/lib/md';
import gql from 'graphql-tag';
import authCheck from 'utils/authCheck';
import Moment, { duration } from 'moment';
import { CounterWidget } from 'components/Widget';
import ReactTable from "react-table";
import {GetLocalToken,apiHost} from 'companyConstants'
import { filter } from 'async';

export const REPORT_QUERY = gql`
query CdrReport($endDate:String,$startDate:String,$agents:[String]){
    cdrReportByAgent(startDate:$startDate,endDate:$endDate,agents:$agents,isOutbound:"true"){
      code
      message
      data{
        agent{
          fullName
        }
        agentExtension
        appointments
        calls
        avgDuration
        duration
        longest
        shortest
        servedCalls
        servedAppointments      
      }
      
    }
  }
`;
class OutboundReportByAgent extends React.Component{

    state = {
        startTime: new Date(),
        endTime: new Date(),
        agents:[]
    }
    render=()=>{
        const {endTime,startTime} = this.state;
        return(
            <Page
                title="Báo cáo theo Tổng Đài Viên"
                breadcrumbs={[{ name: 'QUẢN LÝ FILE GHI ÂM', active: true }]}            
                >
                <Query
                    query={REPORT_QUERY}
                    variables={{ startDate: Moment(startTime).format("DD/MM/YYYY"), endDate: Moment(endTime).format("DD/MM/YYYY") }}
                >
                    {
                        ({loading,data,refetch})=>{
                            var result = [];
                            authCheck(data);
                            try {
                                result = data.cdrReportByAgent.data;     
                                console.log("result="+JSON.stringify(result));                          
                            } catch (err) { }
                            return(
                                <div>
                                    <Row style={{ padding: "1rem" }}>
                                        <Col md={3} sm={6} xs={12}>
                                            <FormGroup>
                                                <Label>Ngày bắt đầu</Label>
                                                <Input value={Moment(this.state.startTime).format("YYYY-MM-DD")} type="date" bsSize="sm" onChange={e => this.setState({ startTime: e.target.value })} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} sm={6} xs={12}>
                                            <FormGroup>
                                                <Label>
                                                    Ngày kết thúc
                                                </Label>
                                                <Input value={Moment(this.state.endTime).format("YYYY-MM-DD")} type="date" bsSize="sm" onChange={e => this.setState({ endTime: e.target.value })} />
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <center>
                                        <Button className="mr-4" size="lg" color="blue" onClick={e => refetch()}><MdSearch /> Làm mới</Button>
                                        <Button className="mr-4 text-white" size="lg" color="blue"><MdImportExport /> Xuất Excel</Button>
                                    </center>
                                    <ReactTable
                                        noDataText="Không có dữ liệu"
                                        className="mt-3"
                                        showPagination={false}
                                        pageSize={result.length}
                                        data={result}
                                        columns={[
                                            {
                                                Header:"Điện thoại viên",
                                                accessor:"agent.fullName",
                                                Cell:row=><span>
                                                    {row.value} ({row.original.agentExtension})
                                                </span>
                                            },
                                            {
                                                Header:"Cuộc gọi",
                                                width:70,
                                                accessor:"calls"
                                            },
                                            {
                                                Header:"Cuộc gọi được pv",
                                                accessor:"servedCalls"
                                            },
                                            {
                                                Header:"Cuộc gọi dài nhất",
                                                accessor:"longest",
                                                Cell:row=><span>
                                                    {Moment("1900-01-01").add(row.value,'seconds').format("HH:mm:ss")}                   
                                                </span>
                                            },
                                            {
                                                Header:"Cuộc gọi ngắn nhất",
                                                accessor:"shortest",
                                                Cell:row=><span>
                                                    {Moment("1900-01-01").add(row.value,'seconds').format("HH:mm:ss")}                   
                                                </span>
                                            },
                                            {
                                                Header:"Cuộc gọi nhỡ",
                                                accessor:"servedCalls",
                                                Cell:row=><span>
                                                    {row.original.calls-row.value}
                                                </span>
                                            },
                                            {
                                                Header:"Thời lượng đàm thoại",
                                                accessor:"duration",
                                                Cell:row=><span>
                                                    {Moment("1900-01-01").add(row.value,'seconds').format("HH:mm:ss")}                   
                                                </span>
                                            },
                                            {
                                                Header:"Thời lượng đàm thoại trung bình",
                                                accessor:"avgDuration",
                                                Cell:row=><span>
                                                    {Moment("1900-01-01").add(row.value,'seconds').format("HH:mm:ss")}                   
                                                </span>     
                                            },
                                            {
                                                Header:"Tổng số đặt khám",
                                                accessor:"appointments"
                                            },
                                            {
                                                Header:"Đặt khám đã xử lý",
                                                accessor:"servedAppointments"
                                            }

                                        ]}
                                        
                                        >
                                    </ReactTable>

                                </div>
                            )
                        }
                    }
                </Query>
            </Page>
        )
    }

}

export default withApollo(OutboundReportByAgent);