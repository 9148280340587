import Page from 'components/Page';
import React from 'react';
import { Button, Input, Row, Col, Label, Card, CardHeader, CardBody, FormGroup, Badge } from 'reactstrap';
import { withApollo, Query } from 'react-apollo'
import { MdSearch, MdImportExport, MdReport, MdCheckCircle, MdClose, MdCheckBoxOutlineBlank } from 'react-icons/lib/md';
import gql from 'graphql-tag';
import authCheck from 'utils/authCheck';
import Moment from 'moment';
import { CounterWidget } from 'components/Widget';
import ReactTable from "react-table";
import { GetLocalToken } from 'companyConstants'
import { filter } from 'async';

export const FEED_DEPARTMENT = gql`
    query Department{
        departments{
            _id            
            name
          }
    }
`
export const QUERY_REPORT = gql`
query cdrReport($startDate:String,$endDate:String){
	cdrGeneralReport(startDate:$startDate,endDate:$endDate){
    code
    message
    data{
      inbound{
        appointments
        calls
        misCalls
      }
      outbound{
        calls
        appointments
        misCalls
        
      }
    }
  }
}
`
class ReportGeneralPage extends React.Component {
    state = {
        startTime: new Date(),
        endTime: new Date(),
    }
    componentDidMount() {
        // this.fetchData(true);
    }
    
    render = () => {
        const { startTime, endTime } = this.state;
        return (
            <Page
                title="Báo cáo tổng quát gọi vào - ra"
                breadcrumbs={[{ name: 'Báo cáo tổng quát gọi vào - ra', active: true }]}
                className="AppointmentReportPage">
                <Query
                    query={QUERY_REPORT}
                    variables={{ startDate: Moment(startTime).format("DD/MM/YYYY"), endDate: Moment(endTime).format("DD/MM/YYYY") }}
                >
                    {
                        ({ loading, data, refetch }) => {
                            var result = [];
                            authCheck(data);
                            try {
                                result = [data.cdrGeneralReport.data];
                                console.log("result="+JSON.stringify(result));
                            } catch (err) { }
                            return (
                                <div>
                                    <Row style={{ padding: "1rem" }}>
                                        <Col md={3} sm={6} xs={12}>
                                            <FormGroup>
                                                <Label>Ngày bắt đầu</Label>
                                                <Input value={Moment(this.state.startTime).format("YYYY-MM-DD")} type="date" bsSize="sm" onChange={e => this.setState({ startTime: e.target.value })} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} sm={6} xs={12}>
                                            <FormGroup>
                                                <Label>
                                                    Ngày kết thúc
                                                </Label>
                                                <Input value={Moment(this.state.endTime).format("YYYY-MM-DD")} type="date" bsSize="sm" onChange={e => this.setState({ endTime: e.target.value })} />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label></Label>
                                                <div>
                                                <Button className="mr-4 text-white" color="dark"><MdImportExport /> Xuất Excel</Button>
                                                </div>
                                            </FormGroup>
                                            
                                        </Col>
                                    </Row>
                                    
                                    <ReactTable
                                        noDataText="Không có dữ liệu"
                                        className="mt-3"
                                        showPagination={false}
                                        pageSize={1}
                                        data={result}
                                        style={{fontSize:14}}
                                        columns={[
                                            {
                                                Header: "Outbound",
                                                columns: [

                                                    {
                                                        Header: "Tổng gọi ra",
                                                        className: "text-center",
                                                        accessor:"outbound.calls",
                                                        style:{fontWeight:"bold"}
                                                    },
                                                    {
                                                        Header: "SL cuộc gọi kết nối",
                                                        className: "text-center",
                                                        accessor:"outbound.misCalls",
                                                        Cell:row=><span>
                                                            {(row.original.outbound.calls-row.value)}
                                                        </span>
                                                    },
                                                    {
                                                        Header: "Tổng số phiếu",
                                                        className: "text-center",
                                                        accessor:"outbound.appointments"
                                                    },

                                                ]
                                            },

                                            {
                                                Header: "Inbound",
                                                className: "text-center",
                                                columns: [
                                                    {
                                                        Header: "Tổng gọi vào",
                                                        className: "text-center",
                                                        accessor:"inbound.calls",
                                                        style:{fontWeight:"bold"}
                                                    },
                                                    {
                                                        Header: "SL cuộc gọi được phục vụ",
                                                        className: "text-center",
                                                        accessor:"inbound.misCalls",
                                                        Cell:row=><span>
                                                            {row.original.inbound.calls-row.value}
                                                        </span>
                                                    },
                                                    {
                                                        Header: "SL cuộc gọi nhỡ",
                                                        className: "text-center",
                                                        accessor:"inbound.misCalls"
                                                    },
                                                    {
                                                        Header: "Tổng số phiếu",
                                                        className: "text-center",
                                                        accessor:"inbound.appointments"
                                                    },
                                                ]
                                            }
                                        ]}
                                    >

                                    </ReactTable>

                                </div>
                            )
                        }
                    }
                </Query>

            </Page>

        )
    }
}

export default withApollo(ReportGeneralPage);