import gql from 'graphql-tag';

export const CREATE_NSTMAU=gql`
mutation CreateNSTMAU($nSTMau:NSTMauInput){
  response:createNSTMau(nSTMau:$nSTMau){
    code
    message
    data{
      _id
    }
  }
}
`
export const DELETE_NSTMAU=gql`
mutation DeleteNSTMau($_id:String!){
  response:deleteNSTMau(_id:$_id){
    code
    message
    data
  }
}
`
export const GET_NSTMAU = gql`
query NSTMau($_id:String!){
  response:nSTMau(_id:$_id){
    code
    message
    data{
      _id
      ngay_lay_mau
      ngay_tra_kq
      nguoi_cay_mau
      nguoi_lay_mau
      nu{
        nghe_nghiep
        ho_va_ten
        bs_chi_dinh
        dia_chi
        so_dien_thoai
        ly_do_kham_benh
        mabn
        ngay_sinh        
      }
      nam{
        nghe_nghiep
        ho_va_ten
        bs_chi_dinh
        dia_chi
        so_dien_thoai
        ly_do_kham_benh
        mabn
        ngay_sinh        
      }
    }
  }
}
`
export const QUERY_NSTMAUS=gql`
query NSTMaus($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
  response:nSTMaus(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
    code
    message
    data{
      _id
      ngay_lay_mau
      ngay_tra_kq
      nguoi_cay_mau
      nguoi_lay_mau
      nu{
        nghe_nghiep
        ho_va_ten
        bs_chi_dinh
        dia_chi
        so_dien_thoai
        ly_do_kham_benh
        mabn
        ngay_sinh        
      }
      nam{
        nghe_nghiep
        ho_va_ten
        bs_chi_dinh
        dia_chi
        so_dien_thoai
        ly_do_kham_benh
        mabn
        ngay_sinh        
      }
    }
  }
}
`

export const CREATE_LIMS=gql`
mutation createLims($lims:LIMSInput){
  response:createLIMS(lims:$lims){
    code
    message
  }
}
`
export const DELETE_LIMS=gql`
mutation deleteLims($_id:String){
  deleteLims(_id:$_id){
    code
    message
  }
}
`
export const UPDATE_LIMS=gql`
mutation UpdateLims($lims:LIMSInput){
  updateLIMS(lims:$lims){
    code
    message
  }
}
`
export const GET_LIMS_BY_MABN=gql`
query LIMS_BY_MABN($mabn:String){
  response:lims_by_mabn(mabn:$mabn){
    code
    message
    data{
      mabn
            nghe_nghiep
            sodienthoai
            chungtoc
            chieu_cao
            can_nang
            ho_va_ten
            ngaysinh
            diachi1
            hutthuoc
            uongruou
            para
            khoang_sang_sau_gay
            phuong_phap_thu_thai
            ngay_dau_tien_ky_kinh_cuoi
            kinh_deu
            vongkinh
            ngay_sieu_am
            bacsy_chi_dinh
            bacsy_chi_dinh_doubletest
            bacsy_chi_dinh_trippletest
            bacsy_chi_dinh_tiensangiat
            tiensu_banthan
            tiensu_gia_dinh
            tuan_thai
            ngay_thai
            bPD
            nhip_tim_thai
            cRL
            soluong_hutthuoc
            soluong_uongruou            
            ho_ten_chong
            tuoi_chong
            nghe_nghiep_chong
            tiensu_chong
            ruou_bia_chong
            thuoc_la_chong
            sdt_chong
            ngay_chuyen_phoi
            ngay_bom_tinh_trung
            so_con_gai
            so_con_trai
            ngay_du_sinh
            tinh_trang_suc_khoe
            soluong_ruou_bia_chong
            soluong_thuoc_la_chong
            dai_thao_duong
            viemgan
            viemgan_nam
            dai_thao_duong_nam
            tien_san_giat
            huyetap_phai1_thap
            huyetap_phai1_cao
            huyetap_phai2_thap
            huyetap_phai2_cao
            huyetap_trai1_thap
            huyetap_trai1_cao
            huyetap_trai2_thap
            huyetap_trai2_cao
            ngay_lam_doppler
            dong_mach_tu_cung_phai
            dong_mach_tu_cung_trai
            tien_san_giat_o_me
            cao_huyet_ap_co_dieu_tri
            nhom_mau
            so_thai
            ma_xet_nghiem_nips
            ngay_lay_mau_nips
            nguoi_lay_mau_nips
            donvi_lay_mau_nips
            bacsy_chi_dinh_nips
            kqxn_sang_loc_cu
    }
  }
}
`
export const GET_LIMS = gql`
query lims($_id:String){
  response:lims(_id:$_id){
    code
    message
    data{
      _id,
            mabn
            nghe_nghiep
            sodienthoai
            chungtoc
            chieu_cao
            can_nang
            ho_va_ten
            ngaysinh
            diachi1
            hutthuoc
            uongruou
            para
            khoang_sang_sau_gay
            phuong_phap_thu_thai
            ngay_dau_tien_ky_kinh_cuoi
            kinh_deu
            vongkinh
            ngay_sieu_am
            bacsy_chi_dinh
            bacsy_chi_dinh_doubletest
            bacsy_chi_dinh_trippletest
            bacsy_chi_dinh_tiensangiat
            tiensu_banthan
            tiensu_gia_dinh
            tuan_thai
            ngay_thai
            bPD
            nhip_tim_thai
            cRL
            soluong_hutthuoc
            soluong_uongruou            
            ho_ten_chong
            tuoi_chong
            nghe_nghiep_chong
            tiensu_chong
            ruou_bia_chong
            thuoc_la_chong
            sdt_chong
            ngay_chuyen_phoi
            ngay_bom_tinh_trung
            so_con_gai
            so_con_trai
            ngay_du_sinh
            tinh_trang_suc_khoe
            soluong_ruou_bia_chong
            soluong_thuoc_la_chong
            dai_thao_duong
            viemgan
            viemgan_nam
            dai_thao_duong_nam
            tien_san_giat
            huyetap_phai1_thap
            huyetap_phai1_cao
            huyetap_phai2_thap
            huyetap_phai2_cao
            huyetap_trai1_thap
            huyetap_trai1_cao
            huyetap_trai2_thap
            huyetap_trai2_cao
            ngay_lam_doppler
            dong_mach_tu_cung_phai
            dong_mach_tu_cung_trai
            tien_san_giat_o_me
            cao_huyet_ap_co_dieu_tri
            nhom_mau
            so_thai
            ma_xet_nghiem_nips
            ngay_lay_mau_nips
            nguoi_lay_mau_nips
            donvi_lay_mau_nips
            bacsy_chi_dinh_nips
            kqxn_sang_loc_cu
    }
  }
}
`
export const FETCH_LIMSES = gql`
query limses($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
  response:limses(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
    code
    message
    pages
    data{
      _id
      ho_va_ten
    	mabn
      diachi1
      sodienthoai
      ngay_tao
      ngaysinh
    }
  }
}
`

export const UPDATE_STATUS_KQXN1= gql`
mutation UpdateKQXN1($_id:String!,$allowUpdate:Boolean!){
  updateStatusKQXN1(_id:$_id,allowUpdate:$allowUpdate){
   code
   message
 } 
 }
`

export const GET_KQXN2s=gql`
query kqxn2s($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
  response:kqxn2s(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
    code
    message
    page
    pages
    data{
      _id
      binhthuong
      cANNANG
      cHOLAM      
      dOITUONG
      dVT
      hOTEN
      hoten_me
      iD
      iD_BV_CHITIET
      iD_BV_SO
      iD_BV_TEN
      iD_LOAI
      iD_NHOM
      iD_TEN
      kETLUAN
      kETQUA_CT
      kTV
      lANIN
      mABN
      mABS
      mABSDOC
      mABV
      mABS
      mABSDOC
      mAICD
      mAKP
      mame
      mAPHUONGXA
      mAXCSBT_NU
      mINCSBT_NU
      mAXCSBT_NAM
      mINCSBT_NAM
      nGAY
      nHANXET
      nGAYSINH
      nGUOINHAP
      nGAYLAYMAU
      nGAYSINH_FULL
      pHAI
      pHAI1
      sONHA
      sOTHE
      sTT_SO
      sOPHIEU
      sTT_TEN
      sTTLAYMAU
      sTT_BV_TEN
      sTT_CHITIET
      tEN_BV_SO
      tHON
      tENBS
      tENKP
      tENTT
      tENKTV
      tENPXA
      tUNGAY
      tEN_TEN
      tENQUAN
      tEN_LOAI
      tEN_NHOM
      tENBSDOC
      tEN_BV_TEN
      uSERID
      uSERID_INKQ
      vVIET
      
    }
  }
}
`

export const GET_KQXNs=gql`
query KQXN($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
  response:kqxns(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
   code
   message
   page
   pages
   data{
     name
     patientId
     option
     testTime
     type
     updatedTime
     patient{
       fullName
       phoneNumber
       user{
        password
       }
     }
   }
 } 
 }
`
export const GET_KQXN1=gql`
query KQXN1($_id:String){
  response:kqxn1(_id:$_id){
    code
    message    
    data{     
      files
    }
  }
}
`;
export const GET_KQXN1s=gql`
query KQXN1($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
  response:kqxn1s(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
    code
    message
    page
    pages
    data{
      _id
      name
      updatedTime
      createdTime
      testTime
      patientId
      files
      allowUpdate
      name
      phoneNumber
      patient{
        hOTEN
        nGAYSINH
        nAMSINH
      }
      user{        
        password
      }
    }
  }
}
`;

export const CREATE_DIAGNOSISDATA=gql`
mutation CreateDiagnosis($diagnosisData:DiagnosisDataInputType!){
  response:createDiagnosis(diagnosisData:$diagnosisData){
    code
    message
  }
}
`
export const UPDATE_DIAGNOSISDATA=gql`
mutation UpdateDiagnosis($diagnosisData:DiagnosisDataInputType!){
  response:updateDiagnosis(diagnosisData:$diagnosisData){
    code
    message
  }
}
`

export const DELETE_DIAGNOSIS=gql`
mutation DeleteDiagnosis($_id:String!){
  deleteDiagnosis(_id:$_id){
    code
    message
  }
}
`
export const DIAGNOSISDATAS = gql`
query DiagnosisDatas($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
  response:diagnosisDatas(filtered:$filtered,sorted:$sorted,page:$page,pageSize:$pageSize){
    code
    message
    data{
      _id
      data
      testId
      testName
      createdTime
      testName
      patientId
      customer{
        address
        birthDay
        fullName
        gender
        phoneNumber
        email
      }
    }
  }
}
`;
export const DIAGNOSISDATA = gql`
query DiagnosisData($_id:String!){
  response:diagnosisData(_id:$_id){
    code
    message
    data{
      _id
      data
      testId
      testName
      createdTime
      testName
      patientId
      customer{
        address
        birthDay
        gender
        phoneNumber
        email
        ethnic
        weight
        height
        work
        birthYear
      }
    }
  }
}
`;

export const CALLOUT = gql`
mutation Asterisk($phoneNumber:String!){
  callout(phoneNumber:$phoneNumber){
    code
    message
    data
  }
}
`;

export const UPDATE_APPOINTMENT_STATE=gql`
mutation UpdateState($_id:String!,$state:String!){
    updateAppointmentState(_id:$_id,state:$state){
      code
      message
      data{
        _id
      }
    }
  }

`;
export const FETCH_CDRS = gql`
query CDR($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
    cdrs(page:$page,pageSize:$pageSize,sorted:$sorted,filtered:$filtered){
      code
      message
      page
      pages
      data{
        _id
        state
        receiver
        agent{name}
        customer{fullName}
        sender
        startTime
        ringTime
        upTime
        isOutbound
        did
        app
        duration
        endTime
        endReason
        hangupTime
        outboundCallerId
      }
    }
  }
`;
export const DELETE_CUSTOMER=gql`
mutation DeleteCustomer($_id:String!){
  deleteCustomer(_id:$_id){
    code
    message
  }
}
`
export const FETCH_CUSTOMERS = gql`
query Customer($page:Int,$pageSize:Int,$sorted:[SortedInput],$filtered:[FilteredInput]){
    customers(page:$page,pageSize:$pageSize,sorted:$sorted,filtered:$filtered){
      page
      pages
      data{
        _id
        address
        birthDay
        fullName
        patientId
        gender
        phoneNumber     
        appointments
        servedAppointments 
      }
    }
  }
    
`

export const CREATE_APPOINTMENT = gql`
  mutation Mutation($appointment: AppointmentInput!,$callid:String) {
    data:createAppointment(appointment: $appointment,callid:$callid) {
      code
      message      
    }
  }
`;

export const FETCH_LIST_APPOINTMENT_HISTORY = gql`
  query Appointments(
    $filtered: [FilteredInput]
    $sorted: [SortedInput]
    $page: Int
    $pageSize: Int
  ) {
    appointments(
      page: $page
      pageSize: $pageSize
      sorted: $sorted
      filtered: $filtered
    ) {
      data {
        _id
        appointmentTime
        appointmentDate
        description
        phoneNumber
        chanel
        state
        department {
          _id
          name
        }
        createdTime        
        phoneNumber
        fullName
        gender  
      }
      page
      pages
    }
  }
`;
export const DELETE_APPOINTMENT=gql`
    mutation Mutation($_id:String!){
        deleteAppointment(_id:$_id){
            code
            message
        }
    }
`

export const FETCH_APPOINTMENT=gql`
  query Appointment($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
      appointments(page:$page,pageSize:$pageSize,sorted:$sorted,filtered:$filtered){
      data{
          _id
          appointmentTime
          appointmentDate
          description
          reason
          chanel
          state
          phoneNumber
          fullName
          gender
          rePhoneNumber
          mariage
          birthDay
          changed
          changeReason
          departmentId
          department{
            _id
            name
          }
          createdTime
          customer{
          _id
            phoneNumber
            fullName
            gender
            birthDay 
          }
      }
      page
      pages
  }
}
  
`
export const FETCH_WORKING_TIME = gql`
  query Department($_id: String, $date: String) {
    department(_id: $_id) {
      code
      message
      data{
        workingTimes(date: $date) {
          timeRange
          remainProcess
        }
      }
    }
  }
`;
export const FETCH_DEPARTMENT = gql`
  query Department {
    departments {
      code
      message
      data{
        _id
        name
      }
    }
  }
`;
export const FETCH_CUSTOMER=gql`
query Customer($page:Int,$pageSize:Int,$sorted:[SortedInput],$filtered:[FilteredInput]){
    customers(page:$page,pageSize:$pageSize,sorted:$sorted,filtered:$filtered){
      page
      pages
      data{
        _id
        address
        birthDay
        fullName
        patientId
        gender
        phoneNumber      
      }
    }
  }
    
`
export const GET_CUSTOMER = gql`
  query Customer($phoneNumber: String,$_id:String,$patientId:String) {
    customer(phoneNumber: $phoneNumber,_id:$_id,patientId:$patientId) {
      code
      message
      data{
        _id
        fullName
        address
        district
        province
        birthDay
        gender
        phoneNumber
        email
        patientId
        work
        gender
      }
    }
  }
`;
export const UPDATE_APPOINTMENT=gql`
    mutation Mutation($appointment:AppointmentInput!){
        updateAppointment(appointment:$appointment){
            code
            message
        }
    }
`