import React from 'react';

import { Navbar, Nav, NavItem,Button,ButtonGroup } from 'reactstrap';

import SourceLink from 'components/SourceLink';

const Footer = () => {
  return (
    <Navbar style={{position:"fixed",bottom:0}}>
      <Nav navbar>
        <NavItem>
            <ButtonGroup>
              <Button onClick={e=>{document.location.href="/call-event"}} size="lg" color="green">
                CALL
              </Button>
              <Button onClick={e=>{document.location.href="/"}} sign="lg" color="primary">
                CRM
              </Button>
            </ButtonGroup>
           
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
