import Page from 'components/Page';
import React from 'react';
import { Button, Input, Row, Col, Label, Card, CardHeader, CardBody, FormGroup, Badge } from 'reactstrap';
import { withApollo, Query } from 'react-apollo'
import { MdSearch, MdImportExport, MdReport, MdCheckCircle, MdClose, MdCheckBoxOutlineBlank } from 'react-icons/lib/md';
import gql from 'graphql-tag';
import authCheck from 'utils/authCheck';
import Moment from 'moment';
import { CounterWidget } from 'components/Widget';
import ReactTable from "react-table";
import { GetLocalToken } from 'companyConstants'
import { filter } from 'async';


export const QUERY_REPORT = gql`
query cdrReport($startDate:String,$endDate:String){
	cdrGeneralReport(startDate:$startDate,endDate:$endDate,isOutbound:"false"){
    code
    message
    data{
      inbound{
        appointments
        calls
        misCalls
        duration
        avgDuration
      }
    }
  }
}
`;

export const QUERY_REPORT_BYMONTH=gql`
query cdrReport($startDate:String,$endDate:String){
    cdrGeneralReportByMonth(startDate:$startDate,endDate:$endDate,isOutbound:"false"){
      code
      message
      data{
        label
        misCalls
        calls
        duration
        avgDuration
      }
    }
  }
`;
class InboundReportByQueue extends React.Component {
    state = {
        startTime: new Date(),
        endTime: new Date(),
    }
    componentDidMount() {
        // this.fetchData(true);
    }

    render = () => {
        const { startTime, endTime } = this.state;
        return (
            <Page
                title="Báo cáo gọi vào theo Queue"
                breadcrumbs={[{ name: 'Báo cáo gọi vào theo Queue', active: true }]}
                className="AppointmentReportPage">
                <div>
                    <Row style={{ padding: "1rem" }}>
                        <Col md={3} sm={6} xs={12}>
                            <FormGroup>
                                <Label>Ngày bắt đầu</Label>
                                <Input value={Moment(this.state.startTime).format("YYYY-MM-DD")} type="date" bsSize="sm" onChange={e => this.setState({ startTime: e.target.value })} />
                            </FormGroup>
                        </Col>
                        <Col md={3} sm={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Ngày kết thúc
                                                </Label>
                                <Input value={Moment(this.state.endTime).format("YYYY-MM-DD")} type="date" bsSize="sm" onChange={e => this.setState({ endTime: e.target.value })} />
                            </FormGroup>
                        </Col>

                    </Row>
                    <center>
                        <Button className="mr-4 text-white" size="lg" color="blue"><MdImportExport /> Xuất Excel</Button>
                    </center>
                </div>
                <Query
                    query={QUERY_REPORT}
                    variables={{ startDate: Moment(startTime).format("DD/MM/YYYY"), endDate: Moment(endTime).format("DD/MM/YYYY")}}
                >
                    {
                        ({ loading, data, refetch }) => {
                            var result = [];
                            authCheck(data);
                            try {
                                result = [data.cdrGeneralReport.data];
                                console.log("result=" + JSON.stringify(result));
                            } catch (err) { }
                            return (
                                <div>
                                    <ReactTable
                                        noDataText="Không có dữ liệu"
                                        className="mt-3"
                                        pageSize={result.length}
                                        showPagination={false}
                                        data={result}
                                        columns={[

                                            {
                                                Header: "Số lượng kết nối",
                                                className: "text-center",
                                                accessor: "inbound.calls"
                                            },
                                            {
                                                Header: "Cuộc gọi được phục vụ",
                                                className: "text-center",
                                                accessor: "inbound.misCalls",
                                                Cell: row => <span>
                                                    {row.original.inbound.calls - row.value}
                                                </span>
                                            },
                                            {
                                                Header: "THỜI LƯỢNG ĐÀM THOẠI",
                                                className: "text-center",
                                                accessor: "inbound.duration",
                                                Cell: row => <span>
                                                    {Moment("1900-01-01").add(row.value, 'seconds').format("HH:mm:ss")}
                                                </span>
                                            },
                                            {
                                                Header: "THỜI LƯỢNG ĐÀM THOẠI TRUNG BÌNH",
                                                className: "text-center",
                                                accessor: "inbound.avgDuration",
                                                Cell: row => <span>
                                                    {Moment("1900-01-01").add(row.value, 'seconds').format("HH:mm:ss")}
                                                </span>
                                            },
                                            {
                                                Header: "Cuộc gọi nhỡ",
                                                className: "text-center",
                                                accessor: "inbound.misCalls"
                                            },
                                            {
                                                Header: "TỈ LỆ BỊ NHỠ",
                                                className: "text-center",
                                                accessor: "inbound.misCalls",
                                                Cell: row => <span>
                                                    {row.value * 100 / row.original.inbound.calls}%
                                                </span>
                                            }
                                        ]}
                                    >

                                    </ReactTable>

                                </div>
                            )
                        }
                    }
                </Query>

                <Query
                    query={QUERY_REPORT_BYMONTH}
                    variables={{ startDate: Moment(startTime).format("DD/MM/YYYY"), endDate: Moment(endTime).format("DD/MM/YYYY") }}
                >
                    {
                        ({ loading, data, refetch }) => {
                            var result = [];
                            authCheck(data);
                            try {
                                result = data.cdrGeneralReportByMonth.data;
                                
                            } catch (err) { }
                            return (
                                <div>
                                    <ReactTable
                                        noDataText="Không có dữ liệu"
                                        className="mt-3"
                                        pageSize={result.length}
                                        showPagination={false}
                                        data={result}
                                        columns={[
                                            {
                                                Header: "Tháng",
                                                className: "text-center",
                                                accessor: "label"
                                            },
                                            {
                                                Header: "Số lượng kết nối",
                                                className: "text-center",
                                                accessor: "calls"
                                            },
                                            {
                                                Header: "Cuộc gọi được phục vụ",
                                                className: "text-center",
                                                accessor: "misCalls",
                                                Cell: row => <span>
                                                    {row.original.calls - row.value}
                                                </span>
                                            },
                                            {
                                                Header: "THỜI LƯỢNG ĐÀM THOẠI",
                                                className: "text-center",
                                                accessor: "duration",
                                                Cell: row => <span>
                                                    {Moment("1900-01-01").add(row.value, 'seconds').format("HH:mm:ss")}
                                                </span>
                                            },
                                            {
                                                Header: "THỜI LƯỢNG ĐÀM THOẠI TRUNG BÌNH",
                                                className: "text-center",
                                                accessor: "avgDuration",
                                                Cell: row => <span>
                                                    {Moment("1900-01-01").add(row.value, 'seconds').format("HH:mm:ss")}
                                                </span>
                                            },
                                            {
                                                Header: "Cuộc gọi nhỡ",
                                                className: "text-center",
                                                accessor: "misCalls"
                                            },
                                            {
                                                Header: "TỈ LỆ BỊ NHỠ",
                                                className: "text-center",
                                                accessor: "misCalls",
                                                Cell: row => <span>
                                                    {row.value * 100 / row.original.calls}%
                                                </span>
                                            }
                                        ]}
                                    >

                                    </ReactTable>

                                </div>
                            )
                        }
                    }
                </Query>
            </Page>

        )
    }
}

export default withApollo(InboundReportByQueue);