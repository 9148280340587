import Page from 'components/Page';
import React,{Fragment} from 'react';
import { Button, ButtonGroup, Card, Input, Label,CardHeader,Tooltip } from 'reactstrap';
import { withApollo,Mutation } from 'react-apollo'
import gql from 'graphql-tag';
import { MdEdit,MdDelete,MdAddCircle } from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import Moment from 'moment'
import confirm from 'components/Confirmation';
import {DIAGNOSISDATAS,DELETE_DIAGNOSIS} from 'AdminCustomer/GqlQuery';



class ArticlePage extends React.Component {

    state = {
        data: [],
        pages: 1,
        loading: true,
        tooltipOpen:{},
        category:""
    }
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);  
        this.state.category=props.match.params.category;
    }
    componentDidMount(){
        this.state.category=this.props.match.params.category;
        this.fetchData({page:0,pageSize:10,filtered:[],sorted:[]});
    }
    componentWillReceiveProps(nextProps){
        if (nextProps.match.params.category !== this.props.match.params.category) {
            this.state.category=nextProps.match.params.category;
            this.fetchData({page:0,pageSize:10,filtered:[],sorted:[]});
        }
    }
    fetchData = async (state, instance) => {
        this.setState({ loading: true });
        let { filtered, pageSize, sorted, page } = state;
        //const {category}=this.state;
        //filtered=filtered.concat([{id:"category",value:category}])
        try {
            const result = await this.props.client.query({
                query: DIAGNOSISDATAS,
                variables: { filtered, pageSize, sorted, page },
            })
            authCheck(result);
            this.setState({ data: result.data.response.data, pages: result.data.response.pages, loading: false });
        } catch (err) {
            authCheck(err);
        }
    }
    onEdit=(row)=>{            
        this.props.history.push("/diagnosis-detail/"+row._id);
    }
    onDelete=(row,mutation)=>{
        confirm(`Bạn có chắc chắn muốn xóa dữ liệu chuẩn đoán?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }
    render = () => {
        const { data, loading, pages,tooltipOpen } = this.state;
        return (
            <Page title="Quản lý hồ sơ chuẩn đoán"
                breadcrumbs={[{ name: 'Quản lý hồ sơ chuẩn đoán', active: true }]}
                className="CustomerPage">
                <Card>
                    <CardHeader className="bg-green">
                        <Button color="primary" onClick={e => { this.props.history.push("/diagnosis-detail") }}><MdAddCircle /> Thêm chuẩn đoán</Button>
                    </CardHeader>
                    <ReactTable
                        manual
                        noDataText="Không có dữ liệu"   
                        sortable={false}   
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={5}
                        className="-striped -highlight"                       
                        columns={[
                            {
                                Header: "#",
                                width:50,
                                className:"text-center",
                                filterable:false,
                                Cell:row=><span>
                                    {row.index+1}
                                </span>
                            },
                            {
                                Header: "Mã bệnh nhân",
                                accessor: "patientId"
                            },
                            {
                                Header: "Tên bệnh nhân",
                                accessor: "customer.fullName"
                            },
                            {
                                Header: "Số điện thoại",
                                accessor: "customer.phoneNumber"
                            },
                            {
                                Header: "Địa chỉ",
                                accessor: "customer.address"
                            },  
                            {
                                Header: "Mã xét nghiệm",
                                accessor: "testId"
                            },                          
                            {
                                Header: "Tên xét nghiệm",
                                accessor: "testName"
                            },    
                            {
                                Header: "Mã bác sỹ",
                                accessor: "doctorId"
                            },                          
                            {
                                Header: "Tên bác sỹ",
                                accessor: "doctorName"
                            },                           
                            {
                                Header: "Thời gian tạo",
                                accessor: "createdTime",
                                filterable:false,
                                Filter: ({ filter, onChange }) =>
                                    <Input bsSize="sm" type="date" placeholder="Chọn ngày" onChange={e => onChange(e.target.value)} />,
                                Cell: row => (<Label>{Moment(row.value).format('HH:mm DD-MM-YYYY')}</Label>)
                            },
                            {
                                Header: "Tác vụ",
                                maxWidth: 100,
                                filterable: false,
                                sortable: false,
                                className:"text-center",
                                Cell: row=>(
                                    <ButtonGroup style={{fontSize:20}}>
                                        <MdEdit id={"edit"+row.original._id} onClick={()=>this.onEdit(row.original)} className="text-warning m-1"/>
                                        <Tooltip placement="bottom" isOpen={tooltipOpen['edit'+row.original._id]} autohide={false} target={"edit"+row.original._id} toggle={e=>{tooltipOpen['edit'+row.original._id]=!tooltipOpen['edit'+row.original._id]; this.setState({tooltipOpen})}}>
                                            Sửa
                                        </Tooltip>
                                        <Mutation mutation={DELETE_DIAGNOSIS}
                                            variables={{_id:row.original._id}}
                                            onCompleted={(result)=>{
                                                this.fetchData({page:0,pageSize:10,filtered:[],sorted:[]});
                                            }}
                                        >
                                            {mutation=>
                                                <Fragment>
                                                    <MdDelete id={"delete"+row.original._id} className="text-danger m-1" onClick={()=>this.onDelete(row.original,mutation)}/>
                                                    <Tooltip placement="bottom" isOpen={tooltipOpen['delete'+row.original._id]} autohide={false} target={"delete"+row.original._id} toggle={e=>{tooltipOpen['delete'+row.original._id]=!tooltipOpen['delete'+row.original._id]; this.setState({tooltipOpen})}}>
                                                        Xóa kết quả
                                                    </Tooltip>
                                                </Fragment>
                                            }
                                        </Mutation>
                                    </ButtonGroup>
                                )
                            }
                        ]}
                        
                    >

                    </ReactTable>
                </Card>

            </Page>
        )
    }
}

export default withApollo(ArticlePage);