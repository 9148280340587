import Page from 'Admin/Components/Page';
import React,{Fragment} from 'react';
import { Button,Input,Row,Col,Label,Form ,FormGroup} from 'reactstrap';
import { Mutation,Query } from 'react-apollo'
import gql from 'graphql-tag';
import authCheck from 'utils/authCheck';

export const MUTATION_CREATE= gql`
mutation AdminMutation($firewall:FirewallInputType){
    addFirewall(firewall:$firewall)
}
`


class FirewallEditPage extends React.Component{
    constructor(){
        super();
        this.state={
            type:"rule",
            name:"http",
            port:"",
            address:"0.0.0.0/32",
            family:"ipv4",
            protocol:"udp"
        };
    }
    

    onCancel=()=>{
        this.props.history.push("/firewall");
    }

    render=()=>{
        let{type,name,port,address,family,protocol}=this.state;
        
        return(
            <Page title="Edit Firewall"
            breadcrumbs={[{ name: 'Edit Firewall', active: true }]}            
            className="firewall"> 
                <Row>
                    <Col md={12}>
                        <Mutation
                            mutation= {MUTATION_CREATE}
                            variables={{firewall:{type,name,port,address,family,protocol}}}
                            onCompleted={(result)=>{this.onCancel()}}
                        >
                            {mutation=><Button color="primary" onClick={mutation}>Cập nhật thông tin</Button>}
                        </Mutation>{" "}<Button color="link" onClick={e=>{console.log(this.state)}}>Hủy</Button>
                    </Col>                
                </Row>                
                <Row>
                    <Col md={12}>
                        <div className="m-3">
                            <FormGroup row>
                                <Label md={2} sm={4} xs={6} className="text-right">Loại:</Label>
                                <Col md={10} sm={8} xs={6}>
                                    <Input type="select" value={type} onChange={e=>{
                                            this.setState({type:e.target.value})
                                        }
                                    } bsSize="sm">
                                        <option value="service">Service</option>
                                        <option value="port">Port</option>
                                        <option value="rule">Rich Rule</option>
                                    </Input>
                                </Col>
                            </FormGroup> 
                            <FormGroup row>
                                <Label md={2} sm={4} xs={6} className="text-right">Thuộc tính:</Label>
                                <Col md={10} sm={8} xs={6}>
                                    
                                        {type==="service"?(<Form inline>
                                            <Label>Name = </Label>&nbsp;
                                            <Input type="select" value={name} bsSize="sm" onChange={e=>{this.setState({name:e.target.value})}}>
                                                <option value="">Chọn dịch vụ</option>
                                                <option value="ssh">SSH</option>
                                                <option value="ftp">FTP</option>
                                                <option value="http">HTTP</option>
                                                <option value="https">HTTPS</option>
                                            </Input>
                                        </Form>):""}
                                        {type==="port"?(<Form inline>
                                            <Label>Port = </Label>&nbsp;
                                            <Input type="text" bsSize="sm" onChange={e=>{
                                                this.setState({port:e.target.value})}}/>&nbsp;
                                            <Label>Protocol = </Label>&nbsp;
                                            <Input type="select" bsSize="sm" value={protocol} onChange={e=>{this.setState({protocol:e.target.value})}}>
                                                <option value="">Chọn Protocol</option>
                                                <option value="udp">UDP</option>
                                                <option value="tcp">TCP</option>
                                            </Input>
                                        </Form>):""}
                                        {
                                            type==="rule"?(<Form inline>
                                                <Label>Family =</Label>&nbsp;
                                                <Input type="select" bsSize="sm" value={family} onChange={e=>{this.setState({family:e.target.value})}}>
                                                    <option value="">Chọn loại IP</option>
                                                    <option value="ipv4">IPV4</option>
                                                    <option value="ipv6">IPV6</option>
                                                </Input>
                                            </Form>):""
                                        }
                                </Col>
                            </FormGroup>  
                            {type==="rule"?(<FormGroup row>
                                <Label md={2} sm={4} xs={6} className="text-right">Elements:</Label>
                                <Col md={10} sm={8} xs={6}>
                                    <Form inline>
                                        <Label>Address =</Label>&nbsp;
                                        <Input type="text" value={address} bsSize="sm" onChange={e=>{this.setState({address:e.target.value})}}/>&nbsp;
                                        <Label>Port = </Label>&nbsp;
                                        <Input type="text" value={port} bsSize="sm" onChange={e=>{this.setState({port:e.target.value})}}/>&nbsp;
                                        <Label>Protocol = </Label>&nbsp;
                                        <Input type="select" value={protocol} bsSize="sm" onChange={e=>{this.setState({protocol:e.target.value})}}>
                                            <option value="">Chọn Protocol</option>
                                            <option value="udp">UDP</option>
                                            <option value="tcp">TCP</option>
                                        </Input>
                                    </Form>
                                </Col>   
                            </FormGroup>):""}
                            
                        </div>  
                    </Col>              
                </Row>
            </Page>
        )
    }
}
export default FirewallEditPage;