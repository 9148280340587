import React from "react";
import logo from 'styles/images/logo_phusan.png'
import { ValidFormDate } from "utils/dateHelper";
import { GetLocalToken } from 'companyConstants'
import Moment from 'moment'

const MYTD = ({ className, colspan, children, style }) => <td className={className} colspan={colspan} style={{ padding: "4px 10px", fontSize: 16, ...style }}>
    {children}
</td>

const PrintTrippleTest = ({
    mabn,
    nghe_nghiep,
    sodienthoai,
    chungtoc,
    chieu_cao,
    can_nang,
    ho_va_ten,
    ngaysinh,
    diachi1,
    hutthuoc,
    uongruou,
    para,
    Khoang_sang_sau_gay,
    phuong_phap_thu_thai,
    ngay_dau_tien_ky_kinh_cuoi,
    kinh_deu,
    vongkinh,
    ngay_sieu_am,
    bacsy_chi_dinh,
    tiensu_banthan,
    tiensu_gia_dinh,
    bacsy_chi_dinh_trippletest,
    tuan_thai,
    ngay_thai,
    bPD,
    nhip_tim_thai,
    cRL,
    soluong_hutthuoc,
    soluong_uongruou,
    data,
    ho_ten_chong,
    tuoi_chong,
    nghe_nghiep_chong,
    tiensu_chong,
    ruou_bia_chong,
    thuoc_la_chong,
    sdt_chong,
    ngay_chuyen_phoi,
    ngay_bom_tinh_trung,
    so_con_gai,
    so_con_trai,
    ngay_du_sinh,
    tinh_trang_suc_khoe,
    printing,
    print_double_test,
    print_tripple_test,
    soluong_ruou_bia_chong,
    soluong_thuoc_la_chong,
    objRef
}) => (
        <div className="body" ref={objRef}>
            <div className="content" id="tripple_test">
                <div class="img_logo" style={{ width: 120, marginRight: 20 }}> <img src={logo} alt="logo hospital" style={{ width: 120 }} /> </div>
                <div style={{ flex: 1 }}>
                    <p style={{ marginBottom: 0 }}><b style={{ fontSize: 24, color: "#478F20" }}>BỆNH VIỆN PHỤ SẢN HÀ NỘI</b><br />
                        <b>
                            TRUNG TÂM SÀNG LỌC, CHUẨN ĐOÁN TRƯỚC SINH VÀ SƠ SINH
                        </b>
                    </p>
                    <p> Địa chỉ: Số 929 - Đường La Thành - Ngọc Khánh - Ba Đình - Hà Nội<br />
                        Hotline: 0243 7757 346
                    </p>
                </div>
                <div style={{ width: "100%" }}>
                    <p style={{ textAlign: "center", fontSize: 32, color: "#478F20", fontWeight: "bold" }}>HỒ SƠ XÉT NGHIỆM SÀNG LỌC TRIPLE TEST</p>
                </div>
                <div class="form_info">
                    <p className="header">I. THÔNG TIN THAI PHỤ</p>
                    <table cellpadding="0" cellspacing="0" >
                        <tbody>
                            <tr>
                                <th style={{ width: `${100 / 6}%` }}></th>
                                <th style={{ width: `${100 / 6}%` }}></th>
                                <th style={{ width: `${100 / 6}%` }}></th>
                                <th style={{ width: `${100 / 6}%` }}></th>
                                <th style={{ width: `${100 / 6}%` }}></th>
                                <th style={{ width: `${100 / 6}%` }}></th>
                            </tr>
                            <tr>
                                <MYTD colspan="2" style={{ width: "33%" }}>Họ tên: <b>{ho_va_ten}</b></MYTD>
                                <MYTD colspan="2">Ngày sinh: <b>{ValidFormDate(ngaysinh)}</b></MYTD>
                                <MYTD colspan="2">Mã BN: <b>{mabn}</b></MYTD>
                            </tr>
                            <tr>
                                <MYTD colspan="2">Địa chỉ: <b>{diachi1}</b></MYTD>
                                <MYTD colspan="2">Cân nặng: <b>{can_nang < 0 ? "........" : can_nang} kg</b></MYTD>
                                <MYTD colspan="2">Chiều cao: <b>{chieu_cao < 0 ? "........" : chieu_cao} cm</b></MYTD>
                            </tr>
                            <tr>
                                <MYTD colspan="2">Số ĐT: <b>{sodienthoai}</b></MYTD>
                                <MYTD colspan="2">Nghề nghiệp: <b>{nghe_nghiep}</b></MYTD>
                                <MYTD colspan="2">Dân tộc: <b>{chungtoc}</b></MYTD>
                            </tr>
                            <tr>
                                <MYTD>Hút thuốc:</MYTD>
                                <MYTD colspan="3">
                                    <table className="table-inline">
                                        <tbody>
                                            <tr >
                                                <MYTD>
                                                    <label class="frm_check"><span className={hutthuoc == 0 ? "checked" : ""} />
                                                        <span class="checkmark"></span>
                                                        Không
                                         </label>
                                                </MYTD>
                                                <MYTD>
                                                    <label class="frm_check"><span className={hutthuoc == 1 ? "checked" : ""} />
                                                        <span class="checkmark"></span>
                                                        Có
                                         </label>
                                                </MYTD>
                                            </tr>
                                        </tbody>
                                    </table>
                                </MYTD>
                                <MYTD colspan="2">
                                    Số lượng: <b>{soluong_hutthuoc}</b>
                                </MYTD>
                            </tr>
                            <tr>
                                <MYTD>Uống rượu:</MYTD>
                                <MYTD colspan="3">
                                    <table className="table-inline">
                                        <tbody>
                                            <tr >
                                                <MYTD>
                                                    <label class="frm_check"><span className={uongruou == 0 ? "checked" : ""} />
                                                        <span class="checkmark"></span>
                                                        Không
                                            </label>
                                                </MYTD>
                                                <MYTD>
                                                    <label class="frm_check"><span className={uongruou == 1 ? "checked" : ""} />
                                                        <span class="checkmark"></span>
                                                        Có
                                            </label>
                                                </MYTD>
                                            </tr>
                                        </tbody>
                                    </table>
                                </MYTD>
                                <MYTD colspan="2">
                                    Số lượng: <b>{soluong_uongruou}</b>
                                </MYTD>
                            </tr>
                            <tr>
                                <MYTD colspan="6">
                                    <table className="table-inline">
                                        <tbody>
                                            <tr>
                                                <MYTD style={{ width: 220 }}>1. PARA:  <b>{para}</b></MYTD>
                                                <MYTD>
                                                    Đã có mấy con:  Trai: <b>{so_con_trai < 0 ? "......." : so_con_trai} </b> ,
                                            Gái: <b> {so_con_gai < 0 ? "......." : so_con_gai} </b>
                                                </MYTD>
                                                <MYTD>
                                                    Tình trạng sức khỏe: <b>{tinh_trang_suc_khoe}</b>
                                                </MYTD>
                                            </tr>
                                        </tbody>
                                    </table>
                                </MYTD>

                            </tr>
                            <tr>
                                <MYTD>2. Thai lần này:</MYTD>
                                <MYTD colspan="5">
                                    <table className="table-inline">
                                        <tbody>
                                            <tr >
                                                <MYTD>
                                                    <label class="frm_check"><span className={phuong_phap_thu_thai == 1 ? "checked" : ""} />
                                                        <span class="checkmark"></span>
                                                        Tự nhiên
                                            </label>
                                                </MYTD>
                                            </tr>
                                            <tr >
                                                <MYTD>
                                                    <label class="frm_check"><span className={phuong_phap_thu_thai == 3 ? "checked" : ""} />
                                                        <span class="checkmark"></span>
                                                        IVF
                                            </label>
                                                </MYTD>
                                                <MYTD>Ngày chuyển phôi:</MYTD>
                                                <MYTD>
                                                    <b>{ValidFormDate(ngay_chuyen_phoi)}</b>
                                                </MYTD>
                                            </tr>
                                            <tr >
                                                <MYTD>
                                                    <label class="frm_check"><span className={phuong_phap_thu_thai == 2 ? "checked" : ""} />
                                                        <span class="checkmark"></span>
                                                        IUI
                                            </label>
                                                </MYTD>
                                                <MYTD>Ngày bơm tinh trùng:</MYTD>
                                                <MYTD>
                                                    <b>{ValidFormDate(ngay_bom_tinh_trung)}</b>
                                                </MYTD>
                                            </tr>
                                        </tbody>
                                    </table>
                                </MYTD>
                            </tr>
                            <tr>
                                <MYTD colspan="6">
                                    <table className="table-inline">
                                        <tbody>
                                            <tr>
                                                <MYTD colspan="3">
                                                    3. Ngày đầu kỳ kinh cuối cùng: <b>{ValidFormDate(ngay_dau_tien_ky_kinh_cuoi)}</b>
                                                </MYTD>
                                                <MYTD>

                                                    <label class="frm_check">Kinh đều: <span className={kinh_deu == 1 ? "checked" : ""} />
                                                        <div class="checkmark"></div>
                                                    </label>
                                                </MYTD>
                                                <MYTD colspan="2">Vòng kinh: <b>{vongkinh < 0 ? "........" : vongkinh} ngày</b></MYTD>
                                            </tr>
                                        </tbody>
                                    </table>
                                </MYTD>
                            </tr>
                            <tr>
                                <MYTD colspan="6">4. Dự kiến sinh (siêu âm lúc 8 - 12 tuần): <b>{ValidFormDate(ngay_du_sinh)}</b></MYTD>
                            </tr>
                            <tr>
                                <MYTD colspan="6">
                                    <table className="table-inline">
                                        <tbody>
                                            <tr>
                                                <MYTD>5. Siêu âm ngày: <b>{ValidFormDate(ngay_sieu_am)}</b></MYTD>
                                                <MYTD>Thai tương đương: <b>{tuan_thai < 0 ? ' ....... ' : tuan_thai} tuần {ngay_thai < 0 ? " ....... " : ngay_thai} ngày</b>
                                                </MYTD>
                                                <MYTD>BPD: <b>{bPD < 0 ? '........' : bPD} mm</b></MYTD>
                                            </tr>
                                        </tbody>
                                    </table>
                                </MYTD>

                            </tr>
                            <tr>
                                <MYTD colspan="6">Bác sĩ chỉ định: <b>{bacsy_chi_dinh_trippletest}</b></MYTD>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div class="form_info">
                    <p className="header">II. THÔNG TIN GIA ĐÌNH</p>
                    <p>Trong gia đình hai bên vợ, bên chồng (xét 3 thế hệ) có ai mắc hoặc sinh con mắc các rối loạn di truyền hoặc dị tật bẩm sinh: <b>{tiensu_gia_dinh}</b></p>

                </div>

                <div class="form_info">
                    <p className="header">III. TƯ VẤN SÀNG LỌC</p>
                    <table class="table_text">
                        <tbody>
                            <tr>
                                <MYTD>1.</MYTD>
                                <MYTD><p>Triple test là xét nghiệm sàng lọc trước sinh để đánh giá nguy cơ thai nhi mắc bệnh lý di truyền bao gồm:<br />
                                    - Hội chứng Down (3 nhiễm sắc thể 21)<br />
                                    - Hội chứng Edwards (3 nhiễm sắc thể 18)<br />
                                    - Dị tật ống thần kinh</p></MYTD>
                            </tr>
                            <tr>
                                <MYTD style={{ verticalAlign: "top" }}>2.</MYTD>
                                <MYTD><p>Xét nghiệm này không có giá trị chuẩn đoán thai bị bệnh và không có giá trị sàng lọc các dị tật khác.</p></MYTD>
                            </tr>
                            <tr>
                                <MYTD style={{ verticalAlign: "top" }}>3.</MYTD>
                                <MYTD><p>Kết quả xét nghiệm nguy cơ cao không có nghĩa là thai nhi mắc hội chứng Down, Edwards, dị tật ống thần kinh; mà chỉ có nghĩa thai nghi có khả năng mắc các bệnh lý này cao hơn so với ngưỡng bình thường và thai phụ cần được tư vấn bởi bác sỹ tại Trung tâm Sàng lọc, chuẩn đoán trước sinh và sơ sinh</p></MYTD>
                            </tr>
                            <tr>
                                <MYTD style={{ verticalAlign: "top" }}>4.</MYTD>
                                <MYTD><p>Kết quả xét nghiệm nguy cơ thấp không đồng nghĩa với việc khẳng định chắc chắn thai nhi không mắc các bệnh lý di truyền. Thai phụ cần tiếp tục theo dõi sự phát triển của thai qua siêu âm quý 2 và quý 3 của thai Kỳ</p></MYTD>
                            </tr>
                            <tr>
                                <MYTD style={{ verticalAlign: "top" }}>5.</MYTD>
                                <MYTD><p>Sự chính xác và chất lượng của xét nghiệm phụ thuộc và sự chính xác của các thông tin cung cấp và thời điểm xét nghiệm</p></MYTD>
                            </tr>
                        </tbody>
                    </table>
                    <p><b>Cam kết của bệnh nhân:  Tôi đã được nghe tư vấn và hiểu hết về xét nghiệm sàng lọc này. Tôi tự nguyện thực hiện xét nghiệm sàng lọc này</b></p>

                    <table class="table_text">
                        <tbody>
                            <tr>
                                <MYTD colspan="4">
                                    <p style={{float:"right",fontStyle:"italic"}}>                                    
                                        {Moment().format("HH [giờ] mm [phút],  [ngày] DD [tháng] MM [năm] YYYY")}
                                    </p>
                                </MYTD>
                            </tr>
                            <tr>
                                <MYTD style={{ textAlign: "center" }}>Bệnh nhân ký tên</MYTD>
                                <td></td>
                                <td></td>
                                <MYTD style={{ textAlign: "center" }}>Người lập phiếu</MYTD>
                            </tr>
                            <tr>
                                <td colspan="4" style={{ height: 60 }}>
                                </td>
                            </tr>
                            <tr>
                                <MYTD></MYTD>
                                <td></td><td></td>
                                <MYTD style={{ textAlign: "center" }}><strong>{GetLocalToken().fullName}</strong></MYTD>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

    )
export default PrintTrippleTest;