import Page from 'Admin/Components/Page';
import React from 'react';
import { Button,ButtonGroup,Card,CardBody,CardHeader,Badge} from 'reactstrap';

import gql from 'graphql-tag';
import {Mutation} from 'react-apollo'
import {MdEdit,MdDelete,MdAddCircle} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import confirm from 'components/Confirmation'
import { withApollo } from 'react-apollo'
import Moment from 'moment'

export const QUERY_Template=gql`
query AdminQuery($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
    accounts(page:$page,pageSize:$pageSize,filtered:$filtered,sorted:$sorted){
        data{
            _id
            claims{
              type
              value
            }
            email
            createdTime
            userName
            name
            phoneNumber
          }
        page
        pages
    }
  }
`;
export const DELTE_QUERY=gql`
    mutation Mutation($_id:String!){
        deleteAccount(_id:$_id){
            _id
        }
    }
`

class AccountPage extends React.Component{
    state={        
        data: [],
        page: 1,
        loading: true
    }
    constructor(){
        super();
        this.fetchData=this.fetchData.bind(this);
    }
    fetchData=async(state,instance)=>{
        this.setState({ loading: true });
        const {filtered,pageSize,sorted,page} = state;
        try{
            const result = await this.props.client.query({
                query: QUERY_Template,
                variables: { filtered,pageSize,sorted,page },
                
            })    
            authCheck(result);
            this.setState({data:result.data.accounts.data,pages:result.data.accounts.pages,page:result.data.accounts.page,loading:false});
        }catch(err){
            authCheck(err);
        }
    }
    onEdit=(row)=>{            
        this.props.history.push("/account-edit/"+row._id);
    }
    onDelete=(row,mutation)=>{
        confirm(`Bạn có chắc chắn muốn xóa tài khoản '${row.name}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }
    render=()=>{
        const {data,loading,pages}=this.state;
        return (
            <Page title="Asterisk Template"
                breadcrumbs={[{ name: 'Quản lý Media File', active: true }]}            
                className="template"> 
                <Card>
                    <CardHeader className="bg-green">                        
                        <Button color="primary" onClick={()=>{this.props.history.push("/account-edit")}}><MdAddCircle/> Tạo tài khoản</Button>
                    </CardHeader>
                    <CardBody>
                        <div style={{height:10}}></div>
                        <ReactTable 
                        noDataText="Không có dữ liệu"                        
                        columns={[    
                            {
                                Header:"#",
                                width:50,
                                style:{textAlign:"center"},
                                filterable:false,
                                Cell: (row) => {
                                    return <div>{row.index+1}</div>;
                                    }
                            },                             
                            {
                                Header:"Họ và tên",
                                accessor:"name",
                                maxWidth:200
                            },   
                            {
                                Header:"Tên đăng nhập",
                                accessor:"userName",
                                maxWidth:120
                            },
                            {
                                Header:"Thời gian tạo",
                                accessor:"createdTime",
                                width:120,
                                Cell:row=>(
                                    <div>
                                        {Moment(row.value).format("DD/MM/YYYY")}{"  "}
                                        <Badge className="text-white">{Moment(row.value).format("hh:mm")}</Badge>
                                    </div>
                                )
                            },   
                            {
                                Header:"email",
                                accessor:"email",
                                maxWidth:200
                            },
                            {
                                Header:"Số điện thoại",
                                accessor:"phoneNumber",
                                width:90
                            },
                            {
                                Header:"Quyền truy cập",
                                accessor:"claims",
                                Cell:row=><div>
                                    {row.value.map((item,index)=>
                                        <span key={index}>{item.value} </span>
                                    )}
                                </div>
                            },
                            {
                                Header:"Tác vụ",
                                maxWidth:100,
                                filterable:false,
                                sortable:false,
                                Cell: row=>(
                                    <ButtonGroup>
                                        <Button onClick={()=>this.onEdit(row.original)}><MdEdit/></Button>
                                        {" "}
                                        <Mutation mutation={DELTE_QUERY}
                                                variables={{_id:row.original._id}}
                                                onCompleted={(result)=>{
                                                    window.location.reload();
                                                }}
                                            >
                                                {mutation=>
                                                    <Button onClick={()=>this.onDelete(row.original,mutation)}><MdDelete/></Button>
                                                }
                                            </Mutation>

                                    </ButtonGroup>
                                )
                            }
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={10}
                        className="-striped -highlight"
                    />     
                    </CardBody>
                </Card>
            </Page>

        )
    }
}
export default withApollo(AccountPage);