import Page from 'components/Page';
import React,{Fragment} from 'react';
import { Button, Card, Input, Label} from 'reactstrap';
import { withApollo,Mutation } from 'react-apollo'
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import Moment from 'moment'
import {GET_KQXNs,GET_KQXN1} from 'AdminCustomer/GqlQuery';

class kqxn extends React.Component {

    state = {
        data: [],
        pages: 1,
        page:0,
        loading: true,
        tooltipOpen:{}
    }
    constructor() {
        super();
        this.fetchData = this.fetchData.bind(this);
    }
    
    loadDetail=(item)=>{
        if(item.type==1){
            this.props.client.query({
                query:GET_KQXN1,
                variables:{_id:item.option}
            }).then(result=>{
                window.open(result.data.response.data.files[0],'_blank')
            });
        }else{
            window.open(`http://kqxn.app.benhvienphusanhanoi.vn/kqxn2/${item.patientId}/${item.option}`,"_blank");
        }
    }
    
    fetchData = async (state, instance) => {

        this.setState({ loading: true });
        const { filtered, pageSize, sorted, page } = state;
        try {
            const result = await this.props.client.query({
                query: GET_KQXNs,
                variables: { filtered, pageSize, sorted, page },
            })
            authCheck(result);
            this.setState({ data: result.data.response.data, pages: result.data.response.pages, page: result.data.response.page, loading: false });
        } catch (err) {
            authCheck(err);
        }
    }
    render = () => {
        const { data, loading, pages,page } = this.state;
        return (
            <Page title="Kết quả xét nghiệm"
                breadcrumbs={[{ name: 'Kết quả xét nghiệm', active: true }]}
                className="CustomerPage">
                <Card>
                    <ReactTable
                        noDataText="Không có dữ liệu"
                        defaultSorted={[{id:"testTime",desc:true}]}                        
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={10}
                        className="-striped -highlight"
                        columns={[
                            {
                                Header: "#",
                                width:50,
                                className:"text-center",
                                filterable:false,
                                Cell:row=><span>
                                    {row.index+1+(page*10)}
                                </span>
                            },
                            {
                                Header: "Tên xét nghiệm",
                                accessor: "name",
                                Cell:row=><Button color="link" onClick={e=>{this.loadDetail(row.original)}}>
                                    {row.value}
                                </Button>
                            },
                            {
                                Header: "Mã bệnh nhân",
                                accessor: "patientId",
                                width:120
                            },
                            {
                                Header: "Tên bệnh nhân",
                                accessor: "patient.fullName"
                            },
                            {
                                Header: "Số điện thoại",
                                accessor: "patient.phoneNumber",
                                width:120
                            },
                            {
                                Header: "Mật khẩu",
                                accessor: "patient.user.password",
                                width:100
                            },  
                            {
                                Header: "Loại",
                                accessor: "type",
                                width:70,
                                className:"text-center"
                            },       
                            {
                                Header: "STT lấy mẫu",
                                accessor: "option",
                                width:120,
                                Cell:row=><span>
                                    {row.original.type==2?row.original.option:''}
                                </span>
                            },                         
                            {
                                Header: "Thời gian trả xét nghiệm",
                                accessor: "testTime",
                                Filter: ({ filter, onChange }) =>
                                    <Input bsSize="sm" type="date" placeholder="Chọn ngày" onChange={e => onChange(e.target.value)} />,
                                Cell: row => (<Label>{Moment(row.value).format('HH:mm DD/MM/YYYY')}</Label>)
                            }
                        ]}
                        
                    >

                    </ReactTable>
                </Card>

            </Page>
        )
    }
}

export default withApollo(kqxn);