import Page from "Admin/Components/Page";
import React from "react";
import { Button, Input, Row, Col, Label, Form, FormGroup,CardHeader } from "reactstrap";
import { withApollo} from "react-apollo";
import gql from "graphql-tag";
import authCheck from "utils/authCheck";
import ToggleButton from 'react-toggle-button'
import AdvanceEditor from 'components/Widget/AdvanceEditor';

export const QUERY_DETAIL = gql`
query ArticleDetail($_id:String){
  response:article(_id:$_id){
    data{
      _id
      content
      shortDesc
      thumb
      name
      category
      title
      disable
    }
    
  }
}
`;
export const QUERY_PERMISSIONS=gql`
{
  permissions{
    data{
      name
      value
    }
  }
}
`;
export const MUTATION_CREATE = gql`
mutation COMPANY($article:ArticleInputType){
  createArticle(article:$article){
    code
    message
  }
}
`;
export const UPLOAD_QUERY= gql`
query CompanyMutation($file:FileUploadInputType){
  uploadNewImage(file:$file)
}
`;
export const MUTATION_EDIT = gql`
mutation COMPANY($article:ArticleInputType){
  updateArticle(article:$article){
    code
    message
  }
} 
`;

class ArticleEditPage extends React.Component {
  constructor() {
    super();
    
    this.state = {
      name: "",
      thumb:"",
      shortDesc: "",
      title:"",
      content:"",
      category:"news",
      disable:true,
    };
    
  }
  componentDidMount() {
    const _id = this.props.match.params._id;
    if(_id){
      this.setState({isEdit:true,_id:_id});
      //fetch detail
      this.props.client.query({
          query:QUERY_DETAIL,
          variables:{_id:_id},
          
      }).then(result=>{
          authCheck(result);
          const article=result.data.response.data;          
          this.setState(article);          
      });
  }
  }
  
  onUpdate=()=>{
    const {
      _id,
      name,
      title,
      shortDesc,
      content,
      disable,
      category,
      file,
    } = this.state;
    this.props.client.mutate({
      mutation : (_id === undefined || _id.length === 0)
      ? MUTATION_CREATE
      : MUTATION_EDIT,
      variables: {article: {
        _id,
        name,
        disable,
        shortDesc,
        title,
        category,
        content,                  
        file
      }}//truong hợp này chỉ dùng 1 dấu nháy vì variables chỉ là 1 object ko phải thuộc tính
    }).then(result=>{
      this.onCancel()
    });
  }
  onCancel = () => {
    this.props.history.push("/article");
  };
  uploadCallback = (file) => {

    console.log("upload callback "+file.name);
   
    // var client = this.props.client;
    return new Promise(
      (resolve, reject) => {
         
          this.props.client.query({
            query : UPLOAD_QUERY,
            variables: {file}//truong hợp này chỉ dùng 1 dấu nháy vì variables chỉ là 1 object ko phải thuộc tính
          }).then(result=>{
            console.log("upload result:"+JSON.stringify(result));
            resolve({data:{link:result.data.uploadNewImage}})
          });
          
      }
    );
  }
  render = () => {
    const {
      _id,
      name,
      title,
      shortDesc,
      content,
      disable,
      thumb,
      file,
      category,
      editorState
    } = this.state;
    
    return (
      <Page
        title={(this.state._id!=null)?"Sửa bài viết":"Tạo bài viết"}
        breadcrumbs={[{ name: (this.state._id!=null)?"Sửa bài viết":"Tạo bài viết", active: true }]}
        className="Article"
      >
        <Row>
          <Col md={12}>
            <Button color="primary" onClick={()=>this.onUpdate()}>
                  Cập nhật thông tin
                </Button>
            <Button color="link" onClick={e=>this.props.history.goBack()}>
              trở lại
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form className="m-3">
            <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Ẩn/Hiện:
                </Label>
                <Col md={10} sm={8} xs={6}>
                <ToggleButton
                  disabled
                  value={ disable }
                  onToggle={(value) => {
                      this.setState({disable:!value});
                  }} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Tên bài viết:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="text"
                    value={name}
                    onChange={e => {
                      this.setState({ name: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Tiêu đề bài viết:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="text"
                    value={title}
                    onChange={e => {
                      this.setState({ title: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Loại:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="select"
                    value={category}
                    onChange={e => {
                      this.setState({ category: e.target.value });
                    }}
                    bsSize="sm"
                  >
                  <option value="news">Tin tức</option>
                  <option value="tutorial">Hướng dẫn người dùng</option>
                  <option value="employee_service_tutorial">Hướng dẫn nhân viên dịch vụ</option>
                  <option value="system_notice">Thông báo</option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Ảnh Thumb:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <img width="100" height="100"  src={thumb} style={{maxHeight:100,maxWidth:"100%"}}/>
                  <Input
                        type="file"
                        onChange={({
                        target: {
                            validity,
                            files: [file]
                        }
                        }) => {
                        this.setState({ file });
                        }}
                        bsSize="sm"
                    />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Mô tả ngắn
                </Label>
                <Col md={10} sm={8} xs={6}>
                
                  <Input
                    type="textarea"
                    value={shortDesc}
                    onChange={e => {
                      this.setState({ shortDesc: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Nội dung
                </Label>
                <Col md={10} sm={8} xs={6}>
                <div >
                    <AdvanceEditor content={content} onChange={content => this.setState({ content })}/>
                   </div>
                </Col>
              </FormGroup>
              
              
            </Form>
          </Col>
        </Row>
      </Page>
    );
  };
}
export default withApollo(ArticleEditPage);
