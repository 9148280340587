import Page from "Admin/Components/Page";
import React from "react";
import  {Card, CardBody } from "reactstrap";

import gql from "graphql-tag";
import { Query } from "react-apollo";
import authCheck from "utils/authCheck";
import ReactTable from "react-table";
import { withApollo } from "react-apollo";

export const QUERY_RINGGROUP = gql`
  {
    ringGroups {
      data{
        _id
        agents {
          _id
          displayName
        }
        name
        description
        extension
      }
    }
  }
`;

class RingGroupPage extends React.Component {
  
  render = () => {
    return (
      <Page
        title="Asterisk Template"
        breadcrumbs={[{ name: "Quản lý Media File", active: true }]}
        className="template"
      >
        <Card>
          
          <CardBody>
            <div style={{ height: 10 }} />
            <Query query={QUERY_RINGGROUP}>
              {({ loading, error, data }) => {
                var ringGroups=[];
                if (error) {
                  authCheck(error);
                }
                try{
                  ringGroups=data.ringGroups.data;
                }catch(err){}
                return (
                  <ReactTable
                    noDataText="Không có dữ liệu"
                    columns={[
                      {
                        Header: "#",
                        width: 50,
                        style: { textAlign: "center" },
                        filterable: false,
                        Cell: row => {
                          return <div>{row.index + 1}</div>;
                        }
                      },
                      {
                        Header: "Tên",
                        accessor: "name"
                      },
                      {
                        Header: "Số gọi",
                        accessor: "extension"
                      },
                      {
                        Header: "Số tổng đài viên",
                        accessor: "agents",
                        Cell: row => (
                          <div>
                            {row.value.map((agent, index) => (
                              <span key={index}>
                                {agent.displayName + " <" + agent._id + ">"}
                              </span>
                            ))}
                          </div>
                        )
                      },
                      {
                        Header: "Mô tả",
                        accessor: "description"
                      }
                    ]}
                    data={ringGroups}
                    loading={loading} // Display the loading overlay when we need it
                    filterable
                    defaultPageSize={10}
                    className="-striped -highlight"
                  />
                );
              }}
            </Query>
          </CardBody>
        </Card>
      </Page>
    );
  };
}
export default withApollo(RingGroupPage);
