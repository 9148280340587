import Page from 'components/Page';
import React from 'react';
import { Form,ButtonGroup,Badge} from 'reactstrap';
import { withApollo } from 'react-apollo'
import {MdFileDownload,MdPlayCircleFilled,MdPause} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import Moment, { duration } from 'moment';
import ReactPlayer from 'react-player'
import ReactTable from "react-table";
import {apiHost} from 'companyConstants'
import {FETCH_CDRS} from 'AdminCustomer/GqlQuery';


class OutboundReportDetail extends React.Component{
    state={
        departments :[], 
        selectedMonth:new Date(),  
        data: [],
        page: 1,
        loading: true,
        playingId:""
    }
    componentDidMount(){
        this.fetchData(true);
    }
    download = (id) => {
        window.open("http://" + apiHost + "/downloadrecord/" + id, "_blank")
    }
    fetchData=async (state, instance)=>{
        this.setState({ loading: true });
        const {filtered,pageSize,sorted,page} = state;
        
        try{
            const result = await this.props.client.query({
                query: FETCH_CDRS,
                variables: { filtered:filtered.concat([{id:"isOutbound",value:"true"},{id:"state",value:"true"}]),pageSize,sorted:sorted.concat([{id:"startTime",desc:true}]),page },
                
            })
        
            this.setState({data:result.data.cdrs.data,pages:result.data.cdrs.pages,loading:false});
        }catch(err){
           authCheck(err);
        }
    }
    download=(id)=>{
        console.log("download id:"+id);
        window.open("http://"+apiHost+"/downloadrecord/"+id,"_blank")
    }
    render=()=>{
        const {data,loading,pages,playingId}=this.state;
        return (
            <Page
                title="BÁO CÁO CHI TIẾT GỌI RA"
                breadcrumbs={[{ name: 'BÁO CÁO CHI TIẾT GỌI RA', active: true }]}            
                className="ReportCallMonitorPage">                
                    <ReactTable
                        noDataText="Không có dữ liệu"
                        className="mt-3"
                        columns={[                            
                            
                            {
                                Header:"khách hàng",
                                className:"text-center",
                                Cell:row=><span>
                                    {row.original.receiver} {row.original.customer?("("+row.original.customer.fullName+")"):null}
                                </span>
                            },                            
                            {
                                Header:"Thời gian bắt đầu",
                                className:"text-center",
                                accessor:"startTime",
                                filterable:false,
                                Cell:row=><span>
                                    <Badge style={{fontSize:14}}>{Moment(row.value).format("HH:mm")}    </Badge> {Moment(row.value).format("DD/MM/YYYY")}                                           
                                </span>
                            },  
                            {
                                Header:"Thời gian đàm thoại",
                                className:"text-center",
                                accessor:"duration",
                                filterable:false,
                                Cell:row=><span>
                                     {Moment("1900-01-01").add(row.value,'seconds').format("HH:mm:ss")}   

                                </span>
                            },                          
                            {
                                Header:"Điện thoại viên",
                                className:"text-center",
                                Cell:row=><span>
                                     {row.original.sender} {row.original.agent?"("+row.original.agent.name+")":null}
                                </span>
                            },
                            {
                                Header: "Tác vụ",
                                className: "text-center",
                                accessor: "_id",
                                width:90,
                                filterable:false,
                                Cell: row =>
                                    <Form inline>
                                        <ButtonGroup style={{fontSize:25}}>
    
                                            {
                                                playingId == row.value ?
                                                   <MdPause className="text-info m-1" onClick={e => { this.setState({ playingId: "" }) }}/>:
                                                   <MdPlayCircleFilled className="text-info m-1" onClick={e => { this.setState({ playingId: row.value }) }}/>
                                            }
    
                                            <MdFileDownload className="text-danger m-1" onClick={e => this.download(row.value)}/>
                                        </ButtonGroup>
                                    </Form>
                            },
                            {
                                Header: "Media",
                                className: "text-center",
                                accessor: "_id",
                                filterable:false,
                                minWidth:200,
                                Cell: row =>
                                    <Form inline>
                                        <ReactPlayer url={"http://" + apiHost + "/downloadrecord/" + row.value} loop={true} playing={playingId == row.value} controls={true} height="40px"/>
                                        
                                    </Form>
                            },
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={10}
                        
                    >

                    </ReactTable>
            </Page>
        )
    }

}

export default withApollo(OutboundReportDetail);