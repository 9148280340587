import Page from "components/Page";
import React, { useRef } from "react";
import { Row, Col, Input } from 'reactstrap'
import Moment from "moment";
import { withApollo } from "react-apollo";
import { Mutation } from "react-apollo";

import { Button, ButtonGroup } from 'reactstrap';
import { CREATE_LIMS, GET_CUSTOMER, GET_LIMS, GET_LIMS_BY_MABN } from 'AdminCustomer/GqlQuery'
import authCheck from "utils/authCheck";
import PrintButton from 'components/PrintButton';
import PrintDoubleTest from './PrintDoubleTest';
import PrintDoubleTest_TSG1 from './PrintDoubleTest_TSG1';
import PrintTrippleTest from './PrintTrippleTest';
import PrintTiensangiat from './PrintTiensangiat';
import PrintNips from './PrintNips';
import ReactToPrint from 'react-to-print';
import { Helmet } from "react-helmet";
// import DatePicker from 'react-datepicker';
// import DatePicker from 'react-date-picker';
import DateTimeInput from 'components/Widget/DateTimeInput'
import DateInput from 'components/Widget/DateInput'

class LimsDetail extends React.Component {
    initState = (isInit) => {
        console.log("initstate", isInit);
        if (isInit) {
            this.state = {
                _id: null,
                cao_huyet_ap: 0,
                luong_mau_nips: "",
                mabn: "",
                ho_va_ten: "",
                ngaysinh: new Date(0, 0, 1),
                diachi1: "",
                sodienthoai: "",
                chieu_cao: "",
                can_nang: "",
                nghe_nghiep: "",
                nhom_mau: "",
                chungtoc: "kinh",
                ho_ten_chong: "",
                para: "",
                tuoi_chong: 0,
                nghe_ngiep_chong: "",
                tiensu_chong: "",
                ruou_bia_chong: 0,
                thuoc_la_chong: 0,
                sdt_chong: "",
                khoang_sang_sau_gay: "",
                ngay_sieu_am: new Date(0, 0, 1),
                tuan_thai: 0,
                ngay_thai: 0,
                hutthuoc: 0,
                kinh_deu: 0,
                vongkinh: 0,
                dai_thao_duong: 0,
                viemgan: 0,
                viemgan_nam: 0,
                dai_thao_duong_nam: 0,
                soluong_ruou_bia_chong: "",
                soluong_thuoc_la_chong: "",
                bPD: "",
                cRL: "",
                ngay_dau_tien_ky_kinh_cuoi: new Date(0, 0, 1),
                ngay_du_sinh: new Date(0, 0, 1),
                ngay_chuyen_phoi: new Date(0, 0, 1),
                ngay_bom_tinh_trung: new Date(0, 0, 1),
                uongruou: 0,
                soluong_hutthuoc: "",
                soluong_uongruou: "",
                bacsy_chi_dinh: "",
                tiensu_banthan: "",
                tiensu_gia_dinh: "Không",
                nhip_tim_thai: "",
                so_con_gai: 0,
                so_con_trai: 0,
                tinh_trang_suc_khoe: "bình thường",
                phuong_phap_thu_thai: 1,
                printing: false,

                tien_san_giat: 0,
                huyetap_phai1_thap: 0,
                huyetap_phai1_cao: 0,
                huyetap_phai2_thap: 0,
                huyetap_phai2_cao: 0,
                huyetap_trai1_thap: 0,
                huyetap_trai1_cao: 0,
                huyetap_trai2_thap: 0,
                huyetap_trai2_cao: 0,
                ngay_lam_doppler: new Date(0, 0, 1),
                dong_mach_tu_cung_phai: "",
                dong_mach_tu_cung_trai: "",
                tien_san_giat_o_me: 2,
                cao_huyet_ap_co_dieu_tri: 2,
                nhom_mau: "",
                so_thai: 1,
                ma_xet_nghiem_nips: "",
                ngay_lay_mau_nips: new Date(0, 0, 1),
                nguoi_lay_mau_nips: "",
                donvi_lay_mau_nips: "",
                bacsy_chi_dinh_nips: "",
                bacsy_chi_dinh_doubletest: "",
                bacsy_chi_dinh_trippletest: "",
                bacsy_chi_dinh_tiensangiat: "",
                kqxn_sang_loc_cu: "",
                print_double_test: false,
                print_tripple_test: false,
                print_tien_san_giat: false,
                print_nips: false,
                print_double_test_tsg1: false,
                print_nst_mau_ngoaivi: false,
            };
        } else {
            this.setState({
                _id: null,
                cao_huyet_ap: 0,
                luong_mau_nips: "",
                mabn: "",
                ho_va_ten: "",
                ngaysinh: new Date(0, 0, 1),
                diachi1: "",
                sodienthoai: "",
                chieu_cao: "",
                can_nang: "",
                nghe_nghiep: "",
                nhom_mau: "",
                chungtoc: "kinh",
                ho_ten_chong: "",
                para: "",
                tuoi_chong: 0,
                nghe_ngiep_chong: "",
                tiensu_chong: "",
                ruou_bia_chong: 0,
                thuoc_la_chong: 0,
                sdt_chong: "",
                khoang_sang_sau_gay: "",
                ngay_sieu_am: new Date(0, 0, 1),
                tuan_thai: 0,
                ngay_thai: 0,
                hutthuoc: 0,
                kinh_deu: 0,
                vongkinh: 0,
                dai_thao_duong: 0,
                viemgan: 0,
                viemgan_nam: 0,
                dai_thao_duong_nam: 0,
                soluong_ruou_bia_chong: "",
                soluong_thuoc_la_chong: "",
                bPD: "",
                cRL: "",
                ngay_dau_tien_ky_kinh_cuoi: new Date(0, 0, 1),
                ngay_du_sinh: new Date(0, 0, 1),
                ngay_chuyen_phoi: new Date(0, 0, 1),
                ngay_bom_tinh_trung: new Date(0, 0, 1),
                uongruou: 0,
                soluong_hutthuoc: "",
                soluong_uongruou: "",
                bacsy_chi_dinh: "",
                tiensu_banthan: "",
                tiensu_gia_dinh: "Không",
                nhip_tim_thai: "",
                so_con_gai: 0,
                so_con_trai: 0,
                tinh_trang_suc_khoe: "bình thường",
                phuong_phap_thu_thai: 1,
                printing: false,

                tien_san_giat: 0,
                huyetap_phai1_thap: 0,
                huyetap_phai1_cao: 0,
                huyetap_phai2_thap: 0,
                huyetap_phai2_cao: 0,
                huyetap_trai1_thap: 0,
                huyetap_trai1_cao: 0,
                huyetap_trai2_thap: 0,
                huyetap_trai2_cao: 0,
                ngay_lam_doppler: new Date(0, 0, 1),
                dong_mach_tu_cung_phai: "",
                dong_mach_tu_cung_trai: "",
                tien_san_giat_o_me: 2,
                cao_huyet_ap_co_dieu_tri: 2,
                nhom_mau: "",
                so_thai: 1,
                ma_xet_nghiem_nips: "",
                ngay_lay_mau_nips: new Date(0, 0, 1),
                nguoi_lay_mau_nips: "",
                donvi_lay_mau_nips: "",
                bacsy_chi_dinh_nips: "",
                bacsy_chi_dinh_doubletest: "",
                bacsy_chi_dinh_trippletest: "",
                bacsy_chi_dinh_tiensangiat: "",
                kqxn_sang_loc_cu: "",
                print_double_test: false,
                print_tripple_test: false,
                print_tien_san_giat: false,
                print_nips: false,
                print_double_test_tsg1: false,
                print_nst_mau_ngoaivi: false,
            })
        }
    }

    constructor(props) {
        super(props);
        this.initState(true);
        this.nipsRef = React.createRef();
        this.doubleTestRef = React.createRef();
        this.trippleTestRef = React.createRef();
        this.tiensangiatRef = React.createRef();
        this.doubleTestTSG1Ref = React.createRef();
        this.nstMauNgoaiViRef = React.createRef();
    }
    componentDidMount() {
        var _id = this.props.match.params._id;
        if (_id) {
            this.props.client.query({
                query: GET_LIMS,
                variables: { _id }
            }).then(result => {
                if (result.data.response.code === 0) {
                    this.setState(result.data.response.data);
                }
            })
        }
    }

    onSave=()=>{
        const {
            _id,
            mabn,
            nghe_nghiep,
            sodienthoai,
            chungtoc,
            chieu_cao,
            can_nang,
            ho_va_ten,
            ngaysinh,
            diachi1,
            hutthuoc,
            uongruou,
            para,
            cao_huyet_ap,
            khoang_sang_sau_gay,
            phuong_phap_thu_thai,
            ngay_dau_tien_ky_kinh_cuoi,
            kinh_deu,
            vongkinh,
            ngay_sieu_am,
            bacsy_chi_dinh,
            bacsy_chi_dinh_doubletest,
            bacsy_chi_dinh_trippletest,
            bacsy_chi_dinh_tiensangiat,
            tiensu_banthan,
            tiensu_gia_dinh,
            tuan_thai,
            ngay_thai,
            bPD,
            nhip_tim_thai,
            cRL,
            soluong_hutthuoc,
            soluong_uongruou,
            ho_ten_chong,
            tuoi_chong,
            nghe_nghiep_chong,
            tiensu_chong,
            ruou_bia_chong,
            thuoc_la_chong,
            sdt_chong,
            ngay_chuyen_phoi,
            luong_mau_nips,
            ngay_bom_tinh_trung,
            so_con_gai,
            so_con_trai,
            ngay_du_sinh,
            tinh_trang_suc_khoe,
            soluong_ruou_bia_chong,
            soluong_thuoc_la_chong,
            dai_thao_duong,
            viemgan,
            viemgan_nam,
            dai_thao_duong_nam,
            tien_san_giat,
            huyetap_phai1_thap,
            huyetap_phai1_cao,
            huyetap_phai2_thap,
            huyetap_phai2_cao,
            huyetap_trai1_thap,
            huyetap_trai1_cao,
            huyetap_trai2_thap,
            huyetap_trai2_cao,
            ngay_lam_doppler,
            dong_mach_tu_cung_phai,
            dong_mach_tu_cung_trai,
            tien_san_giat_o_me,
            cao_huyet_ap_co_dieu_tri,
            nhom_mau,
            so_thai,
            ma_xet_nghiem_nips,
            ngay_lay_mau_nips,
            nguoi_lay_mau_nips,
            donvi_lay_mau_nips,
            bacsy_chi_dinh_nips,
            kqxn_sang_loc_cu,
            print_tien_san_giat,
            print_nips,
            printing,
            print_double_test,
            print_tripple_test,
            print_double_test_tsg1,

        } = this.state;
        this.props.client.mutate({
            mutation:CREATE_LIMS,
            variables:{
                lims: {
                    _id,
                    mabn,
                    nghe_nghiep,
                    sodienthoai,
                    chungtoc,
                    chieu_cao,
                    can_nang,
                    ho_va_ten,
                    ngaysinh: ngaysinh == '' ? null : ngaysinh,
                    diachi1,
                    hutthuoc,
                    uongruou,
                    para,
                    khoang_sang_sau_gay,
                    phuong_phap_thu_thai,
                    ngay_dau_tien_ky_kinh_cuoi,
                    kinh_deu,
                    cao_huyet_ap,
                    vongkinh,
                    ngay_sieu_am: ngay_sieu_am == '' ? null : ngay_sieu_am,
                    bacsy_chi_dinh,
                    bacsy_chi_dinh_doubletest,
                    bacsy_chi_dinh_trippletest,
                    bacsy_chi_dinh_tiensangiat,
                    tiensu_banthan,
                    tiensu_gia_dinh,
                    tuan_thai,
                    ngay_thai,
                    bPD,
                    nhip_tim_thai,
                    cRL,
                    soluong_hutthuoc,
                    soluong_uongruou,
                    ho_ten_chong,
                    tuoi_chong,
                    nghe_nghiep_chong,
                    tiensu_chong,
                    ruou_bia_chong,
                    thuoc_la_chong,
                    sdt_chong,
                    ngay_chuyen_phoi: ngay_chuyen_phoi == '' ? null : ngay_chuyen_phoi,
                    ngay_bom_tinh_trung: ngay_bom_tinh_trung == '' ? null : ngay_bom_tinh_trung,
                    so_con_gai,
                    so_con_trai,
                    ngay_du_sinh,
                    tinh_trang_suc_khoe,
                    soluong_ruou_bia_chong,
                    soluong_thuoc_la_chong,
                    dai_thao_duong,
                    viemgan,
                    viemgan_nam,
                    dai_thao_duong_nam,
                    tien_san_giat,
                    huyetap_phai1_thap,
                    huyetap_phai1_cao,
                    huyetap_phai2_thap,
                    huyetap_phai2_cao,
                    huyetap_trai1_thap,
                    huyetap_trai1_cao,
                    huyetap_trai2_thap,
                    huyetap_trai2_cao,
                    ngay_lam_doppler: ngay_lam_doppler == '' ? null : ngay_lam_doppler,
                    dong_mach_tu_cung_phai,
                    dong_mach_tu_cung_trai,
                    tien_san_giat_o_me,
                    cao_huyet_ap_co_dieu_tri,
                    nhom_mau,
                    so_thai,
                    ma_xet_nghiem_nips,
                    ngay_lay_mau_nips: ngay_lay_mau_nips == '' ? null : ngay_lay_mau_nips,
                    nguoi_lay_mau_nips,
                    donvi_lay_mau_nips,
                    bacsy_chi_dinh_nips,
                    kqxn_sang_loc_cu
                }
            }
        }).then(result=>{
            if(result.data.response.code==0){
                this.initState();
            }
        })
    }

    onFetchCustomer = () => {
        const { mabn } = this.state;
        if (mabn) {
            this.props.client
                .query({
                    query: GET_CUSTOMER,
                    variables: { patientId: mabn }
                })
                .then(result => {
                    authCheck(result);
                    try {
                        // this.setState(
                        //     result.data.response.data
                        // );
                        var customer = result.data.customer.data;
                        this.setState({
                            mabn: customer.patientId,
                            ho_va_ten: customer.fullName,
                            chieu_cao: customer.height,
                            can_nang: customer.weight,
                            ngaysinh: customer.birthDay,
                            diachi1: customer.district != null ? customer.province : customer.address,
                            sodienthoai: customer.phoneNumber
                        })
                    }
                    catch (er) { }
                });
        }
    };

    render() {
        const {
            _id,
            mabn,
            nghe_nghiep,
            sodienthoai,
            chungtoc,
            chieu_cao,
            can_nang,
            ho_va_ten,
            ngaysinh,
            diachi1,
            hutthuoc,
            uongruou,
            para,
            cao_huyet_ap,
            khoang_sang_sau_gay,
            phuong_phap_thu_thai,
            ngay_dau_tien_ky_kinh_cuoi,
            kinh_deu,
            vongkinh,
            ngay_sieu_am,
            bacsy_chi_dinh,
            bacsy_chi_dinh_doubletest,
            bacsy_chi_dinh_trippletest,
            bacsy_chi_dinh_tiensangiat,
            tiensu_banthan,
            tiensu_gia_dinh,
            tuan_thai,
            ngay_thai,
            bPD,
            nhip_tim_thai,
            cRL,
            soluong_hutthuoc,
            soluong_uongruou,
            ho_ten_chong,
            tuoi_chong,
            nghe_nghiep_chong,
            tiensu_chong,
            ruou_bia_chong,
            thuoc_la_chong,
            sdt_chong,
            ngay_chuyen_phoi,
            luong_mau_nips,
            ngay_bom_tinh_trung,
            so_con_gai,
            so_con_trai,
            ngay_du_sinh,
            tinh_trang_suc_khoe,
            soluong_ruou_bia_chong,
            soluong_thuoc_la_chong,
            dai_thao_duong,
            viemgan,
            viemgan_nam,
            dai_thao_duong_nam,
            tien_san_giat,
            huyetap_phai1_thap,
            huyetap_phai1_cao,
            huyetap_phai2_thap,
            huyetap_phai2_cao,
            huyetap_trai1_thap,
            huyetap_trai1_cao,
            huyetap_trai2_thap,
            huyetap_trai2_cao,
            ngay_lam_doppler,
            dong_mach_tu_cung_phai,
            dong_mach_tu_cung_trai,
            tien_san_giat_o_me,
            cao_huyet_ap_co_dieu_tri,
            nhom_mau,
            so_thai,
            ma_xet_nghiem_nips,
            ngay_lay_mau_nips,
            nguoi_lay_mau_nips,
            donvi_lay_mau_nips,
            bacsy_chi_dinh_nips,
            kqxn_sang_loc_cu,
            print_tien_san_giat,
            print_nips,
            printing,
            print_double_test,
            print_tripple_test,
            print_double_test_tsg1,

        } = this.state;


        return (
            <div>

                <Row style={{ position: "fixed", bottom: 10 }}>
                    <Col>
                        <ButtonGroup style={{ marginLeft: 10 }}>
                            <Button onClick={this.onSave} color="success">Lưu</Button>
                            
                            <ReactToPrint
                                trigger={() => <Button>DoubleTest</Button>}
                                content={() => this.doubleTestRef.current}
                            />
                            <ReactToPrint
                                trigger={() => <Button>TrippleTest</Button>}
                                content={() => this.trippleTestRef.current}
                            />
                            <ReactToPrint
                                trigger={() => <Button>TSG</Button>}
                                content={() => this.tiensangiatRef.current}
                            />
                            <ReactToPrint
                                trigger={() => <Button>DoubleTest+TSG</Button>}
                                content={() => this.doubleTestTSG1Ref.current}
                            />
                            <ReactToPrint
                                trigger={() => <Button> Nips!</Button>}
                                content={() => this.nipsRef.current}
                            />
                            <Button color="success" onClick={e => {this.onSave();this.props.history.push(`/phieu-tra-kqxn/${mabn}`)}}>Hẹn trả KQ</Button>
                            <Button color="info" onClick={e => this.props.history.push("/lims")}>Quay lại</Button>
                        </ButtonGroup>
                    </Col>
                </Row>

                <div style={{ display: (print_double_test == true ? "" : "none") }}>
                    <PrintDoubleTest {...this.state} objRef={this.doubleTestRef} />
                </div>
                <div style={{ display: (print_tripple_test == true ? "" : "none") }}>
                    <PrintTrippleTest {...this.state} objRef={this.trippleTestRef} />
                </div>

                <div style={{ display: (print_tien_san_giat == true ? "" : "none") }}>
                    <PrintTiensangiat {...this.state} objRef={this.tiensangiatRef} />
                </div>
                <div style={{ display: (print_double_test_tsg1 == true ? "" : "none") }}>
                    <PrintDoubleTest_TSG1 {...this.state} objRef={this.doubleTestTSG1Ref} />
                </div>
                <div style={{ display: (print_nips == true ? "" : "none") }}>
                    <PrintNips {...this.state} objRef={this.nipsRef} />
                </div>

                {
                    printing == true ? null :
                        <div>
                            <div style={{ margin: "auto", background: "#fff", display: "flex", padding: "0 60px", flexWrap: "wrap", justifyContent: "space-between" }}>
                                <div class="form_info">
                                    <p className="header">I. THÔNG TIN THAI PHỤ</p>
                                    <table cellpadding="0" cellspacing="0" >
                                        <tbody>
                                            <tr>
                                                <th style={{ width: `${100 / 6}%` }}></th>
                                                <th style={{ width: `${100 / 6}%` }}></th>
                                                <th style={{ width: `${100 / 6}%` }}></th>
                                                <th style={{ width: `${100 / 6}%` }}></th>
                                                <th style={{ width: `${100 / 6}%` }}></th>
                                                <th style={{ width: `${100 / 6}%` }}></th>
                                            </tr>
                                            <tr class="bg_gray">
                                                <td colSpan="6">
                                                    1. THÔNG TIN CƠ BẢN
                                            </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Mã BN:
                                            </td>
                                                <td>
                                                    <Input
                                                        onKeyPress={e => {
                                                            if (e.charCode === 13) {
                                                                this.onFetchCustomer();
                                                            }
                                                        }} type="text" placeholder="Nhập Mã BN" class="text_Input" value={mabn} onChange={e => this.setState({ mabn: e.target.value })} />

                                                </td>
                                                <td>Họ tên:</td>
                                                <td>
                                                    <Input type="text" placeholder="Nhập họ tên bệnh nhân" class="text_Input" value={ho_va_ten} onChange={e => this.setState({ ho_va_ten: e.target.value })} />
                                                </td>
                                                <td>Ngày sinh:</td>
                                                <td>

                                                    <DateInput
                                                        onChange={date => this.setState({ ngaysinh: date })}
                                                        value={Moment(ngaysinh).toDate()}
                                                    />
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>Địa chỉ:</td>
                                                <td>

                                                    <Input type="text" placeholder="Địa chỉ liên hệ" class="text_Input" value={diachi1} onChange={e => this.setState({ diachi1: e.target.value })} />

                                                </td>
                                                <td>Cân nặng:</td>
                                                <td>

                                                    <Input type="text" placeholder="Nhập cân nặng (kg)" class="text_Input" value={can_nang ? can_nang : ""} onChange={e => this.setState({ can_nang: e.target.value })} />

                                                </td>
                                                <td>Chiều cao:</td>
                                                <td><Input type="text" placeholder="Nhập chiều cao (cm)" class="text_Input" value={chieu_cao ? chieu_cao : ""} onChange={e => this.setState({ chieu_cao: e.target.value })} /></td>

                                            </tr>
                                            <tr>
                                                <td>Số ĐT:</td>
                                                <td>
                                                    <Input onKeyPress={e => {
                                                        if (e.charCode === 13) this.onFetchCustomer();
                                                    }} type="text" value={sodienthoai} onChange={e => this.setState({ sodienthoai: e.target.value })} placeholder="Nhập số ĐT" class="text_Input" />

                                                </td>
                                                <td>Nghề nghiệp:</td>
                                                <td>
                                                    <Input type="text" placeholder="Nghề nghiệp" class="text_Input" value={nghe_nghiep} onChange={e => this.setState({ nghe_nghiep: e.target.value })} />

                                                </td>
                                                <td>Dân tộc:</td>
                                                <td>
                                                    <Input type="text" placeholder="dân tộc" class="text_Input" value={chungtoc} onChange={e => this.setState({ chungtoc: e.target.value })} />
                                                </td>
                                            </tr>

                                            <tr class="bg_gray">
                                                <td colspan="6">
                                                    2. TIỀN SỬ
                                    </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    Viêm/ung thư gan
                                    </td>
                                                <td>
                                                    <Input checked={viemgan == 1} type="checkbox" className="ml-4" onChange={e => this.setState({ viemgan: (e.target.checked == true ? 1 : 0) })} />
                                                </td>
                                                <td>
                                                    Chuẩn đoán năm:
                                    </td>
                                                <td colspan="2">
                                                    <Input value={viemgan_nam} onChange={e => this.setState({ viemgan_nam: e.target.value })} class="text_Input" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    Đái tháo đường:
                                    </td>
                                                <td>
                                                    <Input type="checkbox" className="ml-4" checked={dai_thao_duong == 1} onChange={e => this.setState({ dai_thao_duong: (e.target.checked == true ? 1 : 0) })} />
                                                </td>
                                                <td>
                                                    Chuẩn đoán năm:
                                    </td>
                                                <td colspan="2">
                                                    <Input value={dai_thao_duong_nam} onChange={e => this.setState({ dai_thao_duong_nam: e.target.value })} class="text_Input" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    Tiền sản giật
                                        </td>
                                                <td>
                                                    <Input type="checkbox" className="ml-4" checked={tien_san_giat == 1} onChange={e => this.setState({ tien_san_giat: (e.target.checked ? 1 : 0) })} />
                                                </td>
                                                <td>
                                                    Tiền sản giật ở mẹ
                                            </td>
                                                <td colspan="2">
                                                    <table className="table-inline">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <label className="ml-4">
                                                                        <Input type="radio" name="tien_san_giat_o_me" checked={tien_san_giat_o_me == 1} onChange={e => this.setState({ tien_san_giat_o_me: (e.target.checked == true ? 1 : tien_san_giat_o_me) })} /><span>&nbsp;Có</span>
                                                                    </label>
                                                                </td>
                                                                <td>
                                                                    <label>
                                                                        <Input type="radio" name="tien_san_giat_o_me" checked={tien_san_giat_o_me == 0} onChange={e => this.setState({ tien_san_giat_o_me: (e.target.checked == true ? 0 : tien_san_giat_o_me) })} /><span>&nbsp;Không</span>
                                                                    </label>
                                                                </td>

                                                                <td>
                                                                    <label>
                                                                        <Input type="radio" name="tien_san_giat_o_me" checked={tien_san_giat_o_me == 2} onChange={e => this.setState({ tien_san_giat_o_me: (e.target.checked == true ? 2 : tien_san_giat_o_me) })} /><span>&nbsp;Không biết</span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr class="bg_gray">
                                                <td colSpan="6">
                                                    3. THÓI QUEN
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Hút thuốc:</td>
                                                <td colspan="3">
                                                    <table className="table-inline">
                                                        <tbody>
                                                            <tr >
                                                                <td>
                                                                    <span className="ml-4"><Input class="form-check-Input1" name="hutthuoc" type="radio" checked={hutthuoc == 0} onChange={e => { this.setState({ hutthuoc: (e.target.checked ? 0 : hutthuoc) }) }} />
                                                                        <label class="form-check-label1"> <b>Không</b> </label></span>
                                                                </td>
                                                                <td>
                                                                    <span className="ml-4"><Input class="form-check-Input1" type="radio" name="hutthuoc" checked={hutthuoc == 1} onChange={e => this.setState({ hutthuoc: (e.target.checked ? 1 : hutthuoc) })} />
                                                                        <label class="form-check-label1"> Có </label></span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    Số lượng
                                            </td>
                                                <td>
                                                    <Input className="text_Input" onChange={e => this.setState({ soluong_hutthuoc: e.target.value })} value={soluong_hutthuoc} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Uống rượu:</td>
                                                <td colspan="3">
                                                    <table className="table-inline" >
                                                        <tbody>
                                                            <tr >
                                                                <td>
                                                                    <span className="ml-4"><Input class="form-check-Input1" type="radio" name="uongruou" checked={uongruou == 0} onChange={e => this.setState({ uongruou: (e.target.checked ? 0 : uongruou) })} id="defaultCheck3" checked />
                                                                        <label class="form-check-label1" for="defaultCheck3"> <b>Không</b> </label></span>:
                                                                </td>
                                                                <td>
                                                                    <span className="ml-4"><Input class="form-check-Input1" type="radio" name="uongruou" id="defaultCheck4" checked={uongruou == 1} onChange={e => this.setState({ uongruou: (e.target.checked ? 1 : uongruou) })} />
                                                                        <label class="form-check-label1" for="defaultCheck4"> Có </label></span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    Số lượng
                                            </td>
                                                <td>
                                                    <Input value={soluong_uongruou} onChange={e => this.setState({ soluong_uongruou: e.target.value })} className="text_Input" />
                                                </td>
                                            </tr>
                                            <tr class="bg_gray">
                                                <td colSpan="6">
                                                    3.PARA
                                        </td>
                                            </tr>
                                            <tr>
                                                <td>PARA:</td>
                                                <td>
                                                    <Input type="text" placeholder="..........." value={para} onChange={e => this.setState({ para: e.target.value })} class="text_Input" />
                                                </td>
                                                <td>Đã có mấy con:</td>
                                                <td>
                                                    <table className="table-inline">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <Input type="text" placeholder=".........." value={so_con_trai ? so_con_trai : ""} onChange={e => this.setState({ so_con_trai: e.target.value })} class="text_Input" style={{ width: 50 }} /></td>
                                                                <td><b>Trai</b></td>
                                                                <td><Input type="text" placeholder=".........." class="text_Input" value={so_con_gai ? so_con_gai : ""} onChange={e => this.setState({ so_con_gai: e.target.value })} style={{ width: 50 }} /></td>
                                                                <td><b>Gái</b></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    Tình trạng sức khỏe
                                             </td>
                                                <td>
                                                    <Input type="text" placeholder="..........." class="text_Input" value={tinh_trang_suc_khoe} onChange={e => this.setState({ tinh_trang_suc_khoe: e.target.value })} />
                                                </td>
                                            </tr>
                                            <tr class="bg_gray">
                                                <td colspan="6">
                                                    4.THAI LẦN NÀY
                                            </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    số thai
                                                 </td>
                                                <td colspan="5">
                                                    <table className="table-inline">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    Đơn thai
                                                        </td>
                                                                <td>
                                                                    <Input type="radio" name="so_thai" checked={so_thai == 1} onChange={e => this.setState({ so_thai: (e.target.checked == true ? 1 : 2) })} />
                                                                </td>
                                                                <td>
                                                                    Đa thai
                                                </td>
                                                                <td>
                                                                    <Input type="radio" name="so_thai" checked={so_thai == 2} onChange={e => this.setState({ so_thai: (e.target.checked == true ? 2 : 1) })} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>phương thức HTSS:</td>
                                                <td colspan="5">
                                                    <table className="table-inline">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <span className="ml-4"><Input class="form-check-Input1" type="radio" name="phuong_phap_thu_thai" id="defaultCheck5" checked={phuong_phap_thu_thai == 1} onChange={e => this.setState({ phuong_phap_thu_thai: 1 })} />
                                                                        <label class="form-check-label1" for="defaultCheck3"> <b>Tự nhiên</b> </label></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <span className="ml-4"><Input class="form-check-Input1" type="radio" name="phuong_phap_thu_thai" id="defaultCheck6" checked={phuong_phap_thu_thai == 3} onChange={e => this.setState({ phuong_phap_thu_thai: 3 })} />
                                                                        <label class="form-check-label1" for="defaultCheck3"> <b>IVF</b> </label></span>
                                                                </td>
                                                                <td>Ngày chuyển phôi:</td>
                                                                <td>

                                                                    <DateInput
                                                                        onChange={date => this.setState({ ngay_chuyen_phoi: date })}
                                                                        value={Moment(ngay_chuyen_phoi).toDate()}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <span className="ml-4"><Input class="form-check-Input1" type="radio" name="phuong_phap_thu_thai" id="defaultCheck7" checked={phuong_phap_thu_thai == 2} onChange={e => this.setState({ phuong_phap_thu_thai: 2 })} />
                                                                        <label class="form-check-label1" for="defaultCheck3"> <b>IUI</b> </label></span>:
                                                        </td>
                                                                <td>Ngày bơm tinh trùng:</td>
                                                                <td>
                                                                    <DateInput
                                                                        onChange={date => this.setState({ ngay_bom_tinh_trung: date })}
                                                                        value={Moment(ngay_bom_tinh_trung).toDate()}
                                                                    />
                                                                    {/* <Input type="date" placeholder=".........." class="text_Input" value={Moment(ngay_bom_tinh_trung).format("YYYY-MM-DD")} onChange={e => this.setState({ ngay_bom_tinh_trung: e.target.value })} /> */}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colSpan="2">Ngày đầu kỳ kinh cuối cùng:</td>
                                                <td>
                                                    <DateInput
                                                        onChange={date => this.setState({ ngay_dau_tien_ky_kinh_cuoi: date })}
                                                        value={Moment(ngay_dau_tien_ky_kinh_cuoi).toDate()}
                                                    />
                                                </td>
                                                <td>
                                                    Kinh đều &nbsp;
                                                    <Input class="form-check-Input1" type="checkbox" className="ml-4" id="defaultCheck8" onChange={e => { this.setState({ kinh_deu: (e.target.checked ? 1 : 0) }) }} checked={kinh_deu == 1} /></td>
                                                <td >Vòng kinh</td>
                                                <td>
                                                    <Input type="text" value={vongkinh} onChange={e => this.setState({ vongkinh: e.target.value })} placeholder=".........." class="text_Input" id="" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">Dự kiến sinh (siêu âm lúc 8 - 12 tuần):</td>
                                                <td colspan="4">

                                                    <DateInput
                                                        onChange={date => this.setState({ ngay_du_sinh: date })}
                                                        value={Moment(ngay_du_sinh).toDate()}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Siêu âm ngày:</td>
                                                <td>
                                                    <DateInput
                                                        onChange={date => this.setState({ ngay_sieu_am: date })}
                                                        value={Moment(ngay_sieu_am).toDate()}
                                                    />
                                                </td>
                                                <td>Thai tương đương:</td>
                                                <td>
                                                    <table className="table-inline">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <Input style={{ width: 50 }} id="tuan" type="text" placeholder=".........." class="text_Input" value={tuan_thai} onChange={e => this.setState({ tuan_thai: e.target.value })} />
                                                                </td>
                                                                <td>
                                                                    tuần
                                                </td>
                                                                <td>
                                                                    <Input style={{ width: 50 }} type="text" placeholder=".........." class="text_Input" value={ngay_thai} onChange={e => this.setState({ ngay_thai: e.target.value })} />
                                                                </td>
                                                                <td>
                                                                    ngày
                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>BPD:</td>
                                                <td>
                                                    <Input type="text" placeholder=".........." class="text_Input" value={bPD} onChange={e => this.setState({ bPD: e.target.value })} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    CRL
                                            </td>
                                                <td>
                                                    <Input placeholder=".....mm" className="text_Input" value={cRL ? cRL : ""} onChange={e => this.setState({ cRL: e.target.value })} />
                                                </td>
                                                <td>Khoảng sáng sau gáy:</td>
                                                <td>
                                                    <Input type="text" placeholder=".....mm" class="text_Input" value={khoang_sang_sau_gay ? khoang_sang_sau_gay : ""} onChange={e => this.setState({ khoang_sang_sau_gay: e.target.value })} />

                                                </td>
                                                <td>NT:</td>
                                                <td>
                                                    <Input type="text" placeholder=".........." class="text_Input" value={nhip_tim_thai ? nhip_tim_thai : ""} onChange={e => this.setState({ nhip_tim_thai: e.target.value })} />
                                                </td>
                                            </tr>
                                            <tr class="bg_gray">
                                                <td colSpan="6">
                                                    5.KẾT QUẢ XÉT NGHIỆM DOPPLER
                                    </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Ngày làm:
                                    </td>
                                                <td>

                                                    <DateInput
                                                        onChange={date => this.setState({ ngay_lam_doppler: date })}
                                                        value={Moment(ngay_lam_doppler).toDate()}

                                                    />
                                                </td>
                                                <td>
                                                    DMTC phải
                                    </td>
                                                <td>
                                                    <Input class="text_Input" value={dong_mach_tu_cung_phai} onChange={e => this.setState({ dong_mach_tu_cung_phai: e.target.value })} />
                                                </td>
                                                <td>
                                                    DMTC Trái
                                    </td>
                                                <td>
                                                    <Input class="text_Input" value={dong_mach_tu_cung_trai} onChange={e => this.setState({ dong_mach_tu_cung_trai: e.target.value })} />
                                                </td>
                                            </tr>
                                            <tr class="bg_gray">
                                                <td colSpan="6">
                                                    6.HUYẾT ÁP
                                            </td>
                                            </tr>

                                            <tr>
                                                <td colspan="2">
                                                    Có tiền sử cao huyết áp? <Input class="form-check-Input1" type="checkbox" className="ml-4" onChange={e => { this.setState({ cao_huyet_ap: (e.target.checked ? 1 : 0), cao_huyet_ap_co_dieu_tri: e.target.checked ? 0 : 2 }) }} checked={cao_huyet_ap == 1} />
                                                </td>
                                                <td colspan="2">
                                                    <label>
                                                        <span>Huyết ap cao có điều trị? </span>
                                                        {cao_huyet_ap == 1 ? <Input onChange={e => this.setState({ cao_huyet_ap_co_dieu_tri: e.target.checked == true ? 1 : 0 })} type="checkbox" className="ml-4" checked={cao_huyet_ap_co_dieu_tri == 1} /> : null}
                                                    </label>
                                                </td>
                                                <td>
                                                    Nhóm máu
                                            </td>
                                                <td>
                                                    <Input class="text_Input" value={nhom_mau} onChange={e => this.setState({ nhom_mau: e.target.value })} />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td rowSpan="2">
                                                    Tay trái
                                    </td>
                                                <td>
                                                    Lần 1
                                    </td>
                                                <td>
                                                    <table className="table-inline">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <Input placeholder="cao" type="number" class="text_Input" value={huyetap_trai1_cao} onChange={e => this.setState({ huyetap_trai1_cao: e.target.value })} />
                                                                </td>
                                                                <td>
                                                                    <Input placeholder="thấp" type="number" class="text_Input" value={huyetap_trai1_thap} onChange={e => this.setState({ huyetap_trai1_thap: e.target.value })} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td rowSpan="2">
                                                    Tay phải
                                            </td>
                                                <td>
                                                    Lần 1
                                            </td>
                                                <td>
                                                    <table className="table-inline">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <Input placeholder="cao" type="number" class="text_Input" value={huyetap_phai1_cao} onChange={e => this.setState({ huyetap_phai1_cao: e.target.value })} />
                                                                </td>
                                                                <td>
                                                                    <Input placeholder="thấp" type="number" class="text_Input" value={huyetap_phai1_thap} onChange={e => this.setState({ huyetap_phai1_thap: e.target.value })} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Lần 2
                                            </td>
                                                <td>
                                                    <table className="table-inline">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <Input placeholder="cao" type="number" class="text_Input" value={huyetap_trai2_cao} onChange={e => this.setState({ huyetap_trai2_cao: e.target.value })} />
                                                                </td>
                                                                <td>
                                                                    <Input placeholder="thấp" type="number" class="text_Input" value={huyetap_trai2_thap} onChange={e => this.setState({ huyetap_trai2_thap: e.target.value })} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    Lần 2
                                            </td>
                                                <td>
                                                    <table className="table-inline">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <Input placeholder="cao" type="number" class="text_Input" value={huyetap_phai2_cao} onChange={e => this.setState({ huyetap_phai2_cao: e.target.value })} />
                                                                </td>
                                                                <td>
                                                                    <Input placeholder="thấp" type="number" class="text_Input" value={huyetap_phai2_thap} onChange={e => this.setState({ huyetap_phai2_thap: e.target.value })} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr class="bg_gray">
                                                <td colSpan="6">
                                                    7. CHỈ ĐỊNH CỦA BÁC SỸ
                                    </td>
                                            </tr>
                                            <tr>
                                                <td>DoubleTest:</td>
                                                <td colspan="5">
                                                    <Input style={{ width: "100%" }} type="text" value={bacsy_chi_dinh_doubletest ? bacsy_chi_dinh_doubletest : ""} onChange={e => this.setState({ bacsy_chi_dinh_doubletest: e.target.value })} placeholder=".........." className="text_Input" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>TrippleTest:</td>
                                                <td colspan="5">
                                                    <Input style={{ width: "100%" }} type="text" value={bacsy_chi_dinh_trippletest ? bacsy_chi_dinh_trippletest : ""} onChange={e => this.setState({ bacsy_chi_dinh_trippletest: e.target.value })} placeholder=".........." class="text_Input" id="" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Tiền sản giật:</td>
                                                <td colspan="5">
                                                    <Input style={{ width: "100%" }} type="text" value={bacsy_chi_dinh_tiensangiat ? bacsy_chi_dinh_tiensangiat : ""} onChange={e => this.setState({ bacsy_chi_dinh_tiensangiat: e.target.value })} placeholder=".........." class="text_Input" id="" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Nips:</td>
                                                <td colspan="5">
                                                    <Input style={{ width: "100%" }} type="text" value={bacsy_chi_dinh_nips ? bacsy_chi_dinh_nips : ""} onChange={e => this.setState({ bacsy_chi_dinh_nips: e.target.value })} placeholder=".........." class="text_Input" id="" />
                                                </td>
                                            </tr>
                                            <tr class="bg_gray">
                                                <td colSpan="6">
                                                    8.XÉT NGHIỆM NIPS
                                    </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Mã XN nips
                                            </td>
                                                <td>
                                                    <Input type="text" class="text_Input" value={ma_xet_nghiem_nips ? ma_xet_nghiem_nips : ""} onChange={e => this.setState({ ma_xet_nghiem_nips: e.target.value })} />
                                                </td>
                                                <td>
                                                    Ngày lấy mẫu:
                                            </td>
                                                <td>
                                                    <DateInput
                                                        onChange={date => this.setState({ ngay_lay_mau_nips: date })}
                                                        value={Moment(ngay_lay_mau_nips).toDate()}
                                                    />
                                                </td>

                                                <td>
                                                    Người lấy mẫu
                                    </td>
                                                <td>
                                                    <Input class="text_Input" value={nguoi_lay_mau_nips ? nguoi_lay_mau_nips : ""} onChange={e => this.setState({ nguoi_lay_mau_nips: e.target.value })} />
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    Đơn vị lấy mẫu:
                                            </td>
                                                <td>
                                                    <Input className="text_Input" value={donvi_lay_mau_nips ? donvi_lay_mau_nips : ""} onChange={e => this.setState({ donvi_lay_mau_nips: e.target.value })} />
                                                </td>
                                                <td>
                                                    Lượng máu:
                                            </td>
                                                <td>
                                                    <Input class="text_Input" value={luong_mau_nips ? luong_mau_nips : ""} onChange={e => this.setState({ luong_mau_nips: e.target.value })} />
                                                </td>
                                                <td colspan="2"></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    KQXN Sàn lọc đã có:
                                            </td>
                                                <td colspan="4">
                                                    <Input class="text_Input" value={kqxn_sang_loc_cu ? kqxn_sang_loc_cu : ""} onChange={e => this.setState({ kqxn_sang_loc_cu: e.target.value })} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <div class="form_info">
                                    <p className="header">II. TIỀN SỬ NỘI NGOẠI KHOA</p>
                                    <table cellpadding="0" cellspacing="0" >
                                        <tbody>
                                            <tr>
                                                <td style={{ width: 200 }}>Bản thân:</td>
                                                <td>
                                                    <Input style={{ width: "100%" }} value={tiensu_banthan} onChange={e => this.setState({ tiensu_banthan: e.target.value })} type="text" placeholder=".........." class="text_Input" id="" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: 200 }}>Gia đình:</td>
                                                <td>
                                                    <Input style={{ width: "100%" }} type="text" placeholder=".........." class="text_Input" value={tiensu_gia_dinh} onChange={e => this.setState({ tiensu_gia_dinh: e.target.value })} />
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="form_info">
                                    <p className="header">III. THÔNG TIN CHỒNG THAI PHỤ</p>
                                    <table cellpadding="0" cellspacing="0" >
                                        <tbody>
                                            <tr>
                                                <td>Họ và tên:</td>
                                                <td>
                                                    <Input
                                                        type="text" placeholder="Tên chồng" class="text_Input" value={ho_ten_chong} onChange={e => this.setState({ ho_ten_chong: e.target.value })} />
                                                </td>
                                                <td>Nghề nghiệp:</td>
                                                <td>
                                                    <Input type="text" placeholder="Nghề nghiệp" class="text_Input" value={nghe_nghiep_chong} onChange={e => this.setState({ nghe_nghiep_chong: e.target.value })} />
                                                </td>
                                                <td>Số điện thoại:</td>
                                                <td>
                                                    <Input type="text" placeholder="Số điện thoại" class="text_Input" value={sdt_chong} onChange={e => this.setState({ sdt_chong: e.target.value })} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Rượu bia:
                                    </td>
                                                <td>
                                                    <Input type="checkbox" className="ml-4" placeholder="Rượu bia" checked={ruou_bia_chong == 1} onChange={e => this.setState({ ruou_bia_chong: (e.target.checked ? 1 : 0) })} />
                                                </td>
                                                <td>
                                                    Số lượng:
                                    </td>
                                                <td colspan="3">
                                                    <Input className="text_Input" onChange={e => this.setState({ soluong_ruou_bia_chong: e.target.value })} value={soluong_ruou_bia_chong} />
                                                </td>


                                            </tr>
                                            <tr>
                                                <td>
                                                    Thuốc lá:
                                            </td>
                                                <td>
                                                    <Input type="checkbox" className="ml-4" checked={thuoc_la_chong == 1} onChange={e => this.setState({ thuoc_la_chong: (e.target.checked == true ? 1 : 0) })} />
                                                </td>
                                                <td>
                                                    Số lượng hút:
                                            </td>
                                                <td colspan="3">
                                                    <Input type="text" placeholder="Điếu/ngày" class="text_Input" value={soluong_thuoc_la_chong} onChange={e => this.setState({ soluong_thuoc_la_chong: e.target.value })} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Tuổi:
                                    </td>
                                                <td>
                                                    <Input onChange={e => this.setState({ tuoi_chong: e.target.value })} type="number" value={tuoi_chong} class="text_Input" />
                                                </td>
                                                <td>
                                                    Tiền sử nội ngoại khoa
                                    </td>
                                                <td colspan="3">
                                                    <Input type="text" placeholder="Tiền sử nội ngoại khoa" class="text_Input" value={tiensu_chong} onChange={e => this.setState({ tiensu_chong: e.target.value })} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>

                }
                <Helmet>
                    <style>
                        {`
                            tr {
                                padding: 5px 10px;
                                border: 1px solid #333333;
                            }
                            td {
                                padding: 10px;
                                border: 1px solid #ededed;
                                text-align:left ;
                            }
                            td p {
                                text-align:left;
                                
                            }
                            p{
                                margin:3px 0 !important
                            }
                            
                            .bg_gray{ background: #F4F1F1;}
                            
                            .table_text tr,.table_text tr td{border: none !important;}
                            .text_input{border: 1px solid #ededed; padding: 0 12px; color: #333; text-transform: uppercase; font-weight: bold; width: 100%;}
                            .form_info{
                                width: calc(100% - 20px); padding-top: 10px;
                            }
                            .form_info .header{
                                width: 100%; font-size: 16px; font-weight: bold;
                            }
                            .form_info td{
                                min-width: 90px;
                            }
                            .form_info .table_text td{
                                vertical-align: top;min-width: 0;
                            }
                            
                            table tr td table tr td{border: none;} 
                            .form_info table{
                                border-top: 1px solid #ededed;  max-width: 100%; width: 100%; 
                            }
                            .form-check-label1{
                                margin-left: 10px;
                            }
                            .body{
                                background: #ededed;
                                font-size:16px;
                            }
                            .body table{
                                font-size: 16px;
                            }
                            .body h1, h2, h3, h4, h5, h6, p, a, td, button{
                                 font-family: Gotham, Helvetica Neue, Helvetica, Arial,' sans-serif' !important;
                            }
                            
                            .body .content{
                                 margin: auto; background: #fff; display: flex; padding:40px 60px; flex-wrap: wrap; justify-content: space-between;
                            }
                            
                            .frm_check {
                                display: block;
                                position: relative;
                                padding-left: 35px;
                                margin-bottom: 12px;
                                cursor: pointer;
                                font-size: 16px;
                                -webkit-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                              }
                              
                              /* Hide the browser's default checkbox */
                              /* .frm_check input {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                                height: 0;
                                width: 0;
                              } */
                              
                              /* Create a custom checkbox */
                              .checkmark {
                                display: inline-block;
                                height: 18px;
                                width: 18px;
                                background-color: #eee;
                                margin: -3px 5px;
                                    border: 1px solid #333;
                              }
                              
                              /* On mouse-over, add a grey background color */
                              /* .frm_check:hover input ~ .checkmark {
                                background-color: #ccc;
                              } */
                              
                              /* When the checkbox is checked, add a blue background */
                              /* .frm_check .checked ~ .checkmark {
                                background-color: #2196F3;
                              } */
                              
                              /* Create the checkmark/indicator (hidden when not checked) */
                              .checkmark:after {
                                content: "";
                                margin:3px 4px;
                                /* position: absolute; */
                                display: none;
                                color:0;
                              }
                              
                              /* Show the checkmark when checked */
                              .frm_check .checked ~ .checkmark:after {
                                display: block;
                              }
                              
                              /* Style the checkmark/indicator */
                              .frm_check .checkmark:after {
                                left: 9px;
                                top: 5px;
                                width: 5px;
                                height: 10px;
                                border: solid grey;
                                border-width: 0 3px 3px 0;
                                -webkit-transform: rotate(45deg);
                                -ms-transform: rotate(45deg);
                                transform: rotate(45deg);
                              }
                              .table-inline{
                                    border-top: 0 !important;margin-left: -10px;
                                    border:none !important;
                                }
                                .table-inline tr{
                                    border:none !important;
                                }
                            
                            
                        `}
                    </style>
                </Helmet>

                <div style={{ width: "100%", height: 100 }}></div>

            </div>
        )
    }

}

export default withApollo(LimsDetail);