import Page from "Admin/Components/Page";
import React, { Fragment } from "react";
import { Button, Input, Col, Label, Form, FormGroup } from "reactstrap";
import { withApollo } from "react-apollo";
import authCheck from "utils/authCheck";
import gql from "graphql-tag";
import { Mutation,Query } from "react-apollo";
import ToggleButton from "react-toggle-button";

export const QUERY_DETAIL = gql`
  query AdminQuery($_id: String) {
    menu(_id: $_id) {
      code
      message
      data{
        _id
        name
        level
        icon
        description
        disable
        path
        parentId
        sort
        isAdmin
      }
    }
  }
`;
export const MUTATION_CREATE_MENU = gql`
  mutation Mutation($menu: MenuAdminInputType) {
    createMenu(menu: $menu) {
      code
      message
      data{_id}
    }
  }
`;
export const MUTATION_EDIT_MENU = gql`
  mutation Mutation($menu: MenuAdminInputType) {
    updateMenu(menu: $menu) {
      code
      message
      data{_id}
    }
  }
`;
export const QUERY_LIST_MENU = gql`
query AdminQuery($isAdmin:String){
    menus(pageSize:0,filtered:[{id:"IsAdmin",value:$isAdmin}],sorted:[{id:"level",desc:false},{id:"sort",desc:false}]){
      code
      message
      data{
        name
        _id
        level
      }
    }
  }
`;

class MenuEditPage extends React.Component {
  constructor() {
    super();
    this.state = {
      _id: "",
      name: "",
      level: 1,
      description: "",
      path: "",
      sort:1,
      disable:false,
      icon:"",
      isAdmin:false
    };
  }
  componentDidMount() {
    const _id = this.props.match.params._id;
    if (_id && _id.length > 0) {
      try {
        this.props.client
          .query({
            query: QUERY_DETAIL,
            variables: { _id: _id }
          })
          .then(result => {
            authCheck(result);
            var menu = result.data.menu.data;
            if (menu !== null) {
              this.setState(menu);
            }
          });
      } catch (err) {
        authCheck(err);
      }
    }
  }
  createOrUpdate = mutation => {};
  onCancel = () => {
    this.props.history.push("/menu");
  };
  render = () => {
    const { _id,icon,isAdmin, name, description,disable, level, parentId, path,sort } = this.state;
    return (
      <Page
        title="Quản trị menu"
        breadcrumbs={[{ name: "Quản trị Menu", active: true }]}
        className="CompanyPage"
      >
        <Form className="m-4">
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Disable
            </Label>
            <Col md={6} sm={7}>
              <ToggleButton
                    value={disable}
                    onToggle={value => {
                      this.setState({ disable: !disable });
                    }}
                  />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Tên Menu
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={name}
                onChange={e => {
                  this.setState({ name: e.target.value });
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Đường dẫn
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={path}
                onChange={e => this.setState({ path: e.target.value })}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Menu cha
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={parentId}
                onChange={e => this.setState({ parentId: e.target.value })}
                type="select"
              >
              <option value="">Menu gốc</option>
                <Query query={QUERY_LIST_MENU} variables={{isAdmin:isAdmin+''}}>
                    {
                        ({loading,error,data})=>{
                            
                           if(data.menus)
                           return <Fragment>
                               {data.menus.data.map((item,index)=><option key={index} value={item._id}>
                               {item.name} (cấp {item.level})
                               </option>)}
                           </Fragment>
                           else
                           return "";
                        }
                    }
                </Query>
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Icon
            </Label>
            <Col md={6} sm={7}>
              <Input
                type="select"
                value={icon}
                onChange={e => this.setState({ icon: e.target.value })}
              >
                <option value="">No Icon</option>
                <option value="MdBusiness">MdBusiness</option>
                <option value="MdCall">MdCall</option>
                <option value="MdMultilineChart">MdMultilineChart</option>
                <option value="MdSystemUpdate">MdSystemUpdate</option>
                <option value="MdKeyboardArrowDown">MdKeyboardArrowDown</option>
                <option value="MdExitToApp">MdExitToApp</option>
                <option value="MdVerifiedUser">MdVerifiedUser</option>
                <option value="MdSettings">MdSettings</option>
                <option value="MdAccountCircle">MdAccountCircle</option>
                <option value="MdEmail">MdEmail</option>
                <option value="MdWork">MdWork</option>
                <option value="MdHome">MdHome</option>
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Cấp độ
            </Label>
            <Col md={6} sm={7}>
              <Input
                type="select"
                value={level}
                onChange={e => this.setState({ level: e.target.value })}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Thứ tự
            </Label>
            <Col md={6} sm={7}>
              <Input
                type="select"
                value={sort}
                onChange={e => this.setState({ sort: e.target.value })}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Ghi chú
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={description}
                onChange={e => this.setState({ description: e.target.value })}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} sm={5} />
            <Col md={6} sm={7}>
              <Mutation
                mutation={
                  _id === undefined || _id.length === 0
                    ? MUTATION_CREATE_MENU
                    : MUTATION_EDIT_MENU
                }
                variables={{
                  menu: { _id, name, path, description,icon,disable, level, parentId,sort }
                }}
                onCompleted={result => {
                  this.onCancel();
                }}
              >
                {mutation => (
                  <Button color="primary" onClick={mutation}>
                    Cập nhật thông tin
                  </Button>
                )}
              </Mutation>

              <Button color="link" onClick={this.onCancel}>
                Hủy
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </Page>
    );
  };
}
export default withApollo(MenuEditPage);
