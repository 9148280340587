import Page from 'Admin/Components/Page';
import React from 'react';
import { Button,ButtonGroup,Card,CardBody,CardHeader} from 'reactstrap';

import gql from 'graphql-tag';
import {Query} from 'react-apollo'
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import confirm from 'components/Confirmation'
import { withApollo } from 'react-apollo'



export const QUERY_sipAccount=gql`
{
    sipAccounts{
        data{
            _id 
            displayName
            userName
            tech
            description
            shortExten
            password    
            }
        }
    }
`;



class sipAccountPage extends React.Component{
        
    render=()=>{
        return(
        <Page title="Tài khoản SIP"
                breadcrumbs={[{ name: 'Danh sách tài khoản SIP', active: true }]}            
                className="sipAccount"> 
            <Card>               
                <CardBody>
                <div style={{height:10}}></div>
                    <Query query={QUERY_sipAccount}>
                    {
                        ({loading,error,data})=>{
                            var result=[];
                            if(error){
                                authCheck(error);
                            }
                            try{
                                result=data.sipAccounts.data;
                            }catch(err){}
                            return(
                                <ReactTable 
                        noDataText="Không có dữ liệu"                        
                        columns={[    
                            {
                                Header:"#",
                                width:50,
                                style:{textAlign:"center"},
                                filterable:false,
                                Cell: (row) => {
                                    return <div>{row.index+1}</div>;
                                    }
                            },                             
                            {
                                Header:"Đầu số",
                                accessor:"_id"
                            },
                            {
                                Header:"Tên đăng nhập",
                                accessor:"userName"
                            },
                            {
                                Header:"Số nội bộ",
                                accessor:"shortExten"
                            },
                            {
                                Header:"Mật khẩu",
                                accessor:"password",
                            }, 
                            {
                                Header:"Tên hiển thị",
                                accessor:"displayName",
                            },
                            {
                                Header:"Mô tả",
                                accessor:"description"
                            }
                        ]}
                        data={result}
                        loading={loading} // Display the loading overlay when we need it
                        filterable
                        defaultPageSize={10}
                        className="-striped -highlight"
                    />                                      
                         
                            )
                        }
                    }
                    </Query>
                       
                </CardBody>
            </Card>
            
        </Page>)
    }
}

export default withApollo(sipAccountPage);