import Page from "Admin/Components/Page";
import React from "react";
import {  Card, CardBody } from "reactstrap";

import gql from "graphql-tag";
import { Query } from "react-apollo";
import authCheck from "utils/authCheck";
import ReactTable from "react-table";
import { withApollo } from "react-apollo";

export const QUERY_IVR = gql`
{
    ivrs{
      data{
        _id
        announcement{
          name
          fileRecord
        }
        name
        invalidRecording{
          name
          fileRecord      
        }
        invalidDestination{
          name
          fileRecord
        }
        entries{
          destType
          selection
          destId
        }
        name
        description
        localExten
      }
    }
  }
`;

class IvrPage extends React.Component {
 

  render = () => {
   return (
      <Page
        title="Asterisk Template"
        breadcrumbs={[{ name: "Quản lý Media File", active: true }]}
        className="template"
      >
        <Card>
          
          <CardBody>
            <div style={{ height: 10 }} />
            <Query query={QUERY_IVR}>
              {({ loading, error, data }) => {
                var result=[];
                if (error) {
                  authCheck(error);
                }
                try{
                  result=data.ivrs.data;
                }catch(err){}
                return (
                  <ReactTable
                    noDataText="Không có dữ liệu"
                    columns={[
                      {
                        Header: "#",
                        width: 50,
                        style: { textAlign: "center" },
                        filterable: false,
                        Cell: row => {
                          return <div>{row.index + 1}</div>;
                        }
                      },
                      {
                        Header: "Tên",
                        accessor: "name"
                      },
                      {
                        Header: "Số gọi",
                        accessor: "localExten"
                      },
                      {
                        Header: "Lời chào",
                        accessor: "announcement.name"
                      },
                      {
                        Header: "Âm báo sai số",
                        accessor: "invalidDestination.name"
                      },
                      {
                        Header: "Âm báo lỗi ghi âm",
                        accessor: "invalidRecording.name"
                      },
                      {
                        Header: "Mô tả",
                        accessor: "description"
                      }
                    ]}
                    data={result}
                    loading={loading} // Display the loading overlay when we need it
                    filterable
                    defaultPageSize={10}
                    className="-striped -highlight"
                  />
                );
              }}
            </Query>
          </CardBody>
        </Card>
      </Page>
    );
  };
}
export default withApollo(IvrPage);
