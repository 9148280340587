import Page from 'components/Page';
import React, { Fragment } from 'react';
import { Button,ButtonGroup,Input,Row,Col,Label,Badge,Card,CardHeader,CardBody,Modal,ModalBody,ModalHeader,ModalFooter ,Form,FormGroup} from 'reactstrap';
import { withApollo } from 'react-apollo'

import AppointmentTime from "components/AppointmentTime";
import gql from 'graphql-tag';
import { Mutation ,Query} from 'react-apollo'
import {MdDelete,MdAddCircle,MdEdit,MdCheckBoxOutlineBlank,MdCheckCircle,MdClose} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import omitDeep from 'omit-deep';
import confirm from 'components/Confirmation'
import Moment from 'moment'
import {FETCH_APPOINTMENT,UPDATE_APPOINTMENT,DELETE_APPOINTMENT,FETCH_WORKING_TIME,FETCH_DEPARTMENT} from 'AdminCustomer/GqlQuery'
class AppointmentPage extends React.Component{
    state={
        modal:false,
        isCreate:true,//trang thai edit hay create
        rowOrigin:{},
        rowEdit:{customer:{},department:{}},
        data: [],
        page: 1,
        workingTimes: [],
        loading: true
    }
    constructor(){
        super();
        this.fetchData = this.fetchData.bind(this);
    }
    // componentDidMount(){
    //     this.fetchData();
    // }
    toggle=modalType=>()=>{
        return this.setState({modal:!this.state.modal})
    }
    onCreate=()=>{
        this.props.history.push("/appointment-create");
    }
     onEdit=(row)=>{            
        const rowEdit = Object.assign({},row);//clone object
        this.setState({rowOrigin:row,rowEdit:rowEdit,modal:true,isCreate:false});
    }
    
    onDelete=(row,mutation)=>{
        confirm(`Bạn có chắc chắn muốn xóa lịch khám với khách hàng '${row.customer.fullName}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }
    onDepartmentSelectedonDepartmentSelected=(e)=>{
        const {rowEdit}=this.state;
        rowEdit.departmentId=e.target.value;
        rowEdit.appointmentDate=new Date();
        this.setState({rowEdit});
        if(e.target.value!==''){
            this.props.client.query({
                query:FETCH_WORKING_TIME,
                variables:{_id:e.target.value,date:Moment(rowEdit.appointmentDate).format("DD/MM/YYYY")}
            }).then(result=>{
                this.setState({workingTimes:result.data.department.data.workingTimes})
            });
        }
      }
      onDateChange = e => {
          const {rowEdit}=this.state;
          rowEdit.appointmentDate=e.target.value;
        this.setState({ rowEdit });
        this.props.client
          .query({
            query: FETCH_WORKING_TIME,
            variables: {
              _id: this.state.rowEdit.departmentId,
              date: Moment(e.target.value).format("DD/MM/YYYY")
            }
          })
          .then(result => {        
            this.setState({ workingTimes: result.data.department.data.workingTimes });
          });
      };

    fetchData= async(state, instance)=>{
        
        this.setState({ loading: true });
        const {filtered,pageSize,sorted,page} = state;
        try{
            const result = await this.props.client.query({
                query: FETCH_APPOINTMENT,
                variables: { filtered,pageSize,sorted,page },
                
            })
        
            this.setState({data:result.data.appointments.data,pages:result.data.appointments.pages,loading:false});
        }catch(err){
           authCheck(err);
        }
    }
    render=()=>{
        const {rowEdit,data,loading,pages,workingTimes}=this.state;
        return(
            <Page title="Thông tin lịch khám"
                breadcrumbs={[{ name: 'Đặt lịch khám', active: true }]}            
                className="ExtensionPage"> 
                <Card>
                    <CardHeader className="bg-green">                        
                        <Button color="primary" onClick={this.onCreate}><MdAddCircle/> Đặt khám</Button>
                    </CardHeader>
                    <CardBody>
                    <div style={{height:10}}></div>
                        
                        <ReactTable 
                            noDataText="Không có dữ liệu"
                            defaultSorted={[
                                {
                                    id: "appointmentDate",
                                    desc: true
                                }
                            ]}
                            columns={[                                    
                                {
                                    Header:"Bệnh nhân",
                                    accessor:"fullName",                                    
                                    maxWidth:150,
                                },
                                {
                                    Header:"Số điện thoại",
                                    accessor:"phoneNumber",                                   
                                    maxWidth:100,
                                    style:{textAlign:"center"},
                                },                                    
                                {
                                    Header:"NGÀY GIỜ KHÁM",
                                    width:140,
                                    accessor:"appointmentDate",
                                    Cell:row=>(
                                        <div>
                                            {Moment(row.value).format("DD/MM/YYYY")}{"  "}
                                            <Badge className="text-white">{row.original.appointmentTime}</Badge>
                                        </div>
                                    )
                                },
                                {
                                    Header:"Lý do khám",
                                    accessor:"reason"
                                },
                                {
                                    Header:"Kênh",
                                    accessor:"chanel",
                                    Cell:row=><span>
                                        {row.value==="call"?"TỔNG ĐÀI":row.value==="app"?"ỨNG DỤNG":row.value==="crm"?"CRM":"WEBSITE"}
                                    </span>,
                                    maxWidth:70,
                                    style:{textAlign:"center"},
                                    Filter:({filter,onChange})=>
                                        <Input
                                            type="select" onChange={e=>onChange(e.target.value)} bsSize="sm"
                                            value={filter?filter.value:""}
                                        >
                                            <option value="">Tất cả</option>
                                            <option value="crm">CRM</option>
                                            <option value="web">Web</option>
                                            <option value="call">Tổng đài</option>
                                            <option value="app">Mobile App</option>
                                        </Input>
                                }, 
                                                                
                                {
                                    Header:"Khoa khám",
                                    accessor:"department.name"
                                },
                                {
                                    Header:"Trạng thái",
                                    accessor:"state",
                                    maxWidth:80,
                                    style:{textAlign:"center"},
                                    Cell:row=>(
                                        <div>
                                            {row.value==="waiting"?"Chờ xác nhận":
                                            row.value==="complete"?"Đã khám":
                                            row.value==="cancel"?"Đã hủy":"Chờ khám"   } 
                                        </div>
                                    ),
                                    Filter:({filter,onChange})=>
                                        <Input
                                            type="select" onChange={e=>onChange(e.target.value)} bsSize="sm"
                                            value={filter?filter.value:""}
                                        >
                                            <option value="">Tất cả</option>                                            
                                            <option value="waiting">Chờ xác nhận</option>
                                            <option value="cancel">Đã hủy</option>
                                            <option value="confirm">Chờ khám</option>
                                            <option value="complete">Đã khám</option>
                                        </Input>
                                },  
                                {
                                    Header:"Tác vụ",
                                    maxWidth:100,
                                    filterable:false,
                                    sortable:false,
                                    Cell: row=>(
                                        <ButtonGroup>
                                            <Button onClick={()=>this.onEdit(row.original)}><MdEdit/></Button>
                                            {" "}<Mutation mutation={DELETE_APPOINTMENT}
                                                variables={{_id:row.original._id}}
                                                onCompleted={(result)=>{
                                                    window.location.reload();
                                                }}
                                            >
                                                {mutation=>
                                                    <Button onClick={()=>this.onDelete(row.original,mutation)}><MdDelete/></Button>
                                                }
                                            </Mutation>
                                        </ButtonGroup>
                                    )
                                }
                            ]}
                            manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                            data={data}
                            pages={pages} // Display the total number of pages
                            loading={loading} // Display the loading overlay when we need it
                            onFetchData={this.fetchData} // Request new data when things change
                            filterable
                            defaultPageSize={10}
                            className="-striped -highlight"
                        />                                      
                               
                    </CardBody>
                </Card>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className={"modal-full"}>
                    <ModalHeader toggle={this.toggle()}>
                       {this.state.isCreate?'Tạo mới bệnh nhân đặt khám':'Sửa thông tin đặt khám'}
                    </ModalHeader>
                    <ModalBody>
                    <Form>
                        <Card style={{marginBottom:"10px"}}>
                            <CardHeader>
                                <span>Thông tin bệnh nhân</span>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="3" sm="6" xs="12">
                                    <FormGroup>
                                        <Label >Họ tên</Label>
                                        <Input value={this.state.rowEdit.fullName} onChange={e=>{ rowEdit.fullName=e.target.value;this.setState({rowEdit:rowEdit})}}>Tên nhóm</Input>
                                    </FormGroup> 
                                    </Col>
                                    <Col md="3" sm="6" xs="12">
                                    <FormGroup>
                                        <Label >Số điện thoại</Label>
                                        <Input value={this.state.rowEdit.phoneNumber} onChange={e=>{ rowEdit.phoneNumber=e.target.value;this.setState({rowEdit:rowEdit})}}>Tên nhóm</Input>
                                    </FormGroup> 
                                    </Col>
                                    <Col md="3" sm="6" xs="12">
                                    <FormGroup>
                                        <Label >Ngày sinh</Label>
                                        <Input value={this.state.rowEdit.customer.birthDay} onChange={e=>{ rowEdit.customer.birthDay=e.target.value;this.setState({rowEdit:rowEdit})}}>Tên nhóm</Input>
                                    </FormGroup> 
                                    </Col>
                                    <Col md="3" sm="6" xs="12">
                                    <FormGroup>
                                        <Label >Địa chỉ</Label>
                                        <Input value={this.state.rowEdit.customer.address} onChange={e=>{ rowEdit.customer.address=e.target.value;this.setState({rowEdit:rowEdit})}}>Tên nhóm</Input>
                                    </FormGroup>  
                                    </Col>
                                </Row>   
                            </CardBody>                            
                        </Card>
                        <Card>
                            <CardHeader>
                                <h5>Thông tin đặt khám</h5>
                            </CardHeader>
                            <CardBody>
                                <FormGroup>
                                    <Label> Lý do đặt khám</Label>
                                    <Input value={this.state.rowEdit.description} onChange={e=>{rowEdit.description=e.target.value; this.setState({rowEdit:rowEdit})}}>số ngắn</Input>
                                </FormGroup>
                                <Row>
                                    <Col md="4" xs="6" sm="6">
                                    <FormGroup>
                                        <Label>
                                            Chọn khoa/phòng ban
                                        </Label>
                                        <Input
                                            className="mb-2"
                                            type="select"
                                            bsSize="sm"
                                            value={this.state.rowEdit.departmentId}
                                            onChange={this.onDepartmentSelected}
                                            >
                                             <Query query={FETCH_DEPARTMENT}>
                                                {({ data }) => {
                                                var departments=[];
                                                authCheck(data);
                                                try{
                                                    departments=data.departments.data;
                                                }catch(err){}
                                                
                                                return (
                                                    <Fragment>
                                                        <option>Chọn phòng/ban</option>
                                                        {departments.map((department, index) => (
                                                            <option key={index} value={department._id}>
                                                                {department.name}
                                                            </option>
                                                        ))}
                                                    </Fragment>
                                                );
                                                }}
                                            </Query>
                                        </Input>
                                    </FormGroup>
                                    </Col>
                                    <Col md="4" xs="6" sm="6">
                                    <FormGroup>
                                        <Label>Ngày hẹn</Label>
                                        <Input
                                        bsSize="sm"
                                        type="date"
                                        value={Moment(this.state.rowEdit.appointmentDate).format("YYYY-MM-DD")}
                                        onChange={this.onDateChange}
                                        />
                                    </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label>Chọn khung giờ khám</Label>
                                    <div>
                                    {this.state.workingTimes.map((workTime, index) => (
                                        <AppointmentTime
                                        key={index}
                                        name="workTime"
                                        title={workTime.timeRange}
                                        process={workTime.remainProcess}
                                        onChange={e => {
                                            rowEdit.appointmentTime=workTime.timeRange ;
                                            this.setState({ rowEdit});
                                        }}
                                        />
                                    ))}
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label> Ghi chú</Label>
                                    <Input value={this.state.rowEdit.description} onChange={e=>{rowEdit.description=e.target.value; this.setState({rowEdit:rowEdit})}}>số ngắn</Input>
                                </FormGroup>
                            </CardBody>
                        </Card>      
                            
                           
                    </Form>                       
                      
                        
                    </ModalBody>
                    <ModalFooter>
                        <Mutation 
                            mutation={UPDATE_APPOINTMENT}
                            variables={{appointment:omitDeep(rowEdit,['__typename'])}}
                            onCompleted={(result)=>{                                
                                this.toggle()();
                            }}
                        >
                        {mutation=>
                            <Button color="primary" onClick={mutation}>
                                Save
                            </Button>
                        }
                            
                        </Mutation>
                        {' '}
                        <Button color="default" onClick={this.toggle()}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </Page>
        )
    }
}
export default withApollo(AppointmentPage);
