import Page from 'Admin/Components/Page';
import React from 'react';
import { Button,ButtonGroup,Card,CardBody,CardHeader,Modal,Form,ModalHeader,ModalBody,ModalFooter,Col,Label,Input,FormGroup} from 'reactstrap';

import gql from 'graphql-tag';
import { Query ,Mutation} from 'react-apollo'
import {MdEdit,MdDelete,MdAddCircle} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import confirm from 'components/Confirmation'

export const FEED_QUERY=gql`
    {
        sipSettings{
            code
            message
            data{
                _id
                key
                val
                type
            }
        }
    }
`

export const UPDATE_QUERY=gql`
    mutation AdminMutation($sipSetting:SipSettingInputType!){
        updateSipSetting(sipSetting:$sipSetting){  
            code
            message
            data{      
                key
                val
                type
            }
        }
    }
`
export const DELETE_QUERY=gql`
    mutation AdminMutation($_id:String!){
        deleteSipSetting(_id:$_id){
            code
            message
        }
    }
`
class SipSettingPage extends React.Component{
    state={
        modal:false,
        key:"",
        val:"",
        type:"pjsip",        
        pollInterval:0
    }
    toggle=modalType=>()=>{
        return this.setState({modal:!this.state.modal})
    }
    onCreate=()=>{
        this.setState({modal:true,key:"",val:"",type:"pjsip"});        
    }
    onEdit=(row)=>{            
        this.setState({modal:true,key:row.key,val:row.val,type:row.type});  
    }
    
    onDelete=(row,mutation)=>{
        confirm(`Bạn có chắc chắn muốn xóa bản ghi '${row.name}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                authCheck(result);
            }
        )
    }
    render=()=>{
        const {_id,val,key,type}=this.state;
        return (
            <Page
                title="Sip Setting"
                breadcrumbs={[{ name: 'Sip Setting', active: true }]}
                className="TablePage"
            >
                <Card>
                    <CardHeader className="bg-green">
                        
                        <Button  color="primary" onClick={this.onCreate}><MdAddCircle/> Thêm giá trị</Button>
                    </CardHeader>
                    <CardBody>
                        
                        <Query query={FEED_QUERY} >
                            {
                                ({loading,error,data,startPolling, stopPolling,refetch })=>{
                                    var sipSettings=[];
                                    if (error){
                                        authCheck(error);
                                        
                                    } 
                                    try{
                                        sipSettings=data.sipSettings.data;                                        
                                    }catch(err){}
                                    return (
                                        <div>
                                        <ReactTable
                                            noDataText="Không có dữ liệu"
                                            data={sipSettings}
                                            showPagination={false}
                                            pageSize={sipSettings.length}
                                            defaultSorted={[
                                                {
                                                    id: "row",
                                                    desc: false
                                                }
                                            ]}
                                            loading={loading} 
                                            className="-striped -highlight"
                                            columns={[
                                                {
                                                    Header:"#",
                                                    width:50,
                                                    id:"row",
                                                    style:{textAlign:"center"},
                                                    filterable:false,
                                                    Cell: (row) => {
                                                        return <div>{row.index+1}</div>;
                                                        }
                                                },      
                                                {
                                                    Header:"Khóa",
                                                    accessor:"key"
                                                },
                                                {
                                                    Header:"Giá trị",
                                                    accessor:"val"
                                                },
                                                {
                                                    Header:"Loại",
                                                    accessor:"type"
                                                },                                                
                                                {
                                                    Header:"Thao tác",
                                                    maxWidth:100,
                                                    sortable:false,
                                                    Cell: row=>(
                                                        <ButtonGroup>
                                                            <Button onClick={()=>this.onEdit(row.original)}><MdEdit/></Button>
                                                            <Mutation mutation={DELETE_QUERY}
                                                                variables={{_id:row.original._id}}
                                                                onCompleted={(result)=>{
                                                                    refetch();
                                                                }}
                                                            >
                                                                {mutation=>
                                                                    <Button onClick={()=>this.onDelete(row.original,mutation)}><MdDelete/></Button>
                                                                }
                                                            </Mutation>
                                                        </ButtonGroup>
                                                    )
                                                }
                                            ]}
                                        >

                                        </ReactTable>
                                        <Modal
                                            isOpen={this.state.modal}
                                            toggle={this.toggle}
                                            className="modal-lg"
                                        >
                                            <ModalHeader toggle={this.toggle()}>
                                            {this.state.isCreate?'Thêm':'Sửa'}
                                            </ModalHeader>
                                            <ModalBody>
                                                <Form>
                                                    <FormGroup row>
                                                        <Label className="text-right" md={3} sm={4}>Khóa:</Label>
                                                        <Col md={9} sm={8}>
                                                            <Input value={key} onChange={e=>{ this.setState({key:e.target.value})}}></Input>                           
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label className="text-right" md={3} sm={4}>Giá trị:</Label>
                                                        <Col md={9} sm={8}>
                                                            <Input value={val} onChange={e=>{ this.setState({val:e.target.value})}}></Input>                           
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label className="text-right" md={3} sm={4}>Loại:</Label>
                                                        <Col md={9} sm={8}>
                                                            <Input type="select" value={type} onChange={e=>{ this.setState({type:e.target.value})}}>
                                                                <option value="PJSIP">PJSIP</option>
                                                                <option value="SIP">SIP</option>
                                                                <option value="BOTH">BOTH</option>
                                                            </Input>                           
                                                        </Col>
                                                    </FormGroup>
                                                </Form>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Mutation 
                                                    mutation={UPDATE_QUERY}
                                                    variables={{sipSetting:{key,_id,val,type}}}
                                                    onCompleted={(result)=>{

                                                        this.setState({modal:false})
                                                        refetch();
                                                    }}
                                                >
                                                {mutation=>
                                                    <Button color="primary" onClick={mutation}>
                                                        Save
                                                    </Button>
                                                }
                                                    
                                                </Mutation>
                                                {' '}
                                                <Button color="secondary" onClick={this.toggle()}>
                                                    Cancel
                                                </Button>
                                            </ModalFooter>
                                        </Modal>   
                        </div>
                                    )
                                }
                            }               
                            
                        </Query>
                        </CardBody>
                </Card>  
                           
            </Page>
        )
    }
}



export default SipSettingPage