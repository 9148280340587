import React from "react";
import logo from 'styles/images/logo_phusan.png'
import { ValidFormDate,ValidFormDateTime } from "utils/dateHelper";
// import "styles/phieuin/nstmau.css"

const PrintNSTMauNgoaiVi = ({
    ngay_lay_mau,
    ngay_tra_kq,
    nguoi_cay_mau,
    nguoi_lay_mau,
    nu,
    nam, objRef
}) => (

        <div className="body" ref={objRef}>

            <div className="content" id="tien_san_giat">
            <div class="img_logo" style={{ width: 120, marginRight: 20 }}> <img src={logo} alt="logo hospital" style={{ width: 120 }} /> </div>
                <div style={{ flex: 1 }}>
                    <p style={{marginBottom:0}}><b style={{ fontSize: 24, color: "#478F20" }}>BỆNH VIỆN PHỤ SẢN HÀ NỘI</b><br />
                        <b>
                            TRUNG TÂM SÀNG LỌC, CHUẨN ĐOÁN TRƯỚC SINH VÀ SƠ SINH
                        </b> 
                    </p>
                    <p> Địa chỉ: Số 929 - Đường La Thành - Ngọc Khánh - Ba Đình - Hà Nội<br />
                        Hotline: 0243 7757 346
                    </p>
                </div>
                <div style={{ width: "100%", margin: "7px 0px 10px 0px" }}>
                    <p style={{ textAlign: "center", fontSize: 22, color: "#478F20", fontWeight: "bold" }}>PHIẾU THÔNG TIN XÉT NGHIỆM NHIỄM SẮC THỂ TỪ MÁU NGOẠI VI</p>
                </div>
                <div class="form_info">
                    <table cellpadding="0" cellspacing="0" >
                        <tr>
                            <td>
                                Thời gian lấy mẫu: {ValidFormDateTime(ngay_lay_mau)}
                            </td>
                            <td>
                                Ngày trả kết quả: {ValidFormDate(ngay_tra_kq)}
                            </td>
                        </tr> 
                        <tr>
                            <td>
                                Người lấy mẫu: {nguoi_lay_mau}
                            </td>
                            <td>
                                Người cấy mẫu: {nguoi_cay_mau}
                            </td>
                        </tr>    
                    </table>
                    <table cellpadding="0" cellspacing="0" style={{borderTop:"none"}}>
                        <tr style={{borderTop:"none"}}>
                            <th style={{width:"50%"}}></th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr class="bg_gray" style={{borderTop:"none"}}>
                            <td colSpan="3">Thông tin bệnh nhân:</td>
                        </tr>
                        <tr>
                            <td>
                                Họ và tên: {nu.ho_va_ten}
                            </td>
                            <td>
                                Năm sinh: {ValidFormDate(nu.ngay_sinh)}
                            </td>
                            <td>
                                Giới tính:  <b>Nữ</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Mã bệnh nhân: {nu.mabn}
                            </td>
                            <td colSpan="2">
                                Địa chỉ: {nu.dia_chi}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Số điện thoại: {nu.so_dien_thoai}
                            </td>
                            <td colSpan="2">
                                Nghề nghiệp: {nu.nghe_nghiep}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                Lý do khám bệnh: {nu.ly_do_kham_benh}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                Bs chỉ định: {nu.bs_chi_dinh}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3" style={{height:350}}>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                Họ và tên: {nam.ho_va_ten}
                            </td>
                            <td>
                                Năm sinh: {ValidFormDate(nam.ngay_sinh)}
                            </td>
                            <td>
                                Giới tính:  <b>Nam</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Mã bệnh nhân: {nam.mabn}
                            </td>
                            <td colSpan="2">
                                Địa chỉ: {nam.dia_chi}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Số điện thoại: {nam.so_dien_thoai}
                            </td>
                            <td colSpan="2">
                                Nghề nghiệp: {nam.nghe_nghiep}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                Lý do khám bệnh: {nam.ly_do_kham_benh}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                Bs chỉ định: {nam.bs_chi_dinh}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3" style={{height:320}}>

                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

    )
export default PrintNSTMauNgoaiVi;