import Page from 'components/Page';
import React from 'react';
import { Button,Input,Row,Col,Label,InputGroup,InputGroupAddon,InputGroupText ,FormGroup,Badge} from 'reactstrap';
import { withApollo } from 'react-apollo'
import {MdSearch,MdImportExport,MdReport,MdDateRange,MdPrint,MdClose,MdCheckBoxOutlineBlank} from 'react-icons/lib/md';
import gql from 'graphql-tag';
import authCheck from 'utils/authCheck';
import Moment from 'moment';
import { CounterWidget } from 'components/Widget';
import ReactTable from "react-table";
import {GetLocalToken} from 'companyConstants'
import Picker from 'react-month-picker'
import {FETCH_DEPARTMENT} from 'AdminCustomer/GqlQuery';

export const QUERY_REPORT=gql`
query Report($month:Int!,$year:Int!){
    appointmentDetailDaily(month:$month,year:$year){
      code
      message
      data{
        appointmentDate
        callCancel
        callServed
        callWaiting
        totalCancel
        webCancel
        webServed
        webWaiting
        totalServed
        crmCancel
        crmServed
        crmWaiting
        totalWaiting
      }
    }
  }
`;
class AppointmentReportDetailPage extends React.Component{
    state={
        departments :[], 
        mvalue : {year: 2019, month: 1},
        data:[],
        departmentId: ""
    }
    componentDidMount(){
        //fetch deparmtnet    
        this.props.client.query({
            query:FETCH_DEPARTMENT
        }).then(result=>{
            authCheck(result);
            this.setState({departments:result.data.departments.data})
        })
    }
    fetchData=async (isInit)=>{
        const { mvalue, departmentId } = this.state;
        this.props.client.query({
            query:QUERY_REPORT,
            variables:{departmentId,...mvalue}
        }).then(result=>{
            authCheck(result);
            this.setState({data:result.data.appointmentDetailDaily.data})
        })
    }
    render=()=>{
        let pickerLang = {
            months: ['th1', 'th2', 'th3', 'th4', 'th5', 'th6', 'th7', 'th8', 'th9', 'th10', 'th11', 'th12']
            , from: 'Từ', to: 'Đến'
        };
        let {mvalue,data,departmentId}=this.state;

        let makeText = m => {
            if (m && m.year && m.month) return (m.month + '/' + m.year)
            return '?'
        }
        return (
            <Page
                title="Báo cáo chi tiết đặt khám"
                breadcrumbs={[{ name: 'Báo cáo chi tiết đặt khám', active: true }]}            
                className="AppointmentReportPage">
                <Row style={{padding:"1rem"}}>
                        <Col md={3} sm={6} xs={12}>
                            <FormGroup>
                                <Label>Tháng báo cáo</Label>
                                <Picker
                                    ref="pickAMonth"
                                    years={[2008, 2010, 2011, 2012, 2014, 2015, 2016, 2017,2018,2019]}
                                    value={mvalue}
                                    lang={pickerLang.months}
                                    theme="dark"
                                    onChange={e=>{this.refs.pickAMonth.dismiss()}}
                                    onDismiss={e=>{this.setState({mvalue:e})}}
                                    >
                                    <InputGroup onClick={e=>{this.refs.pickAMonth.show();}}>
                                        <Input bsSize="sm" value={makeText(mvalue)} onChange={e=>{}}/>
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText><MdDateRange/></InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Picker>    
                            </FormGroup>
                        </Col>
                       
                        <Col md={3} sm={6} xs={12}>
                            <FormGroup>
                                <Label>Lọc theo phòng/ban</Label>
                                <Input bsSize="sm" type="select"
                                    value={departmentId}
                                    onChange={e => {
                                    this.setState({ departmentId: e.target.value });
                                    }}>
                                    <option value="">Tất cả phòng/ban</option>                                    
                                    {this.state.departments.map((item,i)=>{
                                        return <option key={i} value={item._id}>{item.name}</option>
                                    })}
                                                   
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={6} sm={6} xs={12}>
                            <FormGroup>
                                <Label></Label>
                                <center>
                                    <Button className="mr-4" size="lg" color="green" onClick={e=>this.fetchData(false)}><MdSearch/> Tìm kiếm</Button>
                                    <Button className="mr-4 text-white" size="lg" color="dark"><MdImportExport/> Xuất Excel</Button>
                                    <Button className="mr-4 text-white" size="lg" color="dark"><MdPrint/> In báo cáo</Button>
                                </center>
                            </FormGroup>
                           
                        </Col>
                    </Row>
                    <ReactTable
                        noDataText="Không có dữ liệu"
                        className="mt-3"
                        showPagination={false}
                        filterable={false}
                        manual
                        data={data}
                        sortable={false}
                        pageSize={data.length}
                        columns={[
                            {
                                Header:"#",
                                width:50,
                                className:"text-center",
                                Cell:row=><span>{row.index}</span>
                            },
                            {
                                Header:"Ngày",
                                className:"text-center",
                                accessor: "appointmentDate",
                                Cell:row=>(
                                <div>
                                    {Moment(row.value).format("DD-MM-YYYY")}
                                </div>
                                )
                            },
                            {
                                Header:"Đặt khám",
                                columns:[
                                    {
                                        Header:"Website/Ứng dụng",
                                        accessor:"webWaiting",
                                        className:"text-center"
                                    },
                                    {
                                        Header:"CRM",
                                        accessor:"crmWaiting",
                                        className:"text-center"
                                    },
                                    {
                                        Header:"ĐT",
                                        accessor:"callWaiting",
                                        className:"text-center"
                                    },
                                    {
                                        Header:"Tổng",
                                        style:{fontWeight:"bold"},
                                        className:"text-center",
                                        accessor:"totalWaiting"
                                    }
                                ]
                            },
                            {
                                Header:"Đã khám",
                                columns:[
                                    {
                                        Header:"Website/Ứng dụng",
                                        accessor:"webServed",
                                        className:"text-center"
                                    },
                                    {
                                        Header:"CRM",
                                        accessor:"crmServed",
                                        className:"text-center"
                                    },
                                    {Header:"ĐT",accessor:"callServed"},
                                    {
                                        Header:"Tổng",
                                        style:{fontWeight:"bold"},
                                        accessor:"totalServed",
                                        className:"text-center"
                                    }
                                ]
                            },
                            {
                                Header:"Không khám",
                                columns:[
                                    {
                                        Header:"Website/Ứng dụng",
                                        accessor:"webCancel",
                                        className:"text-center"
                                    },
                                    {
                                        Header:"CRM",
                                        accessor:"crmCancel",
                                        className:"text-center"
                                    },
                                    {   
                                        Header:"ĐT",
                                        accessor:"callCancel",
                                        className:"text-center"
                                    },
                                    {
                                        Header:"Tổng",
                                        style:{fontWeight:"bold"},
                                        accessor:"totalCancel",
                                        className:"text-center"
                                    }
                                ]
                            }
                        ]}
                    >

                    </ReactTable>
            </Page>
        )
    }

}

export default withApollo(AppointmentReportDetailPage);