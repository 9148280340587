//ham nay chuyen xu ly loi
import confirm from 'components/Confirmation'
const authCheck=(error)=>{
    // console.log(error);
    if(!error) return;
    if(error.code==1){
        confirm(error.message,{hideCancel:true,title:""});
        return false;
    }else
    if(error.code==2){        
        window.location.href='/login';
    }
    var errors;
    if(error&&error.networkError&&error.networkError.result&&error.networkError.result.errors){
        errors =error.networkError.result.errors;
        
    }else if(error.graphQLErrors){
        errors = error.graphQLErrors;
    }else if(error.errors){
        errors=error.errors;
    }
    if(errors){
        errors.forEach(err => {
            if(err.extensions&&err.extensions.code==='authorization'){
                console.log("authError:"+JSON.stringify(err));
                window.location.href='/login';
            }
        });
    }
    return true;
}
export default authCheck