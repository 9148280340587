import Page from 'Admin/Components/Page';
import React from 'react';
import { Button,ButtonGroup,Card,CardBody,CardHeader} from 'reactstrap';

import gql from 'graphql-tag';
import {Mutation,Query} from 'react-apollo'
import {MdDelete,MdAddCircle} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import confirm from 'components/Confirmation'


export const QUERY_FIREWALL=gql`
{
    readFirewall{
        code
        message
        data{
            type
            _id
            name
            family
            protocol
            port
            address
        }
    }
  }
`;
export const DELTE_QUERY=gql`
    mutation Mutation($_id:String!){
        removeFirewall(_id:$_id){
            code
            message
        }
    }
`

class FirewallPage extends React.Component{
    
    
    onDelete=(row,mutation)=>{
        confirm(`Bạn có chắc chắn muốn xóa đầu số '${row.number}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }
    render=()=>{
        
        return (
            <Page title="Asterisk Template"
                breadcrumbs={[{ name: 'Quản lý Media File', active: true }]}            
                className="template"> 
                <Card>
                    <CardHeader className="bg-green">                        
                        <Button color="primary" onClick={()=>{this.props.history.push("/firewall-edit")}}><MdAddCircle/> Thêm media</Button>
                    </CardHeader>
                    <CardBody>
                        <div style={{height:10}}></div>
                        <Query query={QUERY_FIREWALL}>
                        {({loading,error,data,refetch})=>{
                            authCheck(data);
                            var firewalls=[];
                            
                            try{
                                firewalls=data.readFirewall.data;
                            }catch(err){}
                            return (<ReactTable 
                                noDataText="Không có dữ liệu"                        
                                columns={[    
                                    {
                                        Header:"#",
                                        width:50,
                                        style:{textAlign:"center"},
                                        filterable:false,
                                        Cell: (row) => {
                                            return <div>{row.index+1}</div>;
                                            }
                                    },                             
                                    {
                                        Header:"Type",
                                        accessor:"type",
                                        width:80
                                    },
                                    {
                                        Header:"Thuộc tính",                                        
                                        Cell: row=><div>
                                            {row.original.name?`name=${row.original.name} `:""}
                                            {row.original.protocol?`protocol=${row.original.protocol} `:""}
                                            {row.original.port?`port=${row.original.port} `:""}
                                            {row.original.address?`address=${row.original.address} `:""}
                                            {row.original.family?`family=${row.original.family} `:""}
                                        </div>
                                    },
                                    {
                                        Header:"Tác vụ",
                                        maxWidth:100,
                                        filterable:false,
                                        sortable:false,
                                        Cell: row=>(
                                            <ButtonGroup>
                                                
                                                <Mutation mutation={DELTE_QUERY}
                                                        variables={{_id:row.original._id}}
                                                        onCompleted={(result)=>{
                                                            refetch();
                                                        }}
                                                    >
                                                        {mutation=>
                                                            <Button onClick={()=>this.onDelete(row.original,mutation)}><MdDelete/></Button>
                                                        }
                                                    </Mutation>

                                            </ButtonGroup>
                                        )
                                    }
                                ]}
                                data={firewalls}
                                showPagination={false}
                                pages={1} // Display the total number of pages
                                loading={loading} // Display the loading overlay when we need it
                                filterable
                                pageSize={firewalls.length}
                                className="-striped -highlight"
                            />  )  
                        }}
                        </Query>
                         
                    </CardBody>
                </Card>
            </Page>

        )
    }
}
export default FirewallPage;