import Page from "Admin/Components/Page";
import React from "react";
import { Button, Input, Row, Col, Label, Form, FormGroup,CardHeader } from "reactstrap";
import { withApollo, Mutation,Query } from "react-apollo";
import gql from "graphql-tag";
import authCheck from "utils/authCheck";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ToggleButton from 'react-toggle-button'

export const QUERY_DETAIL = gql`
query HotNewDetail($_id:String){
  hotNew(_id:$_id){
    data{
      _id
      content
      description
      coverImage
      name
      title
      sort
      disable
    }
    
  }
}
`;
export const QUERY_PERMISSIONS=gql`
{
  permissions{
    data{
      name
      value
    }
  }
}
`;
export const MUTATION_CREATE = gql`
mutation COMPANY($hotNews:HotNewsInputType){
  createHotNews(hotNews:$hotNews){
    code
    message
  }
}
`;
export const UPLOAD_QUERY= gql`
query CompanyMutation($file:FileUploadInputType){
  uploadNewImage(file:$file)
}
`;
export const MUTATION_EDIT = gql`
mutation COMPANY($hotNews:HotNewsInputType){
  updateHotNews(hotNews:$hotNews){
    code
    message
  }
} 
`;

class HotNewsEditPage extends React.Component {
  constructor() {
    super();
    
    this.state = {
      name: "",
      coverImage:"",
      description: "",
      title:"",
      content:"",
      sort:1,
      disable:true,
      editorState: EditorState.createEmpty(),
    };
    
  }
  componentDidMount() {
    const _id = this.props.match.params._id;
    if(_id){
      this.setState({isEdit:true,_id:_id});
      //fetch detail
      this.props.client.query({
          query:QUERY_DETAIL,
          variables:{_id:_id},
          
      }).then(result=>{
          authCheck(result);
          const hotNew=result.data.hotNew.data;          
          this.setState(hotNew);
          let { contentBlocks, entityMap } = htmlToDraft(hotNew.content);
          let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          const editorState = EditorState.createWithContent(contentState);
          this.state.editorState =editorState;
          this.refs.editor.editor.update(editorState);
      });
  }
  }
  onEditorStateChange= (editorState) => {
    this.setState({
      editorState,
    });
  };
  
  onCreate=()=>{
    let editorSourceHTML = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    this.state.content=editorSourceHTML;
    const {
      _id,
      name,
      title,
      description,
      content,
      disable,
      coverImage,
      file,
      sort,
      editorState
    } = this.state;
    this.props.client.mutate({
      mutation : (_id === undefined || _id.length === 0)
      ? MUTATION_CREATE
      : MUTATION_EDIT,
      variables: {hotNews: {
        _id,
        name,
        disable,
        description,
        title,
        content,                  
        sort,
        file
      }}//truong hợp này chỉ dùng 1 dấu nháy vì variables chỉ là 1 object ko phải thuộc tính
    }).then(result=>{
      this.onCancel()
    });
  }
  onCancel = () => {
    this.props.history.push("/hotnews");
  };
  uploadCallback = (file) => {

    console.log("upload callback "+file.name);
   
    // var client = this.props.client;
    return new Promise(
      (resolve, reject) => {
         
          this.props.client.query({
            query : UPLOAD_QUERY,
            variables: {file}//truong hợp này chỉ dùng 1 dấu nháy vì variables chỉ là 1 object ko phải thuộc tính
          }).then(result=>{
            console.log("upload result:"+JSON.stringify(result));
            resolve({data:{link:result.data.uploadNewImage}})
          });
          
      }
    );
  }
  render = () => {
    const {
      _id,
      name,
      title,
      description,
      content,
      disable,
      coverImage,
      file,
      sort,
      editorState
    } = this.state;
    
    return (
      <Page
        title={(this.state._id!=null)?"Sửa tin hot":"Tạo tin hot"}
        breadcrumbs={[{ name: (this.state._id!=null)?"Sửa tin hot":"Tạo tin hot", active: true }]}
        className="hotnews"
      >
        <Row>
          <Col md={12}>
            <Button color="primary" onClick={()=>this.onCreate()}>
                  Cập nhật thông tin
                </Button>
            <Button color="link" onClick={()=>this.onCancel()}>
              Hủy
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form className="m-3">
            <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Ẩn/Hiện:
                </Label>
                <Col md={10} sm={8} xs={6}>
                <ToggleButton
                  disabled
                  value={ disable }
                  onToggle={(value) => {
                      this.setState({disable:!value});
                  }} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Tên bài viết:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="text"
                    value={name}
                    onChange={e => {
                      this.setState({ name: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Tiêu đề bài viết:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="text"
                    value={title}
                    onChange={e => {
                      this.setState({ title: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Ảnh Cover:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <img width="100" height="100"  src={coverImage} style={{maxHeight:100,maxWidth:"100%"}}/>
                  <Input
                        type="file"
                        onChange={({
                        target: {
                            validity,
                            files: [file]
                        }
                        }) => {
                        this.setState({ file });
                        }}
                        bsSize="sm"
                                                />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Mô tả ngắn
                </Label>
                <Col md={10} sm={8} xs={6}>
                
                  <Input
                    type="textarea"
                    value={description}
                    onChange={e => {
                      this.setState({ description: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Nội dung
                </Label>
                <Col md={10} sm={8} xs={6}>
                <div >
                    <Editor
                        ref="editor"
                        editorState={editorState}
                        onEditorStateChange={this.onEditorStateChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        wrapperStyle={{borderStyle:"solid",padding:10,borderWidth:1,borderColor:"#ccc",minHeight:300}}
                        toolbar={{
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                          image: {                            
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            urlEnabled: true,
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            uploadCallback: this.uploadCallback,
                            previewImage: true,
                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                            alt: { present: false, mandatory: false },
                            defaultSize: {
                              height: 'auto',
                              width: 'auto',
                            },
                          },
                        }}
                    />
                   </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Ưu tiên hiển thị:
                </Label>
                <Col md={2} sm={2} xs={2}>
                  <Input
                    type="number"
                    value={sort}
                    onChange={e => {
                      this.setState({ sort: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              
            </Form>
          </Col>
        </Row>
      </Page>
    );
  };
}
export default withApollo(HotNewsEditPage);
