import Page from 'components/Page';
import React,{Fragment} from 'react';
import { Button, ButtonGroup, Card, Input, Label ,Tooltip} from 'reactstrap';
import { withApollo,Mutation } from 'react-apollo'
import confirm from 'components/Confirmation';
import gql from 'graphql-tag';
import { MdCall, MdEdit,MdDelete } from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import Moment from 'moment'
import {DELETE_CUSTOMER,FETCH_CUSTOMERS} from 'AdminCustomer/GqlQuery';


class CustomerPage extends React.Component {

    state = {
        data: [],
        pages: 1,
        page:0,
        loading: true,
        tooltipOpen:{}
    }
    constructor() {
        super();
        this.fetchData = this.fetchData.bind(this);
    }
    onEdit=(row)=>{            
        this.props.history.push("/customer-edit/"+row._id);
    }
    
    onDelete=(row,mutation)=>{
        confirm(`Bạn có chắc chắn muốn xóa khách hàng '${row.fullName}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }
    onCreate=()=>{
        this.props.history.push("/customer-edit");
    }
    fetchData = async (state, instance) => {

        this.setState({ loading: true });
        const { filtered, pageSize, sorted, page } = state;
        try {
            const result = await this.props.client.query({
                query: FETCH_CUSTOMERS,
                variables: { filtered, pageSize, sorted, page },
            })
            authCheck(result);
            this.setState({ data: result.data.customers.data, pages: result.data.customers.pages, page: result.data.customers.page, loading: false });
        } catch (err) {
            authCheck(err);
        }
    }
    render = () => {
        const { data, loading, pages,page ,tooltipOpen} = this.state;
        return (
            <Page title="Quản lý khách hàng"
                breadcrumbs={[{ name: 'Quản lý khách hàng', active: true }]}
                className="CustomerPage">
                <Card>
                    <ReactTable
                        noDataText="Không có dữ liệu"
                        sortable={false}
                        columns={[
                            {
                                Header: "#",
                                width:50,
                                className:"text-center",
                                filterable:false,
                                Cell:row=><span>
                                    {row.index+1+(page*10)}
                                </span>
                            },
                            {
                                Header: "Số điện thoại",
                                accessor: "phoneNumber"
                            },
                            {
                                Header: "Mã bệnh nhân",
                                accessor: "patientId"
                            },
                            {
                                Header: "Họ và Tên",
                                accessor: "fullName"
                            },
                            {
                                Header: "Ngày sinh",
                                accessor: "birthDay",
                                Filter: ({ filter, onChange }) =>
                                    <Input bsSize="sm" type="date" placeholder="Chọn ngày" onChange={e => onChange(e.target.value)} />,
                                Cell: row => (<Label>{Moment(row.value).format('DD/MM/YYYY')}</Label>)
                            },
                            {
                                Header: "Địa chỉ",
                                accessor: "address"
                            },
                            {
                                Header: "Đặt khám",
                                accessor: "appointments",
                                className:"text-center",
                                width:90,
                                filterable: false
                            },
                            {
                                Header: "Đã khám",
                                accessor: "servedAppointments",
                                className:"text-center",
                                width:90,
                                filterable: false
                            },
                            {
                                Header: "Tác vụ",
                                maxWidth: 100,
                                className: "text-center",
                                filterable: false,
                                sortable: false,
                                Cell: row => (
                                    <ButtonGroup style={{ fontSize: 18 }}>
                                        <MdEdit id={'edit'+row.original._id} className="text-grey m-1" onClick={() => this.onEdit(row.original)} />
                                        <Tooltip placement="bottom" isOpen={tooltipOpen['edit'+row.original._id]} autohide={false} target={"edit"+row.original._id} toggle={e=>{tooltipOpen['edit'+row.original._id]=!tooltipOpen['edit'+row.original._id]; this.setState({tooltipOpen})}}>
                                            Sửa
                                        </Tooltip>
                                        <Mutation
                                            mutation={DELETE_CUSTOMER}
                                            variables={{_id:row.original._id}}
                                        >
                                            {mutation=><Fragment>
                                                <MdDelete onClick={e=>{
                                                    this.onDelete(row.original,mutation);
                                                }} id={"call"+row.original._id} className="text-warning m-1"/>
                                                <Tooltip placement="bottom" isOpen={tooltipOpen['call'+row.original._id]} autohide={false} target={"call"+row.original._id} toggle={e=>{tooltipOpen['call'+row.original._id]=!tooltipOpen['call'+row.original._id]; this.setState({tooltipOpen})}}>
                                                    Xóa {row.original.fullName}
                                                </Tooltip>

                                            </Fragment>}
                                        </Mutation>
                                    </ButtonGroup>
                                )
                            }
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={10}
                        className="-striped -highlight"
                    >

                    </ReactTable>
                </Card>

            </Page>
        )
    }
}

export default withApollo(CustomerPage);