import Page from 'components/Page';
import React,{Fragment} from 'react';
import { Button, ButtonGroup, Card, Input, Label ,Tooltip} from 'reactstrap';
import { withApollo,Mutation } from 'react-apollo'
import confirm from 'components/Confirmation';
import gql from 'graphql-tag';
import { MdCall, MdEdit,MdDelete } from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import Moment from 'moment'
import {DELETE_CUSTOMER,GET_KQXN2s} from 'AdminCustomer/GqlQuery';


class kqxn2 extends React.Component {

    state = {
        data: [],
        pages: 1,
        page:0,
        loading: true,
        tooltipOpen:{}
    }
    constructor() {
        super();
        this.fetchData = this.fetchData.bind(this);
    }
    onEdit=(row)=>{            
        this.props.history.push("/customer-edit/"+row._id);
    }
    
    onDelete=(row,mutation)=>{
        confirm(`Bạn có chắc chắn muốn xóa khách hàng '${row.fullName}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }
    onCreate=()=>{
        this.props.history.push("/customer-edit");
    }
    fetchData = (state) => {

        this.setState({ loading: true });
        const { filtered, pageSize, sorted, page } = state;
        try {
            this.props.client.query({
                query: GET_KQXN2s,
                variables: { filtered, pageSize, sorted, page },
            }).then(result=>{
                this.setState({ data: result.data.response.data, pages: result.data.response.pages, page: result.data.response.page, loading: false });
            })
            
            
        } catch (err) {
            authCheck(err);
            this.setState({loading:"false"});
        }
    }
    render = () => {
        const { data, loading, pages,page ,tooltipOpen} = this.state;
        return (
            <Page title="Quản lý khách hàng"
                breadcrumbs={[{ name: 'Quản lý khách hàng', active: true }]}
                className="CustomerPage">
                <Card>
                    <ReactTable
                        noDataText="Không có dữ liệu"
                        defaultSorted={[{id:"nGAYLAYMAU",desc:true}]}                        
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={10}
                        className="-striped -highlight"
                        columns={[
                            {
                                Header: "#",
                                width:50,
                                className:"text-center",
                                filterable:false,
                                Cell:row=><span>
                                    {row.index+1+(page*10)}
                                </span>
                            },
                            {
                                Header: "Ngày lấy mẫu",
                                accessor: "nGAYLAYMAU",
                                Filter: ({ filter, onChange }) =>
                                    <Input bsSize="sm" type="date" placeholder="Chọn ngày" onChange={e => onChange(e.target.value)} />
                               
                            },
                            {
                                Header: "Họ tên",
                                accessor: "hOTEN"
                            },
                            {
                                Header: "Họ tên mẹ",
                                accessor: "hoten_me"
                            },
                            {
                                Header: "Mã bệnh nhân",
                                accessor: "mABN"
                            },
                            {
                                Header: "STT Lấy mẫu",
                                accessor: "sTTLAYMAU"
                            },
                            {
                                Header: "Địa chỉ",
                                Cell:row=><span>
                                    {row.original.tHON} - {row.original.tENQUAN}
                                </span>                               
                            }, 
                            {
                                Header: "Đối tượng",
                                accessor: "dOITUONG"
                            },
                            {
                                Header: "Ngày sinh",
                                accessor: "nGAYSINH"
                            },
                            {
                                Header: "Giới tính",
                                accessor: "pHAI"                                
                            },   
                            {
                                Header: "Tên loại",
                                accessor: "tEN_LOAI"
                            },  
                            {
                                Header: "Tên nhóm",
                                accessor: "tEN_NHOM"
                            },                              
                            {
                                Header: "Tên dịch vụ",
                                accessor: "tEN_BV_TEN"
                            }
                        ]}
                        
                    >

                    </ReactTable>
                </Card>

            </Page>
        )
    }
}

export default withApollo(kqxn2);