import Page from "components/Page";
import React, { Fragment } from "react";
import ToggleButton from 'react-toggle-button'
import {
    Button,
    Input,
    Row,
    Col,
    Label,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Badge,
    ButtonGroup,
    Tooltip
} from "reactstrap";
import { withApollo } from "react-apollo";
import {
    MdCheckBoxOutlineBlank,
    MdCheckCircle,
    MdClose,
    MdTimelapse
} from "react-icons/lib/md";
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import authCheck from "utils/authCheck";
import AppointmentTime from "components/AppointmentTime";
import Moment from "moment";
import ReactTable from "react-table";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FETCH_LIST_APPOINTMENT_HISTORY, GET_CUSTOMER } from 'AdminCustomer/GqlQuery'


class CustomerEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: "",
            fullName: "",
            birthDay: new Date(1990, 1, 1),
            phoneNumber: "",
            mariage: false,
            work: "",
            gender: "male",
            PatientId: "",
            email: "",
            tooltipOpen:{}
        };
    }
    componentDidMount() {
        const phoneNumber = this.props.match.params.phoneNumber;
        const _id = this.props.match.params._id;
        if (_id) {
            this.props.client
                .query({
                    query: GET_CUSTOMER,
                    variables: { _id }
                })
                .then(result => {
                    authCheck(result);
                    if (result.data.customer !== null) {
                        this.setState(result.data.customer.data);
                    }
                });
        } else if (phoneNumber) {
            this.props.client
                .query({
                    query: GET_CUSTOMER,
                    variables: { phoneNumber }
                })
                .then(result => {
                    authCheck(result);
                    if (result.data.customer !== null) {
                        this.setState(result.data.customer.data);
                    }
                });
        }
    }
    onCancel = () => {
        this.props.history.push("/customer");
    };
    onFetchCustomer = () => {
        const { phoneNumber } = this.state;
        if (phoneNumber) {
            this.props.client
                .query({
                    query: GET_CUSTOMER,
                    variables: { phoneNumber }
                })
                .then(result => {
                    authCheck(result);
                    try {
                        this.setState(
                            result.data.customer.data
                        );
                        this.fetchAppointmentData(result.data.customer._id, 0, 10);
                    }
                    catch (er) { }
                });
        }
    };

    fetchAppointmentData = async (
        phoneNumber,
        page,
        pageSize,
        _filtered,
        sorted
    ) => {
        var filtered = [{ id: "phoneNumber", value: phoneNumber }];
        if (_filtered && _filtered !== null) filtered = filtered.concat(_filtered);
        try {
            const result = await this.props.client.query({
                query: FETCH_LIST_APPOINTMENT_HISTORY,
                variables: { filtered, pageSize, sorted, page }
            });

            this.setState({
                data: result.data.appointments.data,
                pages: result.data.appointments.pages,
                loading: false
            });
        } catch (err) {
            console.log(err);
            authCheck(err);
            this.setState({ data: [], loading: false });
        }
    };


    render = () => {
        const {
            phoneNumber,
            mariage,
            address,
            fullName,
            birthDay,
            gender,
            work,
            patientId,
            email,
            tooltipOpen
        } = this.state;
        let waiting = 0, served = 0, cancel = 0,approve=0;
        return (
            <Page
                title="Thông tin khách hàng"
                breadcrumbs={[{ name: "Thông tin khách hàng", active: true }]}
            >
                <div className="m-4">

                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Số điện thoại</Label>
                                <Input
                                    bsSize="sm"
                                    value={phoneNumber}
                                    onKeyPress={e => {
                                        if (e.charCode === 13) this.onFetchCustomer();
                                    }}
                                    onChange={e => {
                                        this.setState({ phoneNumber: e.target.value });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Họ tên</Label>
                                <Input
                                    bsSize="sm"
                                    value={fullName}
                                    onChange={e => {
                                        this.setState({ fullName: e.target.value });
                                    }}
                                />
                            </FormGroup>
                        </Col>

                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Ngày sinh</Label>

                                <Input
                                    type="date"
                                    bsSize="sm"
                                    value={Moment(birthDay).format("YYYY-MM-DD")}
                                    onChange={e => {
                                        this.setState({ birthDay: e.target.value });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Địa chỉ</Label>
                                <Input
                                    bsSize="sm"
                                    value={address ? address : ""}
                                    onChange={e => {
                                        this.setState({ address: e.target.value });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Giới tính</Label>
                                <Input
                                    bsSize="sm"
                                    type="select"
                                    value={gender}
                                    onChange={e => {
                                        this.setState({ gender: e.target.value });
                                    }}
                                >
                                    <option value="">
                                        Chưa xác định
                                    </option>
                                    <option value="maile">
                                        Nam
                                    </option>
                                    <option value="female">
                                        Nữ
                                    </option>

                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Công việc hiện tại</Label>
                                <Input
                                    bsSize="sm"
                                    value={work ? work : ""}
                                    onChange={e => {
                                        this.setState({ work: e.target.value });
                                    }}
                                />
                            </FormGroup>
                        </Col>

                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Mã bệnh nhân</Label>
                                <Input
                                    bsSize="sm"
                                    value={patientId ? patientId : ""}
                                    onChange={e => {
                                        this.setState({ work: e.target.value });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Địa chỉ thư điện tử</Label>
                                <Input
                                    bsSize="sm"
                                    value={email}
                                    onChange={e => {
                                        this.setState({ email: e.target.value });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Tình trạng hôn nhân</Label>
                                <ToggleButton
                                    value={mariage}
                                    onToggle={value => {
                                        this.setState({ mariage: !mariage });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                </div>

                <div className="mb-4">
                    <Button color="primary">
                        Lưu thay đổi
                    </Button>{' '}
                    <Button color="info" onClick={this.onCancel}>
                        Quay lại
                    </Button>
                </div>
                <Query
                    query={FETCH_LIST_APPOINTMENT_HISTORY}
                    variables={{
                        pageSize: 0,
                        filtered: [{ id: "phoneNumber", value: phoneNumber }],
                        sorted: [{ id: "appointmentDate", desc: true }]
                    }}
                >
                    {({ loading, error, data }) => {
                        if (error || !data.appointments) {
                            authCheck(error);
                            //return <div>Có lỗi load dữ liệu</div>
                            data = { appointments: { data: [] } };
                        }
                        waiting = data.appointments.data.filter((item) => {
                            return item.state === "waiting";
                        }).length;

                        cancel = data.appointments.data.filter((item) => {
                            return item.state === "cancel";
                        }).length
                        served = data.appointments.data.filter((item) => {
                            return item.state === "served";
                        }).length;
                        approve = data.appointments.data.filter((item) => {
                            return item.state === "approve";
                        }).length
                        return (
                            <Card>
                                <CardHeader className="bg-green">
                                    LỊCH SỬ ĐẶT HẸN ({data.appointments.data.length} LƯỢT)
                                    <ButtonGroup style={{ float: "right" }}>
                                        <Button color="default">{waiting} chờ xác nhận</Button>
                                        <Button color="warning">{approve} chờ khám</Button>
                                        <Button color="primary">{served} đã khám</Button>
                                        <Button color="danger">{cancel} không khám</Button>
                                    </ButtonGroup>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        defaultSorted={[
                                            {
                                                id: "appointmentDate",
                                                desc: true
                                            }
                                        ]}
                                        data={data.appointments.data}
                                        filterable
                                        columns={[
                                            {
                                                Header: "Trạng thái",
                                                accessor: "state",                                                
                                                maxWidth: 120,
                                                style: { textAlign: "center" },
                                                Cell: row => (
                                                    <div style={{fontSize:16}}>
                                                        {row.value==="approve"? 
                                                        <MdCheckBoxOutlineBlank id={"state"+row.original._id} className="text-green"/>:
                                                        row.value==="served"?<MdCheckCircle  id={"state"+row.original._id} className="text-green"/>:
                                                        row.value==="cancel"?<MdClose className="text-danger" id={"state"+row.original._id}/>:
                                                       
                                                        <MdTimelapse className="text-danger" id={"state"+row.original._id}/>
                                                       
                                                       } 
                                                       <Tooltip isOpen={tooltipOpen["state"+row.original._id]} target={"state"+row.original._id} autohide={true} toggle={e=>{tooltipOpen["state"+row.original._id]=!tooltipOpen["state"+row.original._id]; this.setState({tooltipOpen})}}>
                                                        {
                                                            row.value==="waiting"?"Chờ duyệt":
                                                            row.value==="approve"?"Chờ khám":
                                                            row.value==="cancel"?"Hủy khám":
                                                            row.value==="served"?"Đã khám":""
                                                        }
                                                        </Tooltip>
                                                    </div>
                                                ),
                                                Filter: ({ filter, onChange }) => (
                                                    <Input
                                                        type="select"
                                                        onChange={e => onChange(e.target.value)}
                                                        bsSize="sm"
                                                        value={filter ? filter.value : ""}
                                                    >
                                                        <option value="">Tất cả</option>
                                                        <option value="served">Đã khám</option>
                                                        <option value="approve">Chờ khám</option>
                                                        <option value="waiting">Chờ xác nhận</option>
                                                        <option value="cancel">Hủy khám</option>
                                                    </Input>
                                                )
                                            },
                                            {
                                                Header: "Bệnh nhân",
                                                accessor: "fullName",
                                                maxWidth: 150,
                                                filterable:false
                                            },
                                            {
                                                Header: "NGÀY GIỜ KHÁM",
                                                width: 160,
                                                filterable: false,
                                                accessor:"appointmentDate",
                                                Cell: row => (
                                                    <div>
                                                        {Moment(row.value).format(
                                                            "DD/MM/YYYY"
                                                        )}
                                                        {"  "}
                                                        <Badge className="text-white">
                                                            {row.original.appointmentTime}
                                                        </Badge>
                                                    </div>
                                                )
                                            },

                                            {
                                                Header: "Kênh",
                                                accessor: "chanel",
                                                maxWidth: 120,
                                                Filter: ({ filter, onChange }) => (
                                                    <Input
                                                        type="select"
                                                        onChange={e => onChange(e.target.value)}
                                                        bsSize="sm"
                                                        value={filter ? filter.value : ""}
                                                    >
                                                        <option value="">Tất cả</option>
                                                        <option value="crm">CRM</option>
                                                        <option value="web">Web</option>
                                                        <option value="app">Mobile App</option>
                                                    </Input>
                                                ),
                                                Cell: row => <span>
                                                    {row.value === "call" ? "TỔNG ĐÀI" : row.value === "app" ? "ỨNG DỤNG" : row.value === "crm" ? "CRM" : "WEBSITE"}
                                                </span>,
                                                style: { textAlign: "center", textTransform: "uppercase" },
                                            },

                                            {
                                                Header: "Khoa khám",
                                                accessor: "department.name",
                                                className: "text-center",
                                                filterable:false
                                            },
                                            {
                                                Header: "Lý do đặt khám",
                                                accessor: "reason",
                                                filterable:false
                                            }

                                        ]}
                                        defaultPageSize={5}
                                        className="-striped -highlight"
                                    />
                                </CardBody>
                            </Card>
                        );
                    }}
                </Query>
            </Page>
        );
    };
}

export default withApollo(CustomerEditPage);
