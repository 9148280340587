import Page from 'components/Page';
import React,{Fragment} from 'react';
import { Button, ButtonGroup, Card, Input, Label,CardHeader,Tooltip } from 'reactstrap';
import { withApollo,Mutation } from 'react-apollo'
import ToggleButton from 'react-toggle-button'
import gql from 'graphql-tag';
import { MdDelete, MdEdit,MdAddCircle } from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import Moment from 'moment'

export const FEED_QUERY = gql`
{
    hotNews{
      message
      code
      page
      pages
      data{
        _id
        title
        createdTime
        coverImage
        description
        sort
        disable
        name
        updater
      }
    }
  }
    
`
export const HOTNEWS_DELETE = gql`

    mutation DELETE_HOTNEWS($_id:String){
        deleteHotNews(_id:$_id){
          code
          message
        }
      }

    
`
class HotNewsPage extends React.Component {

    state = {
        data: [],
        pages: 1,
        page:0,
        loading: true,
        tooltipOpen:{}
    }
    constructor() {
        super();
        this.fetchData = this.fetchData.bind(this);
    }
    fetchData = async (state, instance) => {

        this.setState({ loading: true });
        const { filtered, pageSize, sorted, page } = state;
        try {
            const result = await this.props.client.query({
                query: FEED_QUERY,
                variables: { filtered, pageSize, sorted, page },
            })
            authCheck(result);
            this.setState({ data: result.data.hotNews.data, pages: result.data.hotNews.pages,page: result.data.hotNews.page, loading: false });
        } catch (err) {
            authCheck(err);
        }
    }
    onEdit=(row)=>{            
        this.props.history.push("/hotnews-edit/"+row._id);
     }
    onDelete=(row)=>{   
        var flag=window.confirm('Bạn có chắc chắn muốn xóa không?');
        if(flag){
        this.props.client.mutate({
          mutation : HOTNEWS_DELETE,
          variables: {_id: row._id}
        }).then(result=>{
            this.fetchData(this.state,null)
        });
        }
    }
    render = () => {
        const { data, loading, pages,tooltipOpen ,page} = this.state;
        return (
            <Page title="Quản lý tin hot"
                breadcrumbs={[{ name: 'Quản lý tin hot', active: true }]}
                className="CustomerPage">
                <Card>
                    <CardHeader className="bg-green">
                        <Button color="primary" onClick={e => { this.props.history.push("/hotnews-edit") }}><MdAddCircle /> Tạo tin hot</Button>
                    </CardHeader>
                    <ReactTable
                        noDataText="Không có dữ liệu"
                        sortable={false}
                        columns={[
                            {
                                Header: "#",
                                width:50,
                                className:"text-center",
                                filterable:false,
                                Cell:row=><span>
                                    {row.index+1+page*10}
                                </span>
                            },
                            {
                                Header: "Tên",
                                accessor: "name"
                            },
                            {
                                Header: "Ảnh Cover",
                                maxWidth:100,
                                accessor: "coverImage",
                                filterable: false,
                                Cell:row=>(
                                    <div>
                                        <img width="60" height="60" alt={row.original.title} src={row.value}/>
                                    </div>
                                ),
                            },
                            {
                                Header: "Tiêu đề",
                                accessor: "title"
                            },

                            {
                                Header: "Mô tả ngắn",
                                accessor: "description"
                            },
                            {
                                Header: "Thứ tự",
                                accessor: "sort",
                                style:{textAlign:"center"},
                                Filter:({filter,onChange})=>
                                        <Input
                                            type="select" onChange={e=>onChange(e.target.value)} bsSize="sm"
                                            value={filter?filter.value:""}
                                        >
                                            <option value="sort_asc">Thấp đến cao</option>
                                            <option value="sort_desc">Cao xuống thấp</option>
                                        </Input>
                            },
                            {
                                Header: "Trạng thái",
                                accessor: "disable",
                                width:90,
                                className:"text-center",
                                filterable:false,
                                Cell:row=>(
                                    <ToggleButton
                                    disabled
                                    value={ row.value }
                                    />
                                )
                            },
                            {
                                Header: "Thời gian tạo",
                                accessor: "createdTime",
                                width:120,
                                className:"text-center",
                                Filter: ({ filter, onChange }) =>
                                    <Input bsSize="sm" type="date" placeholder="Chọn ngày" onChange={e => onChange(e.target.value)} />,
                                Cell: row => (<Label>{Moment(row.value).format('DD/MM/YYYY')}</Label>)
                            },
                            {
                                Header: "Tác vụ",
                                maxWidth: 100,
                                filterable: false,
                                sortable: false,
                                className:"text-center",
                                Cell: row=>(
                                    <ButtonGroup style={{fontSize:20}}>
                                        <MdEdit id={"edit"+row.original._id} onClick={()=>this.onEdit(row.original)} className="text-warning m-1"/>
                                        <Tooltip placement="bottom" isOpen={tooltipOpen['edit'+row.original._id]} autohide={false} target={"edit"+row.original._id} toggle={e=>{tooltipOpen['edit'+row.original._id]=!tooltipOpen['edit'+row.original._id]; this.setState({tooltipOpen})}}>
                                            Sửa
                                        </Tooltip>
                                        <Mutation mutation={HOTNEWS_DELETE}
                                            variables={{_id:row.original._id}}
                                            onCompleted={(result)=>{
                                                this.fetchData(this.state,null)
                                            }}
                                        >
                                            {mutation=>
                                                <Fragment>
                                                    <MdDelete id={"delete"+row.original._id} className="text-danger m-1" onClick={()=>this.onDelete(row.original,mutation)}/>
                                                    <Tooltip placement="bottom" isOpen={tooltipOpen['delete'+row.original._id]} autohide={false} target={"delete"+row.original._id} toggle={e=>{tooltipOpen['delete'+row.original._id]=!tooltipOpen['delete'+row.original._id]; this.setState({tooltipOpen})}}>
                                                        Xóa tin
                                                    </Tooltip>
                                                </Fragment>
                                            }
                                        </Mutation>
                                    </ButtonGroup>
                                )
                            }
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={10}
                        className="-striped -highlight"
                    >

                    </ReactTable>
                </Card>

            </Page>
        )
    }
}

export default withApollo(HotNewsPage);