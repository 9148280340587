import Page from "Admin/Components/Page";
import React from "react";
import { Button, Input, Row, Col, Label, Form, FormGroup,CardHeader } from "reactstrap";
import { withApollo, Mutation,Query } from "react-apollo";
import gql from "graphql-tag";
import authCheck from "utils/authCheck";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ToggleButton from 'react-toggle-button'

export const QUERY_DETAIL = gql`
query BranchDetail($_id:String){
  response:branchLocation(_id:$_id){
    data{
      _id
      address
      description
      geo
      name
      photos
    }
    
  }
}
`;
export const QUERY_PERMISSIONS=gql`
{
  permissions{
    data{
      name
      value
    }
  }
}
`;
export const MUTATION_CREATE = gql`
mutation COMPANY($branchLocation:BranchLocationInputType){
  createBranchLocation(branchLocation:$branchLocation){
    code
    message
  }
}
`;
export const UPLOAD_MUTATION= gql`
mutation Upload($folder:String,$file:FileUploadInputType){
  uploadImage(folder:$folder,file:$file)
}
`;
export const MUTATION_EDIT = gql`
mutation COMPANY($branchLocation:BranchLocationInputType ){
  updateBranchLocation(branchLocation:$branchLocation){
    code
    message
  }
} 
`;

class BranchEditPage extends React.Component {
  constructor() {
    super();
    
    this.state = {
      name: "",
      address:"",
      description: "",
      photos:[]
    };
    
  }
  componentDidMount() {
    const _id = this.props.match.params._id;
    if(_id){
      this.setState({isEdit:true,_id:_id});
      //fetch detail
      this.props.client.query({
          query:QUERY_DETAIL,
          variables:{_id:_id},
          
      }).then(result=>{
          authCheck(result);
          const branch=result.data.response.data;          
          this.setState(branch);
      });
  }
  }
  onEditorStateChange= (editorState) => {
    this.setState({
      editorState,
    });
  };
  
  onCreate=()=>{
    const {
      _id,
      address,
      description,
      name,
      photos
    } = this.state;
    this.props.client.mutate({
      mutation : (_id === undefined || _id.length === 0)
      ? MUTATION_CREATE
      : MUTATION_EDIT,
      variables: {branchLocation: {
        _id,
        name,
        address,
        description,
        photos
      }}//truong hợp này chỉ dùng 1 dấu nháy vì variables chỉ là 1 object ko phải thuộc tính
    }).then(result=>{
      this.onCancel()
    });
  }
  onCancel = () => {
    this.props.history.push("/branch");
  };
  
  render = () => {
    const {
      _id,
      name,
      description,
      address,
      file,
      photos
    } = this.state;
    
    return (
      <Page
        title={(this.state._id!=null)?"Sửa chi nhánh":"Tạo chi nhánh"}
        breadcrumbs={[{ name: (this.state._id!=null)?"Sửa chi nhánh":"Tạo chi nhánh", active: true }]}
        className="hotnews"
      >
        <Row>
          <Col md={12}>
            <Button color="primary" onClick={()=>this.onCreate()}>
                  Cập nhật thông tin
                </Button>
            <Button color="link" onClick={console.log(JSON.stringify(this.state.checked))}>
              Hủy
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form className="m-3">
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Tên chi nhánh:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="text"
                    value={name}
                    onChange={e => {
                      this.setState({ name: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Ảnh:
                </Label>
                <Col md={10} sm={8} xs={6}>
                    <Mutation
                        mutation={UPLOAD_MUTATION}
                        onCompleted={(result)=>{
                          console.log("upload result="+JSON.stringify(result));
                          photos.push(result.uploadImage);
                          this.setState({photos});
                        }}
                      >
                        {
                          mutation=><Input
                            type="file"
                            onChange={({
                            target: {
                                validity,
                                files: [file]
                            }
                            }) => {
                              // this.setState({ file });
                              console.log("filename:"+file.name);
                              mutation({variables:{folder:"location",file}});
                            }}
                            bsSize="sm"
                          />
                        }
                      </Mutation>
                      {photos.map((item,index)=><div key={index}>
                        <img width="100" height="100"  src={item} style={{maxHeight:100,maxWidth:"100%"}}/>
                        <Button onClick={e=>{photos.splice(index,1);this.setState({photos})}}>Remove</Button>
                      </div>)}                         
                  
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Địa chỉ:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="text"
                    value={address}
                    onChange={e => {
                      this.setState({ address: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Mô tả ngắn
                </Label>
                <Col md={10} sm={8} xs={6}>
                
                  <Input
                    type="textarea"
                    value={description}
                    onChange={e => {
                      this.setState({ description: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              
              
            </Form>
          </Col>
        </Row>
      </Page>
    );
  };
}
export default withApollo(BranchEditPage);
