import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import {AUTH_TOKEN as COMPANY_AUTH_TOKEN} from './companyConstants'
import {AUTH_TOKEN as ADMIN_AUTH_TOKEN} from './adminConstants'

import App from './AdminCustomer/App';
import AppAdmin from './Admin/AppAdmin';
var hostname = window.location.hostname;
var isAdmin=false;
var apiHost="apiv2.app.benhvienphusanhanoi.vn:4002";
// var apiHost="api.pshn.benhvien.tech";
//var apiHost="api.dungnt";
//var apiHost="localhost:4000";
console.log("hostname="+hostname);
//if(hostname.startsWith("admin"))
//  isAdmin=true; 
// isAdmin=true;
const wsLink = new WebSocketLink({
   uri: `${isAdmin?'ws://'+apiHost+'/admin/graphql/':'ws://'+apiHost+'/graphql/'}`,
   options: {
     reconnect: true
   }
 });

const httpLink = createUploadLink ({
  uri: `${isAdmin?'http://'+apiHost+'/admin/graphql':'http://'+apiHost+'/graphql'}`
})
 // using the ability to split links, you can send data to each link
 // depending on what kind of operation is being sent
 const link = split(
   // split based on operation type
   ({ query }) => {
     const { kind, operation } = getMainDefinition(query);
     return kind === 'OperationDefinition' && operation === 'subscription';
   },
   wsLink,
   httpLink,
 );
 
 const authLink = setContext((_, { headers }) => {
    var tokenStr='';
    if(isAdmin)
      tokenStr = localStorage.getItem(ADMIN_AUTH_TOKEN);
    else 
      tokenStr=localStorage.getItem(COMPANY_AUTH_TOKEN);
     //const tokenStr = localStorage.getItem(AUTH_TOKEN);
     try{
       if(tokenStr){
         const token = JSON.parse(tokenStr);
         return {
             headers: {
             ...headers,
             authorization: token ? `Bearer ${token.accessToken}` : ''
             }
         }
       }
     }catch(err){
       localStorage.setItem(COMPANY_AUTH_TOKEN,undefined);
       localStorage.setItem(ADMIN_AUTH_TOKEN,undefined);
     }
     return {};
 })
 //this defaultOptions will stop apollo from caching?
 const defaultOptions = {
   watchQuery: {
     fetchPolicy: 'network-only',
     errorPolicy: 'ignore',
   },
   query: {
     fetchPolicy: 'no-cache',
     errorPolicy: 'all',
   },
 }
 export const client = new ApolloClient({
   link: authLink.concat(link),
   cache: new InMemoryCache({addTypename:false}),
   defaultOptions: defaultOptions
 })

ReactDOM.render(
      isAdmin?
      <AppAdmin/>:
      <App />,
   document.getElementById('root')
) 
