import Page from 'components/Page';
import React, { Fragment } from 'react';
import { Button,ButtonGroup,Card,CardBody,CardHeader,Label,Tooltip} from 'reactstrap';
import ToggleButton from "react-toggle-button";
import gql from 'graphql-tag';
import { Query ,Mutation} from 'react-apollo'
import {MdEdit,MdDelete,MdAddCircle} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import confirm from 'components/Confirmation'

export const FEED_QUERY=gql`
    query Department{
        departments{
            data{
                _id
                createdTime
                description
                name
                status
                groupAgent{
                    name
                }
                leaders{
                    name
                }
                agents{
                    name
                }
                workingTimes{
                    timeRange
                }
            }
        }
    }
`
export const CREATE_QUERY=gql`
    mutation Mutation($department:DepartmentInput!){
        createDepartment(department:$department){
            code
            message
            data{
            _id
            createdTime
            description
            name
            status
            groupAgentId
            parentId
            }
        }
    }
`
export const UPDATE_QUERY=gql`
mutation Mutation($department:DepartmentInput!){
    updateDepartment(department:$department){
        code
        message
        data{
            _id
            createdTime
            description
            name
            status
        }
    }
}
`
export const DELTE_QUERY=gql`
    mutation Mutation($_id:String!){
        deleteDepartment(_id:$_id){
            code
            message
        }
    }
`
class DepartmentPage extends React.Component{
    state={
        modal:false,
        isCreate:true,//trang thai edit hay create
        rowOrigin:{},
        rowEdit:{},
        tooltipOpen:{}
    }
    toggle=modalType=>()=>{
        return this.setState({modal:!this.state.modal})
    }
    onCreate=()=>{
        // this.setState({rowOrigin:null,modal:true,isCreate:true});
        this.props.history.push("/department-edit");
    }
     onEdit=(row)=>{            
       this.props.history.push("/department-edit/"+row._id);
    }
    
    onDelete=(row,mutation)=>{
        confirm(`Bạn có chắc chắn muốn xóa bản ghi '${row.name}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }
    render=()=>{
        const {tooltipOpen} = this.state;
        return (
            <Page
                title="Phòng/Ban"
                breadcrumbs={[{ name: 'Phòng/Ban', active: true }]}
                className="TablePage"
            >
                <Card>
                    <CardHeader className="bg-green">
                        
                        <Button  color="primary" onClick={this.onCreate}><MdAddCircle/> Thêm phòng/ban</Button>
                    </CardHeader>
                    <CardBody>
                        
                        <Query query={FEED_QUERY}>
                            {
                                ({loading,error,data})=>{
                                    var result=[];
                                    if (error){
                                        authCheck(error);
                                        //return <div>Có lỗi load dữ liệu</div>
                                        data={departments:{}};
                                    }
                                    try{
                                        result=data.departments.data;
                                    }catch(err){}
                                    return (
                                        <ReactTable
                                            noDataText="Không có dữ liệu"
                                            data={result}
                                            
                                            defaultSorted={[
                                                {
                                                    id: "updatedTime",
                                                    desc: true
                                                }
                                            ]}
                                            loading={loading} 
                                            className="-striped -highlight"
                                            columns={[
                                                {
                                                    Header:"Tên",
                                                    accessor:"name"
                                                },
                                                {
                                                    Header:"Trạng thái",
                                                    accessor:"status",
                                                    Cell:row=><div>
                                                        <ToggleButton
                                                            value={row.value}
                                                        />
                                                    </div>
                                                },
                                                {
                                                    Header:"Mô tả",
                                                    accessor:"description"
                                                },
                                                {
                                                    Header:"Nhóm tổng đài",
                                                    accessor:"groupAgent.name",
                                                    sortable:false,
                                                },
                                                {
                                                    Header:"Lãnh đạo",
                                                    sortable:false,
                                                    Cell:row=>(
                                                        <Label>
                                                            {row.original.leaders.map((leader,index)=>{
                                                                return (<span key={index} style={{marginRight:"15px"}}>{leader.name}</span>)
                                                            })}
                                                            {" "}
                                                            ({row.original.agents.length} nhân viên)
                                                        </Label>
                                                    )
                                                },
                                                {
                                                    Header:"Giờ làm việc",                                                    
                                                    accessor:"workingTimes",
                                                    Cell:row=>(
                                                        <div>
                                                            {
                                                                row.value.map((workTime,index)=>
                                                                (
                                                                    <Label style={{marginRight:"0.7rem"}} key={index}>{workTime.timeRange}</Label>
                                                                ))
                                                            }
                                                        </div>
                                                    )
                                                },
                                                {
                                                    Header:"Tác vụ",
                                                    maxWidth:90,
                                                    sortable:false,
                                                    filterable:false,
                                                    className:"text-center",
                                                    Cell: row=>(
                                                        <ButtonGroup style={{fontSize:20}}>
                                                            <MdEdit id={"edit"+row.original._id} onClick={()=>this.onEdit(row.original)} className="text-warning m-1"/>
                                                            <Tooltip placement="bottom" isOpen={tooltipOpen['edit'+row.original._id]} autohide={false} target={"edit"+row.original._id} toggle={e=>{tooltipOpen['edit'+row.original._id]=!tooltipOpen['edit'+row.original._id]; this.setState({tooltipOpen})}}>
                                                                Sửa
                                                            </Tooltip>
                                                            <Mutation mutation={DELTE_QUERY}
                                                                variables={{_id:row.original._id}}
                                                                onCompleted={(result)=>{
                                                                    window.location.reload();
                                                                }}
                                                            >
                                                                {mutation=>
                                                                    <Fragment>
                                                                        <MdDelete id={"delete"+row.original._id} className="text-danger m-1" onClick={()=>this.onDelete(row.original,mutation)}/>
                                                                        <Tooltip placement="bottom" isOpen={tooltipOpen['delete'+row.original._id]} autohide={false} target={"delete"+row.original._id} toggle={e=>{tooltipOpen['delete'+row.original._id]=!tooltipOpen['delete'+row.original._id]; this.setState({tooltipOpen})}}>
                                                                            Xóa phòng/ban
                                                                        </Tooltip>
                                                                    </Fragment>
                                                                }
                                                            </Mutation>
                                                        </ButtonGroup>
                                                    )
                                                }
                                            ]}
                                        >

                                        </ReactTable>
                                    )
                                }
                            }               
    
                        </Query>
                        </CardBody>
                </Card>                
            </Page>
        )
    }
}



export default DepartmentPage