import Page from "components/Page";
import React from "react";
import ToggleButton from 'react-toggle-button'
import {
    Button,
    Input,
    Row,
    Col,
    Label,
    CardHeader,
    FormGroup,
    Tooltip
} from "reactstrap";
import { withApollo } from "react-apollo";
import { Mutation } from "react-apollo";
import authCheck from "utils/authCheck";

import {CREATE_DIAGNOSISDATA,  DIAGNOSISDATA,GET_CUSTOMER } from 'AdminCustomer/GqlQuery'


class DiagnosisDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            doctorId:"",
            doctorName:"",
            customer:{},
            patientId: "",
            testId: "",
            testName:"",
            data:{chong:{}},         
            tooltipOpen:{}
        };
    }
    componentDidMount() {
        const _id = this.props.match.params._id;
        if (_id) {
            this.props.client
                .query({
                    query: DIAGNOSISDATA,
                    variables: { _id }
                })
                .then(result => {
                    authCheck(result);
                    let data =result.data.response.data;
                    data.data=JSON.parse(data.data);
                    this.setState(data);
                    
                });
        }
    }
    onCancel = () => {
        this.props.history.goBack();
    };
    onFetchCustomer = () => {
        const { phoneNumber,patientId } = this.state;
        if (phoneNumber||patientId) {
            this.props.client
                .query({
                    query: GET_CUSTOMER,
                    variables: { phoneNumber,patientId }
                })
                .then(result => {
                    authCheck(result);
                    try {
                        this.setState(
                            {customer:result.data.customer.data}
                        );
                        
                    }
                    catch (er) { }
                });
        }
    };

    render = () => {
        const {            
            patientId,
            testId,
            testName,
            doctorId,
            doctorName,
            customer,
            data,
            tooltipOpen
        } = this.state;
        
        return (
            <Page
                title="Thông tin chuẩn đoán"
                breadcrumbs={[{ name: "Thông tin chuẩn đoán", active: true }]}
            >
                <div className="m-4">
                    <CardHeader>Thông tin bệnh nhân</CardHeader>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Mã bệnh nhân</Label>
                                <Input
                                    bsSize="sm"
                                    value={patientId}
                                    onKeyPress={e => {
                                        if (e.charCode === 13) this.onFetchCustomer();
                                    }}
                                    onChange={e => {
                                        this.setState({ patientId: e.target.value });
                                    }}
                                />
                            </FormGroup>
                            </Col>
                            <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Mã bác sỹ</Label>
                                <Input
                                    bsSize="sm"
                                    value={doctorId}
                                    onKeyPress={e => {
                                        if (e.charCode === 13) this.onFetchCustomer();
                                    }}
                                    onChange={e => {
                                        this.setState({ doctorId: e.target.value });
                                    }}
                                />
                            </FormGroup>
                            </Col>
                            <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Tên bác sỹ</Label>
                                <Input
                                    bsSize="sm"
                                    value={doctorName}
                                    onKeyPress={e => {
                                        if (e.charCode === 13) this.onFetchCustomer();
                                    }}
                                    onChange={e => {
                                        this.setState({ doctorName: e.target.value });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>&nbsp;</Label>
                                <div>
                                    <Button color="primary" onClick={this.onFetchCustomer}>Kiểm tra thông tin</Button>
                                </div>
                            </FormGroup>   
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Họ và tên</Label>                                
                                <Input
                                    bsSize="sm"
                                    disabled
                                    value={customer?customer.fullName:""}                                   
                                />
                            </FormGroup>
                        </Col>

                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Năm sinh</Label>
                                <Input
                                    disabled
                                    bsSize="sm"
                                    value={customer?customer.birthYear:""}                                    
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Chiều cao</Label>
                                <Input
                                    disabled
                                    bsSize="sm"
                                    value={customer?customer.height:""}                                    
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Cân năng</Label>
                                <Input
                                    disabled
                                    bsSize="sm"
                                    value={customer?customer.weight:""}                                    
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Số điện thoại</Label>
                                <Input
                                    bsSize="sm"
                                    disabled
                                    value={customer ? customer.phoneNumber : ""}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Dân tộc</Label>
                                <Input
                                    bsSize="sm"
                                    disabled
                                    value={customer ? customer.ethnic : ""}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Địa chỉ</Label>
                                <Input
                                    bsSize="sm"
                                    disabled
                                    value={customer ? customer.address : ""}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Nghề nghiệp</Label>
                                <Input
                                    bsSize="sm"
                                    disabled
                                    value={customer?customer.work:""}
                                    
                                />                                    
                            </FormGroup>
                        </Col>                        
                    </Row>

                    
                    <CardHeader>
                        Thông tin chuẩn đoán
                        
                    </CardHeader>
                    <h3>Tiền sử nội ngoại khoa</h3>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Viêm gan,ung thư gan</Label>
                                <Input
                                    bsSize="sm"                                    
                                    value={data?data.viemgan:""}
                                    onChange={e=>{data.viemgan=e.target.value;this.setState({data})}}
                                    
                                />                                    
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Chuẩn đoán năm</Label>
                                <Input
                                    bsSize="sm" 
                                    value={data?data.namviemgan:""}
                                    onChange={e=>{data.nameviemgan=e.target.value;this.setState({data})}}
                                />                                    
                            </FormGroup>       
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Đái tháo đường</Label>
                                <ToggleButton
                                value={ data.daithaoduong || false }
                                onToggle={(value) => {
                                    data.daithaoduong=!data.daithaoduong;
                                    this.setState({data});
                                }} />                                 
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Chuẩn đoán năm</Label>
                                <Input
                                    bsSize="sm"   
                                    value={data.namdaithaoduong}
                                    onChange={e=>{data.namdaithaoduong=e.target.value;this.setState({data})}}
                                />                                    
                            </FormGroup>       
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Hút thuốc</Label>
                                <ToggleButton
                                value={ data.hutthuoc || false }
                                onToggle={(value) => {
                                    data.hutthuoc=!data.hutthuoc;
                                    this.setState({data});
                                }} />                                   
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Số lượng</Label>
                                <Input
                                    bsSize="sm"
                                    value={data.soluong_hutthuoc}    
                                    onChange={e=>{data.soluong_hutthuoc=e.target.value;this.setState({data})}}                                  
                                />                                    
                            </FormGroup>       
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Uống rượu</Label>
                                <ToggleButton
                                value={ data.uongruou || false }
                                onToggle={(value) => {
                                    data.uongruou=!data.uongruou;
                                    this.setState({data});
                                }} />                                       
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Số lượng</Label>
                                <Input
                                    bsSize="sm"
                                    value={data.soluong_uongruou}    
                                    onChange={e=>{data.soluong_uongruou=e.target.value;this.setState({data})}}                                  
                                />                                      
                            </FormGroup>       
                        </Col>
                    </Row>
                    <h3>3.PARA</h3>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Số con gái</Label>
                                <Input
                                    bsSize="sm" 
                                    value={data.daughters}
                                    onChange={e=>{data.daughters=e.target.value;this.setState({data})}}                                      
                                />                                    
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Số con trai</Label>
                                <Input
                                    bsSize="sm" 
                                    value={data.sons}
                                    onChange={e=>{data.sons=e.target.value;this.setState({data})}}                                      
                                />                                      
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Tình trạng sức khỏe</Label>
                                <Input
                                    bsSize="sm" 
                                    value={data.health_state}
                                    onChange={e=>{data.health_state=e.target.value;this.setState({data})}}                                      
                                />                                        
                            </FormGroup>       
                        </Col>
                    </Row>
                    <h3>Thai lần này</h3>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Tự nhiên? </Label>
                                <ToggleButton
                                value={ data.nature || false }
                                onToggle={(value) => {
                                    data.nature=!data.nature;
                                    this.setState({data});
                                }} />                                    
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Ngày chuyển phôi </Label>
                                <Input
                                    bsSize="sm" 
                                    value={data.ngaychuyenphoi}
                                    onChange={e=>{data.ngaychuyenphoi=e.target.value;this.setState({data})}}                                      
                                />                                    
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Ngày bơm tinh trùng</Label>
                                <Input
                                    bsSize="sm"    
                                    value={data.ngay_bom_tinh_trung}
                                    onChange={e=>{data.ngay_bom_tinh_trung=e.target.value;this.setState({data})}}                                  
                                />                                    
                            </FormGroup>       
                        </Col>
                        
                    </Row>
                    
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Ngày đầu tiên kỳ kinh cuối cùng</Label>
                                <Input
                                    bsSize="sm" 
                                    value={data.ngay_kinh_cuoi}
                                    onChange={e=>{data.ngay_kinh_cuoi=e.target.value;this.setState({data})}}                                  
                                                                      
                                />                                    
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Vòng kinh (ngày)</Label>
                                <Input
                                    bsSize="sm"                                       
                                    value={data.vongkinh}
                                    onChange={e=>{data.vongkinh=e.target.value;this.setState({data})}}                                  
                                />                                    
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Dự kiến sinh</Label>
                                <Input
                                    bsSize="sm"
                                    value={data.dukiensinh}
                                    onChange={e=>{data.dukiensinh=e.target.value;this.setState({data})}}                                  
                                                                         
                                />                                    
                            </FormGroup>       
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Siêu âm ngày</Label>
                                <Input
                                    bsSize="sm" 
                                    value={data.ngaysieuam}    
                                    onChange={e=>{data.ngaysieuam=e.target.value;this.setState({data})}}                              
                                />                                    
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Thai tương đương</Label>
                                <Input
                                    bsSize="sm"   placeholder="tuần"  
                                    value={data.thaituongduong_tuan}    
                                    onChange={e=>{data.thaituongduong_tuan=e.target.value;this.setState({data})}}                              
                                />  
                                <Input
                                    bsSize="sm"   placeholder="ngày"                                    
                                    value={data.thaituongduong_ngay}    
                                    onChange={e=>{data.thaituongduong_ngay=e.target.value;this.setState({data})}}                              
                                />                                    
                            </FormGroup>       
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>CRL</Label>
                                <Input
                                    bsSize="sm"                                       
                                    value={data.crl}    
                                    onChange={e=>{data.crl=e.target.value;this.setState({data})}}                              
                                />                                    
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>BPD</Label>
                                <Input
                                    bsSize="sm"   placeholder=""                                    
                                    value={data.bpd}    
                                    onChange={e=>{data.bpd=e.target.value;this.setState({data})}}                              
                                />  
                                                              
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Khoảng sáng sau gáy (mm)</Label>
                                <Input
                                    bsSize="sm"   placeholder=""                                    
                                    value={data.khoangsang}    
                                    onChange={e=>{data.khoangsang=e.target.value;this.setState({data})}}                              
                                
                                />  
                                                              
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Nhịp tim thai</Label>
                                <Input
                                    bsSize="sm"   placeholder=""                                    
                                    value={data.timthai}    
                                    onChange={e=>{data.timthai=e.target.value;this.setState({data})}}                              
                                />  
                                                              
                            </FormGroup>       
                        </Col>
                    </Row>
                    <CardHeader>Thông tin của chồng thai phụ</CardHeader>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Họ và tên</Label>
                                <Input
                                    bsSize="sm"   placeholder=""                                    
                                    value={data.chong.ten}    
                                    onChange={e=>{data.chong.ten=e.target.value;this.setState({data})}}                              
                                />                                                               
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Tuổi</Label>
                                <Input
                                    bsSize="sm"   placeholder=""                                    
                                    value={data.chong.tuoi}    
                                    onChange={e=>{data.tuoi=e.target.value;this.setState({data})}}                              
                                />                                                               
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Nghề nghiệp</Label>
                                <Input
                                    bsSize="sm"   placeholder=""                                    
                                    value={data.chong.congviec}    
                                    onChange={e=>{data.chong.congviec=e.target.value;this.setState({data})}}                              
                                />                                                               
                            </FormGroup>       
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Số điện thoại</Label>
                                <Input
                                    bsSize="sm"   placeholder=""                                    
                                    value={data.chong.sdt}    
                                    onChange={e=>{data.chong.sdt=e.target.value;this.setState({data})}}                              
                                />                                                               
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Tiền sử nội khoa</Label>
                                <Input
                                    bsSize="sm"   placeholder="tuần"                                    
                                    value={data.chong.tiensu}    
                                    onChange={e=>{data.chong.tiensu=e.target.value;this.setState({data})}}                              
                                />                                                               
                            </FormGroup>       
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Rượu/bia</Label>
                                <Input
                                    bsSize="sm"   placeholder=""                                    
                                    value={data.chong.ruoubia}    
                                    onChange={e=>{data.chong.ruoubia=e.target.value;this.setState({data})}}                              
                                />                                                               
                            </FormGroup>       
                        </Col>
                        <Col md="3" sm="6" xs="12">
                            <FormGroup>
                                <Label>Thuốc lá: điếu/ngày</Label>
                                <Input
                                    bsSize="sm"   placeholder=""                                    
                                    value={data.chong.thuocla}    
                                    onChange={e=>{data.chong.thuocla=e.target.value;this.setState({data})}}                              
                                />                                                               
                            </FormGroup>       
                        </Col>
                        
                    </Row>
                    <CardHeader>Tiền sử gia đình</CardHeader>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label>Mắc các rối loạn di truyền hoặc dị tật bẩm sinh</Label>
                                <Input
                                    bsSize="sm"   placeholder=""                                    
                                    value={data.tiensugiadinh}    
                                    onChange={e=>{data.tiensugiadinh=e.target.value;this.setState({data})}}                              
                                />                                                               
                            </FormGroup>       
                        </Col>
                        
                    </Row>
                </div>
                            
                <div className="mb-4">
                    <Mutation
                        mutation={CREATE_DIAGNOSISDATA}
                        variables={{
                            diagnosisData:{
                                testId,testName,patientId,doctorId,doctorName,data:JSON.stringify(data)
                            }
                        }}
                        onCompleted={result=>{
                            this.onCancel();
                        }}
                    >
                        {mutation=>
                            <Button color="primary" onClick={mutation}>
                            Lưu thay đổi
                        </Button>}
                    </Mutation>
                    {' '}
                    <Button color="info" onClick={this.onCancel}>
                        Quay lại
                    </Button>
                </div>
                
            </Page>
        );
    };
}

export default withApollo(DiagnosisDetail);
