import logo200Image from 'assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Mutation } from 'react-apollo'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'

const SIGNUP_MUTATION = gql`
  mutation Mutation($username: String!, $password: String!, $domain: String!, $fullName: String!) {
    signup(username: $username, password: $password, domain: $domain,fullName:$fullName) {
      accessToken
      _id
      fullName
      companyId
      claims{
        type
        value
      }
      company{
        name
        setting{
          workTimes,
          labelDictionary{
            name
            value
          }
        }
      }
    }
  }
`
const LOGIN_MUTATION = gql`
  mutation Mutation($username: String!, $password: String!,$domain: String!) {
    login(username: $username, password: $password, domain:$domain) {
      accessToken
      _id
      fullName
      companyId
      claims{
        type
        value
      }
      company{
        name
        setting{
          workTimes,
          labelDictionary{
            name
            value
          }
        }
      }
    }
  }
`

class AuthForm extends React.Component {
  state={
    username:'',
    password:'',
    remember:false,
    fullName:''
  }
  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  handleSubmitResult = data => {
   console.log("login /sign up result:"+JSON.stringify(data));   
    if(this.isLogin){
      this.props.onLoginSuccess(data);
    }else
      this.props.onSignupSuccess(data);
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      fullNameInputProps,
      fullNameLabel,
      onLogoClick
    } = this.props;
    const { fullName, password, username,remember,passwordConfirm } = this.state
    
    return (
     
      <Form onSubmit={this.handleSubmitResult}>
        {showLogo && (
          <div className="text-center pb-4">
            <img
              src={logo200Image}
              className="rounded"
              style={{ width: 60, height: 60, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        {this.isSignup && (
          <FormGroup>
            <Label for={fullNameLabel}>{fullNameLabel}</Label>
            <Input {...fullNameInputProps} value={fullName} onChange={e=>this.setState({fullName:e.target.value})}/>
          </FormGroup>
        )}
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          <Input {...usernameInputProps} value={username} onChange={e=>this.setState({username:e.target.value})}/>
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel}>{passwordLabel}</Label>
          <Input {...passwordInputProps} value={password} onChange={e=>this.setState({password:e.target.value})}/>
        </FormGroup>
        {this.isSignup && (
          <FormGroup>
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
            <Input {...confirmPasswordInputProps} value={password} onChange={e=>this.setState({passwordConfirm:e.target.value})}/>
          </FormGroup>
        )}
        <FormGroup check>
          <Label check>
            <Input type="checkbox" value={remember} onChange={e=>this.setState({remember:e.target.checked})}/>{' '}
            {this.isSignup ? 'Agree the terms and policy' : 'Remember me'}
          </Label>
        </FormGroup>
        <hr />
        <Mutation
          mutation={this.isLogin?LOGIN_MUTATION:SIGNUP_MUTATION}
          variables={this.isLogin?{username,password,domain:"elsaga"}:{username,password,domain:"elsaga",fullName}}
          onCompleted={data=>this.handleSubmitResult(data)}
          >
          {
            mutation=>(
              <Button
                size="lg"
                className="bg-gradient-theme-left border-0"
                block
                onClick={mutation}>
                {this.renderButtonText()}
              </Button>
            )
          }
         
        </Mutation>
        

        <div className="text-center pt-1">
          <h6>or</h6>
          <h6>
            {this.isSignup ? (
              <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                Login
              </a>
            ) : (
              <a href="#signup" onClick={this.changeAuthState(STATE_SIGNUP)}>
                Signup
              </a>
            )}
          </h6>
        </div>

        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
  onLoginSuccess: PropTypes.func,
  onSignupSuccess: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'username',
  usernameInputProps: {
    type: 'text',
    placeholder: 'your username',
  },
  fullNameLabel:'fullname',
  fullNameInputProps:{
    type:'text',
    placeholder:'your fullname'
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
  onLoginSuccess:()=>{},
  onSignupSuccess:()=>{}
};

export default withApollo(AuthForm);
