import Page from "Admin/Components/Page";
import React from "react";
import { Button, Input, Col, Label, Form, FormGroup } from "reactstrap";
import { withApollo } from "react-apollo";
import authCheck from "utils/authCheck";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";

export const QUERY_DETAIL_COMPANY = gql`
  query AdminQuery($_id: String) {
    company(_id: $_id) {
      code
      message
      data{
        _id
        name
        active
        address
        fax
        tel
        description
        domain
        website
        createdTime
        logo
      }
    }
  }
`;
export const MUTATION_CREATE_COMPANY = gql`
  mutation AdminMutation($company: CompanyInput) {
    createCompany(company: $company) {
      data{_id}
      code
      message
    }
  }
`;
export const MUTATION_EDIT_COMPANY = gql`
  mutation AdminMutation($company: CompanyInput) {
    updateCompany(company: $company) {
      code
      message
      data{_id}
    }
  }
`;
export const MUTATION_DELETE_COMPANY = gql`
  mutation AdminMutation($_id: String) {
    deleteCompany(_id: $_id) {
      code
      message
      data{_id}
    }
  }
`;
class CompanyEditPage extends React.Component {
  constructor() {
    super();
    this.state = {
      _id: "",
      name: "",
      description: "",
      email: "",
      fax: "",
      taxId: "",
      domain: "",
      website: "",
      address: "",
      tel: "",
      file: {},
      logo: ""
    };
  }
  componentDidMount() {
    const _id = this.props.match.params._id;
    if (_id && _id.length > 0) {
      try {
        this.props.client
          .query({
            query: QUERY_DETAIL_COMPANY,
            variables: { _id: _id }
          })
          .then(result => {
            authCheck(result);
            if (result.data.company !== null) {
              //authCheck(result.data.company);
              this.setState(result.data.company.data);
            }
          });
      } catch (err) {
        authCheck(err);
      }
    }
  }
  createOrUpdate = mutation => {};
  onCancel = () => {
    this.props.history.push("/appointment");
  };
  render = () => {
    const {
      _id,
      name,
      website,
      logo,
      file,
      domain,
      description,
      email,
      tel,
      taxId,
      fax,
      address
    } = this.state;
    return (
      <Page
        title="Thông tin Công ty"
        breadcrumbs={[{ name: "Thông tin công ty", active: true }]}
        className="CompanyPage"
      >
        <Form className="m-4">
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Tên công ty
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={name}
                onChange={e => {
                  this.setState({ name: e.target.value });
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Tên miền
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={domain}
                onChange={e => this.setState({ domain: e.target.value })}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Logo:
            </Label>
            <Col md={6} sm={7}>
              <Input
                type="file"
                required
                onChange={({
                  target: {
                    validity,
                    files: [file]
                  }
                }) => {
                  this.setState({ file });
                }}
                bsSize="sm"
              />

              <Input
                className="mt-2"
                value={logo}
                bsSize="sm"
                disabled={true}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Website
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={website}
                onChange={e => this.setState({ website: e.target.value })}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Ghi chú
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={description}
                onChange={e => this.setState({ description: e.target.value })}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Ngày tạo
            </Label>
            <Col md={6} sm={7}>
              <Input disabled={true} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Số điện thoại
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={tel}
                onChange={e => this.setState({ tel: e.target.value })}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Fax
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={fax}
                onChange={e => this.setState({ fax: e.target.value })}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Mã số thuế
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={taxId}
                onChange={e => this.setState({ taxId: e.target.value })}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} className="text-right">
              Địa chỉ
            </Label>
            <Col md={6} sm={7}>
              <Input
                value={address}
                onChange={e => this.setState({ address: e.target.value })}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} sm={5} />
            <Col md={6} sm={7}>
              <Mutation
                mutation={
                  _id === undefined || _id.length === 0
                    ? MUTATION_CREATE_COMPANY
                    : MUTATION_EDIT_COMPANY
                }
                variables={{
                  company: {
                    _id,
                    name,
                    domain,
                    description,
                    email,
                    tel,
                    website,
                    file,
                    taxId,
                    fax,
                    address
                  }
                }}
                onCompleted={result => {
                  this.onCancel();
                }}
              >
                {mutation => (
                  <Button color="primary" onClick={mutation}>
                    Cập nhật thông tin
                  </Button>
                )}
              </Mutation>

              <Button color="link" onClick={this.onCancel}>
                Hủy
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </Page>
    );
  };
}
export default withApollo(CompanyEditPage);
