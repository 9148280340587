import Page from 'components/Page';
import React,{Fragment} from 'react';
import { Card, CardHeader, CardBody, Button, Table, ButtonGroup, FormGroup, Badge, Tooltip,Row } from 'reactstrap';
import { MdCallMade, MdCallReceived, MdCallEnd, MdInfo, MdCall, MdAddToQueue, MdAccountCircle } from 'react-icons/lib/md';
import gql from 'graphql-tag';
import { Subscription,Mutation } from 'react-apollo'
import Moment from 'moment';
import { GetLocalToken } from "companyConstants";
import {CALLOUT} from 'AdminCustomer/GqlQuery'
import confirm from 'components/Confirmation';

export const SubscribeAsterEvent = gql`
subscription Event($accessToken:String!){
    newInboundEvent(accessToken:$accessToken){
      callid
      atTime
      caller
      receiver
      atTime
      state
      customer{
        _id
        phoneNumber
        fullName
        birthDay
        address
        gender
        
      }
    }
  }
`
class CallEventPage extends React.Component {
    state = {
        queues: [],
        tooltipOpen: {}
    }

    render = () => {
        var accessToken = GetLocalToken().accessToken;
        const { tooltipOpen } = this.state;
        var queues = [];

        return (
            <Page
                title="Sự kiện gọi vào"
                breadcrumbs={[{ name: 'Sự kiện gọi vào', active: true }]}
                className="ExtensionPage">
                <Subscription
                    subscription={SubscribeAsterEvent}
                    variables={{ accessToken }}
                >
                    {
                        ({ data, loading }) => {
                            console.log("data=" + JSON.stringify(data));

                            if (data) {

                                queues = queues.filter(item => {
                                    return item.callid !== data.newInboundEvent.callid;
                                });
                                queues.push(data.newInboundEvent);
                                var activeCall = queues.filter(item => {
                                    return item.state === "Up"
                                }).shift();
                            }
                            return (

                                <div>
                                    {!activeCall ? "Chưa có cuộc gọi đang đàm thoại" :
                                        <Card>
                                            <CardHeader>
                                                {activeCall.state === "Ringing" ? <MdCallReceived /> : (activeCall.state === "Up" ? <MdCallMade /> : <MdCallEnd />)}  {activeCall.state === "Ringing" ? "Gọi đến" : activeCall.state === "Up" ? "Đang đàm thoại " : "Kết thúc "}
                                                vào lúc <Badge className="text-white">{Moment(activeCall.atTime).format("hh:mm")}</Badge>
                                                {Moment(activeCall.atTime).format("DD/MM/YYYY")}
                                            </CardHeader>
                                            <CardBody className="m-2">
                                                <div>
                                                    <p>Số điện thoại: {activeCall.caller}</p>
                                                    {
                                                        activeCall.customer === null ? "khách hàng này chưa có thông tin trên hệ thống" : (<h1>
                                                            <p>Tên: {activeCall.customer.fullName}</p>
                                                            <p>Địa chỉ: {activeCall.customer.address}</p>
                                                            <p>Năm sinh: {Moment(activeCall.customer.birthDay).format("YYYY")}</p>
                                                            <p>Giới tính: {activeCall.customer.gender}</p>
                                                        </h1>)
                                                    }
                                                </div>

                                                <FormGroup>
                                                    <Button name="primary" onClick={e => this.props.history.push("appointment-create/" + activeCall.caller + "/inbound/" + activeCall.callid)} className="text-right text-primary"> Đặt khám</Button>

                                                </FormGroup>
                                            </CardBody>
                                        </Card>
                                    }
                                    <Table responsive hover>
                                        <thead>
                                            <tr className="text-capitalize align-middle text-center">
                                                <th>TRẠNG THÁI</th>
                                                <th>SĐT</th>
                                                <th>TÊN</th>
                                                <th>VÀO LÚC</th>
                                                <th>TÁC VỤ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {queues.map((item, index) =>
                                                <tr key={index}>
                                                    <td className="align-middle text-center">
                                                        {item.state === "Ringing" ? <MdCallReceived /> : (item.state === "Up" ? <MdCallMade /> : <MdCallEnd />)}  {item.state === "Ringing" ? "Gọi đến" : item.state === "Up" ? "Đang đàm thoại " : "Kết thúc "}

                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {item.caller}
                                                    </td>
                                                    <td className="align-middle text-center">{item.customer ? item.customer.fullName : ""}</td>
                                                    <td className="align-middle text-center"><Badge className="text-white" style={{ fontSize: 14 }}>{Moment(item.atTime).format("hh:mm")}</Badge></td>
                                                    <td className="align-middle text-center">
                                                        <ButtonGroup style={{ fontSize: 18 }}>
                                                            <MdAddToQueue id={"appointment" + index} className="text-danger m-1" onClick={e => this.props.history.push("appointment-create/" + item.caller + "/inbound/" + item.callid)} />
                                                            <Tooltip placement="left" isOpen={tooltipOpen["appointment" + index]} autohide={false} target={"appointment" + index} toggle={e => { tooltipOpen["appointment" + index] = !tooltipOpen["appointment" + index]; this.setState({ tooltipOpen }) }}>
                                                                Sửa đặt khám
                                                        </Tooltip>
                                                            <Mutation
                                                                mutation={CALLOUT}
                                                                variables={{ phoneNumber: item.caller }}
                                                            >
                                                                {mutation => <Fragment>
                                                                    <MdCall onClick={e=>{                                                                    
                                                                        confirm("Cuộc gọi tới số "+item.caller+" đang được thực hiện, vui lòng chờ trong ít giây",{hideCancel:true})
                                                                        mutation();
                                                                    }} id={"call" + index} className="text-green m-1" />
                                                                    <Tooltip placement="left" isOpen={tooltipOpen["call" + index]} autohide={false} target={"call" + index} toggle={e => { tooltipOpen["call" + index] = !tooltipOpen["call" + index]; this.setState({ tooltipOpen }) }}>
                                                                        Gọi tới {item.caller}
                                                                    </Tooltip>
                                                                </Fragment>}
                                                            </Mutation>
                                                            <MdInfo id={"info" + index} className="text-warning m-1" />
                                                            <Tooltip placement="left" isOpen={tooltipOpen["info" + index]} autohide={false} target={"info" + index} toggle={e => { tooltipOpen["info" + index] = !tooltipOpen["info" + index]; this.setState({ tooltipOpen }) }}>
                                                                Chi tiết cuộc gọi
                                                        </Tooltip>
                                                            <MdAccountCircle onClick={e=>this.props.history.push("customer-edit/"+item.customer._id)} id={"user" + index} className="text-blue m-1" />
                                                            <Tooltip placement="left" isOpen={tooltipOpen["user" + index]} autohide={false} target={"user" + index} toggle={e => { tooltipOpen["user" + index] = !tooltipOpen["user" + index]; this.setState({ tooltipOpen }) }}>
                                                                Thông tin bệnh nhân
                                                        </Tooltip>
                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            )}


                                        </tbody>
                                    </Table>
                                </div>
                            )
                        }
                    }
                </Subscription>
            </Page>
        )
    }
}
export default CallEventPage;