import React, { Fragment } from "react";
import { withApollo } from "react-apollo";
import { DIAGNOSISDATA } from 'AdminCustomer/GqlQuery'
import authCheck from "utils/authCheck";
import {
    Button,
    Input,
    Row,
    Col,
    Label,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Badge,
    ButtonGroup,
    Tooltip
} from "reactstrap";

class DiagnosisPrint extends React.Component{

    state = {
        doctorId:"",
        doctorName:"",
        customer:{},
        patientId: "",
        testId: "",
        testName:"",
        data:{chong:{}},         
        tooltipOpen:{}
    };
    componentDidMount() {
        const _id = this.props.match.params._id;
        if (_id) {
            this.props.client
                .query({
                    query: DIAGNOSISDATA,
                    variables: { _id }
                })
                .then(result => {
                    authCheck(result);
                    let data =result.data.response.data;
                    data.data=JSON.parse(data.data);
                    this.setState(data);
                    
                });
        }
    }

    render=()=>{
        const {            
            patientId,
            testId,
            testName,
            doctorId,
            doctorName,
            customer,
            data,
            tooltipOpen
        } = this.state;
        return (
            <div>
                <h1>Hello</h1>
            </div>
        )
    }
}
export default withApollo(DiagnosisPrint);