import sidebarBgImage from "assets/img/sidebar/sidebar-4.jpg";
import React from "react";
import { GetLocalToken } from "adminConstants";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import {
  MdKeyboardArrowDown,
  MdExitToApp,
  MdVerifiedUser,
  MdSettings,
  MdAccountCircle,
  MdEmail,
  MdMultilineChart,
  MdWork,
  MdHome,
  MdSystemUpdate,
  MdCall,
  MdBusiness
} from "react-icons/lib/md";
import { NavLink } from "react-router-dom";
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  NavItem,
  NavLink as BSNavLink
} from "reactstrap";
import bn from "utils/bemnames";
import authCheck from "../../utils/authCheck";

const QUERY_SIDEMENU = gql`
  query AdminQuery {
    myMenus {
      data{
        name
        to
        icon
        children {
          name
          to
          icon
        }
      }
    }
  }
`;
const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat"
};
const IconTranslate = ({ icon, ...restProps }) => {
  console.log("icon=" + icon);
  switch (icon) {
    case "MdBusiness":
      return <MdBusiness className={bem.e("nav-item-icon")} />;
    case "MdCall":
      return <MdCall className={bem.e("nav-item-icon")}/>;
    case "MdMultilineChart":
      return <MdMultilineChart className={bem.e("nav-item-icon")} />;
    case "MdSystemUpdate":
      return <MdSystemUpdate className={bem.e("nav-item-icon")} />;
    case "MdKeyboardArrowDown":
      return <MdKeyboardArrowDown className={bem.e("nav-item-icon")} />;
    case "MdExitToApp":
      return <MdExitToApp className={bem.e("nav-item-icon")} />;
    case "MdVerifiedUser":
      return <MdVerifiedUser className={bem.e("nav-item-icon")} />;
    case "MdSettings":
      return <MdSettings className={bem.e("nav-item-icon")} />;
    case "MdAccountCircle":
      return <MdAccountCircle className={bem.e("nav-item-icon")} />;
    case "MdEmail":
      return <MdEmail className={bem.e("nav-item-icon")} />;
    case "MdWork":
      return <MdWork className={bem.e("nav-item-icon")} />;
    case "MdHome":
      return <MdHome className={bem.e("nav-item-icon")} />;
    default:
      return "";
  }
};



const bem = bn.create("sidebar");

class Sidebar extends React.Component {
  state = {
    isOpenComponents: false,
    isOpenContents: false,
    isOpenPages: false,
    isOpenCompanies: false,
    isOpenWorks: false,
    isOpenReports: false,
    isOpenSettings: false,
    isOpenAdmins: false,
    menu: []
  };
  token = {};
  constructor(props) {
    super();
    this.token = GetLocalToken();
  }
  componentDidMount() {
    this.props.client.query({ query: QUERY_SIDEMENU }).then(result => {
      authCheck(result.data.myMenus);
      this.setState({ menu: result.data.myMenus.data });
    });
  }
  toggleSideBar = () => {
    document.querySelector(".cr-sidebar").classList.toggle("cr-sidebar--open");
  };
  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e("background")} style={sidebarBackground} />
        <div className={bem.e("content")}>
          <Nav vertical>
            <NavItem className="profile-menu">
              <div style={{ height: 110 }} />
            </NavItem>

            {this.state.menu.map((item, index) => (
              <div key={index}>
                <NavItem className={bem.e("nav-item")}>
                  <BSNavLink
                    id={`navItem-${item.name}-${index}`}
                    className={bem.e("nav-item-collapse text-uppercase")}
                    tag={NavLink}
                    to={item.to}
                    activeClassName="active"
                    onClick={e => {
                      if (item.children === undefined) {
                        this.toggleSideBar();
                      } else {
                        item.isOpen = !item.isOpen;
                      }
                    }}
                    exact={false}
                  >
                    <div className="d-flex">
                      <IconTranslate icon={item.icon} />
                      <span className=" align-self-start">{item.name}</span>
                    </div>

                    {!item.children || item.children.length == 0 ? (
                      ""
                    ) : (
                      <MdKeyboardArrowDown
                        className={bem.e("nav-item-icon")}
                        style={{
                          padding: 0,
                          transform: item.isOpen
                            ? "rotate(0deg)"
                            : "rotate(-90deg)",
                          transitionDuration: "0.3s",
                          transitionProperty: "transform"
                        }}
                      />
                    )}
                  </BSNavLink>
                </NavItem>
                {!item.children ? (
                  ""
                ) : (
                  <Collapse isOpen={item.isOpen}>
                    {item.children.map(({ to, name, exact, Icon }, idx) => (
                      <NavItem key={idx} className={bem.e("nav-item")}>
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          className="nav-item-collapse sub-sidebar"
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          onClick={this.toggleSideBar}
                          exact={exact}
                        >
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    ))}
                  </Collapse>
                )}
              </div>
            ))}
          </Nav>
          <div style={{height:100}}></div>
        </div>
        
      </aside>
    );
  }
}

export default withApollo(Sidebar);
