import Page from "components/Page";
import React from "react";
import { Row, Col,Input } from 'reactstrap'
import Moment from "moment";
import { withApollo } from "react-apollo";
import { Mutation } from "react-apollo";
// import "styles/lims.css"
import { Button, ButtonGroup } from 'reactstrap';
import {  GET_CUSTOMER, GET_NSTMAU,CREATE_NSTMAU } from 'AdminCustomer/GqlQuery'
import authCheck from "utils/authCheck";
import ReactToPrint from 'react-to-print';
import PrintNSTMauNgoaiVi from './PrintNSTMauNgoaiVi';
import DatePicker from 'react-date-picker';
import {Helmet} from "react-helmet";
import DateTimePicker from 'react-datetime-picker';
import DateTimeInput from 'components/Widget/DateTimeInput'
import DateInput from 'components/Widget/DateInput'
class NstMauDetail extends React.Component{

    state={
        _id:null,
        ngay_lay_mau:new Date(),
        ngay_tra_kq:new Date(),
        nguoi_cay_mau:null,
        nguoi_lay_mau:null,
        nu:{},
        nam:{},
        printing:false
    }

    constructor(props) {
        super(props);
        this.nstMauNgoaiViRef = React.createRef();
    }
    componentDidMount() {
        var _id = this.props.match.params._id;
        if (_id) {
            this.props.client.query({
                query: GET_NSTMAU,
                variables: { _id }
            }).then(result => {
                if (result.data.response.code === 0) {
                    var result = result.data.response.data;
                    if(result.ngay_lay_mau==null)
                        result.ngay_lay_mau=new Date(1900,1,1);
                    if(result.ngay_tra_kq==null)
                        result.ngay_tra_kq=new Date(1900,1,1);
                    this.setState(result);
                }
            })
        }
    }
    onFetchCustomer = (user) => {
        
        if (user&&user.mabn) {
            this.props.client
                .query({
                    query: GET_CUSTOMER,
                    variables: { patientId: user.mabn }
                })
                .then(result => {
                    authCheck(result);
                    try {
                        // this.setState(
                        //     result.data.response.data
                        // );
                        var customer = result.data.customer.data;
                        
                        user.mabn= customer.patientId;
                        user.ho_va_ten= customer.fullName;
                        user.ngay_sinh= customer.birthDay;
                        user.dia_chi= customer.district!=null?(customer.district+", "+customer.province):customer.address;
                        user.so_dien_thoai= customer.phoneNumber;
                        this.setState({});
                    }
                    catch (er) { }
                });
        }
    };
    render(){
        const{
            _id,
            ngay_lay_mau,
            ngay_tra_kq,
            nguoi_cay_mau,
            nguoi_lay_mau,
            nu,
            printing,
            nam} = this.state;
        return(
            <div>
                <Row style={{ position: "fixed", bottom: 10 }}>
                    <Col>
                        <ButtonGroup style={{ marginLeft: 10 }}>
                            <Mutation
                                mutation={CREATE_NSTMAU}
                                variables={{
                                    nSTMau:{
                                        _id,
                                        ngay_lay_mau,
                                        ngay_tra_kq,
                                        nguoi_cay_mau,
                                        nguoi_lay_mau,
                                        nu,
                                        nam
                                    }
                                }}
                                onCompleted={e => this.props.history.push("/nstmau")}
                            >
                                 {mutation => <Button onClick={e => { mutation() }} color="success">Lưu</Button>}
                            </Mutation>
                            <ReactToPrint
                                trigger={() => <Button>In phiếu</Button>}
                                content={() => this.nstMauNgoaiViRef.current}
                                onBeforePrint={() => this.setState({ printing: true })}
                                onAfterPrint={() => this.setState({ printing: false })}
                            />
                            
                        </ButtonGroup>
                    </Col>
                </Row>
                <div style={{ display: "none" }}>
                    <PrintNSTMauNgoaiVi {...this.state} css={printing} objRef={this.nstMauNgoaiViRef} />
                </div>
                <div className="body">
                    <div className="content" id="content">
                        <div class="form_info">
                            <table cellpadding="0" cellspacing="0" >
                                <tr>
                                    <td>
                                        Thời gian lấy mẫu
                                    </td>
                                    <td>
                                        {/* <DateTimePicker
                                            onChange={date=>this.setState({ngay_lay_mau:date})}
                                            value={Moment(ngay_lay_mau).toDate()}
                                            format="HH:mm dd/MM/y"
                                            disableClock={true}
                                            calendarIcon={null}
                                            style={{width:"100%"}}
                                            /> */}
                                        <DateTimeInput
                                            onChange={date=>this.setState({ngay_lay_mau:date})}
                                            value={Moment(ngay_lay_mau).toDate()}
                                        />
                                    </td>
                                    <td>
                                        Ngày trả kết quả
                                    </td>
                                    <td>
                                        {/* <DateTimePicker
                                            onChange={date=>this.setState({ngay_tra_kq:date})}
                                            value={Moment(ngay_tra_kq).toDate()}
                                            format="HH:mm dd/MM/y"
                                            disableClock={true}
                                            calendarIcon={null}
                                            style={{width:"100%"}}
                                            /> */}
                                        <DateTimeInput
                                            onChange={date=>this.setState({ngay_tra_kq:date})}
                                            value={Moment(ngay_tra_kq).toDate()}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Người lấy mẫu
                                    </td>
                                    <td>
                                        <Input value={nguoi_lay_mau} onChange={e=>this.setState({nguoi_lay_mau:e.target.value})} className="text_Input"/>
                                    </td>
                                    <td>
                                        Người cấy mẫu
                                    </td>
                                    <td>
                                        <Input value={nguoi_cay_mau} onChange={e=>this.setState({nguoi_cay_mau:e.target.value})} className="text_Input"/>
                                    </td>
                                </tr>
                            </table>

                        </div>
                        <div class="form_info">
                            <p className="header">I. THÔNG TIN BN NỮ</p>
                            <table cellpadding="0" cellspacing="0" >
                                <tr>
                                    <td>
                                        Mã bệnh nhân
                                    </td>
                                    <td>
                                        <Input onKeyPress={e => {
                                                        if (e.charCode === 13) {
                                                            this.onFetchCustomer(nu);
                                                        }
                                                    }} value={nu.mabn} onChange={e=>{nu.mabn=e.target.value;this.setState({nu})}} className="text_Input"/>
                                    </td>
                                    <td>
                                        Tên bệnh nhân
                                    </td>
                                    <td>
                                        <Input value={nu.ho_va_ten} onChange={e=>{nu.ho_va_ten=e.target.value;this.setState({nu})}} className="text_Input"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Số điện thoại
                                    </td>
                                    <td>
                                        <Input value={nu.so_dien_thoai} onChange={e=>{nu.so_dien_thoai=e.target.value;this.setState({nu})}} className="text_Input"/>
                                    </td>
                                    <td>
                                        Năm sinh
                                    </td>
                                    <td>
                                        {/* <DatePicker
                                            value={Moment(nu.ngay_sinh)}
                                            onChange={e=>{nu.ngay_sinh=e;this.setState({nu})}}
                                            format="dd/MM/y"
                                            calendarIcon={null}                                   
                                        /> */}
                                        <DateInput
                                            value={Moment(nu.ngay_sinh)}
                                            onChange={e=>{nu.ngay_sinh=e;this.setState({nu})}}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Nghề nghiệp
                                    </td>
                                    <td>
                                        <Input value={nu.nghe_nghiep} onChange={e=>{nu.nghe_nghiep=e.target.value;this.setState({nu})}} className="text_Input"/>
                                    </td>
                                    <td>
                                        Địa chỉ
                                    </td>
                                    <td>
                                        <Input value={nu.dia_chi} onChange={e=>{nu.dia_chi=e.target.value;this.setState({nu})}} className="text_Input"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Lý do khám bệnh
                                    </td>
                                    <td colSpan="3">
                                        <Input value={nu.ly_do_kham_benh} onChange={e=>{nu.ly_do_kham_benh=e.target.value;this.setState({nu})}} className="text_Input"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Bác sỹ chỉ định
                                    </td>
                                    <td colSpan="3">
                                        <Input value={nu.bs_chi_dinh} onChange={e=>{nu.bs_chi_dinh=e.target.value;this.setState({nu})}} className="text_Input"/>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="form_info">
                            <p className="header">I. THÔNG TIN BN NAM</p>
                            <table cellpadding="0" cellspacing="0" >
                                <tr>
                                    <td>
                                        Mã bệnh nhân
                                    </td>
                                    <td>
                                        <Input onKeyPress={e => {
                                                        if (e.charCode === 13) {
                                                            this.onFetchCustomer(nam);
                                                        }
                                                    }} value={nam.mabn} onChange={e=>{nam.mabn=e.target.value;this.setState({nam})}} className="text_Input"/>
                                    </td>
                                    <td>
                                        Tên bệnh nhân
                                    </td>
                                    <td>
                                        <Input value={nam.ho_va_ten} onChange={e=>{nam.ho_va_ten=e.target.value;this.setState({nam})}} className="text_Input"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Số điện thoại
                                    </td>
                                    <td>
                                        <Input value={nam.so_dien_thoai} onChange={e=>{nam.so_dien_thoai=e.target.value;this.setState({nam})}} className="text_Input"/>
                                    </td>
                                    <td>
                                        Năm sinh
                                    </td>
                                    <td>
                                        {/* <DatePicker
                                            value={Moment(nam.ngay_sinh)}
                                            onChange={e=>{nam.ngay_sinh=e;this.setState({nam})}}
                                            format="dd/MM/y"
                                            calendarIcon={null}                                       
                                        /> */}
                                        <DateInput
                                            value={Moment(nam.ngay_sinh)}
                                            onChange={e=>{nam.ngay_sinh=e;this.setState({nam})}}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Nghề nghiệp
                                    </td>
                                    <td>
                                        <Input value={nam.nghe_nghiep} onChange={e=>{nam.nghe_nghiep=e.target.value;this.setState({nam})}} className="text_Input"/>
                                    </td>
                                    <td>
                                        Địa chỉ
                                    </td>
                                    <td>
                                        <Input value={nam.dia_chi} onChange={e=>{nam.dia_chi=e.target.value;this.setState({nam})}} className="text_Input"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Lý do khám bệnh
                                    </td>
                                    <td colSpan="3">
                                        <Input value={nam.ly_do_kham_benh} onChange={e=>{nam.ly_do_kham_benh=e.target.value;this.setState({nam})}} className="text_Input"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Bác sỹ chỉ định
                                    </td>
                                    <td colSpan="3">
                                        <Input value={nam.bs_chi_dinh} onChange={e=>{nam.bs_chi_dinh=e.target.value;this.setState({nam})}} className="text_Input"/>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <Helmet>
                    <style>
                        {`
                         .react-datetime-picker__inputGroup__input{
                            width:20px !important;
                        }
                        .react-date-picker__inputGroup__input{
                            width:20px !important;
                        }
                        .react-datetime-picker__inputGroup__year{
                            width:40px !important;
                        }
                        .react-date-picker__inputGroup__year{
                            width:40px !important;
                        }
                        tr {
                            padding: 1px 10px;
                            border: 1px solid #333333;
                        }
                        td {
                            padding: 3px 10px;
                            border: 1px solid #ededed;
                            text-align:left ;
                        }
                        td p {
                            text-align:left;
                            
                        }
                        p{
                            margin:3px 0 !important
                        }
                        
                        .bg_gray{ background: #F4F1F1;}
                        
                        .table_text tr,.table_text tr td{border: none !important;}
                        .text_input{border: 1px solid #ededed; padding: 0 12px; color: #333; text-transform: uppercase; font-weight: bold; width: 100%;}
                        .form_info{
                            width: calc(100% - 20px); padding-top: 10px;
                        }
                        .form_info .header{
                            width: 100%; font-size: 16px; font-weight: bold;
                        }
                        .form_info td{
                            min-width: 90px;
                        }
                        .form_info .table_text td{
                            vertical-align: top;min-width: 0;
                        }
                        
                        table tr td table tr td{border: none;} 
                        .form_info table{
                            border-top: 1px solid #ededed;  max-width: 100%; width: 100%; 
                        }
                        .form-check-label1{
                            margin-left: 10px;
                        }
                        .body{
                            background: #ededed;
                            font-size:18px !important;
                        }
                        .body table{
                            font-size: 18px !important;
                        }
                        .body h1, h2, h3, h4, h5, h6, p, a, td, button{
                             font-family: Gotham, Helvetica Neue, Helvetica, Arial,' sans-serif' !important;
                        }
                        
                        .body .content{
                             margin: auto;max-width: 1200px; background: #fff; display: flex; padding: 60px 60px 0 60px; flex-wrap: wrap; justify-content: space-between;
                        }
                        
                        .frm_check {
                            display: block;
                            position: relative;
                            padding-left: 35px;
                            margin-bottom: 12px;
                            cursor: pointer;
                            font-size: 16px;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                          }
                          
                          /* Hide the browser's default checkbox */
                          /* .frm_check input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                          } */
                          
                          /* Create a custom checkbox */
                          .checkmark {
                            display: inline-block;
                            height: 18px;
                            width: 18px;
                            background-color: #eee;
                            margin: -3px 5px;
                                border: 1px solid #333;
                          }
                          
                          /* On mouse-over, add a grey background color */
                          /* .frm_check:hover input ~ .checkmark {
                            background-color: #ccc;
                          } */
                          
                          /* When the checkbox is checked, add a blue background */
                          /* .frm_check .checked ~ .checkmark {
                            background-color: #2196F3;
                          } */
                          
                          /* Create the checkmark/indicator (hidden when not checked) */
                          .checkmark:after {
                            content: "";
                            margin:3px 4px;
                            /* position: absolute; */
                            display: none;
                            color:0;
                          }
                          
                          /* Show the checkmark when checked */
                          .frm_check .checked ~ .checkmark:after {
                            display: block;
                          }
                          
                          /* Style the checkmark/indicator */
                          .frm_check .checkmark:after {
                            left: 9px;
                            top: 5px;
                            width: 5px;
                            height: 10px;
                            border: solid grey;
                            border-width: 0 3px 3px 0;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                          }
                          .table-inline{
                                border-top: 0 !important;margin-left: -10px;
                                border:none !important;
                            }
                            .table-inline tr{
                                border:none !important;
                            }
                        
                        `}
                    </style>
                </Helmet>
            </div>
        )
    }
}
export default withApollo(NstMauDetail);