import Page from "components/Page";
import React from "react";
import { Row, Col, Input } from 'reactstrap'
import Moment from "moment";
import { withApollo } from "react-apollo";
import { Mutation } from "react-apollo";
import { Button, ButtonGroup } from 'reactstrap';
import { GET_CUSTOMER } from 'AdminCustomer/GqlQuery'
import authCheck from "utils/authCheck";
import ReactToPrint from 'react-to-print';
import PrintTraKQXN from './PrintTraKQXN';
// import DatePicker from 'react-datepicker';
import {Helmet} from "react-helmet";
import DateTimePicker from 'react-datetime-picker';
import DatePicker from 'react-date-picker';
import DateTimeInput from 'components/Widget/DateTimeInput'
import DateInput from 'components/Widget/DateInput'
class PhieuTraKQXN extends React.Component {

    state = {
        _id: null,
        ngay_lay_mau: new Date(),
        ngay_tra_kq: new Date(),
        ma_xet_nghiem: null,
        mabn: null,
        ho_va_ten: null,
        ngay_sinh: new Date(),
        ten_xet_nghiem: null,
        printing:false
    }

    constructor(props) {
        super(props);
        this.nstMauNgoaiViRef = React.createRef();
    }
    componentDidMount(){
        const mabn=this.props.match.params.mabn;
        if(mabn!=null){
            this.onFetchCustomer(mabn);
        }
    }

    onFetchCustomer = (mabn) => {

        if (mabn) {
            this.props.client
                .query({
                    query: GET_CUSTOMER,
                    variables: { patientId: mabn }
                })
                .then(result => {
                    authCheck(result);
                    try {
                        
                        var customer = result.data.customer.data;


                        this.setState({
                            mabn: customer.patientId,
                            ho_va_ten: customer.fullName,
                            ngay_sinh: customer.birthDay
                        });
                    }
                    catch (er) { }
                });
        }
    };
    render() {
        const {
            khoa_xet_nghiem,
            ngay_lay_mau,
            ngay_tra_kq,
            mabn, ho_va_ten, ma_xet_nghiem, ten_xet_nghiem,
            ngay_sinh,
            printing
        } = this.state;
        return (
            <div>
                <Row style={{ position: "fixed", bottom: 10 }}>
                    <Col>
                        <ButtonGroup style={{ marginLeft: 10 }}>
                            <ReactToPrint
                                trigger={() => <Button>In phiếu</Button>}
                                content={() => this.nstMauNgoaiViRef.current}
                                onBeforePrint={() => this.setState({ printing: true })}
                                onAfterPrint={() => this.setState({ printing: false })}
                            />
                            <Button color="default" onClick={e=>this.props.history.goBack()}>Quay lại</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <div style={{ display: "none" }}>
                    <PrintTraKQXN {...this.state} css={printing} objRef={this.nstMauNgoaiViRef} />
                </div>
                <div className="body">
                    <div className="content" id="content">
                        <div class="form_info">
                            <table cellpadding="0" cellspacing="0" >
                                <tbody>
                                <tr>
                                    <td>
                                        Thời gian lấy mẫu
                                    </td>
                                    <td>
                                        
                                        <DateTimeInput
                                            onChange={date=>this.setState({ngay_lay_mau:date})}
                                            value={Moment(ngay_lay_mau).toDate()}
                                        />
                                    </td>
                                    <td>
                                        Thời gian trả kết quả
                                    </td>
                                    <td>
                                        <DateTimeInput
                                            onChange={date=>this.setState({ngay_tra_kq:date})}
                                            value={Moment(ngay_tra_kq).toDate()}
                                        />
                                        
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="form_info">
                            <p className="header">I. THÔNG TIN BỆNH NHÂN</p>
                            <table cellpadding="0" cellspacing="0" >
                                <tbody>
                                <tr>
                                    <td>
                                        Mã bệnh nhân
                                    </td>
                                    <td>
                                        <Input onKeyPress={e => {
                                            if (e.charCode === 13) {
                                                this.onFetchCustomer(mabn);
                                            }
                                        }} value={mabn} onChange={e => { this.setState({ mabn: e.target.value }) }} className="text_Input" />
                                    </td>
                                    <td>
                                        Tên bệnh nhân
                                    </td>
                                    <td>
                                        <Input value={ho_va_ten} onChange={e => { this.setState({ ho_va_ten: e.target.value }) }} className="text_Input" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Năm sinh
                                    </td>
                                    <td>
                                        
                                        <DateInput
                                            onChange={date=>this.setState({ngay_sinh:date})}
                                            value={Moment(ngay_sinh).toDate()}
                                        />
                                    </td>
                                    <td>
                                        Mã xét nghiệm
                                    </td>
                                    <td>
                                        <Input value={ma_xet_nghiem} onChange={e => { this.setState({ ma_xet_nghiem: e.target.value }) }} className="text_Input" />
                                    </td>

                                </tr>
                                <tr>

                                    <td>
                                        Tên xét nghiệm
                                    </td>
                                    <td colSpan={3}>
                                        <Input type="select" value={ten_xet_nghiem} onChange={e => { this.setState({ ten_xet_nghiem: e.target.value }) }} className="text_Input">
                                            <option value="">
                                                Chọn tên xét nghiệm
                                            </option>
                                            <option>
                                                Double Test
                                            </option>
                                            <option>
                                                Tripple Test
                                            </option>
                                            <option>
                                                Sàng lọc TSG quý I
                                            </option>
                                            <option>
                                                Điện di HST
                                            </option>
                                            <option>
                                                XĐ Gen ĐB Thalassemia
                                            </option>
                                            <option>
                                                HIV Định lượng PCR
                                            </option>
                                            <option>
                                                NST từ máu ngoại vi
                                            </option>
                                        </Input>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        Địa điểm lấy kết quả
                                    </td>
                                    <td colSpan="3">
                                        <Input type="select" onChange={e => this.setState({ khoa_xet_nghiem: e.target.value })} value={khoa_xet_nghiem}>
                                            <option>
                                                Chọn 1 địa chỉ
                                                </option>
                                            <option>
                                                Sảnh tiếp đón TT SL,CĐTS & SS tầng 2 nhà B (đối diện phòng 223)
                                            </option>
                                            <option>
                                                Trung tâm SL,CĐTS & SS tầng 5 nhà A.
                                            </option>
                                            <option>
                                                KHOA HỖ TRỢ SINH SẢN
                                            </option>
                                            <option>
                                                KHOA KHÁM CHUYÊN SÂU SẢN PHỤ  KHOA VÀ SƠ SINH
                                            </option>
                                            <option>
                                                KHOA KHÁM PHỤ KHOA TỰ NGUYỆN
                                            </option>
                                            <option>
                                                KHOA KHÁM SẢN TỰ NGUYỆN
                                            </option>
                                            <option>
                                                KHOA KHÁM BỆNH
                                            </option>
                                            <option>
                                                KHOA SINH ĐẺ KẾ HOẠCH
                                            </option>


                                        </Input>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <Helmet>
                    <style>{`
                    .react-datetime-picker__inputGroup__input{
                        width:18px !important;
                    }
                    .react-date-picker__inputGroup__input{
                        width:18px !important;
                    }
                    .react-datetime-picker__inputGroup__year{
                        width:40px !important;
                    }
                    .react-date-picker__inputGroup__year{
                        width:40px !important;
                    }
                    tr {
                        padding: 1px 10px;
                        border: 1px solid #333333;
                    }
                    td {
                        padding: 3px 10px;
                        border: 1px solid #ededed;
                        text-align:left ;
                    }
                    td p {
                        text-align:left;
                        
                    }
                    p{
                        margin:3px 0 !important
                    }
                    
                    .bg_gray{ background: #F4F1F1;}
                    
                    .table_text tr,.table_text tr td{border: none !important;}
                    .text_input{border: 1px solid #ededed; padding: 0 12px; color: #333; text-transform: uppercase; font-weight: bold; width: 100%;}
                    .form_info{
                        width: calc(100% - 20px); padding-top: 10px;
                    }
                    .form_info .header{
                        width: 100%; font-size: 16px; font-weight: bold;
                    }
                    .form_info td{
                        min-width: 90px;
                    }
                    .form_info .table_text td{
                        vertical-align: top;min-width: 0;
                    }
                    
                    table tr td table tr td{border: none;} 
                    .form_info table{
                        border-top: 1px solid #ededed;  max-width: 100%; width: 100%; 
                    }
                    .form-check-label1{
                        margin-left: 10px;
                    }
                    .body{
                        background: #ededed;
                        font-size:15px !important;
                    }
                    .body table{
                        font-size: 17px !important;
                    }
                    .body h1, h2, h3, h4, h5, h6, p, a, td, button{
                         font-family: Gotham, Helvetica Neue, Helvetica, Arial,' sans-serif' !important;
                    }
                    
                    .body .content{
                         margin: auto;max-width: 1200px; background: #fff; display: flex; padding: 40px 60px; flex-wrap: wrap; justify-content: space-between;
                    }
                    
                    .frm_check {
                        display: block;
                        position: relative;
                        padding-left: 35px;
                        margin-bottom: 12px;
                        cursor: pointer;
                        font-size: 16px;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                      }
                      
                      /* Hide the browser's default checkbox */
                      /* .frm_check input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                      } */
                      
                      /* Create a custom checkbox */
                      .checkmark {
                        display: inline-block;
                        height: 18px;
                        width: 18px;
                        background-color: #eee;
                        margin: -3px 5px;
                            border: 1px solid #333;
                      }
                      
                      /* On mouse-over, add a grey background color */
                      /* .frm_check:hover input ~ .checkmark {
                        background-color: #ccc;
                      } */
                      
                      /* When the checkbox is checked, add a blue background */
                      /* .frm_check .checked ~ .checkmark {
                        background-color: #2196F3;
                      } */
                      
                      /* Create the checkmark/indicator (hidden when not checked) */
                      .checkmark:after {
                        content: "";
                        margin:3px 4px;
                        /* position: absolute; */
                        display: none;
                        color:0;
                      }
                      
                      /* Show the checkmark when checked */
                      .frm_check .checked ~ .checkmark:after {
                        display: block;
                      }
                      
                      /* Style the checkmark/indicator */
                      .frm_check .checkmark:after {
                        left: 9px;
                        top: 5px;
                        width: 5px;
                        height: 10px;
                        border: solid grey;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                      }
                      .table-inline{
                            border-top: 0 !important;margin-left: -10px;
                            border:none !important;
                        }
                        .table-inline tr{
                            border:none !important;
                        }
                    
                    `}</style>
                </Helmet>
            </div>
        )
    }
}
export default withApollo(PhieuTraKQXN);