import React from "react";
import { withApollo } from "react-apollo";
import gql from 'graphql-tag';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw, ContentState, Modifier, SelectionState, Entity, ContentBlock, genKey } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const UPLOAD_MUTATION= gql`
mutation Upload($folder:String,$file:FileUploadInputType){
  uploadImage(folder:$folder,file:$file)
}
`;
class AdvanceEditor extends React.Component {
    constructor() {
        super();
        this.state = {
            content: "",
            folder:"article",
            editorState: EditorState.createEmpty(),
        };

    }
    componentDidMount() {
        const { content ,folder} = this.props;
        if (content == null || content == '')
            return
        if(folder){
            this.state.folder=folder;
        }
        let { contentBlocks, entityMap } = htmlToDraft(content);
        let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        this.state.editorState = editorState;
        this.refs.editor.editor.update(editorState);
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.content == null)
            return;
        if (nextProps.content !== this.content) {
            let { contentBlocks, entityMap } = htmlToDraft(nextProps.content);
            let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            this.state.editorState = editorState;


            // this.refs.editor.editor.update(editorState);
            // this.setState({
            //     editorState,
            // })
        }
    }
    getEntities = (editorState, entityType = null) => {
        const content = editorState.getCurrentContent();
        const entities = [];
        content.getBlocksAsArray().forEach((block) => {
            let selectedEntity = null;
            block.findEntityRanges(
                (character) => {
                    if (character.getEntity() !== null) {
                        const entity = content.getEntity(character.getEntity());
                        if (!entityType || (entityType && entity.getType() === entityType)) {
                            selectedEntity = {
                                entityKey: character.getEntity(),
                                blockKey: block.getKey(),
                                entity: content.getEntity(character.getEntity()),
                            };
                            return true;
                        }
                    }
                    return false;
                },
                (start, end) => {
                    entities.push({ ...selectedEntity, start, end });
                });
        });
        return entities;
    }
    updateEntityValue(editorState) {
        const contentState = editorState.getCurrentContent();
        var newBlocks = [];
        var oriBlocks = contentState.getBlocksAsArray();
        oriBlocks.forEach((block) => {
            var characterList = block.characterList.filter((value, index, self) => {
                return value.entity != null;
            })
            if (characterList.length > 0 || newBlocks.length == 0) {
                // console.log("oldblock:"+JSON.stringify(block));
                var newBlock = new ContentBlock({ key: block.key, type: block.type, data: block.data, text: block.text, characterList, depth: block.depth });
                // newBlock.characterList=characterList;
                console.log("newBlock:", JSON.stringify(newBlock));

                newBlocks.push(newBlock);
            }
        })
        const newContentState = ContentState.createFromBlockArray(contentState.getBlockMap().toArray(), contentState.getEntityMap())
            .set('selectionBefore', contentState.getSelectionBefore())
            .set('selectionAfter', contentState.getSelectionAfter());
        // const newEditorState = EditorState.createWithContent(contentState);
        const newEditorState = EditorState.push(
            editorState,
            contentState,
            'insert-characters'
        )
        this.setState({ editorState: newEditorState });
    }

    addImageEntity = (editorState, selection) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'IMAGE',
            'MUTABLE',
            { "src": "https://icdn.dantri.com.vn/thumb_w/640/2019/06/23/vinamed-1561261352482.jpg", "alt": "Sai phạm cổ phần hoá Tổng công ty Thiết bị y tế Việt Nam được xử lý thế nào? - 1", "height": "", "width": "" }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        const contentStateWithImage = Modifier.applyEntity(
            contentStateWithEntity,
            selection,
            entityKey
        );
        const newEditorState = EditorState.push(editorState, contentStateWithImage);
        this.setState({ editorState: newEditorState });
    }

    validateState = (editorState) => {
        // console.log(editorState);
        // var contentJson=JSON.stringify(editorState.getCurrentContent().toJSON());
        // console.log(contentJson);
        const currentContent = editorState.getCurrentContent();
        // console.log("entityMap:",JSON.stringify(currentContent.getEntityMap()))
        var entities = this.getEntities(editorState);
        var selection = editorState.getSelection();
        var newContentState = currentContent;
        var entityMap = currentContent.getEntityMap();

        if (entities.length > 0) {
            // console.log(entities);
            entities.forEach(function ({ blockKey, start, end, entityKey, entity }) {
                console.log(`entityKey=${entityKey},blockey=${blockKey},start=${start},end=${end}, type=${entity.getType()},data=${JSON.stringify(entity.getData())},mutability=${entity.getMutability()}`);
                var newConntent = currentContent.addEntity(entity);
                console.log("ContentStateChangeTo", JSON.stringify(newConntent.toJSON()))
            })
        }
        const newEditorState = EditorState.push(editorState, currentContent, 'replace-text');
        this.setState({ editorState: newEditorState });
    }

    onEditorStateChange = (editorState) => {
        const currentContent = editorState.getCurrentContent();
        const editorSourceHTML = draftToHtml(convertToRaw(currentContent));
        if (this.props.onChange) {
            this.props.onChange(editorSourceHTML);
        }
        this.setState({
            editorState
        });
    };

    uploadCallback = (file) => {
        return new Promise(
            (resolve, reject) => {

                this.props.client.mutate({
                    mutation: UPLOAD_MUTATION,
                    variables: { folder: this.state.folder, file }//truong hợp này chỉ dùng 1 dấu nháy vì variables chỉ là 1 object ko phải thuộc tính
                }).then(result => {

                    resolve({ data: { link: result.data.uploadImage } })
                });
            }
        );
    }
    handlePasteText = (text, html, editorState) => {
        var shouldReplaceAll = false;
        
        var pattern = /<figure/g;
        if (html&&pattern.test(html)) {
            shouldReplaceAll=true;            
            html = html.replace(/<figure/g, '<div').replace(/figure>/g, 'div>');
            const { contentBlocks, entityMap } = htmlToDraft(html);
            const newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
            this.setState({
                editorState: newEditorState
            });
        } else if (text && pattern.test(text)) {
            shouldReplaceAll=true;
            html = text.replace(/<figure/g, '<div').replace(/figure>/g, 'div>');
            const { contentBlocks, entityMap } = htmlToDraft(html);
            const newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
            this.setState({
                editorState: newEditorState
            });

        }
        if (this.props.onChange&&shouldReplaceAll) {
            this.props.onChange(html);
        }
        
        return shouldReplaceAll?"handled":false;
    }
    render() {
        const { editorState } = this.state;
        return (
            <div >
                <Editor
                    ref="editor"
                    editorState={editorState}
                    // onChange={this.onChange}
                    // onContentStateChange={this.onContentStateChange}
                    onEditorStateChange={this.onEditorStateChange}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    stripPastedStyles={false}
                    handlePastedText={this.handlePasteText}
                    wrapperStyle={{ borderStyle: "solid", padding: 10, borderWidth: 1, borderColor: "#ccc", minHeight: 300 }}
                    toolbar={{
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        // history: { inDropdown: true },
                        image: {
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            urlEnabled: true,
                            uploadEnabled: true,
                            alignmentEnabled: true,

                            uploadCallback: this.uploadCallback,
                            previewImage: true,
                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                            alt: { present: false, mandatory: false },
                            defaultSize: {
                                height: 'auto',
                                width: 'auto',
                            },
                        },
                    }}
                />
            </div>
        )
    }
}

export default withApollo(AdvanceEditor);