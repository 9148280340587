import React from "react";
import { Button, Form, FormGroup, Input, Label, Row, Col, Card } from "reactstrap";
import logo200Image from "assets/img/logo/logo.png";
import logoTelehub from "assets/img/logo/logo-pos.png";
import { AUTH_TOKEN } from 'companyConstants';
import { MdAccountCircle } from 'react-icons/lib/md';
import { FaUser, FaKey } from 'react-icons/lib/fa';
import { Mutation, withApollo } from "react-apollo";
import gql from "graphql-tag";
import confirm from 'components/Confirmation'
const LOGIN_MUTATION = gql`
mutation Mutation($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      code
      message
      data{
        accessToken
        _id
        fullName
        setting{
          companyName
          labelDictionary{
            name
            value
          }
          logo
          website
          workTimes
        }
        claims{
          type
          value
        }
        account{
          name
          userName
          menus{
            path
          }
          info{
            phoneNumber
            fullName
            email
            image          
          }
        }
        
      }
    }
  }
`;


class LoginPage extends React.Component {
  state = {
    username: "",
    password: "",
    remember: true
  };


  handleSubmitResult = (data) => {
      var token = data.login.data;
      localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
      
      this.props.history.push("/");
    
  }
  onLogoClick = () => { };

  render() {
    const { username, password, remember } = this.state;
    return (
      <Row
        style={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Col md={6} lg={4}>
          <Mutation
            mutation={LOGIN_MUTATION}
            variables={{ username, password }}
            onCompleted={data => this.handleSubmitResult(data)}
            onError={data=>{confirm("Tên đăng nhập hoặc mật khẩu chưa chính xác.",{title:"Lỗi đăng nhập"});}}
          >
            {mutation => (
              <Card body style={{boxShadow:"0 1px 11px rgba(0, 0, 0, 0.27)",borderRadius:2}}>
                <Form className="m-4" onSubmit={this.handleSubmitResult}>
                  <div className="text-center">
                    
                    <img
                      src={logo200Image}
                      className="rounded"
                      style={{ width: 65, height: 55, cursor: "pointer" }}
                      alt="logo"
                      onClick={this.onLogoClick}
                    />
                  </div>
                  <div className="m-5">

                  </div>
                  <FormGroup row>
                    <Label md={1} className="text-right" style={{ fontSize: 18, marginBottom: 10 }}><FaUser /></Label>
                    <Col md={11}>
                      <Input
                        type="text"
                        placeholder="username"
                        value={username}
                        onChange={e => this.setState({ username: e.target.value })}
                        onKeyPress={e => {
                          if (e.charCode === 13) mutation();
                        }}
                      />
                    </Col>

                  </FormGroup>
                  <FormGroup row>
                    <Label md={1} className="text-right" style={{ fontSize: 18, marginBottom: 10 }}><FaKey /></Label>
                    <Col md={11}>
                      <Input
                        type="password"
                        placeholder="Mật khẩu"
                        value={password}
                        onChange={e => this.setState({ password: e.target.value })}
                        onKeyPress={e => {
                          if (e.charCode === 13) mutation();
                        }}
                      />
                    </Col>

                  </FormGroup>



                  <div style={{ textAlign: "center" }}>
                    <Button
                      size="lg"
                      color="green"
                      className="border-0"

                      onClick={mutation}
                    >
                      {" "}
                      Đăng nhập
                    </Button>
                  </div>
                </Form>
              </Card>
            )}
          </Mutation>
        </Col>
      </Row>
    );
  }
}
export default withApollo(LoginPage);
