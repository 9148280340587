import Page from 'components/Page';
import React from 'react';
import { Button,ButtonGroup,Card,Input,Label,CardHeader,CardBody} from 'reactstrap';
import { withApollo } from 'react-apollo'

import gql from 'graphql-tag';
import {MdCall,MdEdit,MdAddCircle} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import Moment from 'moment'

export const FEED_QUERY=gql`
query Account($page:Int,$pageSize:Int,$sorted:[SortedInput],$filtered:[FilteredInput]){
    accounts(page:$page,pageSize:$pageSize,sorted:$sorted,filtered:$filtered){
      page
      pages
      data{
        _id
        extension
        userName
        info{
            createdTime
            email
            fullName
            phoneNumber
        }
        isAgent
        claims{
          type
          value
        }        
        leaders{
          name
        }        
        name                
      }
    }
  }
    
`

class AccountPage extends React.Component{

    state={
        data: [],
        pages: 1,
        loading: true
    }
    constructor(){
        super();
        this.fetchData = this.fetchData.bind(this);
    }
    fetchData= async(state, instance)=>{
        
        this.setState({ loading: true });
        const {filtered,pageSize,sorted,page} = state;
        try{
            const result = await this.props.client.query({
                query: FEED_QUERY,
                variables: { filtered,pageSize,sorted,page },                
            })
            authCheck(result);
            this.setState({data:result.data.accounts.data,pages:result.data.accounts.pages,loading:false});
        }catch(err){
           authCheck(err);
        }
    }
    render=()=>{
        const {data,loading,pages}=this.state;
        return(
            <Page title="Quản lý tài khoản"
                breadcrumbs={[{ name: 'Quản lý khách hàng', active: true }]}            
                className="CustomerPage">   
                <Card>
                    <CardHeader className="bg-green">                        
                        <Button  color="primary" onClick={e=>{this.props.history.push("/account-edit")}}><MdAddCircle/> Thêm tài khoản</Button>
                    </CardHeader>
                <CardBody>
                    <ReactTable 
                        noDataText="Không có dữ liệu"
                        columns={[ 
                            {
                                Header:"Họ và Tên",
                                accessor:"name"
                            },   
                            {
                                Header:"Tên đăng nhập",
                                accessor:"userName"
                            },                           
                            {
                                Header:"Số điện thoại",
                                accessor:"info.phoneNumber"
                            },                            
                            {
                                Header:"Thời gian tạo",
                                accessor:"info.createdTime",
                                Filter:({filter,onChange})=>
                                    <Input bsSize="sm" type="date" placeholder="Chọn ngày" onChange={e=>onChange(e.target.value)}/>,
                                Cell:row=>(<Label>{Moment(row.value).format('DD/MM/YYYY')}</Label>)
                            },
                            {
                                Header:"email",
                                accessor:"info.email"

                            },
                            {
                                Header:"Đầu số VOIP",
                                accessor:"extension"
                            },                           
                            {
                                Header:"Tác vụ",                      
                                maxWidth:100,
                                        filterable:false,
                                        sortable:false,
                                        Cell: row=>(
                                            <ButtonGroup>
                                                <Button size="sm" onClick={()=>this.props.history.push("/account-edit/"+row.original._id)}><MdEdit/></Button>
                                                {" "}
                                                <Button size="sm"><MdCall/></Button>
                                            </ButtonGroup>
                                        )
                            }
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={10}
                        className="-striped -highlight"
                    >

                    </ReactTable>
                    </CardBody>
                </Card>

            </Page>
        )
    }
}

export default withApollo(AccountPage);