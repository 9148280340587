import Page from "components/Page";
import React, { Fragment } from "react";
import ToggleButton from 'react-toggle-button'
import {
  Button,
  Input,
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Badge,
  ButtonGroup
} from "reactstrap";
import { withApollo } from "react-apollo";
import {
  MdCheckBoxOutlineBlank,
  MdCheckCircle,
  MdClose
} from "react-icons/lib/md";
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import authCheck from "utils/authCheck";
import AppointmentTime from "components/AppointmentTime";
import Moment from "moment";
import ReactTable from "react-table";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {FETCH_LIST_APPOINTMENT_HISTORY,FETCH_DEPARTMENT,FETCH_WORKING_TIME,GET_CUSTOMER,CREATE_APPOINTMENT} from 'AdminCustomer/GqlQuery'


class AppointmentCreatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      address: "",
      callid:"",
      type:"",
      fullName: "",
      birthDay: new Date(1990, 1, 1),      
      description: "",
      phoneNumber: "",
      reason: "",
      mariage:false,
      chanel:"crm",
      work:"",
      gender:"male",
      departmentId: "",
      appointmentDate: new Date(),
      appointmentTime: "",
      workingTimes: [],
      birthDay: new Date(),
      rePhoneNumber:"",
      relationShip:""
    };
  }
  componentDidMount() {
    const phoneNumber = this.props.match.params.phoneNumber;
    const callid =this.props.match.params.callid;
    const type =this.props.match.params.type;
    console.log("type="+type);
    var params = {callid,chanel:"crm",phoneNumber};
    if(type==="inbound"){      
      params.chanel="call";
    }else if(type==="outbound"){      
      params.chanel="call";}
    if (phoneNumber && phoneNumber.length > 0) {
      this.setState({...params})
      this.props.client
        .query({
          query: GET_CUSTOMER,
          variables: { phoneNumber: phoneNumber}
        })
        .then(result => {
          authCheck(result);
          if (result.data.customer !== null) {
            this.setState({CustomerId:result.data.customer.data._id, ...result.data.customer.data,...params } );
          }
        });
    }
  }
  onCancel = () => {
    this.props.history.push("/");
  };
  onFetchCustomer = () => {
    const { phoneNumber } = this.state;
    if (phoneNumber) {
      this.props.client
        .query({
          query: GET_CUSTOMER,
          variables: { phoneNumber }
        })
        .then(result => {
          authCheck(result);
          try{
              this.setState(
                result.data.customer.data
              );
              this.fetchAppointmentData(result.data.customer._id, 0, 10);
            }
          catch(er){}
        });
    }
  };
  onDepartmentSelected=(e)=>{
    this.setState({departmentId:e.target.value,appointmentDate:new Date()});
    if(e.target.value!==''){
        this.props.client.query({
            query:FETCH_WORKING_TIME,
            variables:{_id:e.target.value,date:Moment(this.state.appointmentDate).format("DD/MM/YYYY")}
        }).then(result=>{
            this.setState({workingTimes:result.data.department.data.workingTimes})
        });
    }
  }
  fetchAppointmentData = async (
    phoneNumber,
    page,
    pageSize,
    _filtered,
    sorted
  ) => {
    var filtered = [{ id: "phoneNumber", value: phoneNumber }];
    if (_filtered && _filtered !== null) filtered = filtered.concat(_filtered);
    try {
      const result = await this.props.client.query({
        query: FETCH_LIST_APPOINTMENT_HISTORY,
        variables: { filtered, pageSize, sorted: sorted, page }
      });

      this.setState({
        data: result.data.appointments.data,
        pages: result.data.appointments.pages,
        loading: false
      });
    } catch (err) {
      console.log(err);
      authCheck(err);
      this.setState({ data: [], loading: false });
    }
  };

  onDateChange = e => {
    this.setState({ appointmentDate: e.target.value });
    this.props.client
      .query({
        query: FETCH_WORKING_TIME,
        variables: {
          _id: this.state.departmentId,
          date: Moment(e.target.value).format("DD/MM/YYYY")
        }
      })
      .then(result => {        
        this.setState({ workingTimes: result.data.department.data.workingTimes });
      });
  };
  render = () => {
    const {
      customer,
      description,
      phoneNumber,
      mariage,
      appointmentTime,
      appointmentDate,
      departmentId,
      address,
      fullName,
      chanel,
      birthDay,
      gender,
      work,
      rePhoneNumber,
      reason,
      callid,
      relationShip
    } = this.state;
    let waiting=0,complete=0,cancel=0;
    return (
      <Page
        title="Tạo khách hàng mới & Đặt lịch khám"
        breadcrumbs={[{ name: "Đặt lịch khám", active: true }]}
        className="AppointmentPage"
      >
        <Tabs>
          <TabList>
              <Tab>Thông tin đặt khám</Tab>              
              <Tab>Thông tin bổ xung</Tab>
          </TabList>
          <TabPanel className="mt-1">
          <div className="m-4">
              
              <Row>
                <Col md="3" sm="6" xs="12">
                  <FormGroup>
                    <Label>Số điện thoại</Label>
                    <Input
                      bsSize="sm"
                      value={phoneNumber}
                      onKeyPress={e => {
                        if (e.charCode === 13) this.onFetchCustomer();
                      }}
                      onChange={e => {
                        this.setState({ phoneNumber: e.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3" sm="6" xs="12">
                  <FormGroup>
                    <Label>Họ tên</Label>
                    <Input
                      bsSize="sm"
                      value={fullName}
                      onChange={e => {
                        this.setState({fullName:e.target.value});
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="3" sm="6" xs="12">
                  <FormGroup>
                    <Label>Ngày sinh</Label>

                    <Input
                      type="date"
                      bsSize="sm"
                      value={Moment(birthDay).format("YYYY-MM-DD")}
                      onChange={e => {
                        this.setState({birthDay:e.target.value});
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3" sm="6" xs="12">
                  <FormGroup>
                    <Label>Địa chỉ</Label>
                    <Input
                      bsSize="sm"
                      value={address ? address : ""}
                      onChange={e => {
                        this.setState({address:e.target.value});
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Label>
                <h3>Thông tin đặt khám</h3>
              </Label>
              <FormGroup>
                <Label> Lý do đặt khám</Label>
                <Input
                  bsSize="sm"
                  value={this.state.reason}
                  onChange={e => {
                    this.setState({ reason: e.target.value });
                  }}
                />
              </FormGroup>
              <Row>
                <Col md="4" xs="6" sm="6">
                  <FormGroup>
                    <Label>Chọn khoa/phòng ban</Label>
                    <Input
                      className="mb-2"
                      type="select"
                      bsSize="sm"
                      value={departmentId}
                      onChange={this.onDepartmentSelected}
                    >
                      <Query query={FETCH_DEPARTMENT}>
                        {({ loading, error, data }) => {
                          var departments=[];
                          authCheck(data);
                          try{
                            departments=data.departments.data;
                          }catch(err){}
                         
                          return (
                            <Fragment>
                              <option>Chọn phòng/ban</option>
                              {departments.map((department, index) => (
                                <option key={index} value={department._id}>
                                  {department.name}
                                </option>
                              ))}
                            </Fragment>
                          );
                        }}
                      </Query>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4" xs="6" sm="6">
                  <FormGroup>
                    <Label>Ngày hẹn</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={Moment(appointmentDate).format("YYYY-MM-DD")}
                      onChange={this.onDateChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label>Chọn khung giờ khám</Label>
                <div>
                  {this.state.workingTimes.map((workTime, index) => (
                    <AppointmentTime
                      key={index}
                      name="workTime"
                      title={workTime.timeRange}
                      process={workTime.remainProcess}
                      onChange={e => {
                        this.setState({ appointmentTime: workTime.timeRange });
                      }}
                    />
                  ))}
                </div>
              </FormGroup>
              <FormGroup>
                <Label> Ghi chú</Label>
                <Input
                  bsSize="sm"
                  value={this.state.description}
                  onChange={e => {
                    this.setState({ description: e.target.value });
                  }}
                >
                  
                </Input>
              </FormGroup>
              
            </div>
          </TabPanel>
          <TabPanel className="mt-1">
             <div className="m-4">
                <Row>
                  <Col md={12}>
                    <FormGroup row>
                      <Label md={3} className="text-right">Giới tính:</Label>
                      <Col md={9}>
                        <Input type="select" onChange={e=>this.setState({gender:e.target.value})} bsSize="sm" value="gender">
                          <option value="other">Chưa xác định</option>
                          <option value="male">Nam</option>
                          <option value="female">Nữ</option>
                        </Input>
                      </Col>                      
                    </FormGroup>
                    <FormGroup row>
                      <Label md={3} className="text-right">Người bệnh là:</Label>
                      <Col md={9}>
                        <Input type="select" onChange={e=>this.setState({relationShip:e.target.value})} bsSize="sm" value="relationShip">
                          <option value="">người đặt khám</option>
                          <option value="con">Con của người đặt khám</option>
                          <option value="bố">Bố của người đặt khám</option>
                          <option value="mẹ">Mẹ của người đặt khám</option>
                          <option value="ông">Ông của người đặt khám</option>
                          <option value="bà">Bà của người đặt khám</option>
                          <option value="anh">Anh của người đặt khám</option>
                          <option value="chị">Chị của người đặt khám</option>
                          <option value="bạn bè">Bạn bè của người đặt khám</option>
                        </Input>
                      </Col>                      
                    </FormGroup>
                    <FormGroup row>
                      <Label md={3} className="text-right">SĐT người đặt hộ:</Label>
                      <Col md={9}>
                         <Input value={rePhoneNumber} type="text" bsSize="sm"/>
                      </Col>                      
                    </FormGroup>
                    <FormGroup row>
                      <Label md={3} className="text-right">Đã kết hôn:</Label>
                      <Col md={9}>
                        <ToggleButton
                            value={ mariage }
                            onToggle={(value) => {
                                this.setState({mariage:!value});
                            }} />
                      </Col>                      
                    </FormGroup>
                    <FormGroup row>
                      <Label md={3} className="text-right">Công việc:</Label>
                      <Col md={9}>
                         <Input value={work} type="text" bsSize="sm" onChange={e=>this.setState({work:e.target.value})}/>
                      </Col>                      
                    </FormGroup>
                    
                    </Col>
                </Row>
             </div>
          </TabPanel>
        </Tabs>
        <div className="m-4">
              <Mutation
                mutation={CREATE_APPOINTMENT}
                variables={{
                  appointment: {
                    phoneNumber,
                    address,
                    fullName,
                    gender,
                    birthDay,
                    description,
                    work,
                    appointmentTime,
                    appointmentDate,
                    departmentId,
                    reason,
                    chanel,
                    rePhoneNumber,
                    relationShip
                  },
                  callid
                }}
                onCompleted={result => {
                  if(authCheck(result.data))
                    this.onCancel();
                }}
              >
                {mutation => (
                  <Button onClick={mutation} color="warning">
                    Xác nhận đặt khám
                  </Button>
                )}
              </Mutation>{" "}
              <Button color="info" onClick={this.onCancel}>
                Quay lại
              </Button>
            </div>
        <Query
          query={FETCH_LIST_APPOINTMENT_HISTORY}
          variables={{
            pageSize: 0,
            filtered: [{ id: "phoneNumber", value: phoneNumber }],
            sorted: [{ id: "appointmentDate", desc: true }]
          }}
        >
          {({ loading, error, data }) => {
            if (error || !data.appointments) {
              authCheck(error);
              //return <div>Có lỗi load dữ liệu</div>
              data = { appointments: { data: [] } };
            }
            waiting=data.appointments.data.filter((item)=>{
                return item.state==="waiting";
            }).length;
            
            cancel=data.appointments.data.filter((item)=>{
                return item.state==="cancel";
            }).length
            complete=data.appointments.data.filter((item)=>{
                return item.state==="complete";
            }).length
            return (
              <Card>
                <CardHeader className="bg-green">
                  LỊCH SỬ ĐẶT HẸN ({data.appointments.data.length} LƯỢT)
                  <ButtonGroup style={{ float: "right" }}>
                    <Button color="secondary">{waiting} chờ khám</Button>
                    <Button color="primary">{complete} đã khám</Button>
                    <Button color="warning">{cancel} không khám</Button>
                  </ButtonGroup>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    defaultSorted={[
                      {
                        id: "appointmentDate",
                        desc: true
                      }
                    ]}
                    data={data.appointments.data}
                    filterable
                    columns={[
                      {
                        Header: "Bệnh nhân",
                        accessor: "fullName",

                        maxWidth: 150
                      },                     
                      {
                        Header: "NGÀY GIỜ KHÁM",
                        width: 140,
                        filterable: false,
                        Cell: row => (
                          <div>
                            {Moment(row.original.appointmentDate).format(
                              "DD/MM/YYYY"
                            )}
                            {"  "}
                            <Badge className="text-white">
                              {row.original.appointmentTime}
                            </Badge>
                          </div>
                        )
                      },
                      {
                        Header: "Mô tả",
                        accessor: "description"
                      },
                      {
                        Header: "Kênh",
                        accessor: "chanel",
                        maxWidth: 120,
                        style: { textAlign: "center" },
                        Filter: ({ filter, onChange }) => (
                          <Input
                            type="select"
                            onChange={e => onChange(e.target.value)}
                            bsSize="sm"
                            value={filter ? filter.value : ""}
                          >
                            <option value="">Tất cả</option>
                            <option value="crm">CRM</option>
                            <option value="web">Web</option>
                            <option value="app">Mobile App</option>
                          </Input>
                        )
                      },

                      {
                        Header: "Khoa khám",
                        accessor: "department.name"
                      },
                      {
                        Header: "Trạng thái",
                        accessor: "state",
                        maxWidth: 80,
                        style: { textAlign: "center" },
                        Cell: row => (
                          <div style={{ fontSize: 18 }}>
                            {row.value === "waiting" ? (
                              <MdCheckBoxOutlineBlank className="text-green" />
                            ) : row.value === "complete" ? (
                              <MdCheckCircle className="text-green" />
                            ) : (
                              <MdClose className="text-danger" />
                            )}
                          </div>
                        ),
                        Filter: ({ filter, onChange }) => (
                          <Input
                            type="select"
                            onChange={e => onChange(e.target.value)}
                            bsSize="sm"
                            value={filter ? filter.value : ""}
                          >
                            <option value="">Tất cả</option>
                            <option value="complete">Hoàn thành</option>
                            <option value="waiting">Đang chờ</option>
                            <option value="cancel">Đã hủy</option>
                          </Input>
                        )
                      }
                    ]}
                    defaultPageSize={5}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            );
          }}
        </Query>
      </Page>
    );
  };
}

export default withApollo(AppointmentCreatePage);
