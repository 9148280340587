import Page from "Admin/Components/Page";
import React from "react";
import { Button, Input, Row, Col, Label, Form, FormGroup } from "reactstrap";
import { withApollo, Mutation,Query } from "react-apollo";
import gql from "graphql-tag";
import authCheck from "utils/authCheck";
import CheckboxTree from 'react-checkbox-tree';

export const QUERY_DETAIL = gql`
  query AdminQuery($_id: String) {
    account(_id: $_id) {
      code
      message
      data{
        _id
        name
        userName
        email
        phoneNumber
        menuIds
        claims {
          type
          value
        }
      }
    }
  }
`;
export const QUERY_PERMISSIONS=gql`
{
  permissions{
    code
    message
    data{
      name
      value
    }
  }
}
`;
export const MUTATION_CREATE = gql`
  mutation AdminMutation($account: AdminAccountInputType!) {
    createAccount(account: $account) {
      code
      message
      data{_id}
    }
  }
`;
export const QUERY_MENUTREES=gql`
  query AdminQuery{
    menutrees{   
      code
      message
      data{   
        label:name
        value:_id
        children
        {
          label:name
          value:_id
        }
      }
    }
          
  }
`;
export const MUTATION_EDIT = gql`
  mutation AdminMutation($account: AdminAccountInputType!) {
    updateAccount(account: $account) {
      code
      message
      data{ _id}
    }
  }
`;

class AccountEditPage extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      fullName: "",
      email: "",
      phoneNumber: "",
      description: "",
      claims: { root: false},
      password: "",
      menuIds:[],
      nodes:[],      
      expanded: [],
      clicked:{}
    };
  }
  componentDidMount() {
    try {
      const _id = this.props.match.params._id;
      if (_id && _id.length > 0) {
        this.props.client
          .query({
            query: QUERY_DETAIL,
            variables: { _id: _id }
          })
          .then(result => {
            authCheck(result);
            var account =result.data.account.data;
            if (account !== null) {
              var claims = {};
              account.claims.forEach(function(item, i) {
                if (item.type === "permission") claims[`${item.value}`] = true;
              });
              account.claims = claims;
              this.setState(account);
            }
          });
      }
    } catch (err) {
      authCheck(err);
    }
    try{
      this.props.client.query({
        query:QUERY_MENUTREES
      }).then(result=>{
        authCheck(result);
        var menutrees= result.data.menutrees.data;
        if(menutrees!==null){
          this.setState({nodes:menutrees});
        }
      })
    }catch(err){
      authCheck(err);
    }
  }

  onCancel = () => {
    this.props.history.push("/Account");
  };

  render = () => {
    const {
      _id,
      name,
      userName,
      claims,
      password,
      phoneNumber,
      email,
      nodes,
      menuIds
    } = this.state;
    var claimsConvert = [];
    for (let key in claims) {
      if (claims[key] === true) {
        claimsConvert.push({ type: "permission", value: key });
      }
    }
    return (
      <Page
        title="Chi tiết Account"
        breadcrumbs={[{ name: "Chi tiết Account", active: true }]}
        className="Account"
      >
        <Row>
          <Col md={12}>
            <Mutation
              mutation={
                _id === undefined || _id.length === 0
                  ? MUTATION_CREATE
                  : MUTATION_EDIT
              }
              variables={{
                account: {
                  _id,
                  name,
                  userName,
                  claims: claimsConvert,
                  phoneNumber,
                  email,
                  password,
                  menuIds
                }
              }}
              onCompleted={result => {
                this.onCancel();
              }}
            >
              {mutation => (
                <Button color="primary" onClick={mutation}>
                  Cập nhật thông tin
                </Button>
              )}
            </Mutation>{" "}
            <Button color="link" onClick={console.log(JSON.stringify(this.state.checked))}>
              Hủy
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form className="m-3">
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Tên:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="text"
                    value={name}
                    onChange={e => {
                      this.setState({ name: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Tên đăng nhập:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="text"
                    value={userName}
                    onChange={e => {
                      this.setState({ userName: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Mật khẩu:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="password"
                    value={password}
                    placeholder="*******"
                    onChange={e => {
                      this.setState({ password: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Email:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="text"
                    value={email}
                    onChange={e => {
                      this.setState({ email: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Số điện thoại:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <Input
                    type="text"
                    value={phoneNumber}
                    onChange={e => {
                      this.setState({ phoneNumber: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Menu truy cập:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <CheckboxTree
                    nodes={nodes}
                    checked={this.state.menuIds}
                    expanded={this.state.expanded}
                    expandOnClick
                    onCheck={checked => this.setState({ menuIds:checked })}
                    onExpand={expanded => this.setState({ expanded })}
                    onClick={clicked => this.setState({clicked})}
                />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Quyền truy cập:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  
                  <Query query={QUERY_PERMISSIONS}>
                  {
                    ({loading,error,data})=>{
                      var permissions=[];
                      authCheck(data);
                      try{
                        permissions=data.permissions.data;
                      }catch(err){}
                      return(
                        <div>
                          <div check style={{ paddingLeft: "1rem" }}>
                            <Input
                              type="checkbox"
                              checked={claims.root}
                              onChange={e => {                                
                                claims.root = !claims.root;
                                permissions.forEach(function(item){                                 
                                    claims[`${item.value}`]=claims.root;                                 
                                })
                                this.setState({ claims });
                              }}
                            />{" "}
                            Root
                          </div>
                          {permissions.map((item,index)=>(<div check style={{ paddingLeft: "1rem" }} key={index}>
                            <Input
                                type="checkbox"
                                checked={claims[`${item.value}`]}
                                onChange={e => {
                                  claims[`${item.value}`] = !claims[`${item.value}`];                                  
                                  this.setState({ claims });
                                }}
                              />{" "}
                              {item.name?item.name:item.value}
                          </div>))}
                        </div>
                      )
                    }
                  }
                  </Query>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Page>
    );
  };
}
export default withApollo(AccountEditPage);
