import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from './Layout';



import React from 'react';
import componentQueries from 'react-component-queries';
import { Redirect, Switch,BrowserRouter } from 'react-router-dom';
import CompanyPage from 'Admin/Pages/Company/CompanyPage';
import LoginPage from 'Admin/Pages/LoginPage';
import TemplatePage from 'Admin/Pages/Template/TemplatePage';
import TemplateDetailPage from 'Admin/Pages/Template/TemplateDetailPage';
import SipAccountEditPage from 'Admin/Pages/SipAccount/SipAccountEditPage';
import AccountPage from 'Admin/Pages/Account/AccountPage';
import AccountEditPage from 'Admin/Pages/Account/AccountEditPage';
import CompanyEditPage from 'Admin/Pages/Company/CompanyEditPage';
import SipAccountPage from 'Admin/Pages/SipAccount/SipAccountPage';
import GroupAgentPage from 'Admin/Pages/GroupAgentPage';
import SipSettingPage from 'Admin/Pages/SipSettingPage';
import TrunkPage from 'Admin/Pages/Trunk/TrunkPage';
import TrunkDetailPage from 'Admin/Pages/Trunk/TrunkDetailPage';
import AnnouncementPage from 'Admin/Pages/Announcement/AnnouncementPage';
import RingGroupPage from 'Admin/Pages/RingGroup/RingGroupPage';
import RingGroupEditPage from 'Admin/Pages/RingGroup/RingGroupEditPage';
import AnnouncementEditPage from 'Admin/Pages/Announcement/AnnouncementEditPage';
import LogPage from 'Admin/Pages/Log/LogPage.js'
import IvrPage from 'Admin/Pages/Ivr/IvrPage';
import MenuPage from 'Admin/Pages/Menu/MenuPage';
import MenuEditPage from 'Admin/Pages/Menu/MenuEditPage';
import IvrEditPage from 'Admin/Pages/Ivr/IvrEditPage';
import FirewallPage from 'Admin/Pages/Firewall/FirewallPage';
import FirewallEditPage from 'Admin/Pages/Firewall/FirewallEditPage'
import 'styles/reduction.css';
import "react-table/react-table.css";
import "react-datepicker/dist/react-datepicker.css"
import "react-tabs/style/react-tabs.css";
import "pretty-checkbox/src/pretty-checkbox.scss";
//import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { ApolloProvider } from 'react-apollo'

import {client} from '../index';
//import { BrowserRouter } from 'react-router-dom'

import Moment from 'moment'
import 'moment/locale/vi'



const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};
class AppAdmin extends React.Component {
  constructor(){
    Moment.locale("vi");    
    super();
    console.log("Load App Admin")
  }
  
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
      <ApolloProvider client={client}>
        <GAListener>
          <Switch>
          <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={props => (
                <LoginPage {...props}/>
              )}
            />
            <LayoutRoute
              exact
              path="/company"
              layout={MainLayout}
              component={CompanyPage}
            />
            <LayoutRoute
              exact
              path="/ringgroup"
              layout={MainLayout}
              component={RingGroupPage}
            />
            <LayoutRoute
              exact
              path="/ringgroup-edit"
              layout={MainLayout}
              component={RingGroupEditPage}
            />
            <LayoutRoute
              exact
              path="/ringgroup-edit/:_id"
              layout={MainLayout}
              component={RingGroupEditPage}
            />
            <LayoutRoute
              exact
              path="/log"
              layout={MainLayout}
              component={LogPage}
            />
             <LayoutRoute
              exact
              path="/menu"
              layout={MainLayout}
              component={MenuPage}
            />
             <LayoutRoute
              exact
              path="/menu-edit"
              layout={MainLayout}
              component={MenuEditPage}
            />
             <LayoutRoute
              exact
              path="/menu-edit/:_id"
              layout={MainLayout}
              component={MenuEditPage}
            />
            <LayoutRoute
              exact
              path="/firewall"
              layout={MainLayout}
              component={FirewallPage}
            />
            <LayoutRoute
              exact
              path="/firewall-edit"
              layout={MainLayout}
              component={FirewallEditPage}
            />
            <LayoutRoute
              exact
              path="/account"
              layout={MainLayout}
              component={AccountPage}
            />
            <LayoutRoute
              exact
              path="/account-edit"
              layout={MainLayout}
              component={AccountEditPage}
            />
            <LayoutRoute
              exact
              path="/account-edit/:_id"
              layout={MainLayout}
              component={AccountEditPage}
            />
            <LayoutRoute
              exact
              path="/ivr"
              layout={MainLayout}
              component={IvrPage}
            />
            <LayoutRoute
              exact
              path="/ivr-edit"
              layout={MainLayout}
              component={IvrEditPage}
            />
            <LayoutRoute
              exact
              path="/ivr-edit/:_id"
              layout={MainLayout}
              component={IvrEditPage}
            />
            <LayoutRoute
              exact
              path="/announcement"
              layout={MainLayout}
              component={AnnouncementPage}
            />
            <LayoutRoute
              exact
              path="/announcement-edit"
              layout={MainLayout}
              component={AnnouncementEditPage}
            />
            <LayoutRoute
              exact
              path="/announcement-edit/:_id"
              layout={MainLayout}
              component={AnnouncementEditPage}
            />
            <LayoutRoute
              exact
              path="/trunk"
              layout={MainLayout}
              component={TrunkPage}
            />
            <LayoutRoute
              exact
              path="/trunk-edit"
              layout={MainLayout}
              component={TrunkDetailPage}
            />
            <LayoutRoute
              exact
              path="/trunk-edit/:_id"
              layout={MainLayout}
              component={TrunkDetailPage}
            />
            <LayoutRoute
              exact
              path="/sip-account"
              layout={MainLayout}
              component={SipAccountPage}
            />
            <LayoutRoute
              exact
              path="/sip-account-edit"
              layout={MainLayout}
              component={SipAccountEditPage}
            />
            <LayoutRoute
              exact
              path="/sip-account-edit/:_id"
              layout={MainLayout}
              component={SipAccountEditPage}
            />
            <LayoutRoute
              exact
              path="/template"
              layout={MainLayout}
              component={TemplatePage}
            />
            <LayoutRoute
              exact
              path="/template-edit"
              layout={MainLayout}
              component={TemplateDetailPage}
            />
            <LayoutRoute
              exact
              path="/sip-setting"
              layout={MainLayout}
              component={SipSettingPage}
            />
            <LayoutRoute
              exact
              path="/template-edit/:_id"
              layout={MainLayout}
              component={TemplateDetailPage}
            />
            <LayoutRoute
              exact
              path="/company-edit"
              layout={MainLayout}
              component={CompanyEditPage}
            />
            <LayoutRoute
              exact
              path="/company-edit/:_id"
              layout={MainLayout}
              component={CompanyEditPage}
            />
            <LayoutRoute
              exact
              path="/group-agent"
              layout={MainLayout}
              component={GroupAgentPage}
            />
            <Redirect to="/company" />
          </Switch>
        </GAListener>
        </ApolloProvider>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(AppAdmin);
