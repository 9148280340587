import Page from 'Admin/Components/Page';
import React from 'react';
import { Button,ButtonGroup,Card,CardBody,CardHeader,Badge,Modal,ModalHeader,ModalBody,ModalFooter} from 'reactstrap';

import gql from 'graphql-tag';
import {Mutation} from 'react-apollo'
import {MdDelete,MdAddCircle} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import confirm from 'components/Confirmation'
import { withApollo } from 'react-apollo'
import Moment from 'moment';

export const QUERY_Template=gql`
query AdminQuery($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
    logs(page:$page,pageSize:$pageSize,filtered:$filtered,sorted:$sorted){
        data{
            _id
            action
            api_message{
                delay
                down_size
                request
                response
                up_size
            }
            authorize_name
            error_message
            ip
            log_level
            project_name
            time
          }
        page
        pages
    }
  }
`;


class LogPage extends React.Component{
    state={        
        data: [],
        page: 1,
        loading: true,
        showDetail:false,
        detailError:""
    }
    constructor(){
        super();
        this.fetchData=this.fetchData.bind(this);
    }
    fetchData=async(state,instance)=>{
        this.setState({ loading: true });
        const {filtered,pageSize,sorted,page} = state;
        try{
            const result = await this.props.client.query({
                query: QUERY_Template,
                variables: { filtered,pageSize,sorted,page },
                
            })   
            authCheck(result);
             
            this.setState({data:result.data.logs.data,pages:result.data.logs.pages,page:result.data.logs.page,loading:false});
        }catch(err){
            console.log("error:"+JSON.stringify(err));
            authCheck(err);
        }
    }
    
    onDelete=(row,mutation)=>{
        confirm(`Bạn có chắc chắn muốn xóa đầu số '${row.number}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }
    toggle=modalType=>()=>{
        return this.setState({showDetail:!this.state.showDetail})
    }
    render=()=>{
        const {data,loading,pages,detailError}=this.state;
        return (
            <Page title="Log"
                breadcrumbs={[{ name: 'Quản lý Log', active: true }]}            
                className="log"> 
                <Card>
                    
                    <CardBody>
                        <div style={{height:10}}></div>
                        <ReactTable 
                        noDataText="Không có dữ liệu"
                        defaultSorted={[
                            {
                                id: "_id",
                                desc: true
                            }
                        ]}
                        columns={[    
                            {
                                Header:"#",
                                width:50,
                                style:{textAlign:"center"},
                                filterable:false,
                                Cell: (row) => {
                                    return <div>{row.index+1}</div>;
                                    }
                            },                             
                            {
                                Header:"Action",
                                accessor:"action",
                                maxWidth:150,
                                Cell:row=><span>
                                    <Button color="link" onClick={e=>{this.setState({showDetail:true,detailError:row.original.error_message})}}>{row.value}</Button>
                                </span>
                            }, 
                            {
                                Header:"Thời gian",
                                accessor:"time",
                                Cell:row=><span>
                                    <Badge color="primary" className="text-white">{Moment(row.value).format("hh:mm")}</Badge>{" "}{Moment(row.value).format("DD/MM/YYYY")}
                                </span>,
                                width:120
                            },                                                          
                            {
                                Header:"Error",
                                accessor:"error_message",
                                style:{maxHeight:110,overFlow:'hidden',textOverFlow:"ellipsis"}
                            },
                            {
                                Header:"Level",
                                accessor:"log_level",
                                width:80                                
                            },                            
                            {
                                Header:"Project",
                                accessor:"project_name",
                                width:70
                            },   
                            {
                                Header:"ip",
                                accessor:"ip",
                                width:100
                            }
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={10}
                        className="-striped -highlight"
                    />     
                    </CardBody>
                </Card>
                <Modal isOpen={this.state.showDetail}
                    toggle={this.toggle}
                    className="modal-lg">
                    <ModalHeader>Error Detail</ModalHeader>
                    <ModalBody>
                        {detailError}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle()}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </Page>

        )
    }
}
export default withApollo(LogPage);