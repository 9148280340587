import Page from 'components/Page';
import React,{Fragment} from 'react';
import { Button, ButtonGroup, Card, Input, Label ,Tooltip} from 'reactstrap';
import { withApollo,Mutation } from 'react-apollo'
import confirm from 'components/Confirmation';
import { MdEdit } from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import Moment from 'moment'
import {GET_KQXN1s,UPDATE_STATUS_KQXN1} from 'AdminCustomer/GqlQuery';
import ToggleButton from "react-toggle-button";

class kqxn1 extends React.Component {

    state = {
        data: [],
        pages: 1,
        page:0,
        loading: true,
        tooltipOpen:{}
    }
    constructor() {
        super();
        this.fetchData = this.fetchData.bind(this);
    }
    updateStatus=(row)=>{            
        row.allowUpdated=!row.allowUpdated;
    }
    
    onCreate=()=>{
        this.props.history.push("/customer-edit");
    }
    fetchData = async (state, instance) => {

        this.setState({ loading: true });
        const { filtered, pageSize, sorted, page } = state;
        try {
            const result = await this.props.client.query({
                query: GET_KQXN1s,
                variables: { filtered, pageSize, sorted, page },
            })
            authCheck(result);
            this.setState({ data: result.data.response.data, pages: result.data.response.pages, page: result.data.response.page, loading: false });
        } catch (err) {
            authCheck(err);
        }
    }
    render = () => {
        const { data, loading, pages,page ,tooltipOpen} = this.state;
        return (
            <Page title="Quản lý khách hàng"
                breadcrumbs={[{ name: 'Quản lý khách hàng', active: true }]}
                className="CustomerPage">
                <Card>
                    <ReactTable
                        noDataText="Không có dữ liệu"
                        defaultSorted={[{id:"testTime",desc:true}]}                        
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={10}
                        className="-striped -highlight"
                        columns={[
                            {
                                Header: "#",
                                width:50,
                                className:"text-center",
                                filterable:false,
                                Cell:row=><span>
                                    {row.index+1+(page*10)}
                                </span>
                            },
                            {
                                Header: "Tên xét nghiệm",
                                accessor: "name",
                                Cell:row=><Button color="link" onClick={e=>{window.open(row.original.files[0],'_blank')}}>
                                    {row.value}
                                </Button>
                            },
                            {
                                Header: "Mã bệnh nhân",
                                accessor: "patientId",
                                width:120
                            },
                            {
                                Header: "Tên bệnh nhân",
                                accessor: "patient.hOTEN",
                                width:120
                            },
                            {
                                Header: "Năm sinh",
                                accessor: "patient.nAMSINH",
                                width:120
                            },
                            {
                                Header: "Số điện thoại",
                                accessor: "phoneNumber",
                                width:120
                            },
                            {
                                Header: "Mật khẩu",
                                accessor: "user.password",
                                width:120
                            },
                            {
                                Header: "Cho phép cập nhật",
                                accessor: "allowUpdate",
                                className:"text-center",
                                width:150,
                                Cell:row=><Mutation
                                    mutation={UPDATE_STATUS_KQXN1}
                                    variables={{_id:row.original._id,allowUpdate:!row.value}}
                                    onCompleted={e=>this.fetchData({filtered:[],pageSize:10,page:0,sorted:[{id:"testTime",desc:true}]})}
                                >                                    
                                    {
                                        mutation=>
                                            <ToggleButton
                                            value={row.value}
                                            onToggle={mutation}
                                        />
                                    }
                                    
                                </Mutation>
                            },
                            {
                                Header: "Thời gian trả xét nghiệm",
                                accessor: "testTime",
                                Filter: ({ filter, onChange }) =>
                                    <Input bsSize="sm" type="date" placeholder="Chọn ngày" onChange={e => onChange(e.target.value)} />,
                                Cell: row => (<Label>{Moment(row.value).format('HH:mm DD/MM/YYYY')}</Label>)
                            }
                        ]}
                        
                    >

                    </ReactTable>
                </Card>

            </Page>
        )
    }
}

export default withApollo(kqxn1);