import Page from 'components/Page';
import React from 'react';
import { ButtonGroup,Card,Input,Form} from 'reactstrap';
import { withApollo } from 'react-apollo'
import Moment from 'moment';
import gql from 'graphql-tag';
import {MdPause,MdPlayCircleFilled,MdFileDownload} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import ReactPlayer from 'react-player';
import { apiHost } from 'companyConstants';

export const CdrQuery = gql`
query CDR($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
    cdrs(page:$page,pageSize:$pageSize,sorted:$sorted,filtered:$filtered){
      code
      message
      page
      pages
      data{
       _id
        state
        receiver
        classify
        customer{
            fullName
          }
        agent{name}
        appointmentId
        appointment{
            state
        }
        sender
        startTime
        ringTime
        upTime
        isOutbound
        did
        duration
        endTime
        endReason
        hangupTime
        outboundCallerId
      }
    }
  }
`;


class InboundPage extends React.Component{
    state={
        data: [],
        pages: 1,
        page:0,
        loading: true,
        playingId:""
    }
    constructor(){
        super();
        this.fetchData = this.fetchData.bind(this);
    }
    fetchData= async(state, instance)=>{
        
        this.setState({ loading: true });
        const {filtered,pageSize,sorted,page} = state;
        
        // filtered.push({id:"isOutbound",value:"false"});
        // sorted.push({id:"startTime",desc:true})
        try{
            const result = await this.props.client.query({
                query: CdrQuery,
                variables: {filtered: filtered.concat([{id:"isOutbound",value:"false"},{id:"app",value:"Dial"}]),pageSize,sorted:sorted.concat([{id:"startTime",desc:true}]),page },
                
            })
        
            this.setState({data:result.data.cdrs.data,pages:result.data.cdrs.pages,loading:false});
        }catch(err){
           authCheck(err);
        }
    }
    render=()=>{
        const {data,loading,pages,page,playingId}=this.state;
        return (
            <Page title="Quản lý gọi vào"
                breadcrumbs={[{ name: 'Quản lý gọi vào', active: true }]}            
                className="InboundPage">     
                <Card>
                        
                    <ReactTable 
                        noDataText="Không có dữ liệu"
                        columns={[     
                            {
                                Header: "#",
                                width:50,
                                className:"text-center",
                                filterable:false,
                                Cell:row=><span>
                                    {row.index+1+page*10}
                                </span>
                            },                       
                            {
                                Header:"Số điện thoại",
                                accessor:"sender"
                            },
                            {
                                Header:"Người gọi",
                                accessor:"customer.fullName"
                            },
                            {
                                Header:"Nhóm tình trạng",
                                accessor:"classify",
                                Cell:row=>(
                                    <span>{row.value==="inbound"?"Gọi vào":row.value==="disturb"?"Quấy rối":row.value==="wrong"?"Nhầm máy":"Khác"}</span>
                                ),
                                filterMethod: (filter, row) => {
                                    return filter.value;
                                },
                                Filter: ({ filter, onChange }) =>
                                    <select
                                        onChange={event => onChange(event.target.value)}
                                        style={{ width: "100%" }}
                                        value={filter ? filter.value : "inbound"}
                                        >
                                        <option value="inbound">Gọi vào</option>
                                        <option value="appointment">Đặt khám</option>
                                        <option value="wrong">Nhầm máy</option>
                                        <option value="disturb">Quấy rối</option>
                                        <option value="">Khác</option>
                                    </select>
                            },
                            {
                                Header:"Trạng thái",
                                accessor:"appointment.state",
                                Cell:row=>(
                                    <span>{row.value==="waiting"?"Chờ xử lý":row.value==="approve"?"Đã duyệt":row.value==="served"?"Hoàn thành":row.value==="cancel"?"Hủy khám":""}</span>
                                ),
                                Filter: ({ filter, onChange }) =>
                                    <select
                                        onChange={event => onChange(event.target.value)}
                                        style={{ width: "100%" }}
                                        value={filter ? filter.value : ""}
                                        >
                                        <option value="">Tất cả</option>
                                        <option value="waiting">Chờ xử lý</option>
                                        <option value="approve">Đã duyệt</option>
                                        <option value="served">Đã khám</option>
                                        <option value="cancel">Đã hủy</option>
                                    </select>
                            },
                            {
                                Header:"Thời gian gọi",
                                accessor:"startTime",
                                Cell:row=><span>
                                    {Moment(row.value).format("HH:mm DD/MM/YYYY")}      
                                </span>,
                                Filter:({filter,onChange})=>
                                    <Input bsSize="sm" type="date" placeholder="Chọn ngày" onChange={e=>onChange(e.target.value)}/>
                            },
                            {
                                Header:"Ngày cập nhật",
                                accessor:"endTime",
                                Cell:row=><span>
                                    {Moment(row.value).format("DD/MM/YYYY")}      
                                </span>,
                                Filter:({filter,onChange})=>
                                    <Input bsSize="sm" type="date" placeholder="Chọn ngày" onChange={e=>onChange(e.target.value)}/>
                            
                            },
                            
                            {
                                Header:"Chú thích yêu cầu",
                                accessor:"note"
                            },
                            {
                                Header:"Agent tiếp nhận",
                                accessor:"agent.name"
                            },
                            {
                                Header: "Tác vụ",
                                className: "text-center",
                                accessor: "_id",
                                width:90,
                                filterable:false,
                                Cell: row =>
                                    <Form inline>
                                        <ButtonGroup style={{fontSize:25}}>
    
                                            {
                                                playingId == row.value ?
                                                   <MdPause className="text-info m-1" onClick={e => { this.setState({ playingId: "" }) }}/>:
                                                   <MdPlayCircleFilled className="text-info m-1" onClick={e => { this.setState({ playingId: row.value }) }}/>
                                            }
    
                                            <MdFileDownload className="text-danger m-1" onClick={e => this.download(row.value)}/>
                                        </ButtonGroup>
                                    </Form>
                            },
                            {
                                Header: "Media",
                                className: "text-center",
                                accessor: "_id",
                                filterable:false,
                                minWidth:200,
                                Cell: row =>
                                    <Form inline>
                                        <ReactPlayer url={"http://" + apiHost + "/downloadrecord/" + row.value} loop={true} playing={playingId == row.value} controls={true} height="40px"/>
                                        
                                    </Form>
                            },
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={10}
                        className="-highlight"
                    >

                    </ReactTable>
                    
                </Card>
            </Page>
        )
    }
}

export default withApollo(InboundPage);