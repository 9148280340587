import Page from 'components/Page';
import React,{Fragment} from 'react';
import { Button, ButtonGroup, Card, Input, Label,CardHeader,Tooltip } from 'reactstrap';
import { withApollo,Mutation } from 'react-apollo'
import gql from 'graphql-tag';
import { MdEdit,MdDelete,MdAddCircle } from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import Moment from 'moment'
import confirm from 'components/Confirmation';
import {FETCH_LIMSES} from 'AdminCustomer/GqlQuery';
import { DELETE_LIMS } from '../../GqlQuery';



class Lims extends React.Component {

    state = {
        data: [],
        pages: 1,
        loading: true,
        tooltipOpen:{}
    }
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);  
        this.state.category=props.match.params.category;
    }
    componentDidMount(){
        
        this.fetchData({page:0,pageSize:10,filtered:[],sorted:[{id:"ngay_tao",desc:true}]});
    }
    onDelete=(row,mutation)=>{
        confirm(`Bạn có chắc chắn muốn xóa bản ghi bệnh nhân '${row.ho_va_ten}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }
    
    fetchData = async (state, instance) => {
        this.setState({ loading: true });
        let { filtered, pageSize, sorted, page } = state;
        //const {category}=this.state;
        //filtered=filtered.concat([{id:"category",value:category}])
        try {
            const result = await this.props.client.query({
                query: FETCH_LIMSES,
                variables: { filtered, pageSize, sorted, page },
            })
            authCheck(result);
            this.setState({ data: result.data.response.data, pages: result.data.response.pages, loading: false });
        } catch (err) {
            authCheck(err);
        }
    }
    onEdit=(row)=>{            
        this.props.history.push("/lims-detail/"+row._id);
    }
   
    render = () => {
        const { data, loading, pages,tooltipOpen } = this.state;
        return (
            <Page title="Quản lý hồ sơ chuẩn đoán"
                breadcrumbs={[{ name: 'Quản lý hồ sơ chuẩn đoán', active: true }]}
                className="CustomerPage">
                <Card>
                    <CardHeader className="bg-green">
                        <Button color="primary" onClick={e => { this.props.history.push("/lims-detail") }}><MdAddCircle /> Thêm hồ sơ</Button>
                    </CardHeader>
                    <ReactTable
                        manual
                        noDataText="Không có dữ liệu"   
                        sortable={false} 
                        defaultSorted={[{id:"ngay_tao",desc:true}]}
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={10}
                        className="-striped -highlight"                       
                        columns={[
                            {
                                Header: "#",
                                width:50,
                                className:"text-center",
                                filterable:false,
                                Cell:row=><span>
                                    {row.index+1}
                                </span>
                            },
                            {
                                Header: "Mã bệnh nhân",
                                accessor: "mabn"
                            },
                            {
                                Header: "Tên bệnh nhân",
                                accessor: "ho_va_ten"
                            },
                            {
                                Header: "Số điện thoại",
                                accessor: "sodienthoai"
                            },
                            {
                                Header: "Địa chỉ",
                                accessor: "diachi1"
                            },  
                            {
                                Header: "Ngày sinh",
                                accessor: "ngaysinh"
                            },                          
                                              
                            {
                                Header: "Ngày tạo",
                                accessor: "ngay_tao",
                                filterable:false,
                                Filter: ({ filter, onChange }) =>
                                    <Input bsSize="sm" type="date" placeholder="Chọn ngày" onChange={e => onChange(e.target.value)} />,
                                Cell: row => (<Label>{Moment(row.value).format('DD-MM-YYYY HH:mm')}</Label>)
                            },
                            {
                                Header: "Tác vụ",
                                maxWidth: 100,
                                filterable: false,
                                sortable: false,
                                className:"text-center",
                                Cell: row=>(
                                    <ButtonGroup style={{fontSize:20}}>
                                        <MdEdit id={"edit"+row.original._id} onClick={()=>this.onEdit(row.original)} className="text-warning m-1"/>
                                        <Tooltip placement="bottom" isOpen={tooltipOpen['edit'+row.original._id]} autohide={false} target={"edit"+row.original._id} toggle={e=>{tooltipOpen['edit'+row.original._id]=!tooltipOpen['edit'+row.original._id]; this.setState({tooltipOpen})}}>
                                            Sửa
                                        </Tooltip>
                                        <Mutation
                                            mutation={DELETE_LIMS}
                                            variables={{_id:row.original._id}}
                                            onCompleted={e=>this.fetchData({page:0,pageSize:10,sorted:[{id:"ngay_tao",desc:true}]})}
                                        >
                                            {
                                                mutation=><Fragment>
                                                    <MdDelete id={"delete"+row.original._id} onClick={()=>this.onDelete(row.original,mutation)} className="text-danger m-1"/>
                                                    <Tooltip placement="bottom" isOpen={tooltipOpen['delete'+row.original._id]} autohide={false} target={"delete"+row.original._id} toggle={e=>{tooltipOpen['delete'+row.original._id]=!tooltipOpen['delete'+row.original._id]; this.setState({tooltipOpen})}}>
                                            Xóa
                                        </Tooltip>
                                                </Fragment>
                                            }
                                        </Mutation>
                                        
                                    </ButtonGroup>
                                )
                            }
                        ]}
                        
                    >

                    </ReactTable>
                </Card>

            </Page>
        )
    }
}

export default withApollo(Lims);