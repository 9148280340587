import Page from 'components/Page';
import React from 'react';
import { Button,ButtonGroup,Input,Row,Col,Label,Card,CardHeader,CardBody,Modal,ModalBody,ModalHeader,ModalFooter ,Form,FormGroup} from 'reactstrap';
import authCheck from "utils/authCheck";

import gql from 'graphql-tag';

import { Mutation,withApollo } from 'react-apollo'
import {MdSave} from 'react-icons/lib/md';
import confirm from 'components/Confirmation'
import { WithContext as ReactTags } from 'react-tag-input';
import ReactTable from "react-table";
import {GetLocalToken,UpdateLocalSetting} from 'companyConstants'
const KeyCodes = {
    comma: 188,
    enter: 13,
  };
const delimiters = [KeyCodes.comma, KeyCodes.enter];
   
const SETTING_QUERY=gql`
{
    settings{
      code
      message
      data{
        companyName
        labelDictionary{
            name
            value
        }
        logo
        workTimes
        website      
      }
    }
  }
`;
const UPDATE_QUERY=gql`
mutation SettingUpdate($setting:CompanySettingInput){
    updateSetting(setting:$setting){
        code
        message
        data{
            companyName
            labelDictionary{
                name
                value
            }
            logo
            workTimes
            website      
          }
    }
  }
`;
class SettingPage extends React.Component{
    
    state={
        workTimes:[],
        companyName:"",
        website:"",
        logo:"",
        labelDictionary:[
            {name:"department",value:"phòng/ban"},
            {name:"appointment",value:"đặt khám"},
        ]
    }
    componentDidMount(){
        
        try{
            this.props.client.query({
                query:SETTING_QUERY
            }).then(result=>{
                authCheck(result);                
                const setting=result.data.settings.data;
                setting.workTimes = setting.workTimes.map((time,i)=>{return {id:time,text:time}});
                this.setState(setting);
            })            
            
        }catch(err){
            console.log(err);
        }
    }
    handleTimeDelete(i) { 
        let {workTimes} = this.state;       
        workTimes=workTimes.filter((time,index)=>index!==i);
        this.setState({workTimes:workTimes});
    }
    handleTimeDrag(tag, currPos, newPos) {
        const tags = [...this.state.workTimes];
        
        //const tags =workTime.workTimes;
        const newTags = tags.slice();
 
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
 
        // re-render
        this.setState({ workTimes: newTags });
        
    }
    handleTimeAddition(tag) {
        const workTimes= [...this.state.workTimes,tag];
        this.setState({workTimes:workTimes});
    }
    
    render=()=>{
        const {labelDictionary,workTimes,companyName,file,logo,website} = this.state;
        return(
            <Page
            title="Cài đặt"
            breadcrumbs={[{ name: 'Cài đặt', active: true }]}            
            className="SettingPage">
                <CardBody>
                    <Row>
                        <Col md={12}>
                            <Mutation
                                mutation={UPDATE_QUERY}
                                variables={{setting:{file,companyName,website,workTimes:workTimes.map((item,i)=>{return item.text}),labelDictionary:labelDictionary.filter((item,i)=>{return item.name!==undefined&&item.value!==undefined})}}}
                                onCompleted={(result)=>{
                                    //update to current store      
                                    if(result.updateSetting.code===0){                              
                                        UpdateLocalSetting(result.updateSetting.data);
                                        confirm('Cập nhật thành công',{hideCancel:true,title:""});
                                    }else{
                                        confirm(result.updateSetting.message,{hideCancel:true,title:""});
                                    }
                                }}
                            >
                            {
                                mutation=><Button onClick={mutation} color="success"><MdSave/> Lưu thiết lập</Button>  
                            }
                            </Mutation>
                                                      
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12} xs={12}>
                            <Card>
                                <CardHeader className="bg-dark text-white">
                                    Thiết lập danh sách các khung giờ làm việc
                                </CardHeader>
                                <CardBody>
                                <ReactTags tags={workTimes}
                                                        suggestions={[]}
                                                        handleDelete={i=>{this.handleTimeDelete(i)}}
                                                        handleAddition={value=>{this.handleTimeAddition(value)}}
                                                        handleDrag={(tag, currPos, newPos)=>{this.handleTimeDrag(tag, currPos, newPos)}}
                                                        delimiters={delimiters} />  
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} sm={12} xs={12}>
                            <Card>
                                <CardHeader className="bg-dark text-white">
                                    Thông tin công ty
                                </CardHeader>
                                <CardBody>
                                    
                                    <Form>
                                        <FormGroup row>
                                            <Label md={3} className="text-right">
                                                Tên công ty
                                            </Label>
                                            <Col md={8}>
                                                <Input value={companyName} onChange={e=>this.setState({companyName:e.target.value})}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label md={3} className="text-right">
                                                Website
                                            </Label>
                                            <Col md={8}>
                                                <Input value={website} onChange={e=>this.setState({website:e.target.value})}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label md={3} className="text-right">
                                                Logo
                                            </Label>
                                            <Col md={8}>
                                                <Input
                                                    type="file"
                                                    required
                                                    onChange={({
                                                    target: {
                                                        validity,
                                                        files: [file]
                                                    }
                                                    }) => {
                                                    this.setState({ file });
                                                    }}
                                                    bsSize="sm"
                                                />
                                                <img src={website}/>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader className="bg-dark text-white">
                                    Thiết lập nhãn hiển thị
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        noDataText="Không có dữ liệu"
                                        data={labelDictionary}
                                        columns={[
                                            {
                                                Header:"Khóa gốc",
                                                accessor:"name",
                                                className:"text-center"
                                            },
                                            {
                                                Header:"Nhãn hiển thị",
                                                accessor:"value",
                                                className:"text-center"
                                            }
                                        ]}
                                        defaultPageSize={5}
                                    >
                                        
                                    </ReactTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>
            </Page>

        )
    }
}
export default withApollo(SettingPage);