import Page from 'components/Page';
import React,{Fragment} from 'react';
import { Button,ButtonGroup,Badge,Input,Row,Col,Label,Card,CardHeader,CardBody,Form,FormGroup,Tooltip,Modal,ModalBody,ModalHeader,ModalFooter} from 'reactstrap';
import { withApollo,Query,Mutation } from 'react-apollo'
import AppointmentTime from "components/AppointmentTime";
import gql from 'graphql-tag';
import {MdDelete,MdCall,MdEdit,MdCheckCircle,MdClose,MdCheckBoxOutlineBlank,MdTimelapse,MdRefresh,MdCallToAction} from 'react-icons/lib/md';
import ReactTable from "react-table";
import Moment from 'moment'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SearchInput from 'components/SearchInput';
import { CounterWidget } from 'components/Widget';
import authCheck from 'utils/authCheck';
import confirm from 'components/Confirmation';
import omitDeep from 'omit-deep';   
import {CALLOUT,FETCH_CUSTOMER,DELETE_APPOINTMENT,FETCH_WORKING_TIME,UPDATE_APPOINTMENT,FETCH_DEPARTMENT,FETCH_APPOINTMENT,UPDATE_APPOINTMENT_STATE} from "../GqlQuery";

class DashboardPage extends React.Component{
    state={
        rowEdit:{customer:{},department:{}},
        showEditModal:false,
        showEditStateModal:false,
        loadingInDay:true,
        loadingWeb:true,
        appointmentInDay:[],
        appointmentWeb:[],
        customers:{pages:1,data:[],filtered:[],sorted:[],pageSize:10,page:0,loading:true},
        searchKey:"",
        workingTimes: [],
        departments:[],
        searchDate:new Date(),
        filterType:"appointmentdate",//appointmentdate,created,expired
        tooltipOpen: {},
        approveModal:false
    }
    constructor(){
        super();
        this.fetchAppointmentToday = this.fetchAppointmentToday.bind(this);
        this.fetchCustomerData = this.fetchCustomerData.bind(this);
       
    }
    fetchCustomerData=async(state,instance)=>{
        const {filtered,pageSize,sorted,page} = state;
        try{
            await this.props.client.query({
                query: FETCH_CUSTOMER,
                variables: { filtered,pageSize,sorted,page }                
            }).then(result=>{
                authCheck(result);
                const customers={...result.data.customers,filtered,pageSize,sorted,page};
                this.setState({customers});
                console.log(customers);
            })
        
        }catch(err){
           authCheck(err);
        }
    }
    fetchAppointmentToday= async()=>{
        
        try{
            
            const {searchKey, filterType,searchDate}=this.state;
            var today=Moment(searchDate).format("DD/MM/YYYY");
            var filtered=[{id:"key",value:searchKey}];
            switch(filterType){
                case "appointmentdate":
                    filtered.push({id:"AppointmentDate",value:today});
                    break;
                case "created":
                    filtered.push({id:"CreatedTime",value:today});
                    break;
                case "expired":
                    filtered.push({id:"expired",value:"true"});
                    break;
            }
            this.props.client.query({
                query:FETCH_APPOINTMENT,
                variables:{filtered,sorted:[{id:filterType?"AppointmentTime":"CreatedTime",desc:false}]}
            }).then(result=>{
                authCheck(result);
                this.setState({loadingInDay:false,appointmentInDay:result.data.appointments.data});
            })
        }catch(err){
            authCheck(err);
        }
    }
    fetchAppointmentWaiting=async(state,instance)=>{
        const {searchKey}=this.state;
        try{            
            this.props.client.query({
                query:FETCH_APPOINTMENT,
                variables:{filtered:[{id:"state",value:"served",ne:true},{id:"state",value:"cancel",ne:true},{id:"state",value:"approve",ne:true},{id:"key",value:searchKey}],sorted:[{id:"AppointmentTime",desc:false}]}
            }).then(result=>{   
                authCheck(result);             
                this.setState({loadingWeb:false,appointmentWeb:result.data.appointments.data});
            })
        }catch(err){
            authCheck(err);
        }
    }
    toggleEditModal=modalType=>()=>{
        return this.setState({showEditModal:!this.state.showEditModal})
    }
    toggleEditStateModal=modalType=>()=>{
        return this.setState({showEditStateModal:!this.state.showEditStateModal})
    }
    onEdit=(row)=>{                 
        const rowEdit = Object.assign({},row);//clone object 
        if(this.state.departments.length===0){
            this.props.client.query({
                query:FETCH_DEPARTMENT,
            }).then(result=>{
                authCheck(result);
                this.setState({departments:result.data.departments.data});
            })
        }
        this.props.client
          .query({
            query: FETCH_WORKING_TIME,
            variables: {
              _id: rowEdit.departmentId,
              date: Moment(rowEdit.appointmentDate).format("DD/MM/YYYY")
            }
          })
          .then(result => {        
            this.setState({ workingTimes: result.data.department.data.workingTimes });
          });
        this.setState({rowOrigin:row,rowEdit:rowEdit,showEditModal:true});
    }
    onEditState=(row,approveModal)=>{
        const rowEdit = Object.assign({},row);//clone object  
        this.setState({rowOrigin:row,rowEdit:rowEdit,showEditStateModal:true,approveModal});
    }
    onDelete=(row,mutation,isWaiting)=>{
        confirm(`Bạn có chắc chắn muốn xóa lịch khám với khách hàng '${row.fullName}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }

    onDepartmentSelected=(e)=>{
        const {rowEdit}=this.state;
        rowEdit.departmentId=e.target.value;
        rowEdit.appointmentDate=new Date();
        this.setState({rowEdit});
        if(e.target.value!==''){
            this.props.client.query({
                query:FETCH_WORKING_TIME,
                variables:{_id:e.target.value,date:Moment(rowEdit.appointmentDate).format("DD/MM/YYYY")}
            }).then(result=>{
                this.setState({workingTimes:result.data.department.data.workingTimes})
            });
        }
      }
      onDateChange = e => {
          const {rowEdit}=this.state;
          rowEdit.appointmentDate=e.target.value;
        this.setState({ rowEdit });
        this.props.client
          .query({
            query: FETCH_WORKING_TIME,
            variables: {
              _id: this.state.rowEdit.departmentId,
              date: Moment(e.target.value).format("DD/MM/YYYY")
            }
          })
          .then(result => {        
            this.setState({ workingTimes: result.data.department.data.workingTimes });
          });
      };




    render=()=>{
        const {tooltipOpen,approveModal,rowEdit,filterType,rowOrigin,showEditModal,showEditStateModal,departments,workingTimes}=this.state;
        return(
            <Page title="Dashboard"
                breadcrumbs={[{ name: 'Dashboard', active: true }]}            
                className="DashboardPage"
                rightComp={(
                    <div style={{marginTop:"1rem"}}>
                        <Button className="mr-1 text-white" onClick={e=>{this.props.history.push("/appointment-report")}}><MdTimelapse/> Báo cáo đặt hẹn</Button>
                        <Button color="grey" className="mr-1" onClick={e=>{document.location.href="/"}}><MdRefresh/> Làm mới</Button>
                        <Button color="green" className="mr-1 text-white" onClick={e=>{this.props.history.push("/appointment-create")}}><MdCallToAction/> Đặt hẹn khám</Button>
                    </div>
                )}> 
               
                <CardBody>
                    
                    <Tabs>
                        <TabList>
                            <Tab>Khách hàng trong ngày ({this.state.appointmentInDay.length})</Tab>
                            <Tab>Khách hẹn chờ duyệt ({this.state.appointmentWeb.length})</Tab>
                        </TabList>
                        <TabPanel className="mt-1">
                            <Row className="mt-1" style={{fontWeight:"bold"}}>                                
                                <Col md={4} sm={6}>
                                    <Label>
                                        Tìm theo tên hoặc số điện thoại:
                                    </Label>
                                    <SearchInput bsSize="sm" value={this.state.searchKey} 
                                        onChange={e=>{this.setState({searchKey:e.target.value})}} 
                                        onKeyPress={e => {
                                            if (e.charCode === 13) this.fetchAppointmentToday();
                                        }}
                                        placeholder="Tìm theo tên hoặc số điện thoại" style={{width:"100%"}}/>
                                    
                                </Col>
                                <Col md={6}>
                                    <FormGroup check inline >                                        
                                            <Label check>
                                                <Input type="radio" checked={filterType==="appointmentdate"} onChange={e=>this.setState({filterType:"appointmentdate"})} name="filterType"/> Ngày khám
                                            </Label>
                                            <Label check style={{marginLeft:20}}>
                                                <Input type="radio" checked={filterType==="created"} name="filterType" onChange={e=>this.setState({filterType:"created"})}/> Ngày đặt
                                            </Label> 
                                            <Label check style={{marginLeft:20}}>
                                                <Input type="radio" checked={filterType==="expired"} name="filterType" onChange={e=>this.setState({filterType:"expired"})}/> Đặt khám quá hạn
                                            </Label>                                        
                                    </FormGroup>
                                    {
                                        filterType!=="expired"?(
                                            <Form inline>
                                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">    
                                                    <Input style={{minWidth:300}} value={Moment(this.state.searchDate).format("YYYY-MM-DD")} type="date" bsSize="sm" onChange={e => this.setState({ searchDate: e.target.value })} />                          
                                                </FormGroup>                                        
                                                <Button color="green" onClick={e=>this.fetchAppointmentToday()}>Tìm kiếm</Button>
                                            </Form>
                                        ):<Form inline>
                                                                                
                                            <Button color="green" onClick={e=>this.fetchAppointmentToday()}>Tìm kiếm</Button>
                                        </Form>
                                            
                                    }
                                    
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col lg={3} md={6} sm={6} xs={12}>
                                    <CounterWidget
                                        inverse={false}
                                        title="TỔNG ĐẶT KHÁM"
                                        number={this.state.appointmentInDay.length}
                                        badgeColor="success"
                                        badgeSize="display-4"
                                        textLeft={60}
                                        style={{lineHeight:"24px",height:"64px"}}
                                    />
                                </Col>
                                <Col lg={3} md={6} sm={6} xs={12}>
                                    <CounterWidget
                                        inverse={false}
                                        title="Đặt khám qua điện thoại"
                                        number={this.state.appointmentInDay.filter((ap)=>{
                                            return ap.chanel==="call";
                                        }).length}
                                        textLeft={60}
                                        badgeSize="display-4"
                                        style={{lineHeight:"24px",height:"64px"}}
                                    />
                                </Col>
                                <Col lg={3} md={6} sm={6} xs={12}>
                                    <CounterWidget
                                        inverse={false}
                                        title="Đặt khám qua web/ứng dụng"
                                        number={this.state.appointmentInDay.filter((ap)=>{
                                            return ap.chanel==="web"||ap.chanel==="app";
                                        }).length}
                                        textLeft={60}
                                        badgeSize="display-4"
                                        style={{lineHeight:"24px",height:"64px"}}
                                    />
                                </Col>
                                <Col lg={3} md={6} sm={6} xs={12}>
                                    <CounterWidget
                                        inverse={false}
                                        title="Đặt khám qua CRM"
                                        number={this.state.appointmentInDay.filter((ap)=>{
                                            return ap.chanel==="crm";
                                        }).length}
                                        textLeft={60}
                                        badgeSize="display-4"
                                        style={{lineHeight:"24px",height:"64px"}}
                                    />
                                </Col>
                            </Row>
                            <Card className="mt-4">
                                <CardHeader className="bg-green text-white">Danh sách đặt khám trong ngày</CardHeader>
                                <CardBody>                                    
                                    <ReactTable
                                        style={{marginTop:7}}
                                        noDataText="Không có dữ liệu"
                                        columns={[
                                            {
                                                Header:"#",
                                                minWidth:30,
                                                style:{textAlign:"center"},
                                                Cell:row=><span>{(row.index+1)}</span>
                                            },
                                            {
                                                Header:"TÊN KHÁCH HÀNG",
                                                accessor:"customer.fullName",
                                                style:{textAlign:"center"}
                                            },
                                            {
                                                Header:"Đ.THOẠI",
                                                accessor:"customer.phoneNumber",
                                                style:{textAlign:"center"}
                                            },
                                            {
                                                Header:"NGÀY SINH",
                                                accessor:"customer.birthDay",
                                                width:120,
                                                Cell:row=><div style={{textAlign:"center"}}>{Moment(row.value).format("DD/MM/YYYY")}</div>
                                            },
                                            {
                                                Header:"NGÀY GIỜ KHÁM",
                                                style:{textAlign:"center"},
                                                Cell:row=>(
                                                    <div>
                                                        {Moment(row.original.appointmentDate).format("DD/MM/YYYY")}{"  "}
                                                        <Badge className="text-white">{row.original.appointmentTime}</Badge>
                                                    </div>
                                                )
                                            },
                                            {
                                                Header:"KHOA/PHÒNG BAN",
                                                style:{textAlign:"center"},
                                                accessor:"department.name"
                                            },
                                            {
                                                Header:"ĐẶT QUA",
                                                accessor:"chanel",
                                                Cell:row=><span>
                                                    {row.value==="call"?"TỔNG ĐÀI":row.value==="app"?"ỨNG DỤNG":row.value==="crm"?"CRM":"WEBSITE"}
                                                </span>,
                                                style:{textAlign:"center",textTransform:"uppercase"},
                                                maxWidth:90
                                            },
                                            {
                                                Header:"LÝ DO KHÁM",
                                                accessor:"reason"
                                            },
                                            {
                                                Header:"GHI CHÚ",
                                                accessor:"description"
                                            },
                                            {
                                                Header:"Trạng thái",
                                                accessor:"state",
                                                maxWidth:80,
                                                style:{textAlign:"center"},
                                                Cell:row=>(
                                                    <div style={{fontSize:16}} onClick={e=>{this.onEditState(row.original,false)}}>
                                                        {row.value==="approve"? 
                                                        <MdCheckBoxOutlineBlank id={"state"+row.original._id} className="text-green"/>:
                                                        row.value==="served"?<MdCheckCircle  id={"state"+row.original._id} className="text-green"/>:
                                                        row.value==="cancel"?<MdClose className="text-danger" id={"state"+row.original._id}/>:
                                                       
                                                        <MdTimelapse className="text-danger" id={"state"+row.original._id}/>
                                                     
                                                       
                                                       } 
                                                       <Tooltip  placement="left" isOpen={tooltipOpen["state"+row.original._id]} target={"state"+row.original._id} autohide={true} toggle={e=>{tooltipOpen["state"+row.original._id]=!tooltipOpen["state"+row.original._id]; this.setState({tooltipOpen})}}>
                                                        {
                                                            row.value==="waiting"?"Chờ duyệt":
                                                            row.value==="approve"?"Chờ khám":
                                                            row.value==="cancel"?"Hủy khám":
                                                            row.value==="served"?"Đã khám":""
                                                        }
                                                        </Tooltip>
                                                    </div>
                                                ),
                                                Filter:({filter,onChange})=>
                                                    <Input
                                                        type="select" onChange={e=>onChange(e.target.value)} bsSize="sm"
                                                        value={filter?filter.value:""}
                                                    >
                                                        <option value="">Tất cả</option>                                            
                                                        <option value="waiting">Chờ xác nhận</option>
                                                        <option value="cancel">Đã hủy</option>
                                                        <option value="approve">Chờ khám</option>
                                                        <option value="complete">Đã khám</option>
                                                    </Input>
                                            },  
                                            {
                                                Header:"TÁC VỤ",
                                                style:{textAlign:"center"},
                                                width:100,
                                                Cell:row=>(
                                                    <ButtonGroup style={{fontSize:18}}>
                                                        <MdEdit id={"edit"+row.original._id} className="text-green m-1" 
                                                            onClick={e=>{this.onEdit(row.original)}}/>                                                        
                                                        <Tooltip placement="left" isOpen={tooltipOpen['edit'+row.original._id]} autohide={false} target={"edit"+row.original._id} toggle={e=>{tooltipOpen['edit'+row.original._id]=!tooltipOpen['edit'+row.original._id]; this.setState({tooltipOpen})}}>
                                                            Sửa đặt khám
                                                        </Tooltip>
                                                        <Mutation mutation={DELETE_APPOINTMENT}
                                                            variables={{_id:row.original._id}}
                                                            onCompleted={(result)=>{
                                                                console.log("delete result:"+JSON.stringify(result));                                                                
                                                                this.fetchAppointmentToday();
                                                                
                                                            }}
                                                        >
                                                            {mutation=>
                                                                <Fragment>
                                                                    <MdDelete onClick={()=>this.onDelete(row.original,mutation,false)} id={"delete"+row.original._id} className="text-warning m-1"/>
                                                                    <Tooltip placement="left" isOpen={tooltipOpen['delete'+row.original._id]} autohide={false} target={"delete"+row.original._id} toggle={e=>{tooltipOpen['delete'+row.original._id]=!tooltipOpen['delete'+row.original._id]; this.setState({tooltipOpen})}}>
                                                                        Xóa đặt khám
                                                                    </Tooltip>
                                                                </Fragment>
                                                            }
                                                        </Mutation>
                                                        <Mutation
                                                            mutation={CALLOUT}
                                                            variables={{phoneNumber:row.original.phoneNumber}}
                                                        >
                                                            {mutation=><Fragment>
                                                                <MdCall onClick={e=>{
                                                                    console.log("click call");
                                                                    confirm("Cuộc gọi tới số "+row.original.phoneNumber+" đang được thực hiện, vui lòng chờ trong ít giây",{hideCancel:true})
                                                                    mutation();
                                                                }} id={"call"+row.original._id} className="text-green m-1"/>
                                                                <Tooltip placement="left" isOpen={tooltipOpen['call'+row.original._id]} autohide={false} target={"call"+row.original._id} toggle={e=>{tooltipOpen['call'+row.original._id]=!tooltipOpen['call'+row.original._id]; this.setState({tooltipOpen})}}>
                                                                    Gọi tới số {row.original.phoneNumber}
                                                                </Tooltip>

                                                            </Fragment>}
                                                        </Mutation>
                                                       
                                                    </ButtonGroup>
                                                )
                                            }
                                        ]}
                                        manual
                                        pages={1}
                                        showPagination={false}
                                        pageSize={this.state.appointmentInDay.length}
                                        onFetchData={this.fetchAppointmentToday}
                                        data={this.state.appointmentInDay}
                                        loading={this.state.loadingInDay}
                                        className="-striped -highlight"                                        
                                    >

                                    </ReactTable>                                          
                                    
                                </CardBody>
                            </Card>
                        </TabPanel>
                        <TabPanel>
                            <Card>
                                <CardHeader style={{background:"bg-green"}}>DANH SÁCH ĐẶT KHÁM CHỜ DUYỆT</CardHeader>
                                <CardBody>
                                    <SearchInput 
                                        bsSize="sm"
                                        onChange={e=>{this.setState({searchKey:e.target.value})}} 
                                        onKeyPress={e => {                                            
                                            if (e.charCode === 13) this.fetchAppointmentWaiting();
                                        }}
                                        placeholder="Tìm theo tên khách hàng hoặc số điện thoại" style={{width:"100%"}}/>
                                    
                                    <ReactTable
                                        style={{marginTop:7}}   
                                        noDataText="Không có dữ liệu"
                                        columns={[
                                            {
                                                Header:"#",
                                                minWidth:30,
                                                style:{textAlign:"center"},
                                                Cell:row=><span>{(row.index+1)}</span>
                                            },
                                            {
                                                Header:"TÊN KHÁCH HÀNG",
                                                accessor:"customer.fullName",
                                                style:{textAlign:"center"}
                                            },
                                            {
                                                Header:"Đ.THOẠI",
                                                accessor:"customer.phoneNumber",
                                                style:{textAlign:"center"}
                                            },
                                            {
                                                Header:"NGÀY SINH",
                                                accessor:"customer.birthDay",
                                                width:120,
                                                Cell:row=><div style={{textAlign:"center"}}>{Moment(row.value).format("DD/MM/YYYY")}</div>
                                            },
                                            {
                                                Header:"NGÀY GIỜ KHÁM",
                                                style:{textAlign:"center"},
                                                Cell:row=>(
                                                    <div>
                                                        {Moment(row.original.appointmentDate).format("DD/MM/YYYY")}{"  "}
                                                        <Badge className="text-white">{row.original.appointmentTime}</Badge>
                                                    </div>
                                                )
                                            },
                                            {
                                                Header:"KHOA/PHÒNG BAN",
                                                style:{textAlign:"center"},
                                                accessor:"department.name"
                                            },
                                            {
                                                Header:"ĐẶT QUA",
                                                accessor:"chanel",
                                                Cell:row=><span>
                                                    {row.value==="call"?"TỔNG ĐÀI":row.value==="app"?"ỨNG DỤNG":row.value==="crm"?"CRM":"WEBSITE"}
                                                </span>,
                                                style:{textAlign:"center",textTransform:"uppercase"},
                                                maxWidth:90
                                            },
                                            {
                                                Header:"NỘI DUNG KHÁM",
                                                accessor:"reason"
                                            },                                            
                                            {
                                                Header:"TÁC VỤ",
                                                style:{textAlign:"center"},
                                                width:100,
                                                Cell:row=>(
                                                    <ButtonGroup style={{fontSize:18}}>
                                                        <MdCheckCircle id={'edit'+row.original._id} className="text-green m-1" onClick={e=>{this.onEditState(row.original,true)}} />
                                                        <Tooltip placement="left" isOpen={tooltipOpen['edit'+row.original._id]} autohide={false} target={"edit"+row.original._id} toggle={e=>{tooltipOpen['edit'+row.original._id]=!tooltipOpen['edit'+row.original._id]; this.setState({tooltipOpen})}}>
                                                            Xác nhận và gửi tin nhắn
                                                        </Tooltip>
                                                        <Mutation mutation={DELETE_APPOINTMENT}
                                                            variables={{_id:row.original._id}}
                                                            onCompleted={(result)=>{
                                                                this.fetchAppointmentWaiting();                                                                
                                                            }}
                                                        >
                                                            {mutation=>
                                                                <Fragment>
                                                                    <MdDelete onClick={()=>this.onDelete(row.original,mutation,true)} id={"delete"+row.original._id} className="text-warning m-1"/>
                                                                    <Tooltip placement="left" isOpen={tooltipOpen['delete'+row.original._id]} autohide={false} target={"delete"+row.original._id} toggle={e=>{tooltipOpen['delete'+row.original._id]=!tooltipOpen['delete'+row.original._id]; this.setState({tooltipOpen})}}>
                                                                        Xóa đặt khám
                                                                    </Tooltip>
                                                                </Fragment>
                                                            }
                                                        </Mutation>
                                                        <Mutation
                                                            mutation={CALLOUT}
                                                            variables={{phoneNumber:row.original.phoneNumber}}
                                                        >
                                                            {mutation=><Fragment>
                                                                <MdCall onClick={e=>{
                                                                    console.log("click call");
                                                                    confirm("Cuộc gọi tới số "+row.original.phoneNumber+" đang được thực hiện, vui lòng chờ trong ít giây",{hideCancel:true})
                                                                    mutation();
                                                                }} id={"call"+row.original._id} className="text-green m-1"/>
                                                                <Tooltip placement="left" isOpen={tooltipOpen['call'+row.original._id]} autohide={false} target={"call"+row.original._id} toggle={e=>{tooltipOpen['call'+row.original._id]=!tooltipOpen['call'+row.original._id]; this.setState({tooltipOpen})}}>
                                                                    Gọi tới số {row.original.phoneNumber}
                                                                </Tooltip>

                                                            </Fragment>}
                                                        </Mutation>
                                                    </ButtonGroup>
                                                )
                                            }
                                        ]}
                                        manual
                                        pages={1}
                                        showPagination={false}
                                        pageSize={this.state.appointmentWeb.length}
                                        onFetchData={this.fetchAppointmentWaiting}
                                        data={this.state.appointmentWeb}
                                        loading={this.state.loadingWeb}
                                        className="-striped -highlight"
                                        
                                    >

                                    </ReactTable>
                                           
                                    
                                </CardBody>
                        </Card>
                        </TabPanel>
                        
                    </Tabs>
                </CardBody>

                <Modal
                    isOpen={showEditModal}
                    toggle={this.toggleEditModal}
                    className={"modal-lg"}>                    
                    <ModalBody>
                    <Form>
                        <Card style={{marginBottom:"10px"}}>
                            <CardHeader>
                                <span>Thông tin bệnh nhân</span>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="3" sm="6" xs="12">
                                    <FormGroup>
                                        <Label >Họ tên</Label>
                                        <Input bsSize="sm" value={rowEdit.customer?rowEdit.customer.fullName:''} disabled={true}/>
                                    </FormGroup> 
                                    </Col>
                                    <Col md="3" sm="6" xs="12">
                                    <FormGroup>
                                        <Label>Số điện thoại</Label>
                                        <Input bsSize="sm" value={rowEdit.customer?rowEdit.customer.phoneNumber:''} disabled={true}/>
                                    </FormGroup> 
                                    </Col>
                                    <Col md="3" sm="6" xs="12">
                                    <FormGroup>
                                        <Label>Ngày đặt hẹn</Label>
                                        <Input bsSize="sm" disabled={true} value={Moment(rowEdit.appointmentDate).format("DD/MM/YYYY")}/>
                                    </FormGroup> 
                                    </Col>
                                    <Col md="3" sm="6" xs="12">
                                    <FormGroup>
                                        <Label >Giờ khám</Label>
                                        <Input disabled={true} bsSize="sm" value={rowEdit.appointmentTime}/>
                                    </FormGroup>  
                                    </Col>
                                </Row>   
                                <Row>
                                    <Col md="6" sm="6" xs="12">
                                        <FormGroup>
                                            <Label> Khoa khám</Label>
                                            <Input bsSize="sm" disabled={true} value={rowEdit.department.name} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="6" xs="12">
                                        <FormGroup>
                                            <Label> Lý do đặt khám</Label>
                                            <Input bsSize="sm" disabled={true} value={rowEdit.reason} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12" sm="12" xs="12">
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" checked={rowEdit.changed} onChange={e=>{rowEdit.changed=e.target.checked;this.setState({rowEdit})}}/>Đổi thông tin đặt hẹn
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr/>
                                {
                                    rowEdit.changed===true?(<div>
                                        <Row>
                                            <Col md="6" sm="12" xs="12">
                                            <FormGroup>
                                                <Label>
                                                    Chọn khoa/phòng ban
                                                </Label>
                                                <Input
                                                    className="mb-2"
                                                    type="select"
                                                    bsSize="sm"
                                                    value={rowEdit.departmentId}
                                                    onChange={this.onDepartmentSelected}
                                                    >
                                                    
                                                        <Fragment>
                                                            <option>Chọn phòng/ban</option>
                                                            {departments.map((department, index) => (
                                                                <option key={index} value={department._id}>
                                                                    {department.name}
                                                                </option>
                                                            ))}
                                                        </Fragment>
                                                       
                                                </Input>
                                            </FormGroup>                                             
                                            </Col>
                                            <Col md="6" sm="12" xs="12">
                                                <FormGroup>
                                                    <Label>Ngày hẹn</Label>
                                                    <Input
                                                    bsSize="sm"
                                                    type="date"
                                                    value={Moment(rowEdit.appointmentDate).format("YYYY-MM-DD")}
                                                    onChange={this.onDateChange}
                                                    />
                                                </FormGroup>                                          
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12" sm="12" xs="12">                                            
                                                <FormGroup>
                                                    <Label>Chọn khung giờ khám</Label>
                                                    
                                                        <div>
                                                            {workingTimes.map((workTime, index) => (
                                                                <AppointmentTime
                                                                key={index}
                                                                name="workTime"
                                                                checked={workTime.timeRange===rowEdit.appointmentTime}
                                                                title={workTime.timeRange}
                                                                process={workTime.remainProcess}
                                                                onChange={e => {
                                                                    rowEdit.appointmentTime=workTime.timeRange ;
                                                                    this.setState({ rowEdit});
                                                                }}
                                                                />
                                                            ))}
                                                        </div>
                                                            
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>):null
                                }
                                <Row>
                                    <Col md="12" sm="12" xs="12">     
                                        <FormGroup>
                                            <Label> Ghi chú lý do thay đổi</Label>
                                            <Input value={rowEdit.description} onChange={e=>{rowEdit.description=e.target.value; this.setState({rowEdit})}}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>                            
                        </Card>                        
                    </Form>    
                    </ModalBody>
                    <ModalFooter>
                        <Mutation 
                            mutation={UPDATE_APPOINTMENT}
                            variables={{appointment:{changed:rowEdit.changed,description:rowEdit.description,appointmentDate:rowEdit.appointmentDate,appointmentTime:rowEdit.appointmentTime,departmentId:rowEdit.departmentId,_id:rowEdit._id,chanel:rowEdit.chanel}}}
                            onCompleted={(result)=>{      
                                if(result.updateAppointment.code===0){
                                    for(var k in rowOrigin){
                                        rowOrigin[k]=rowEdit[k];
                                    }                  
                                    this.toggleEditModal()();
                                }
                            }}
                        >
                        {mutation=>
                            <Button color="primary" onClick={mutation}>
                                Save
                            </Button>
                        }
                            
                        </Mutation>
                        {' '}
                        <Button color="default" onClick={this.toggleEditModal()}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={showEditStateModal}
                    toggle={this.toggleEditStateModal}
                    className={"modal-lg"}>                    
                    <ModalBody>
                    <Form>
                        <Card style={{marginBottom:"10px"}}>
                            <CardHeader>
                                <span>Thông tin bệnh nhân</span>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="3" sm="6" xs="12">
                                    <FormGroup>
                                        <Label >Họ tên</Label>
                                        <Input bsSize="sm" value={rowEdit.customer?rowEdit.customer.fullName:''} disabled={true}/>
                                    </FormGroup> 
                                    </Col>
                                    <Col md="3" sm="6" xs="12">
                                    <FormGroup>
                                        <Label>Số điện thoại</Label>
                                        <Input bsSize="sm" value={rowEdit.customer?rowEdit.customer.phoneNumber:''} disabled={true}/>
                                    </FormGroup> 
                                    </Col>
                                    <Col md="3" sm="6" xs="12">
                                    <FormGroup>
                                        <Label>Ngày đặt hẹn</Label>
                                        <Input bsSize="sm" disabled={true} value={Moment(rowEdit.appointmentDate).format("DD/MM/YYYY")}/>
                                    </FormGroup> 
                                    </Col>
                                    <Col md="3" sm="6" xs="12">
                                    <FormGroup>
                                        <Label >Giờ khám</Label>
                                        <Input disabled={true} bsSize="sm" value={rowEdit.appointmentTime}/>
                                    </FormGroup>  
                                    </Col>
                                </Row>   
                                <Row>
                                    <Col md="6" sm="6" xs="12">
                                        <FormGroup>
                                            <Label> Khoa khám</Label>
                                            <Input bsSize="sm" disabled={true} value={rowEdit.department.name} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="6" xs="12">
                                        <FormGroup>
                                            <Label> Lý do đặt khám</Label>
                                            <Input bsSize="sm" disabled={true} value={rowEdit.reason} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12" sm="12" xs="12">
                                        <FormGroup check inline >  
                                            {
                                                this.state.filterType!=="expired"||approveModal===true?
                                                    (
                                                        <Label check>
                                                            <Input type="radio" checked={rowEdit.state==="approve"} onChange={e=>{rowEdit.state="approve"; this.setState({rowEdit})}}/> Chờ khám
                                                        </Label>  
                                                    ):null

                                            } 
                                            {
                                                approveModal!==true?(
                                                    <Fragment>
                                                        <Label check style={{marginLeft:20}}>
                                                            <Input type="radio" checked={rowEdit.state==="served"} onChange={e=>{rowEdit.state="served"; this.setState({rowEdit})}}/> Đã khám
                                                        </Label>
                                                        <Label check style={{marginLeft:20}}>
                                                            <Input type="radio" checked={rowEdit.state==="cancel"} onChange={e=>{rowEdit.state="cancel"; this.setState({rowEdit})}}/> Hủy khám
                                                        </Label>
                                                    </Fragment>
                                                   
                                                ): <Label check style={{marginLeft:20}}>
                                                    <Input type="radio" checked={rowEdit.state==="cancel"} onChange={e=>{rowEdit.state="cancel"; this.setState({rowEdit})}}/> Hủy khám
                                                </Label>
                                            }                                                                               
                                                                                   
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>                            
                        </Card>                        
                    </Form>    
                    </ModalBody>
                    <ModalFooter>
                        <Mutation 
                            mutation={UPDATE_APPOINTMENT_STATE}
                            variables={{_id:rowEdit._id,state:rowEdit.state}}
                            onCompleted={(result)=>{    
                                if(result.updateAppointmentState.code===0){       
                                    rowOrigin.state=rowEdit.state; 
                                    if(approveModal===true)
                                        this.fetchAppointmentWaiting();
                                    this.toggleEditStateModal()();
                                }
                            }}
                        >
                        {mutation=>
                            <Button color="primary" onClick={mutation}>
                                Xác nhận
                            </Button>
                        }
                            
                        </Mutation>
                        {' '}
                        <Button color="default" onClick={this.toggleEditStateModal()}>
                            Hủy
                        </Button>
                    </ModalFooter>
                </Modal>
            </Page>
        )
    }
}

export default withApollo(DashboardPage);