import React from "react";
import { Button, Form, FormGroup, Input, Label,Row,Col,Card } from "reactstrap";
import logo200Image from "assets/img/logo/logo_200.png";
import {AUTH_TOKEN} from 'adminConstants'
import { Mutation } from "react-apollo";
import gql from "graphql-tag";

const LOGIN_MUTATION = gql`
  mutation Mutation($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      code
      message
      data{
        accessToken
        _id
        fullName
        claims {
          type
          value
        }
      }
    }
  }
`;

class LoginPage extends React.Component {
  state = {
    username: "",
    password: "",
    remember: true,
    message:""
  };
  handleSubmitResult = (data)=>{
    if(data.login.code!==0){
      this.setState({message:data.login.message});      
    }else{
      localStorage.setItem(AUTH_TOKEN, JSON.stringify(data.login.data));
      this.props.history.push("/");
    }
  }
  onLogoClick = () => {};

  render() {
    const { username, password, remember,message } = this.state;
    return (
      <Row
        style={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <Form onSubmit={this.handleSubmitResult}>
              <div className="text-center pb-4">
                <img
                  src={logo200Image}
                  className="rounded mt-3"
                  style={{ width: 60, height: 60, cursor: "pointer" }}
                  alt="logo"
                  onClick={this.onLogoClick}
                />
                
              </div>
              <div className="text-center" style={{height:10}}>
                <span style={{color:"red"}}>{message}</span>
              </div>
              <FormGroup>
                <Label>UserName</Label>
                <Input
                  type="text"
                  placeholder="username"
                  value={username}
                  onChange={e => this.setState({ username: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  type="password"
                  placeholder="Mật khẩu"
                  value={password}
                  onChange={e => this.setState({ password: e.target.value })}
                />
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value={remember}
                    onChange={e =>
                      this.setState({ remember: e.target.checked })
                    }
                  />{" "}
                  {this.isSignup ? "Agree the terms and policy" : "Remember me"}
                </Label>
              </FormGroup>
              <hr />
              <Mutation
                mutation={LOGIN_MUTATION}
                variables={{username, password }}
                onCompleted={data => this.handleSubmitResult(data)}
              >
                {mutation => (
                  <Button
                    size="lg"
                    className="bg-gradient-theme-left border-0"
                    block
                    onClick={mutation}
                  >
                    {" "}
                    Login
                  </Button>
                )}
              </Mutation>
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}
export default LoginPage;
