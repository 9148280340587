import Page from 'Admin/Components/Page';
import React,{Fragment} from 'react';
import { Button,Input,Row,Col,Label,Form ,FormGroup} from 'reactstrap';
import { withApollo,Mutation,Query } from 'react-apollo'
import gql from 'graphql-tag';
import authCheck from 'utils/authCheck';
export const QUERY_DETAIL = gql`
    query AdminQuery($_id: String){
        announcement(_id:$_id){
            code
            message
            data{
                _id
                name
                company{
                name
                domain
                }
                description
                fileRecord
            }
        }
    }
`
export const MUTATION_CREATE= gql`
    mutation AdminMutation($announcement: AnnouncementInputType!){
        createAnnouncement(announcement:$announcement){
            code
            message
            data{name}
        }
    }
`

export const MUTATION_EDIT = gql`
mutation AdminMutation($announcement: AnnouncementInputType!){
    updateAnnouncement(announcement:$announcement){
       code
       message
       data{name}
    }
}
`
export const QUERY_COMPANY=gql`
{
    companies{
        code
        message
        data{
            name
            domain      
        }      
    }
  }
`;

class AnnouncementEditPage extends React.Component{
    constructor(){
        super();
        this.state={
            name:"",
            description:"",
            company:{},
            file:{}
        };
    }
    componentDidMount(){
        const _id = this.props.match.params._id;
        if(_id&&_id.length>0){
            try{
                this.props.client.query(
                    {
                        query:QUERY_DETAIL,
                        variables:{_id:_id}
                    }
                ).then(result=>{
                    authCheck(result);
                    var announcement = result.data.announcement.data;
                    if(announcement!==null){
                        this.setState(announcement);
                    }
                });
            }catch(err){
                authCheck(err);
            }
        }
    }

    onCancel=()=>{
        this.props.history.push("/announcement");
    }

    render=()=>{
        const{_id,name,description,company,fileRecord,file}=this.state;
        
        return(
            <Page title="Chi tiết Announcement"
            breadcrumbs={[{ name: 'Chi tiết Announcement', active: true }]}            
            className="announcement"> 
                <Row>
                    <Col md={12}>
                        <Mutation
                            mutation= {(_id===undefined||_id.length===0)?MUTATION_CREATE:MUTATION_EDIT}
                            variables={{announcement:{_id,name,description,company:company.domain,file}}}
                            onCompleted={(result)=>{this.onCancel()}}
                        >
                            {mutation=><Button color="primary" onClick={mutation}>Cập nhật thông tin</Button>}
                        </Mutation>{" "}<Button color="link" onClick={this.onCancel}>Hủy</Button>
                    </Col>                
                </Row>                
                <Row>
                    <Col md={12}>
                        <Form className="m-3">
                            <FormGroup row>
                                <Label md={2} sm={4} xs={6} className="text-right">Tên:</Label>
                                <Col md={10} sm={8} xs={6}>
                                    <Input type="text" value={name} onChange={e=>{this.setState({name:e.target.value})}} bsSize="sm"/>
                                </Col>
                            </FormGroup> 
                            <FormGroup row>
                                <Label md={2} sm={4} xs={6} className="text-right">FileUpload(Wav file):</Label>
                                <Col md={10} sm={8} xs={6}>
                                    
                                    <Input type="file" required onChange={({target:{
                                        validity,files:[file]
                                    }})=>{
                                        
                                        this.setState({file})
                                        
                                    }} bsSize="sm"/>
                                    
                                    <Input className="mt-2" value={fileRecord} bsSize="sm" disabled={true}/>
                                </Col>
                            </FormGroup>  
                            <FormGroup row>
                                    <Label md={2} sm={4} xs={6} className="text-right">Công ty:</Label>
                                    <Col md={10} sm={8} xs={6}>
                                        <Input type="select" value={company.domain} onChange={e=>{ this.setState({company:{domain:e.target.value}})}}>                        
                                    
                                            <Query query={QUERY_COMPANY}>
                                            {
                                                ({loading,error,data})=>{
                                                    if (error){
                                                        console.log(error);
                                                        //authCheck(error);                                                    
                                                    }
                                                    if(!data.companies)
                                                        return "";
                                                    return(
                                                        <Fragment>
                                                            <option value="">Chọn Công Ty</option>
                                                            {
                                                                data.companies.data.map((com,index)=>(
                                                                    <option key={index} value={com.domain}>{com.name}</option>
                                                                ))
                                                            }
                                                        </Fragment>
                                                    )
                                                }
                                            }
                                            </Query>
                                        </Input>   
                                    </Col>
                                </FormGroup>                       
                            <FormGroup row>
                                <Label md={2} sm={4} xs={6} className="text-right">Mô tả:</Label>
                                <Col md={10} sm={8} xs={6}>
                                    <Input type="textarea" value={description} bsSize="sm" onChange={e=>{this.setState({description:e.target.value})}}/>
                                </Col>
                            </FormGroup>
                        </Form>  
                    </Col>              
                </Row>
            </Page>
        )
    }
}
export default withApollo(AnnouncementEditPage);