import Page from "Admin/Components/Page";
import React, { Fragment } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Label,
  Form,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  FormGroup
} from "reactstrap";
import { withApollo } from "react-apollo";
import { MdDelete, MdAddCircle, MdEdit } from "react-icons/lib/md";
import gql from "graphql-tag";
import { Mutation, Query } from "react-apollo";
import ReactTable from "react-table";
import Autosuggest from "react-autosuggest";
import authCheck from "utils/authCheck";

export const QUERY_COMPANY = gql`
  {
    companies {
      message
      code
      data {
        name
        domain
      }
    }
  }
`;
export const QUERY_DETAIL = gql`
  query AdminQuery($_id: String!) {
    sipAccount(_id: $_id) {
      message
      code
      data{
        description
        userName
        displayName
        _id
        password
        tech
        shortExten
        templates {
          _id
          name
          type
        }
        details {
          keyword
          data
          flags
        }
        company {
          name
          _id
          domain
          tel
          website
          address
        }
      }
    }
  }
`;
export const QUERY_Template = gql`
  query AdminQuery(
    $filtered: [FilteredInput]
    $sorted: [SortedInput]
    $page: Int
    $pageSize: Int
  ) {
    templates(
      page: $page
      pageSize: $pageSize
      filtered: $filtered
      sorted: $sorted
    ) {
      data {
        _id
        name
        type
        description
        flags
      }
    }
  }
`;
export const CREATE_QUERY = gql`
  mutation AdminMutation($sipAccount: SipAccountInput!) {
    createSipAccount(sipAccount: $sipAccount) {
      message
      code
      data{
        description
        displayName
        userName
        _id
        password
        company {
          name
          _id
          domain
          tel
          website
          address
        }
      }
    }
  }
`;
export const UPDATE_QUERY = gql`
  mutation AdminMutation($sipAccount: SipAccountInput!) {
    updateSipAccount(sipAccount: $sipAccount) {
      code
      message
      data{
        description
        displayName
        _id
        password
        company {
          name
          _id
          domain
          tel
          website
          address
        }
      }
    }
  }
`;

const getSuggestionValue = suggestion => suggestion.name;
// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div className="account-select">
    {suggestion.name + " < " + suggestion.type + " >"}
  </div>
);

class SipAccountEditPage extends React.Component {
  state = {
    _id: "",
    userName: "",
    password: "",
    displayName: "",
    tech: "",
    shortExten: "",
    templates: [],
    company: {},
    description: "",
    details: [],
    allTemplates: [],
    templateSuggests: [],
    tmpTemplate: "",
    isEdit: false
  };
  back = () => {
    this.props.history.push("/sip-account");
  };
  componentDidMount() {
    this.props.client
      .query({
        query: QUERY_Template,
        variables: { pageSize: 100, filtered: [{ id: "type", value: "sip" }] }
      })
      .then(result => {
        this.setState({
          allTemplates: result.data.templates.data,
          templateSuggests: result.data.templates.data
        });
      });
    const _id = this.props.match.params._id;
    if (_id) {
      try {
        this.setState({ isEdit: true, _id: _id });
        //fetch detail
        this.props.client
          .query({
            query: QUERY_DETAIL,
            variables: { _id: _id }
          })
          .then(result => {
            authCheck(result.data.sipAccount);
            this.setState(result.data.sipAccount.data);
          });
      } catch (err) {
        authCheck(err);
      }
    }
  }
  onTemplateSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    var { templates } = this.state;
    templates = templates.filter(item => {
      return item.type !== suggestion.type;
    });
    templates.push(suggestion);
    this.setState({
      tmpTemplate: "",
      templates
    });
  };
  onTemplatesClearRequested = () => {
    this.setState({
      templateSuggests: []
    });
  };
  onTemplatesFetchRequested = ({ value }) => {
    this.setState({
      templateSuggests: this.getTemplateSuggestions(value)
    });
  };
  onTemplateSearchChange = (event, { newValue }) => {
    this.setState({
      tmpTemplate: newValue
    });
  };
  getTemplateSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const { allTemplates } = this.state;

    return inputLength === 0
      ? []
      : allTemplates.filter(
          tpl =>
            tpl.name.toLowerCase().indexOf(inputValue) >= 0 ||
            tpl.type.toLowerCase().indexOf(inputValue) >= 0
        );
  };
  render = () => {
    const {
      _id,
      userName,
      company,
      displayName,
      templates,
      password,
      shortExten,
      description,
      details,
      tech,
      editData,
      editFlags,
      editKeyword,
      tmpTemplate,
      isEdit,
      templateSuggests
    } = this.state;

    // Autosuggest will pass through all these props to the input.
    const templateInputProps = {
      placeholder: "Nhập tên template muốn thêm",
      value: tmpTemplate,
      onChange: this.onTemplateSearchChange
    };
    return (
      <Page
        title="Chi tiết Template"
        breadcrumbs={[{ name: "Chi tiết Template", active: true }]}
        className="template"
      >
        <Row>
          <Col md={12}>
            <Mutation
              mutation={isEdit ? UPDATE_QUERY : CREATE_QUERY}
              variables={{
                sipAccount: {
                  _id,
                  userName,
                  company: company.domain,
                  templates: templates.map(item => {
                    return item._id;
                  }),
                  password,
                  description,
                  details,
                  tech,
                  displayName,
                  shortExten
                }
              }}
              onCompleted={result => {
                this.back();
              }}
            >
              {mutation => (
                <Button color="primary" onClick={mutation}>
                  Cập nhật
                </Button>
              )}
            </Mutation>{" "}
            <Button color="link" onClick={this.back}>
              Hủy
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12} sm={12}>
            <Card>
              <CardHeader className="bg-dark text-white">
                Thông tin cơ bản
              </CardHeader>
              <CardBody>
                <Form className="m-3">
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      UserId:
                    </Label>
                    <Col md={9} sm={8}>
                      <Input
                        disabled={isEdit}
                        value={_id}
                        onChange={e => {
                          this.setState({ _id: e.target.value });
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Số nội bộ:
                    </Label>
                    <Col md={9} sm={8}>
                      <Input
                        value={shortExten ? shortExten : ""}
                        onChange={e => {
                          this.setState({ shortExten: e.target.value });
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      UserName:
                    </Label>
                    <Col md={9} sm={8}>
                      <Input
                        value={userName ? userName : ""}
                        onChange={e => {
                          this.setState({ userName: e.target.value });
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Mật khẩu:
                    </Label>
                    <Col md={9} sm={8}>
                      <Input
                        value={password}
                        onChange={e => {
                          this.setState({ password: e.target.value });
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Tên hiển thị:
                    </Label>
                    <Col md={9} sm={8}>
                      <Input
                        value={displayName}
                        onChange={e => {
                          this.setState({ displayName: e.target.value });
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Loại Tài khoản:
                    </Label>
                    <Col md={9} sm={8}>
                      <Input
                        type="select"
                        value={tech}
                        onChange={e => {
                          this.setState({ tech: e.target.value });
                        }}
                      >
                        <option value="pjsip">pjsip</option>
                        <option value="sip">sip</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Chọn template:
                    </Label>
                    <Col md={9} sm={8}>
                      <Autosuggest
                        suggestions={templateSuggests}
                        onSuggestionsFetchRequested={
                          this.onTemplatesFetchRequested
                        }
                        onSuggestionsClearRequested={
                          this.onTemplatesClearRequested
                        }
                        onSuggestionSelected={this.onTemplateSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={templateInputProps}
                      />
                      <Input
                        type="select"
                        name="selectMulti"
                        multiple
                        onDoubleClick={e => {
                          var newTemplates = templates.filter(item => {
                            return item._id !== e.target.value;
                          });
                          this.setState({ templates: newTemplates });
                        }}
                      >
                        {templates.map((tpl, index) => (
                          <option key={tpl._id} value={tpl._id}>
                            {tpl.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Công ty:
                    </Label>
                    <Col md={9} sm={8}>
                      <Input
                        type="select"
                        value={company.domain}
                        onChange={e => {
                          this.setState({
                            company: { domain: e.target.value }
                          });
                        }}
                      >
                        <Query query={QUERY_COMPANY}>
                          {({ loading, error, data }) => {
                            if (error) {
                              console.log(error);
                              //authCheck(error);
                            }
                            if (!data.companies) return "";
                            return (
                              <Fragment>
                                <option value="">Chọn Công Ty</option>
                                {data.companies.data.map((com, index) => (
                                  <option key={index} value={com.domain}>
                                    {com.name}
                                  </option>
                                ))}
                              </Fragment>
                            );
                          }}
                        </Query>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} sm={4} className="text-right">
                      Mô tả:
                    </Label>
                    <Col md={9} sm={8}>
                      <Input
                        type="textarea"
                        value={description}
                        onChange={e => {
                          this.setState({ description: e.target.value });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} xs={12} sm={12}>
            <Card>
              <CardHeader className="bg-dark text-white">
                Cấu hình bổ xung
              </CardHeader>
              <CardBody>
                <Form className="mt-2">
                  <FormGroup row>
                    <Col md={4}>
                      <Input
                        value={editKeyword}
                        onChange={e => {
                          this.setState({ editKeyword: e.target.value });
                        }}
                        bsSize="sm"
                        placeholder="keyword"
                      />
                    </Col>
                    <Col md={4}>
                      <Input
                        value={editData}
                        bsSize="sm"
                        onChange={e => {
                          this.setState({ editData: e.target.value });
                        }}
                        placeholder="value"
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        value={editFlags}
                        bsSize="sm"
                        onChange={e => {
                          this.setState({ editFlags: e.target.value });
                        }}
                        type="number"
                        placeholder="flags"
                      />
                    </Col>
                    <Col md={2}>
                      <Button
                        color="primary"
                        onClick={e => {
                          var newDetails = details.filter(function(
                            item,
                            index,
                            arr
                          ) {
                            return item.keyword !== editKeyword;
                          });
                          newDetails.push({
                            keyword: editKeyword,
                            data: editData,
                            flags: editFlags
                          });
                          this.setState({
                            details: newDetails,
                            editData: "",
                            editFlags: 0,
                            editKeyword: ""
                          });
                        }}
                      >
                        <MdAddCircle /> Thêm
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
                <ReactTable
                  noDataText="Data is Empty"
                  data={details}
                  showPagination={false}
                  pageSize={details ? details.length : 1}
                  columns={[
                    {
                      Header: "keyword",
                      accessor: "keyword"
                    },
                    {
                      Header: "data",
                      accessor: "data"
                    },
                    {
                      Header: "flags",
                      accessor: "flags",
                      width: 80
                    },
                    {
                      Header: "",
                      width: 90,
                      Cell: row => (
                        <ButtonGroup>
                          <Button
                            onClick={e => {
                              this.setState({
                                editData: row.original.data,
                                editKeyword: row.original.keyword,
                                editFlags: row.original.flags
                              });
                            }}
                          >
                            <MdEdit />
                          </Button>{" "}
                          <Button
                            onClick={e => {
                              var newDetails = details.filter(function(
                                item,
                                index,
                                arr
                              ) {
                                return (
                                  item.keyword !== row.original.keyword &&
                                  item.data !== row.original.data &&
                                  item.flags !== row.original.flags
                                );
                              });
                              this.setState({ details: newDetails });
                            }}
                          >
                            <MdDelete />
                          </Button>
                        </ButtonGroup>
                      )
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  };
}

export default withApollo(SipAccountEditPage);
