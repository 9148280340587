import Page from "Admin/Components/Page";
import React, { Fragment } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Label,
  Form,
  Card,
  CardHeader,
  CardBody,
  FormGroup
} from "reactstrap";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { Mutation, Query } from "react-apollo";
import authCheck from "utils/authCheck";
import Autosuggest from "react-autosuggest";

export const QUERY_EXTENSION = gql`
  query AdminQuery($filtered: [FilteredInput]) {
    sipAccounts(pageSize: 0, filtered: $filtered) {
      code
      message
      data {
        _id
        displayName
      }
    }
  }
`;

export const QUERY_COMPANY = gql`
  {
    companies {
      code
      message
      data {
        name
        domain
      }
    }
  }
`;
export const QUERY_ANNOUNCEMENT = gql`
  query AdminQuery($filtered: [FilteredInput]) {
    announcements(filtered: $filtered, pageSize: 0) {
      code
      message
      data {
        _id
        name
      }
    }
  }
`;

export const QUERY_DETAIL = gql`
  query AdminQuery($_id: String) {
    ringGroup(_id: $_id) {
      code
      message
      data{
        _id
        agents{
          _id
          displayName
        }
        name
        company{domain name}
        description
        extension
        announcement{
          _id
          name
        }
      }
    }
  }
`;
export const MUTATION_CREATE = gql`
mutation AdminMutation($ringGroup:RingGroupInputType!){
  createRingGroup(ringGroup:$ringGroup){
    code
    message
    data{_id}
  }
}
`;
export const MUTATION_EDIT = gql`
mutation AdminMutation($ringGroup:RingGroupInputType!){
  updateRingGroup(ringGroup:$ringGroup){
    code
    message
    data{_id}
  }
}
`;
const getSuggestionValue = suggestion => suggestion._id;
// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div className="account-select">
    {suggestion.displayName + " < " + suggestion._id + " >"}
  </div>
);
class RingGroupEditPage extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      description: "",
      extension: "",
      agents: [],
      company:{},
      agentSuggests: [],
      announcement:"",
      selectedAgentId:"",
      agentData:[]
    };
  }
  componentDidMount() {
    const _id = this.props.match.params._id;
    if (_id && _id.length > 0) {
      this.props.client
        .query({
          query: QUERY_DETAIL,
          variables: { _id: _id }
        })
        .then(result => {
          authCheck(result);
          if (result.data.ringGroup !== null) {
            var ringGroup = result.data.ringGroup.data;
            this.setState(ringGroup);
            if(ringGroup.company){
              this.onCompanySelectedChange(ringGroup.company.domain);
            }
          }
        });

    }
  }
  onCompanySelectedChange=(company)=>{
    this.props.client.query({
      query:QUERY_EXTENSION,
      variables:{filtered: [{ id: "company", value: company }]}
    }).then(result=>{
      authCheck(result);
      if(result.data.sipAccounts!==null){
        this.setState({agentData:result.data.sipAccounts.data,company:{domain:company}});
      }
    })
  }
  onAgentSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    
    var { agents } = this.state;
    agents = agents.filter(item => {
      return item._id !== suggestion._id;
    });
    agents.push(suggestion);
    this.setState({
      selectedAgentId: "",
      agents
    });
  };
  onCancel = () => {
    this.props.history.push("/ringgroup");
  };
  onAgentSearchChange = (event, { newValue }) => {
    this.setState({
      selectedAgentId: newValue
    });
  };
  onAgentsClearRequested = () => {
    this.setState({
      agentSuggests: []
    });
  };
  onAgentsFetchRequested = ({ value }) => {
    this.setState({
      agentSuggests: this.getAgentSuggestions(value)
    });
  };
  getAgentSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const {  agentData } = this.state;

    return inputLength === 0
      ? []
      : agentData.filter(
          agent =>
            agent._id.indexOf(inputValue) >= 0 
        );
  };
  render = () => {
    const {
      _id,
      name,
      description,
      announcement,
      extension,
      agents,
      company,
      agentSuggests,
      selectedAgentId
    } = this.state;
// Autosuggest will pass through all these props to the input.
    const agentInputProps = {
      placeholder: "Nhập tên RingGroup muốn thêm",
      value: selectedAgentId,
      onChange: this.onAgentSearchChange
    };
    return (
      <Page
        title="Chi tiết RingGroup"
        breadcrumbs={[{ name: "Chi tiết RingGroup", active: true }]}
        className="ringgroup"
      >
        <Row>
          <Col md={12}>
            <Mutation
              mutation={
                _id === undefined || _id.length === 0
                  ? MUTATION_CREATE
                  : MUTATION_EDIT
              }
              variables={{
                ringGroup: {
                  _id,
                  name,
                  description,
                  extension,
                  agents:agents.map((item,index)=>{return item._id}),
                  announcement:announcement._id,
                  company:company.domain
                }
              }}
              onCompleted={result => {
                this.onCancel();
              }}
            >
              {mutation => (
                <Button color="primary" onClick={mutation}>
                  Cập nhật thông tin
                </Button>
              )}
            </Mutation>{" "}
            <Button color="link" onClick={this.onCancel}>
              Hủy
            </Button>
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={12} sm={12}>
            <Card>
              <CardHeader className="bg-dark text-white">
                Thông tin cơ bản
              </CardHeader>
              <CardBody>
                <Form className="m-3">
                  <FormGroup row>
                    <Label md={2} sm={4} xs={6} className="text-right">
                      Tên:
                    </Label>
                    <Col md={10} sm={8} xs={6}>
                      <Input
                        type="text"
                        value={name}
                        onChange={e => {                          
                          this.setState({ name: e.target.value });
                        }}
                        bsSize="sm"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={2} sm={4} xs={6} className="text-right">
                      Đầu số gọi:
                    </Label>
                    <Col md={10} sm={8} xs={6}>
                      <Input
                        type="text"
                        value={extension}
                        onChange={e => {
                          var validateName = e.target.value.trim();
                          this.setState({ extension: validateName });
                        }}
                        bsSize="sm"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={2} sm={4} xs={6} className="text-right">
                      Công ty:
                    </Label>
                    <Col md={10} sm={8} xs={6}>
                      <Input
                        type="select"
                        value={company.domain}
                        onChange={e => {
                          this.onCompanySelectedChange(e.target.value);
                        }}
                      >
                        <Query query={QUERY_COMPANY}>
                          {({ loading, error, data }) => {
                            if (error) {
                              console.log(error);
                              //authCheck(error);
                            }
                            if (!data.companies) return "";
                            return (
                              <Fragment>
                                <option value="">Chọn Công Ty</option>
                                {data.companies.data.map((com, index) => (
                                  <option key={index} value={com.domain}>
                                    {com.name}
                                  </option>
                                ))}
                              </Fragment>
                            );
                          }}
                        </Query>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={2} sm={4} xs={6} className="text-right">
                      Thông báo mở đầu:
                    </Label>
                    <Col md={10} sm={8} xs={6}>
                      <Input
                        type="select"
                        value={announcement ? announcement._id : ""}
                        onChange={e => {
                          this.setState({
                            announcement: { _id: e.target.value }
                          });
                        }}
                      >
                        <Query
                          query={QUERY_ANNOUNCEMENT}
                          variables={{
                            filtered: [{ id: "company", value: company.domain }]
                          }}
                        >
                          {({ loading, error, data }) => {
                            if (error) {
                              console.log(error);
                              //authCheck(error);
                            }
                            if (!data.announcements) return "";
                            return (
                              <Fragment>
                                <option value="">Chọn Thông Báo</option>
                                {data.announcements.data.map((com, index) => (
                                  <option key={index} value={com._id}>
                                    {com.name}
                                  </option>
                                ))}
                              </Fragment>
                            );
                          }}
                        </Query>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={2} sm={4} xs={6} className="text-right">
                      Nhóm nhân viên:
                    </Label>
                    <Col md={10} sm={8} xs={6}>
                      <Autosuggest
                        suggestions={agentSuggests}
                        onSuggestionsFetchRequested={
                          this.onAgentsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          this.onAgentsClearRequested
                        }
                        onSuggestionSelected={this.onAgentSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={agentInputProps}
                      />
                      <Input
                        type="select"
                        name="selectMulti"
                        multiple
                        onDoubleClick={e => {
                          var newAgents = agents.filter(item => {
                            return item !== e.target.value;
                          });
                          this.setState({ agents: newAgents });
                        }}
                      >
                        {agents.map((agent, index) => (
                          <option key={index} value={agent._id}>
                            {agent.displayName+ " <"+agent._id+">"} 
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={2} sm={4} xs={6} className="text-right">
                      Mô tả:
                    </Label>
                    <Col md={10} sm={8} xs={6}>
                      <Input
                        type="textarea"
                        value={description}
                        bsSize="sm"
                        onChange={e => {
                          this.setState({ description: e.target.value });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
          
        </Row>
      </Page>
    );
  };
}
export default withApollo(RingGroupEditPage);
