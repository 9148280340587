import Moment from 'moment';
export const ValidFormDate=(date)=>{
    
    if(!date||date==null||date==''||Moment(date).toDate()<new Date(1901,1,1))
        return " ..... / ..... / ..........";
    else
        return " "+Moment(date).format("DD/MM/YYYY");
}

export const ValidFormDateTime=(date)=>{
    
    if(!date||date==null||date==''||Moment(date).toDate()<new Date(1901,1,1))
        return " ..... / ..... / ..........";
    else
        return " "+Moment(date).format("HH:mm DD/MM/YYYY");
}