import Page from 'components/Page';
import React from 'react';
import { Button,ButtonGroup,Input,Row,Col,Label,Card,CardHeader,CardBody,Modal,ModalBody,ModalHeader,ModalFooter ,Form,FormGroup} from 'reactstrap';
import DateInput from 'components/Widget/DateInput';
import DateTimeInput from 'components/Widget/DateTimeInput';
import Moment from 'moment';

class SettingPage extends React.Component{
    
    state={
        testDate:new Date(),
        // testDateTime:Moment().format("DD/MM/YYYY HH:mm")
    }
    
    
    render=()=>{
        return(
            <Page
            title="Cài đặt"
            breadcrumbs={[{ name: 'Cài đặt', active: true }]}            
            className="SettingPage">
                <CardBody>
                    <Form>

                        <FormGroup>
                            <Label>Kiểu date tiêu chuẩn</Label>
                            <Input type="date"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                Kiểu custom Date
                            </Label>
                            <DateInput value={this.state.testDate} onChange={date=>{console.log(date);this.setState({testDate:date})}}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                Kiểu custom DateTime
                            </Label>
                            <DateTimeInput value={this.state.testDate} onChange={date=>{console.log(date);this.setState({testDate:date})}}/>
                        </FormGroup>
                    </Form>

                </CardBody>
            </Page>

        )
    }
}
export default SettingPage;