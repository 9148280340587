import Page from 'components/Page';
import React from 'react';
import {  ButtonGroup,Form } from 'reactstrap';
import { withApollo } from 'react-apollo'
import { MdFileDownload, MdPlayCircleFilled, MdPause } from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import Moment, { duration } from 'moment';
import ReactPlayer from 'react-player'
import ReactTable from "react-table";
import { apiHost } from 'companyConstants'
import {FETCH_CDRS} from 'AdminCustomer/GqlQuery';


class ReportCallMonitorPage extends React.Component {
    state = {
        departments: [],
        selectedMonth: new Date(),
        data: [],
        page: 1,
        loading: true,
        playingId: ''
    }
    componentDidMount() {
        this.fetchData(true);
    }
    fetchData = async (state, instance) => {
        this.setState({ loading: true });
        const { filtered, pageSize, sorted, page } = state;
        try {
            const result = await this.props.client.query({
                query: FETCH_CDRS,
                variables: { filtered, pageSize, sorted: sorted.concat([{ id: "startTime", desc: true }]), page },

            })

            this.setState({ data: result.data.cdrs.data, pages: result.data.cdrs.pages, loading: false });
        } catch (err) {
            authCheck(err);
        }
    }
    download = (id) => {
        window.open("http://" + apiHost + "/downloadrecord/" + id, "_blank")
    }
    render = () => {
        const { data, loading, pages, playingId } = this.state;
        return (
            <Page
                title="QUẢN LÝ FILE GHI ÂM"
                breadcrumbs={[{ name: 'QUẢN LÝ FILE GHI ÂM', active: true }]}
                className="ReportCallMonitorPage">
                <ReactTable
                    noDataText="Không có dữ liệu"
                    className="mt-3"
                    defaultFiltered={[{id:"app",value:"Dial"}]}
                    columns={[

                        {
                            Header: "Loại gọi",
                            className: "text-center",
                            accessor: "isOutbound",                            
                            Cell: row => <span>
                                {row.value ? 'Cuộc gọi ra' : "Cuộc gọi vào"}
                            </span>,
                            Filter: ({ filter, onChange }) =>
                                <select
                                    onChange={event => onChange(event.target.value)}
                                    style={{ width: "100%" }}
                                    value={filter ? filter.value : ""}
                                >
                                    <option value="">Tất cả</option>
                                    <option value="false">Gọi vào</option>
                                    <option value="true">Gọi ra</option>
                                </select>
                        },
                        {
                            Header: "Loại đối tượng",
                            className: "text-center",
                            accessor: "app",
                            Cell: row => <span>
                                {row.value === "Dial" ? "Điện thoại viên" : "Nhóm điện thoại viên"}
                            </span>,
                            Filter: ({ filter, onChange }) =>
                                <select
                                    onChange={event => onChange(event.target.value)}
                                    style={{ width: "100%" }}
                                    value={filter ? filter.value : ""}
                                    
                                >
                                    <option value="">Tất cả</option>
                                    <option value="Dial">Điện thoại viên</option>
                                    <option value="Queue">Nhóm điện thoại viên</option>
                                </select>
                        },
                        {
                            Header: "Tổng đài viên",
                            className: "text-center",
                            accessor: "agent.name",
                            Cell:row=><span>
                                {row.original.isOutbound===false?row.original.receiver:row.original.sender}
                                {row.value?("("+row.value+")"):null}
                            </span>
                        },
                        {
                            Header: "Khách hàng",
                            className: "text-center",
                            Cell: row => <span>
                                {row.original.isOutbound === false ? row.original.sender : row.original.receiver}
                            </span>
                        },
                        {
                            Header: "Ngày",
                            className: "text-center",
                            accessor: "startTime",
                            Cell: row => <span>
                                {Moment(row.value).format("DD/MM/YYYY")}
                            </span>
                        },
                        {
                            Header: "Giờ bắt đầu",
                            className: "text-center",
                            accessor: "startTime",
                            Cell: row => <span>
                                {Moment(row.value).format("HH:mm:ss")}
                            </span>
                        },
                        {
                            Header: "Giờ kết thúc",
                            className: "text-center",
                            accessor: "endTime",
                            Cell: row => <span>
                                {Moment(row.value).format("HH:mm:ss")}
                            </span>
                        },
                        {
                            Header: "Giờ phục vụ",
                            className: "text-center",
                            accessor: "duration",
                            Cell: row => <span>
                                {Moment("1900-01-01").add(row.value, 'seconds').format("HH:mm:ss")}

                            </span>
                        },
                        {
                            Header: "Tác vụ",
                            className: "text-center",
                            accessor: "_id",
                            width:90,
                            filterable:false,
                            Cell: row =>
                                <Form inline>
                                    <ButtonGroup style={{fontSize:25}}>

                                        {
                                            playingId == row.value ?
                                               <MdPause className="text-info m-1" onClick={e => { this.setState({ playingId: "" }) }}/>:
                                               <MdPlayCircleFilled className="text-info m-1" onClick={e => { this.setState({ playingId: row.value }) }}/>
                                        }

                                        <MdFileDownload className="text-danger m-1" onClick={e => this.download(row.value)}/>
                                    </ButtonGroup>
                                </Form>
                        },
                        {
                            Header: "Media",
                            className: "text-center",
                            accessor: "_id",
                            filterable:false,
                            minWidth:200,
                            Cell: row =>
                                <Form inline>
                                    <ReactPlayer url={"http://" + apiHost + "/downloadrecord/" + row.value} loop={true} playing={playingId == row.value} controls={true} height="40px"/>
                                    
                                </Form>
                        },
                        
                    ]}
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    data={data}
                    pages={pages} // Display the total number of pages
                    loading={loading} // Display the loading overlay when we need it
                    onFetchData={this.fetchData} // Request new data when things change
                    filterable
                    defaultPageSize={10}

                >

                </ReactTable>
            </Page>
        )
    }

}

export default withApollo(ReportCallMonitorPage);