import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import {Button,Modal,ModalHeader,ModalBody,ModalFooter} from 'reactstrap'; // your choice.
import { createConfirmation } from 'react-confirm';
import {MdWarning,MdInfo} from 'react-icons/lib/md';
import Typography from 'components/Typography';
const Confirmation = ({ proceed, dismiss, cancel, confirmMessage, options}) => {
    return (
        <Modal toggle={dismiss} isOpen={true}>   
            <ModalHeader toggle={dismiss}>{options.title}</ModalHeader>
            <ModalBody>
                <div style={{textAlign:"center"}}>
                    <div>
                    {
                        options.level==='warning'? 
                        <Typography type="display-1" className='text-warning'><MdWarning/></Typography> 
                        :<Typography type="display-1" className='text-info'><MdInfo/></Typography> 
                    }
                    </div>
                    <h4>{confirmMessage}</h4>
                </div>
            </ModalBody>            
            <ModalFooter>
                {options.hideCancel?(<Button color='primary' onClick={proceed}>ĐÓNG</Button>  ):(
                    <div>
                        <Button color='default' onClick={cancel}> Bỏ qua </Button>{' '}
                        <Button color='primary' onClick={proceed}>Xác nhận</Button>  
                    </div>    
                )}
                          
            </ModalFooter>
        </Modal>
    )
}
 
Confirmation.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  confirmation: PropTypes.string,  // arguments of your confirm function
  options: PropTypes.object        // arguments of your confirm function
}
Confirmation.defaultProps={
    options:{title:""}
}
const confirm = createConfirmation(confirmable(Confirmation));
export default function(confirmMessage, options = {}) {
// You can pass whatever you want to the component. These arguments will be your Component's props
    return confirm({ confirmMessage, options });
}
// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
//export default confirmable(Confirmation);