import Page from 'Admin/Components/Page';
import React from 'react';
import { Button,ButtonGroup,Card,CardBody,CardHeader} from 'reactstrap';
import Avatar from 'components/Avatar';
import gql from 'graphql-tag';
import {Mutation} from 'react-apollo'
import {MdEdit,MdDelete,MdAddCircle,MdCheckBoxOutlineBlank,MdCheckCircle,MdClose} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import Moment from 'moment'
import { withApollo } from 'react-apollo'

export const QUERY_COMPANY=gql`
    query AdminQuery($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
        companies(page:$page,pageSize:$pageSize,filtered:$filtered,sorted:$sorted){
        data{
            _id
            name
            active
            address
            fax
            tel
            website
            description
            domain
            createdTime
            logo
        }
        page
        pages
        }
    }
`;

class CompanyPage extends React.Component{
    state={
        modal:false,
        isCreate:true,//trang thai edit hay create
        rowOrigin:{},
        rowEdit:{customer:{},department:{}},
        data: [],
        page: 1,
        loading: true
    }
    constructor(){
        super();
        this.fetchData=this.fetchData.bind(this);        
    }
    fetchData=async(state, instance)=>{
        this.setState({ loading: true });
        const {filtered,pageSize,sorted,page} = state;
        try{
            const result = await this.props.client.query({
                query: QUERY_COMPANY,
                variables: { filtered,pageSize,sorted,page },
                
            })       
            authCheck(result); 
            this.setState({data:result.data.companies.data,pages:result.data.companies.pages,page:result.data.companies.page,loading:false});
        }catch(err){
            //console.log(err);
            authCheck(err);
        }
    }
    render=()=>{
        const {data,loading,pages}=this.state;
        return(
        <Page title="Công ty"
                breadcrumbs={[{ name: 'Danh sách công ty', active: true }]}            
                className="ExtensionPage"> 
            <Card>
                <CardHeader className="bg-green">                        
                    <Button color="primary" onClick={e=>this.props.history.push("/company-edit")}><MdAddCircle/> Thêm công ty</Button>
                </CardHeader>
                <CardBody>
                <div style={{height:10}}></div>
            
                    <ReactTable 
                        noDataText="Không có dữ liệu"                        
                        columns={[    
                            {
                                Header:"#",
                                width:50,
                                style:{textAlign:"center"},
                                Cell: (row) => {
                                    return <div>{row.index+1}</div>;
                                    }
                            },  
                            {
                                Header:"Logo",
                                accessor:"logo",                                
                                maxWidth:200,
                                filterable:false,
                                Cell:row=><div>
                                    <img src={row.value} alt="logo" style={{height:40,width:"auto"}}></img>
                                </div>
                                
                            },                           
                            {
                                Header:"Tên công ty",
                                accessor:"name",                                
                                maxWidth:150,
                            },
                            {
                                Header:"Tên miền",
                                accessor:"domain",
                            }, 
                            {
                                Header:"Số điện thoại",
                                accessor:"tel",
                                style:{textAlign:"center"},
                            },                                    
                            {
                                Header:"Ngày khởi tạo",
                                width:140,
                                Cell:row=>(
                                    <div>
                                        {Moment(row.original.createdTime).format("DD/MM/YYYY")}                                       
                                    </div>
                                )
                            },
                            {
                                Header:"Mô tả",
                                accessor:"description"
                            },
                            {
                                Header:"Website",
                                accessor:"website",                                
                                style:{textAlign:"center"},
                            }, 
                            {
                                Header:"Trạng thái",
                                accessor:"active",
                                maxWidth:50,
                                style:{textAlign:"center"},
                                Cell:row=>(
                                    <div style={{fontSize:18}}>
                                    {row.value==="waiting"?<MdCheckBoxOutlineBlank className="text-green"/>:
                                    row.value==="complete"?<MdCheckCircle className="text-green"/>:<MdClose className="text-danger"/>   } 
                                    </div>
                                )
                            },  
                            {
                                Header:"Tác vụ",
                                maxWidth:100,
                                filterable:false,
                                sortable:false,
                                Cell: row=>(
                                    <ButtonGroup>
                                        <Button onClick={()=>this.props.history.push("/company-edit/"+row.original._id)}><MdEdit/></Button>
                                        {" "}<Button><MdDelete/></Button>

                                    </ButtonGroup>
                                )
                            }
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={5}
                        className="-striped -highlight"
                    />                                      
                            
                </CardBody>
            </Card>
        </Page>)
    }
}

export default withApollo(CompanyPage);