import Page from 'components/Page';
import React,{Fragment} from 'react';
import { Button, ButtonGroup, Card,CardHeader,Tooltip } from 'reactstrap';
import { withApollo,Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { MdDelete, MdEdit,MdAddCircle } from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import confirm from 'components/Confirmation';

export const FEED_QUERY = gql`
{
   response:branchLocations{
    code
    data{
      _id
      address
      description
      geo
      name
      photos
    }
    message
  }
}
    
`
export const BRANCH_DELETE = gql`

    mutation DELETE_BRANCH($_id:String){
        deleteBranchLocation(_id:$_id){
          code
          message
        }
      }

    
`
class BranchPage extends React.Component {

    state = {
        data: [],
        pages: 1,
        loading: true,
        tooltipOpen:{}
    }
    constructor() {
        super();
        this.fetchData = this.fetchData.bind(this);
    }
    fetchData = async (state, instance) => {

        this.setState({ loading: true });
        const { filtered, pageSize, sorted, page } = state;
        try {
            const result = await this.props.client.query({
                query: FEED_QUERY,
                variables: { filtered, pageSize, sorted, page },
            })
            authCheck(result);
            this.setState({ data: result.data.response.data, pages: result.data.response.pages, loading: false });
        } catch (err) {
            authCheck(err);
        }
    }
    onDelete=(row,mutation)=>{
        confirm(`Bạn có chắc chắn muốn chi nhánh '${row.name}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }
    onEdit=(row)=>{            
        this.props.history.push("/branch-edit/"+row._id);
     }
    
    render = () => {
        const { data, loading, pages,tooltipOpen } = this.state;
        return (
            <Page title="Quản lý chi nhánh"
                breadcrumbs={[{ name: 'Quản lý chi nhánh', active: true }]}
                className="CustomerPage">
                <Card>
                    <CardHeader className="bg-green">
                        <Button color="primary" onClick={e => { this.props.history.push("/branch-edit") }}><MdAddCircle /> Tạo chi nhánh</Button>
                    </CardHeader>
                    <ReactTable
                        noDataText="Không có dữ liệu"
                        columns={[
                            {
                                Header: "#",
                                width:50,
                                className:"text-center",
                                filterable:false,
                                Cell:row=><span>
                                    {row.index+1}
                                </span>
                            },
                            {
                                Header: "Tên",
                                accessor: "name"
                            },
                            {
                                Header: "Địa chỉ",
                                accessor: "address",
                                
                            },
                            {
                                Header: "Mô tả",
                                accessor: "description"
                            },
                            {
                                Header: "Tác vụ",
                                maxWidth: 100,
                                filterable: false,
                                sortable: false,
                                className:"text-center",
                                Cell: row=>(
                                    <ButtonGroup style={{fontSize:20}}>
                                        <MdEdit id={"edit"+row.original._id} onClick={()=>this.onEdit(row.original)} className="text-warning m-1"/>
                                        <Tooltip placement="bottom" isOpen={tooltipOpen['edit'+row.original._id]} autohide={false} target={"edit"+row.original._id} toggle={e=>{tooltipOpen['edit'+row.original._id]=!tooltipOpen['edit'+row.original._id]; this.setState({tooltipOpen})}}>
                                            Sửa
                                        </Tooltip>
                                        <Mutation mutation={BRANCH_DELETE}
                                            variables={{_id:row.original._id}}
                                            onCompleted={(result)=>{
                                                this.fetchData([],null)
                                            }}
                                        >
                                            {mutation=>
                                                <Fragment>
                                                    <MdDelete id={"delete"+row.original._id} className="text-danger m-1" onClick={()=>this.onDelete(row.original,mutation)}/>
                                                    <Tooltip placement="bottom" isOpen={tooltipOpen['delete'+row.original._id]} autohide={false} target={"delete"+row.original._id} toggle={e=>{tooltipOpen['delete'+row.original._id]=!tooltipOpen['delete'+row.original._id]; this.setState({tooltipOpen})}}>
                                                        Xóa phòng/ban
                                                    </Tooltip>
                                                </Fragment>
                                            }
                                        </Mutation>
                                    </ButtonGroup>
                                )
                            }
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}                       
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        className="-striped -highlight"
                    >

                    </ReactTable>
                </Card>

            </Page>
        )
    }
}

export default withApollo(BranchPage);