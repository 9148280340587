import React from "react";
import logo from 'styles/images/logo_phusan.png'
import {ValidFormDate} from "utils/dateHelper";
import Moment from 'moment'
import {GetLocalToken} from 'companyConstants'


const MYTD=({className,colspan,rowspan,children,style})=><td className={className} colspan={colspan} rowspan={rowspan} style={{padding:"8px 10px",fontSize:16,...style}}>
    {children}
</td>

const PrintTiensangiat = ({
    mabn,
    nghe_nghiep,
    sodienthoai,
    chungtoc,
    chieu_cao,
    can_nang,
    ho_va_ten,
    ngaysinh,
    diachi1,
    hutthuoc,
    uongruou,
    para,
    khoang_sang_sau_gay,
    phuong_phap_thu_thai,
    ngay_dau_tien_ky_kinh_cuoi,
    kinh_deu,
    vongkinh,
    ngay_sieu_am,
    bacsy_chi_dinh,
    tiensu_banthan,
    tiensu_gia_dinh,
    tuan_thai,
    ngay_thai,
    bPD,
    nhip_tim_thai,
    cRL,
    cao_huyet_ap,
    soluong_hutthuoc,
    soluong_uongruou,
    ho_ten_chong,
    tuoi_chong,
    nghe_nghiep_chong,
    tiensu_chong,
    ruou_bia_chong,
    thuoc_la_chong,
    sdt_chong,
    ngay_chuyen_phoi,
    ngay_bom_tinh_trung,
    so_con_gai,
    so_con_trai,
    ngay_du_sinh,
    tinh_trang_suc_khoe,
    printing,
    print_double_test,
    print_tripple_test,
    soluong_ruou_bia_chong,
    soluong_thuoc_la_chong,
    dai_thao_duong,
    viemgan,
    viemgan_nam,
    dai_thao_duong_nam,
    tien_san_giat,
    huyetap_phai1_thap,
    huyetap_phai1_cao,
    huyetap_phai2_thap,
    huyetap_phai2_cao,
    huyetap_trai1_thap,
    huyetap_trai1_cao,
    huyetap_trai2_thap,
    huyetap_trai2_cao,
    ngay_lam_doppler,
    dong_mach_tu_cung_phai,
    dong_mach_tu_cung_trai,
    tien_san_giat_o_me,
    css,
    cao_huyet_ap_co_dieu_tri, objRef
    }) => (

        <div className="body" ref={objRef}>

            <div className="content" id="tien_san_giat">
                <div class="img_logo" style={{ width: 120, marginRight: 20 }}> <img src={logo} alt="logo hospital" style={{ width: 120 }} /> </div>
                <div style={{ flex: 1 }}>
                    <p style={{marginBottom:0}}><b style={{ fontSize: 24, color: "#478F20" }}>BỆNH VIỆN PHỤ SẢN HÀ NỘI</b><br />
                        <b>
                            TRUNG TÂM SÀNG LỌC, CHUẨN ĐOÁN TRƯỚC SINH VÀ SƠ SINH
                        </b> 
                    </p>
                    <p> Địa chỉ: Số 929 - Đường La Thành - Ngọc Khánh - Ba Đình - Hà Nội - Hotline: 0243 7757 346
                    </p>
                </div>
                <div style={{ width: "100%"}}>
                    <p style={{ textAlign: "center", fontSize: 24, color: "#478F20", fontWeight: "bold" }}>HỒ SƠ XÉT NGHIỆM SÀNG LỌC TIỀN SẢN GIẬT THAI KỲ I</p>
                </div>
                <div class="form_info">
                    <p className="header">I. THÔNG TIN THAI PHỤ</p>
                    <table cellpadding="0" cellspacing="0" >
                        <tbody>
                        <tr>
                                <MYTD colspan="2" style={{ width: "33%" }}>Họ tên: <b>{ho_va_ten}</b></MYTD>
                                <MYTD colspan="2">Ngày sinh: <b>{ValidFormDate(ngaysinh)}</b></MYTD>
                                <MYTD colspan="2">Mã BN: <b>{mabn}</b></MYTD>
                            </tr>
                        <tr >
                            <MYTD colspan="2">Địa chỉ: <b>{diachi1}</b></MYTD>
                            <MYTD colspan="2">Cân nặng: {can_nang<0?"........":can_nang}</MYTD>
                            <MYTD colspan="2">Chiều cao: <b>{chieu_cao < 0 ? "........" : chieu_cao} cm</b></MYTD>
                        </tr>
                        <tr>
                            <MYTD colspan="2">Nghề nghiệp: <b>{nghe_nghiep}</b></MYTD>
                            <MYTD colspan="2">Dân tộc: <b>{chungtoc}</b></MYTD>
                            <MYTD colspan="2">Số ĐT: <b>{sodienthoai}</b></MYTD>
                        </tr>

                        <tr>
                            <MYTD colspan="2">Uống rượu:</MYTD>
                            <MYTD colspan="2">
                                <table className="table-inline">
                                    <tr >
                                        <MYTD>
                                            <label class="frm_check"><span className={uongruou==0?"checked":""} />
                                                <span class="checkmark"></span>
                                                Không 
                                            </label>
                                        </MYTD>
                                        <MYTD>
                                            <label class="frm_check"><span className={uongruou==1?"checked":""} />
                                                <span class="checkmark"></span>
                                                Có 
                                            </label>
                                        </MYTD>
                                    </tr>
                                </table>
                            </MYTD>
                            <MYTD colspan="2">
                                
                            </MYTD>
                        </tr>
                        <tr>
                            <MYTD colspan="6">
                                <table className="table-inline">
                                    <tr>
                                        <MYTD style={{width:220}}>3. PARA:  <b>{para}</b></MYTD>
                                        <MYTD>
                                            Đã có mấy con:  Trai: <b>{so_con_trai < 0 ? "......." : so_con_trai} </b> ,
                                            Gái: <b> {so_con_gai < 0 ? "......." : so_con_gai} </b>
                                        </MYTD>
                                        <MYTD>
                                            Tình trạng sức khỏe: <b>{tinh_trang_suc_khoe}</b>
                                        </MYTD>
                                    </tr>
                                </table>
                            </MYTD>
                            
                        </tr>
                        <tr>
                            <MYTD colspan="3">4. Siêu âm ngày: <b>{ValidFormDate(ngay_sieu_am)}</b></MYTD>
                            <MYTD colspan="3">Thai tương đương: <b>{tuan_thai<0?"......":tuan_thai} tuần {ngay_thai<0?"......":ngay_thai} ngày</b></MYTD>
                        </tr>
                        <tr>
                            <MYTD colspan="6">
                                <table className="table-inline">
                                    <tr>
                                        <MYTD>CRL:<b>{cRL<0?"...... ":cRL} mm</b></MYTD>
                                        <MYTD>BPD:<b>{bPD<0? "...... ":bPD} mm</b></MYTD>
                                        <MYTD>Khoảng sáng sau gáy:<b>{khoang_sang_sau_gay<0?".......":khoang_sang_sau_gay} mm</b></MYTD>
                                        <MYTD>Nhịp tim thai: <b>{nhip_tim_thai<0?".......":nhip_tim_thai} mm</b></MYTD>

                                    </tr>
                                </table>
                            </MYTD>
                        </tr>
                        <tr>
                            <MYTD colspan="6">5.Ngày làm Doppler:  <b>{ValidFormDate(ngay_lam_doppler)}</b></MYTD>
                        </tr>
                        <tr>
                            <MYTD></MYTD>
                            <MYTD colspan="5">Động mạch tử cung bên phải: <b>{dong_mach_tu_cung_phai}</b></MYTD>
                            
                        </tr>
                        <tr>
                            <MYTD></MYTD>
                            <MYTD colspan="5">Động mạch tử cung bên trái: <b>{dong_mach_tu_cung_phai}</b></MYTD>
                        </tr>
                        <tr>
                            <MYTD colspan="6">6.Kết quả đo huyết áp</MYTD>
                        </tr>
                        <tr>
                            <MYTD rowspan="2">
                                Tay trái
                            </MYTD>
                            <MYTD>
                                Lần 1
                            </MYTD>
                            <MYTD>
                                <b>{huyetap_trai1_cao<0?" ...... ":huyetap_trai1_cao}/{huyetap_trai1_thap<0?" ...... ":huyetap_trai1_thap}</b>
                            </MYTD>
                            <MYTD rowspan="2">
                                Tay phải
                            </MYTD>
                            <MYTD>
                                Lần 1
                            </MYTD>
                            <MYTD>
                                <b>{huyetap_phai1_cao<0?" ...... ":huyetap_phai1_cao}/{huyetap_phai1_thap<0?" ...... ":huyetap_phai1_thap}</b>
                            </MYTD>
                        </tr>
                        <tr>
                            <MYTD>
                                Lần 2
                            </MYTD>
                            <MYTD>
                                <b>{huyetap_trai2_cao<0?" ...... ":huyetap_trai2_cao}/{huyetap_trai2_thap<0?" ...... ":huyetap_trai2_thap}</b>
                            </MYTD>
                            <MYTD>
                                Lần 2
                            </MYTD>
                            <MYTD>
                                <b>{huyetap_phai2_cao<0? " ...... ":huyetap_phai2_cao}/{huyetap_phai2_thap<0? " ...... ":huyetap_phai2_thap}</b>
                            </MYTD>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="form_info">
                    <p className="header">II. TIỀN SỬ</p>
                    <table cellpadding="0" cellspacing="0" >
                        <tbody>
                        <tr>
                            <MYTD colspan="3">Thai phụ: <b>{tiensu_banthan}</b></MYTD>
                        </tr>
                        <tr>
                            <MYTD style={{width:"33%"}}>Có bị tiền sản giật không?</MYTD>
                            <MYTD>
                                <label class="frm_check" style={{float:"right"}}> Có <span className={tien_san_giat==1?"checked":""} />
                                    <span class="checkmark"></span>
                                </label>
                                
                            </MYTD>
                            <MYTD>
                                <label class="frm_check" style={{float:"right"}}> Không <span className={tien_san_giat==0?"checked":""} />
                                    <span class="checkmark"></span>
                                </label>
                            </MYTD>
                        </tr>
                        <tr>
                            <MYTD>Có tiền sử cao huyết áp không?</MYTD>
                            
                            <MYTD>
                                <label class="frm_check" style={{float:"right"}}> Có <span className={cao_huyet_ap==1?"checked":""} />
                                    <span class="checkmark"></span>
                                </label>
                            </MYTD>
                            <MYTD>
                                <label class="frm_check" style={{float:"right"}}> Không <span className={cao_huyet_ap==0?"checked":""} />
                                    <span class="checkmark"></span>
                                </label>
                            </MYTD>
                        </tr>
                        <tr>
                            <MYTD colspan="3">
                                <table class="table-inline">
                                    <tr>
                                        <MYTD>
                                            <label class="frm_check" style={{float:"right"}}> Huyết ap cao có điều trị <span className={cao_huyet_ap_co_dieu_tri==1?"checked":""} />
                                                <span class="checkmark"></span>
                                            </label>
                                        
                                        </MYTD>
                                        <MYTD>
                                            <label class="frm_check" style={{float:"right"}}> Huyết ap cao không điều trị <span className={cao_huyet_ap_co_dieu_tri==0?"checked":""} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </MYTD>

                                    </tr>
                                </table>
                            </MYTD>
                            
                        </tr>
                        <tr>
                            <MYTD colspan="3">Gia đình:</MYTD>
                        </tr>
                        <tr>
                            <MYTD colspan="3">
                                Mẹ bệnh nhân có tiền sử bị tiền sản giật không?
                            </MYTD>
                        </tr>
                        <tr>                            
                            <MYTD>
                                <label class="frm_check" style={{float:"right"}}> Có <span className={tien_san_giat_o_me==1?"checked":""} />
                                    <span class="checkmark"></span>
                                </label>
                            </MYTD>
                            <MYTD>
                                <label class="frm_check" style={{float:"right"}}> Không <span className={tien_san_giat_o_me==0?"checked":""} />
                                    <span class="checkmark"></span>
                                </label>
                            </MYTD>
                            <MYTD>
                                <label class="frm_check" style={{float:"right"}}> Không biết <span className={tien_san_giat_o_me==2?"checked":""} />
                                    <span class="checkmark"></span>
                                </label>
                            </MYTD>
                        </tr>
                        </tbody>
                    </table>
                </div> 

                <div class="form_info">

                    <p><b>Cam kết của bệnh nhân:  Tôi đã được nghe tư vấn và hiểu hết về xét nghiệm sàng lọc này. Tôi xin cam đoan những thông tin trên là chính xác</b></p>
                    <table class="table_text">
                        <tr>                            
                            <MYTD colspan="4">
                                <p style={{float:"right",fontStyle:"italic"}}>                                    
                                    {Moment().format("HH [giờ] mm [phút],  [ngày] DD [tháng] MM [năm] YYYY")}
                                </p>
                            </MYTD>
                        </tr>
                        <tr>
                            <MYTD style={{textAlign:"center"}}>Bệnh nhân ký tên</MYTD>
                            <td></td>
                            <td></td>
                            <MYTD style={{textAlign:"center"}}>Người lập phiếu</MYTD>
                        </tr>
                        <tr>
                            <td colspan="4" style={{height:80}}>
                            </td>
                        </tr>
                        <tr>
                            <MYTD></MYTD>
                            <td></td><td></td>
                            <MYTD style={{textAlign:"center"}}><strong>{GetLocalToken().fullName}</strong></MYTD>
                        </tr>
                    </table>
                </div>
            </div>
            
        </div>

    )
export default PrintTiensangiat;