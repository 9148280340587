export const AUTH_TOKEN = 'company-auth-token'
export const ROOT_DOMAIN="crm.meapp.vn"
export const LINKS_PER_PAGE = 10
export const apiHost="api.pshn.benhvien.tech";
export const GetLocalToken=()=>{
    const tokenStr = localStorage.getItem(AUTH_TOKEN);
    try{
      if(tokenStr){
        const token = JSON.parse(tokenStr);
        
        return token;
      }
    }catch(err){
        console.log("getLocalToken error:"+JSON.stringify(err));
        localStorage.setItem(AUTH_TOKEN,undefined);
        //window.location.href='/login';
    }
}
export const UpdateLocalToken=(token)=>{
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
}
export const UpdateLocalSetting=(setting)=>{
    const token =GetLocalToken();
    token.setting = setting;
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
}
export const SignOut = ()=>{
    console.log("signout from adminConstant");
    localStorage.setItem(AUTH_TOKEN,undefined);
    window.location.href='/login';
}