import Page from "Admin/Components/Page";
import React, { Fragment } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Label,
  Form,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup
} from "reactstrap";
import { withApollo, Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import ReactTable from "react-table";
import { MdDelete, MdAddCircle, MdEdit } from "react-icons/lib/md";
import ToggleButton from "react-toggle-button";
import authCheck from "utils/authCheck";
export const QUERY_DETAIL = gql`
  query AdminQuery($_id: String) {
    ivr(_id: $_id) {
      code
      message
      data{
        _id
        description
        announcement {
          _id
          name
        }
        company {
          name
          domain
        }
        invalidRecording {
          _id
          name
        }
        invalidDestination {
          _id
          name
        }
        localExten
        name
        entries {
          selection
          destType
          destId
          ivrRet
        }
      }
    }
  }
`;
export const QUERY_EXTENSION = gql`
  query AdminQuery($filtered: [FilteredInput]) {
    sipAccounts(pageSize: 0, filtered: $filtered) {
      data {
        _id
        displayName
      }
    }
  }
`;
export const MUTATION_CREATE = gql`
  mutation AdminMutation($ivr: IvrInputType!) {
    createIvr(ivr: $ivr) {
      code
      message
      data{_id}
    }
  }
`;
export const MUTATION_EDIT = gql`
  mutation AdminMutation($ivr: IvrInputType!) {
    updateIvr(ivr: $ivr) {
      code
      message
      data{_id}
    }
  }
`;
export const QUERY_COMPANY = gql`
  {
    companies {
      code
      message
      data {
        name
        domain
      }
    }
  }
`;
export const QUERY_ANNOUNCEMENT = gql`
  query AdminQuery($filtered: [FilteredInput]) {
    announcements(filtered: $filtered, pageSize: 0) {
      code
      message
      data {
        _id
        name
      }
    }
  }
`;

class IvrEditPage extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      description: "",
      company: {},
      announcement: {},
      invalidRecording: {},
      invalidDestination: {},
      localExten: "",
      entries: [],
      editSelection: 0,
      editDestType: "",
      editDestId: "",
      editIvrRet: false,
      disable: false
    };
  }
  componentDidMount() {
    const _id = this.props.match.params._id;
    if (_id && _id.length > 0) {
      try {
        this.props.client
          .query({
            query: QUERY_DETAIL,
            variables: { _id: _id }
          })
          .then(result => {
            authCheck(result);
            if (result.data.ivr !== null) {
              this.setState(result.data.ivr.data);
            }
          });
      } catch (err) {
        authCheck(err);
      }
    }
  }

  onCancel = () => {
    this.props.history.push("/ivr");
  };

  render = () => {
    const {
      _id,
      name,
      description,
      localExten,
      entries,
      disable,
      company,
      announcement,
      invalidDestination,
      invalidRecording,
      editDestType,
      editDestId,
      editSelection,
      editIvrRet
    } = this.state;

    return (
      <Page
        title="Chi tiết IVR"
        breadcrumbs={[{ name: "Chi tiết IVR", active: true }]}
        className="ivr"
      >
        <Row>
          <Col md={12}>
            <Mutation
              mutation={
                _id === undefined || _id.length === 0
                  ? MUTATION_CREATE
                  : MUTATION_EDIT
              }
              variables={{
                ivr: {
                  _id,
                  name,
                  description,
                  localExten,
                  entries,
                  company: company.domain,
                  announcement: announcement ? announcement._id : "",
                  invalidDestination: invalidDestination
                    ? invalidDestination._id
                    : "",
                  invalidRecording: invalidRecording ? invalidRecording._id : ""
                }
              }}
              onCompleted={result => {
                this.onCancel();
              }}
            >
              {mutation => (
                <Button color="primary" onClick={mutation}>
                  Cập nhật thông tin
                </Button>
              )}
            </Mutation>{" "}
            <Button color="link" onClick={this.onCancel}>
              Hủy
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <Form className="m-3">
              <FormGroup row>
                <Label md={4} sm={6} className="text-right">
                  Tên:
                </Label>
                <Col md={8} sm={6}>
                  <Input
                    type="text"
                    value={name}
                    onChange={e => {
                      this.setState({ name: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} sm={6} className="text-right">
                  Đầu số nội bộ:
                </Label>
                <Col md={8} sm={6}>
                  <Input
                    type="text"
                    value={localExten ? localExten : ""}
                    onChange={e => {
                      this.setState({ localExten: e.target.value });
                    }}
                    bsSize="sm"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} sm={6} className="text-right">
                  Công ty:
                </Label>
                <Col md={8} sm={6}>
                  <Input
                    type="select"
                    value={company.domain}
                    onChange={e => {
                      this.setState({ company: { domain: e.target.value } });
                    }}
                  >
                    <Query query={QUERY_COMPANY}>
                      {({ loading, error, data }) => {
                        if (error) {
                          console.log(error);
                          //authCheck(error);
                        }
                        if (!data.companies) return "";
                        return (
                          <Fragment>
                            <option value="">Chọn Công Ty</option>
                            {data.companies.data.map((com, index) => (
                              <option key={index} value={com.domain}>
                                {com.name}
                              </option>
                            ))}
                          </Fragment>
                        );
                      }}
                    </Query>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} sm={6} className="text-right">
                  Thông báo mở đầu:
                </Label>
                <Col md={8} sm={6}>
                  <Input
                    type="select"
                    value={announcement ? announcement._id : ""}
                    onChange={e => {
                      this.setState({ announcement: { _id: e.target.value } });
                    }}
                  >
                    <Query
                      query={QUERY_ANNOUNCEMENT}
                      variables={{
                        filtered: [{ id: "company", value: company.domain }]
                      }}
                    >
                      {({ loading, error, data }) => {
                        if (error) {
                          console.log(error);
                          //authCheck(error);
                        }
                        if (!data.announcements) return "";
                        return (
                          <Fragment>
                            <option value="">Chọn Thông Báo</option>
                            {data.announcements.data.map((com, index) => (
                              <option key={index} value={com._id}>
                                {com.name}
                              </option>
                            ))}
                          </Fragment>
                        );
                      }}
                    </Query>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} sm={6} className="text-right">
                  Thông báo thuê bao không đúng:
                </Label>
                <Col md={8} sm={6}>
                  <Input
                    type="select"
                    value={invalidDestination ? invalidDestination._id : ""}
                    onChange={e => {
                      this.setState({
                        invalidDestination: { _id: e.target.value }
                      });
                    }}
                  >
                    <Query
                      query={QUERY_ANNOUNCEMENT}
                      variables={{
                        filtered: [{ id: "company", value: company.domain }]
                      }}
                    >
                      {({ loading, error, data }) => {
                        if (error) {
                          console.log(error);
                          //authCheck(error);
                        }
                        if (!data.announcements) return "";
                        return (
                          <Fragment>
                            <option value="">Chọn Thông Báo</option>
                            {data.announcements.data.map((com, index) => (
                              <option key={index} value={com._id}>
                                {com.name}
                              </option>
                            ))}
                          </Fragment>
                        );
                      }}
                    </Query>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} sm={6} className="text-right">
                  Thông báo ghi âm không hợp lệ:
                </Label>
                <Col md={4} sm={6}>
                  <Input
                    type="select"
                    value={invalidRecording ? invalidRecording._id : ""}
                    onChange={e => {
                      this.setState({
                        invalidRecording: { _id: e.target.value }
                      });
                    }}
                  >
                    <Query
                      query={QUERY_ANNOUNCEMENT}
                      variables={{
                        filtered: [{ id: "company", value: company.domain }]
                      }}
                    >
                      {({ loading, error, data }) => {
                        if (error) {
                          console.log(error);
                          //authCheck(error);
                        }
                        if (!data.announcements) return "";
                        return (
                          <Fragment>
                            <option value="">Chọn Thông Báo</option>
                            {data.announcements.data.map((com, index) => (
                              <option key={index} value={com._id}>
                                {com.name}
                              </option>
                            ))}
                          </Fragment>
                        );
                      }}
                    </Query>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={4} sm={6} className="text-right">
                  Mô tả:
                </Label>
                <Col md={8} sm={6}>
                  <Input
                    type="textarea"
                    value={description}
                    bsSize="sm"
                    onChange={e => {
                      this.setState({ description: e.target.value });
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={2} sm={4} xs={6} className="text-right">
                  Disable:
                </Label>
                <Col md={10} sm={8} xs={6}>
                  <div className="mt-2">
                    <ToggleButton
                      value={disable}
                      onToggle={value => {
                        this.setState({ disable: !disable });
                      }}
                    />
                  </div>
                </Col>
              </FormGroup>
            </Form>
          </Col>
          <Col md={6} sm={12}>
            <Card>
              <CardHeader className="bg-dark text-white">Entries</CardHeader>
              <CardBody>
                <Form className="mt-2">
                  <FormGroup row>
                    <Col md={2}>
                      <Input
                        type="number"
                        value={editSelection}
                        onChange={e => {
                          this.setState({ editSelection: e.target.value });
                        }}
                        bsSize="sm"
                        placeholder="selection"
                      />
                    </Col>
                    <Col md={5}>
                      <Input
                        type="select"
                        value={editDestType}
                        bsSize="sm"
                        onChange={e => {
                          this.setState({ editDestType: e.target.value });
                        }}
                      >
                        <option>Chọn đích</option>
                        <option value="extension">Extension</option>
                        <option value="announcement">Thông báo</option>
                        <option value="ringgroup">Ring Group</option>
                        <option value="queue">Queue</option>
                      </Input>
                      {editDestType === "extension" ? (
                        <Input
                          bsSize="sm"
                          className="mt-1"
                          type="select"
                          value={editDestId}
                          onChange={e => {
                            this.setState({ editDestId: e.target.value });
                          }}
                        >
                          <Query
                            query={QUERY_EXTENSION}
                            variables={{
                              filtered: [
                                {
                                  id: "company",
                                  value: company ? company.domain : ""
                                }
                              ]
                            }}
                          >
                            {({ loading, error, data }) => {
                              if (error) console.log(error);
                              if (!data.sipAccounts) return "";
                              return (
                                <Fragment>
                                  <option value="">Chọn extension</option>
                                  {data.sipAccounts.data.map((item, index) => (
                                    <option key={index} value={item._id}>
                                      {item.displayName + "<" + item._id + ">"}
                                    </option>
                                  ))}
                                </Fragment>
                              );
                            }}
                          </Query>
                        </Input>
                      ) : editDestType === "announcement" ? (
                        <Input
                          bsSize="sm"
                          className="mt-1"
                          type="select"
                          value={editDestId}
                          onChange={e => {
                            this.setState({ editDestId: e.target.value });
                          }}
                        >
                          <Query
                            query={QUERY_ANNOUNCEMENT}
                            variables={{
                              filtered: [
                                {
                                  id: "company",
                                  value: company ? company.domain : ""
                                }
                              ]
                            }}
                          >
                            {({ loading, error, data }) => {
                              if (error) {
                                console.log(error);
                                //authCheck(error);
                              }
                              if (!data.announcements) return "";
                              return (
                                <Fragment>
                                  <option value="">Chọn Thông Báo</option>
                                  {data.announcements.data.map((com, index) => (
                                    <option key={index} value={com._id}>
                                      {com.name}
                                    </option>
                                  ))}
                                </Fragment>
                              );
                            }}
                          </Query>
                        </Input>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col md={2}>
                      <div className="mt-1">
                        <label>
                          <ToggleButton
                            id="ivrRet"
                            value={editIvrRet}
                            onToggle={value => {
                              this.setState({ editIvrRet: !editIvrRet });
                            }}
                          />
                          <span />
                        </label>
                      </div>
                    </Col>
                    <Col md={3}>
                      <Button
                        color="primary"
                        onClick={e => {
                          var newDetails = entries.filter(function(
                            item,
                            index,
                            arr
                          ) {
                            return item.selection !== editSelection;
                          });
                          newDetails.push({
                            selection: editSelection,
                            destType: editDestType,
                            destId: editDestId,
                            ivrRet: editIvrRet
                          });
                          this.setState({
                            entries: newDetails,
                            editDestType: "",
                            editDestId: "",
                            editSelection: 0,
                            editIvrRet: false
                          });
                        }}
                      >
                        <MdAddCircle /> Submit
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
                <ReactTable
                  noDataText="Data is Empty"
                  data={entries}
                  showPagination={false}
                  pageSize={entries ? entries.length : 1}
                  defaultSorted={[
                    {
                      id: "selection",
                      desc: false
                    }
                  ]}
                  columns={[
                    {
                      Header: "Number",
                      accessor: "selection",
                      width: 90
                    },
                    {
                      Header: "Application",
                      accessor: "destType",
                      className: "text-center"
                    },
                    {
                      Header: "Dest Value",
                      accessor: "destId",
                      className: "text-center"
                    },
                    {
                      Header: "ivrRet",
                      accessor: "ivrRet",
                      Cell: row => (
                        <ToggleButton value={row.value} disable={true} />
                      ),
                      width: 90
                    },
                    {
                      Header: "",
                      width: 110,
                      Cell: row => (
                        <ButtonGroup>
                          <Button
                            onClick={e => {
                              this.setState({
                                editSelection: row.original.selection,
                                editDestType: row.original.destType,
                                editDestId: row.original.destId,
                                editIvrRet: row.original.ivrRet
                              });
                            }}
                          >
                            <MdEdit />
                          </Button>{" "}
                          <Button
                            onClick={e => {
                              var newEntries = entries.filter(function(
                                item,
                                index,
                                arr
                              ) {
                                return index !== row.index;
                              });
                              this.setState({ entries: newEntries });
                            }}
                          >
                            <MdDelete />
                          </Button>
                        </ButtonGroup>
                      )
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  };
}
export default withApollo(IvrEditPage);
