import Page from 'components/Page';
import React from 'react';
import { Button,Input,Row,Col,Label,Card,CardHeader,CardBody ,FormGroup,Badge,Tooltip} from 'reactstrap';
import { withApollo } from 'react-apollo'
import {MdSearch,MdImportExport,MdReport,MdCheckCircle,MdClose,MdCheckBoxOutlineBlank,MdTimelapse} from 'react-icons/lib/md';
import gql from 'graphql-tag';
import authCheck from 'utils/authCheck';
import Moment from 'moment';
import { CounterWidget } from 'components/Widget';
import ReactTable from "react-table";
import {GetLocalToken} from 'companyConstants'

export const APPOINTMENT_QUERY=gql`
  query Appointment($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
      appointments(page:$page,pageSize:$pageSize,sorted:$sorted,filtered:$filtered){
      data{
          _id
          appointmentTime
          appointmentDate
          description
          reason
          chanel
          state
          department{
            _id
            name
          }
          createdTime
          customer{
          _id
            phoneNumber
            fullName
            gender
            birthDay 
          }
      }
      page
      pages
  }
}
  
`
export const FEED_DEPARTMENT=gql`
    query Department{
        departments{
            data{
                _id            
                name
            }
          }
    }
`

class AppointmentReportPage extends React.Component{
    state={
        loadingAppointment:true,
        departments :[],    
        appointments:[],
        departmentId:"",
        workTime:"",
        state:"",
        chanel:"",
        startTime:new Date(),
        endTime:new Date(),
        workTimes:[],
        tooltipOpen:{}
    }
    
    componentDidMount(){
        var token = GetLocalToken();
        this.setState({workTimes:token.setting.workTimes});
        this.fetchAll(true);
    }
    fetchAll =async(isInit)=>{
        //fetch deparmtnet
        if(isInit){
            this.props.client.query({
                query:FEED_DEPARTMENT
            }).then(result=>{
                authCheck(result);
                this.setState({departments:result.data.departments.data})
            })
        }
        this.fetchAppointment();
    }
    fetchAppointment= async(state,instance)=>{
        
        try{
            const {startTime,endTime,state,chanel,departmentId,workTime}=this.state;
            var dateRange = Moment(startTime).format("DD/MM/YYYY")+"-"+Moment(endTime).format("DD/MM/YYYY");
            var filtered=[{id:"appointmentDateRange",value:dateRange}];
            if(state.length>0)
                filtered.push({id:"state",value:state});
            if(chanel.length>0)
                filtered.push({id:"chanel",value:chanel});
            if(workTime.length>0)
                filtered.push({id:"appointmentTime",value:workTime});
            if(departmentId.length>0)
                filtered.push({id:"departmentId",value:departmentId});
            this.props.client.query({
                query:APPOINTMENT_QUERY,
                variables:{filtered,sorted:[{id:"CreatedTime",desc:false}],pageSize:1000}
            }).then(result=>{                
                this.setState({loadingAppointment:false,appointments:result.data.appointments.data});
            })
        }catch(err){
            authCheck(err);
        }
    }
    render=()=>{
        const {startTime,endTime,state,chanel,departmentId,workTime,tooltipOpen}=this.state;
        return(
            <Page
                title="Báo cáo đặt khám"
                breadcrumbs={[{ name: 'Báo cáo đặt khám', active: true }]}            
                className="AppointmentReportPage">
                <CardBody>
                    <Row style={{padding:"1rem"}}>
                        <Col md={3} sm={6} xs={12}>
                            <FormGroup>
                                <Label>Lọc theo phòng/ban</Label>
                                <Input bsSize="sm" type="select" value={departmentId} onChange={e=>{this.setState({departmentId:e.target.value})}}>
                                    <option value="">Tất cả phòng/ban</option>                                    
                                    {this.state.departments.map((item,i)=>{
                                        return <option key={i} value={item._id}>{item.name}</option>
                                    })}
                                                   
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3} sm={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Khung giờ
                                </Label>
                                <Input bsSize="sm" type="select">
                                    <option value={workTime} onChange={e=>{this.setState({workTime:e.target.value})}}>Tất cả khung giờ</option>
                                    {
                                        this.state.workTimes.map((time,i)=>{
                                            return <option key={i} value={time}>{time}</option>
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3} sm={6} xs={12}>
                            <FormGroup>
                                <Label>Trạng thái</Label>
                                <Input bsSize="sm" type="select" value={state} onChange={e=>this.setState({state:e.target.value})}>
                                    <option value="">Tất cả trạng thái</option>
                                    <option value="waiting">Đang chờ khám</option>
                                    <option value="waiting">Đã hủy khám</option>
                                    <option value="waiting">Đã khám</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3} sm={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Đặt hẹn qua
                                </Label>
                                <Input bsSize="sm" type="select" value={chanel} onChange={e=>{this.setState({chanel:e.target.value})}}>
                                    <option value="">Tất cả các kênh</option>
                                    <option value="web">WebSite</option>
                                    <option value="phone">Gọi lên tổng đài</option>
                                    <option value="crm">CRM</option>
                                    <option value="other">Hình thức khác</option>
                                </Input>

                            </FormGroup>
                        </Col>
                        <Col md={3} sm={6} xs={12}>
                        <FormGroup>
                                <Label>Ngày bắt đầu</Label>
                                <Input value={Moment(startTime).format("YYYY-MM-DD")} type="date" bsSize="sm" onChange={e=>this.setState({startTime:e.target.value})}/>
                            </FormGroup>
                        </Col>
                        <Col md={3} sm={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Ngày kết thúc
                                </Label>
                                <Input value={Moment(endTime).format("YYYY-MM-DD")} type="date" bsSize="sm" onChange={e=>this.setState({endTime:e.target.value})}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <center>
                        <Button className="mr-4" size="lg" color="blue" onClick={e=>this.fetchAll(false)}><MdSearch/> Tìm kiếm</Button>
                        <Button className="mr-4 text-white" size="lg" color="blue"><MdImportExport/> Xuất Excel</Button>
                        <Button className="mr-4 text-white" size="lg" color="blue"><MdReport/> In báo cáo</Button>
                    </center>
                </CardBody>
                <Card className="mt-4">
                    <CardHeader className="bg-green">
                        Có {this.state.appointments.length} kết quả trong báo cáo từ ngày {Moment(startTime).format("DD/MM/YYYY")} đến ngày {Moment(this.state.endTime).format("DD/MM/YYYY")}
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3} sm={6} xs={12}>
                                <CounterWidget
                                    inverse={false}
                                    title="Tổng số lượt đặt khám"
                                    number={this.state.appointments.length}
                                    badgeColor="success"
                                    badgeSize="display-4"
                                    textLeft={60}
                                    style={{lineHeight:"24px",height:"64px"}}
                                />
                            </Col>
                            <Col md={9} sm={6} xs={12}>
                                <Row>
                                    <Col md={4} sm={6} xs={12}>
                                        <CounterWidget
                                            inverse={false}
                                            title="Đặt khám qua điện thoại"
                                            number={this.state.appointments.filter((item)=>{return item.chanel==="phone"}).length}
                                        />
                                    </Col>
                                    <Col md={4} sm={6} xs={12}>
                                        <CounterWidget
                                            inverse={false}
                                            title="Đặt khám qua web"
                                            number={this.state.appointments.filter((item)=>{return item.chanel==="web"}).length}
                                        />
                                    </Col>
                                    <Col md={4} sm={6} xs={12}>
                                        <CounterWidget
                                            inverse={false}
                                            title="Đặt khám qua CRM"
                                            number={this.state.appointments.filter((item)=>{return item.chanel==="crm"}).length}
                                        />
                                    </Col>
                                    <Col md={4} sm={6} xs={12}>
                                        <CounterWidget
                                            inverse={false}
                                            title="Đã đến khám"
                                            number={this.state.appointments.filter((item)=>{return item.state==="complete"}).length}
                                        />
                                    </Col>
                                    <Col md={4} sm={6} xs={12}>
                                        <CounterWidget
                                            inverse={false}
                                            title="Không khám"
                                            number={this.state.appointments.filter((item)=>{return item.state==="cancel"}).length}
                                        />
                                    </Col>
                                    <Col md={4} sm={6} xs={12}>
                                        <CounterWidget
                                            inverse={false}
                                            title="Không xác nhận"
                                            number={this.state.appointments.filter((item)=>{return item.state==="waiting"}).length}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader className="bg-warning">
                        Số lượt đặt khám theo Khoa/Phòng ban
                    </CardHeader>
                    <CardBody>
                        <Row>
                            {
                                this.state.departments.map((item,i)=>{
                                    return <Col key={i} md={3} sm={6} xs={12}>
                                        <CounterWidget
                                            inverse={false}
                                            title={item.name}
                                            number={this.state.appointments.filter((ap)=>{
                                                return ap.department._id===item._id;
                                            }).length}
                                            badgeColor="blue"
                                        />
                                    </Col>
                                })
                            }
                            
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader className="bg-green">
                        Chi tiết đặt khám
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            style={{marginTop:7}}
                            noDataText="Không có dữ liệu"
                            columns={[
                                {
                                    Header:"#",
                                    minWidth:30,
                                    style:{textAlign:"center"},
                                    Cell:row=><span>{(row.index+1)}</span>
                                },
                                {
                                    Header:"TÊN KHÁCH HÀNG",
                                    accessor:"customer.fullName",
                                    style:{textAlign:"center"}
                                },
                                {
                                    Header:"Đ.THOẠI",
                                    accessor:"customer.phoneNumber",
                                    style:{textAlign:"center"}
                                },
                                {
                                    Header:"NGÀY SINH",
                                    accessor:"customer.birthDay",
                                    width:120,
                                    Cell:row=><div style={{textAlign:"center"}}>{Moment(row.value).format("DD/MM/YYYY")}</div>
                                },
                                {
                                    Header:"NGÀY GIỜ KHÁM",
                                    style:{textAlign:"center"},
                                    Cell:row=>(
                                        <div>
                                            {Moment(row.original.appointmentDate).format("DD/MM/YYYY")}{"  "}
                                            <Badge className="text-white">{row.original.appointmentTime}</Badge>
                                        </div>
                                    )
                                },
                                {
                                    Header:"KHOA/PHÒNG BAN",
                                    style:{textAlign:"center"},
                                    accessor:"department.name"
                                },
                                {
                                    Header:"ĐẶT QUA",
                                    accessor:"chanel",
                                    Cell:row=><span>
                                        {row.value==="call"?"TỔNG ĐÀI":row.value==="app"?"ỨNG DỤNG":row.value==="crm"?"CRM":"WEBSITE"}
                                    </span>,
                                    style:{textAlign:"center",textTransform:"uppercase"},
                                    maxWidth:90
                                },
                                {
                                    Header:"TRẠNG THÁI",
                                    accessor:"state",
                                    style:{textAlign:"center"},
                                    maxWidth:80,
                                    Cell:row=>(
                                        <div onClick={e=>{this.onEditState(row.original,false)}} style={{fontSize:16}}>
                                            {row.value==="approve"? 
                                            <MdCheckBoxOutlineBlank id={"state"+row.original._id} className="text-green"/>:
                                            row.value==="served"?<MdCheckCircle  id={"state"+row.original._id} className="text-green"/>:
                                            row.value==="cancel"?<MdClose className="text-danger" id={"state"+row.original._id}/>:
                                           
                                            <MdTimelapse className="text-danger" id={"state"+row.original._id}/>
                                         
                                           
                                           } 
                                           <Tooltip placement="left" isOpen={tooltipOpen["state"+row.original._id]} target={"state"+row.original._id} autohide={true} toggle={e=>{tooltipOpen["state"+row.original._id]=!tooltipOpen["state"+row.original._id]; this.setState({tooltipOpen})}}>
                                            {
                                                row.value==="waiting"?"Chờ duyệt":
                                                row.value==="approve"?"Chờ khám":
                                                row.value==="cancel"?"Hủy khám":
                                                row.value==="served"?"Đã khám":""
                                            }
                                            </Tooltip>
                                        </div>
                                    )                                    
                                },
                                {
                                    Header:"NỘI DUNG KHÁM",
                                    accessor:"reason"
                                }
                            ]}
                            manual
                            pages={1}
                            onFetchData={this.fetchAppointment}
                            data={this.state.appointments}
                            loading={this.state.loadingAppointment}
                            className="-striped -highlight"
                            
                        >

                        </ReactTable>
                    </CardBody>
                </Card>
            </Page>
        )
    }
}

export default withApollo(AppointmentReportPage);