import Page from 'Admin/Components/Page';
import React, { Fragment } from 'react';
import { Button,ButtonGroup,Input,Col,Badge,Label,Modal,ModalBody,ModalHeader,ModalFooter ,Form,FormGroup,Card,CardHeader,CardBody} from 'reactstrap';


import gql from 'graphql-tag';
import { Query,Mutation } from 'react-apollo'
import {MdDelete,MdAddCircle,MdEdit,MdCheckBoxOutlineBlank,MdCheckCircle,MdClose} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import omitDeep from 'omit-deep';
import confirm from 'components/Confirmation'
import { withApollo } from 'react-apollo'
import Moment from 'moment'

export const FEED_QUERY=gql`
    query GroupAgent{        
        groupAgents{
            page
            pages
            data{
                _id,description,name,sipAccounts,status,updatedTime,createdTime,companyId,company{_id,name},sipAccountDetails{_id,displayName}
            }
        }
    }
    
`
export const QUERY_COMPANY=gql`
    query AdminQuery($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
        companies(page:$page,pageSize:$pageSize,filtered:$filtered,sorted:$sorted){
            data{
                _id
                name            
            }
        }
    }
`;
export const QUERY_sipAccount=gql`            
    query AdminQuery($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
        sipAccounts(page:$page,pageSize:$pageSize,filtered:$filtered,sorted:$sorted){
            data{            
                displayName
                _id                
            }
        }
    }
    
`
export const CREATE_QUERY=gql`
    mutation Mutation($groupAgent:GroupAgentInput!){
        createGroupAgent(groupagent:$groupAgent){
            _id,description,name,sipAccounts,status,updatedTime,createdTime,companyId,company{_id,name},sipAccountDetails{_id,displayName}
            
        }
    }
`
export const UPDATE_QUERY=gql`
    mutation Mutation($groupAgent:GroupAgentInput!){
        updateGroupAgent(groupagent:$groupAgent){
            _id,description,name,sipAccounts,status,updatedTime,createdTime,companyId,company{_id,name},sipAccountDetails{_id,displayName}
            
        }
    }
`
export const DELTE_QUERY=gql`
    mutation Mutation($_id:String!){
        deleteGroupAgent(_id:$_id){
            _id
        }
    }
`
class GroupAgentPage extends React.Component{
    state={
        modal:false,
        isCreate:true,//trang thai edit hay create
        rowOrigin:{},
        rowEdit:{sipAccounts :[]},
        data: [],
        page: 1,
        loading: true,
        sipAccounts:[]
    }
    constructor(){
        super();
        this.fetchGroupAgents=this.fetchGroupAgents.bind(this);        
    }
    toggle=modalType=>()=>{
        return this.setState({modal:!this.state.modal})
    }
    onCreate=()=>{
        this.setState({rowOrigin:{},rowEdit:{sipAccounts :[]},modal:true,isCreate:true});
    }
    onEdit=(row)=>{            
        const rowEdit = Object.assign({},row);
        console.log("edit row:"+JSON.stringify(rowEdit));
        this.setState({rowOrigin:row,rowEdit:rowEdit,modal:true,isCreate:false});
        this.feetchSipAccount(rowEdit.companyId);
    }
    
    onChangeCompany=(companyId)=>{
        
        const {rowEdit} = this.state;
        rowEdit.companyId=companyId;
        this.setState({rowEdit});
        this.feetchSipAccount(companyId);
    }
    feetchSipAccount=(companyId)=>{
        var filtered=[];
        if(companyId&&companyId.length>0)
            filtered.push({id:"CompanyId",value:companyId});
        
        this.props.client.query({
            query:QUERY_sipAccount,
            variables:{filtered,pageSize:1000}
        }).then(result=>{       
            this.setState({sipAccounts:result.data.sipAccounts.data});
        })
    }
    fetchGroupAgents=async(state, instance)=>{
        this.setState({ loading: true });
        const {filtered,pageSize,sorted,page} = state;
        try{
            const result = await this.props.client.query({
                query: FEED_QUERY,
                variables: { filtered,pageSize,sorted,page },
                
            })       
            authCheck(result);
            this.setState({data:result.data.groupAgents.data,pages:result.data.groupAgents.pages,page:result.data.groupAgents.page,loading:false});
        }catch(err){           
           authCheck(err);
        }
    }
    onDelete=(row,mutation)=>{
        console.log("delete row:"+JSON.stringify(row));
        confirm(`Bạn có chắc chắn muốn xóa bản ghi '${row.name}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }
    render=()=>{
        const {rowEdit,rowOrigin,data,loading,pages,isCreate,sipAccounts}=this.state;
        let selectedSipAccount = rowEdit.sipAccounts;
        var sipAccountStr  = "";
        if(selectedSipAccount&&selectedSipAccount.length>0){
            sipAccountStr  = selectedSipAccount.join("\n");
        }
        return (
            <Page title="Nhóm Agent"
                breadcrumbs={[{ name: 'Nhóm Agent', active: true }]}            
                className="ExtensionPage">     
                <Card>
                    <CardHeader className="bg-green">
                        
                        <Button color="primary" onClick={this.onCreate}><MdAddCircle/> Thêm nhóm</Button>
                    </CardHeader>
                    <CardBody>                       
                        <ReactTable
                            noDataText="Không có dữ liệu"
                            columns={[                                    
                                {
                                    Header:"Tên",
                                    accessor:"name"
                                },
                                {
                                    Header:"Tài khoản SIP",
                                    accessor:"sipAccountDetails",
                                    Cell:row=>(
                                        <div>
                                            {row.value===null?"":(
                                                row.value.map((item,index)=><span style={{paddingRight:10}} key={index}><Badge>{item._id}</Badge>{" <"+item.displayName+">"}</span>)
                                            )}
                                            
                                        </div>
                                    )
                                },
                                {
                                    Header:"Công ty",
                                    accessor:"company.name"
                                },
                                {
                                    Header:"T.Thái",
                                    accessor:"status",
                                    width:70,
                                    Cell:row=>(
                                        <div style={{fontSize:18}}>
                                        {row.value==="waiting"?<MdCheckBoxOutlineBlank className="text-green"/>:
                                        row.value==="complete"?<MdCheckCircle className="text-green"/>:<MdClose className="text-danger"/>   } 
                                        </div>
                                    )
                                },
                                {
                                    Header:"Mô tả",
                                    accessor:"description"
                                },
                                {
                                    Header:"Cập nhật",
                                    accessor:"updatedTime",
                                    width:130,
                                    Cell:row=>(
                                        <div>
                                            <Badge className="text-white">{Moment(row.value).format("hh:mm")}</Badge>{" "}
                                            {Moment(row.value).format("DD/MM/YYYY")}                                          
                                        </div>
                                    )
                                },
                                {
                                    Header:"Thao tác",
                                    maxWidth:100,
                                    sortable:false,
                                    Cell: row=>(
                                        <ButtonGroup>
                                            <Button onClick={()=>this.onEdit(row.original)}><MdEdit/></Button>
                                            <Mutation mutation={DELTE_QUERY}
                                                variables={{_id:row.original._id}}
                                                onCompleted={(result)=>{
                                                    window.location.reload();
                                                }}
                                            >
                                                {mutation=>
                                                    <Button onClick={()=>this.onDelete(row.original,mutation)}><MdDelete/></Button>
                                                }
                                            </Mutation>
                                            
                                    </ButtonGroup>
                                    )
                                }

                            ]}
                            
                            manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                            data={data}
                            pages={pages} // Display the total number of pages
                            loading={loading} // Display the loading overlay when we need it
                            onFetchData={this.fetchGroupAgents} // Request new data when things change
                            filterable
                            defaultPageSize={10}
                            className="-striped -highlight"
                        />
                                
                    </CardBody>
                </Card>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className="modal-lg"
                >
                    <ModalHeader toggle={this.toggle()}>
                       {this.state.isCreate?'Thêm':'Sửa'}
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup row>
                                <Label className="text-right" md={3} sm={4}>Tên nhóm:</Label>
                                <Col md={9} sm={8}>
                                    <Input value={rowEdit.name?rowEdit.name:""} onChange={e=>{ rowEdit.name=e.target.value;this.setState({rowEdit:rowEdit})}}></Input>                           
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label className="text-right" md={3} sm={4}>Công ty:</Label>
                                <Col md={9} sm={8}>
                                    
                                    <Input type="select" value={rowEdit.companyId} onChange={e=>{ this.onChangeCompany(e.target.value)}}>
                                        <option value="">Chọn công ty</option>
                                        <Query query={QUERY_COMPANY} variables={{pageSize:10000}}>
                                            {
                                                ({loading,error,data})=>{
                                                    var dataSource=[];
                                                    try{
                                                        dataSource=data.companies.data;
                                                    }catch(err){}
                                                    return <Fragment>
                                                        {dataSource.map((item,index)=><option key={index} value={item._id}>
                                                            {item.name}
                                                        </option>)}
                                                    </Fragment>
                                                }
                                            }
                                        </Query>

                                    </Input>                           
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label className="text-right" md={3} sm={4}>Tài khoản SIP:</Label>
                                <Col md={6} sm={5}>
                                    <Input type="textarea" value={sipAccountStr} onChange={e=>{rowEdit.sipAccounts=e.target.value.split("\n"); this.setState({rowEdit})}}></Input>
                                </Col>
                                <Col md={3} sm={3}>
                                    <Input type="select" value="" onChange={e=>{if(e.target.value==="")return;rowEdit.sipAccounts = rowEdit.sipAccounts.filter((item)=>{return item!==e.target.value});rowEdit.sipAccounts.push(e.target.value);this.setState({rowEdit})}}>
                                        <option value="">Chọn để thêm</option>
                                        {sipAccounts.map((item,index)=>
                                                            <option key={index} value={item._id}>{item._id} {"<"}{item.displayName}{">"}</option>
                                                        )}
                                    </Input>
                                </Col>
                            </FormGroup>                            
                            <FormGroup row>
                                <Label className="text-right" md={3} sm={4}>Mô tả:</Label>
                                <Col md={9} sm={8}>
                                    <Input type="textarea" value={rowEdit.description?rowEdit.description:""} onChange={e=>{rowEdit.description=e.target.value; this.setState({rowEdit:rowEdit})}}></Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label className="text-right" md={3} sm={4}>Trạng thái:</Label>
                                <Col md={9} sm={8}>
                                    <Input type="checkbox" checked={rowEdit.status} onChange={e=>{rowEdit.status=(e.target.checked);this.setState({rowEdit:rowEdit})}}/>                                    
                                </Col>
                                    
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Mutation 
                            mutation={this.state.isCreate?CREATE_QUERY:UPDATE_QUERY}
                            variables={{groupAgent:omitDeep(rowEdit,['__typename','sipAccountDetails','company','createdTime','updatedTime'])}}
                            onCompleted={(result)=>{
                                //reload the list or manual add to the source list
                                if(isCreate){
                                    //require reload all table
                                    data.push(result.createGroupAgent);
                                    this.setState({data});
                                }else{
                                    for(var key in result.updateGroupAgent){
                                        rowOrigin[key]=result.updateGroupAgent[key];
                                    }
                                }
                                this.toggle()();
                            }}
                        >
                        {mutation=>
                            <Button color="primary" onClick={mutation}>
                                Save
                            </Button>
                        }
                            
                        </Mutation>
                        {' '}
                        <Button color="secondary" onClick={this.toggle()}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </Page>
        )
    }

}

export default withApollo(GroupAgentPage);
