import Page from 'Admin/Components/Page';
import React from 'react';
import { Button,ButtonGroup,Card,CardBody,CardHeader,Input} from 'reactstrap';

import gql from 'graphql-tag';
import {Mutation} from 'react-apollo'
import {MdEdit,MdDelete,MdAddCircle,MdCheckBoxOutlineBlank,MdCheckCircle,MdClose} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import Moment from 'moment'
import { withApollo } from 'react-apollo'

import confirm from 'components/Confirmation'

export const QUERY_COMPANY=gql`
    query AdminQuery($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
        menus(page:$page,pageSize:$pageSize,filtered:$filtered,sorted:$sorted){
        data{
            _id
            name
            level
            description
            path
            sort
            icon
            parent{
                _id
                name
            }
            isAdmin
        }
        page
        pages
        }
    }
`;
export const DELTE_QUERY=gql`
    mutation Mutation($_id:String!){
        deleteMenu(_id:$_id){
            _id
        }
    }
`
class MenuPage extends React.Component{
    state={
        data: [],
        page: 1,
        loading: true
    }
    constructor(){
        super();
        this.fetchData=this.fetchData.bind(this);        
    }
    onDelete=(row,mutation)=>{
        confirm(`Bạn có chắc chắn muốn xóa bản ghi '${row.name}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }
    fetchData=async(state, instance)=>{
        this.setState({ loading: true });
        const {filtered,pageSize,sorted,page} = state;
        try{
            const result = await this.props.client.query({
                query: QUERY_COMPANY,
                variables: { filtered,pageSize,sorted,page },
                
            })       
            authCheck(result); 
            this.setState({data:result.data.menus.data,pages:result.data.menus.pages,page:result.data.menus.page,loading:false});
        }catch(err){
            //console.log(err);
            authCheck(err);
        }
    }
    render=()=>{
        const {data,loading,pages}=this.state;
        return(
        <Page title="Công ty"
                breadcrumbs={[{ name: 'Danh sách công ty', active: true }]}            
                className="ExtensionPage"> 
            <Card>
                <CardHeader className="bg-green">                        
                    <Button color="primary" onClick={e=>this.props.history.push("/menu-edit")}><MdAddCircle/> Thêm Menu</Button>
                </CardHeader>
                <CardBody>
                <div style={{height:10}}></div>
            
                    <ReactTable 
                        noDataText="Không có dữ liệu"                        
                        columns={[    
                            {
                                Header:"#",
                                width:50,
                                style:{textAlign:"center"},
                                Cell: (row) => {
                                    return <div>{row.index+1}</div>;
                                    },
                                filterable:false
                            },  
                            {
                                Header:"Admin Menu",
                                accessor:"isAdmin",                                
                                style:{textAlign:"center"},
                                Cell:row=>(<span>
                                    {row.value===true?"Admin":"Company"}
                                </span>),
                                Filter:({filter,onChange})=>
                                        <Input
                                            type="select" onChange={e=>onChange(e.target.value)} bsSize="sm"
                                            value={filter?filter.value:""}
                                        >
                                            <option value="true">Admin</option>
                                            <option value="false">Customer</option>
                                            
                                        </Input>
                            },                             
                            {
                                Header:"Tên Menu",
                                accessor:"name",                                
                                maxWidth:150,
                            },
                            {
                                Header:"Path",
                                accessor:"path",
                            }, 
                            {
                                Header:"Menu Cha",
                                accessor:"parent.name",
                                style:{textAlign:"center"},
                            },
                            {
                                Header:"Icon",
                                accessor:"icon",
                            }, 
                            {
                                Header:"Mô tả",
                                accessor:"description"
                            },
                            {
                                Header:"Cấp",
                                accessor:"level",                                
                                style:{textAlign:"center"},
                            },  
                            {
                                Header:"Thứ tự",
                                accessor:"sort",                                
                                style:{textAlign:"center"},
                            },  
                            {
                                Header:"Tác vụ",
                                maxWidth:100,
                                filterable:false,
                                sortable:false,
                                Cell: row=>(
                                    <ButtonGroup>
                                        <Button onClick={()=>this.props.history.push("/menu-edit/"+row.original._id)}><MdEdit/></Button>
                                        {" "}
                                        <Mutation mutation={DELTE_QUERY}
                                                variables={{_id:row.original._id}}
                                                onCompleted={(result)=>{
                                                    window.location.reload();
                                                }}
                                            >
                                                {mutation=>
                                                    <Button onClick={()=>this.onDelete(row.original,mutation)}><MdDelete/></Button>
                                                }
                                            </Mutation>

                                    </ButtonGroup>
                                )
                            }
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={20}
                        className="-striped -highlight"
                    />                                      
                            
                </CardBody>
            </Card>
        </Page>)
    }
}

export default withApollo(MenuPage);