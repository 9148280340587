import Page from 'Admin/Components/Page';
import React from 'react';
import { Button,ButtonGroup,Card,CardBody,CardHeader} from 'reactstrap';

import gql from 'graphql-tag';
import {Mutation} from 'react-apollo'
import {MdEdit,MdDelete,MdAddCircle} from 'react-icons/lib/md';
import authCheck from 'utils/authCheck';
import ReactTable from "react-table";
import confirm from 'components/Confirmation'
import { withApollo } from 'react-apollo'



export const QUERY_sipAccount=gql`
query AdminQuery($filtered:[FilteredInput],$sorted:[SortedInput],$page:Int,$pageSize:Int){
    sipAccounts(page:$page,pageSize:$pageSize,filtered:$filtered,sorted:$sorted){
      data{
        description
        userName
        displayName
        shortExten
        _id
        password
        tech
        company{
          name
          _id
          domain
          tel
          website
          address
        }
      },
      page
      pages
    }
  }
`;

export const DELTE_QUERY=gql`
    mutation Mutation($_id:String!){
        deleteSipAccount(_id:$_id){
            _id
        }
    }
`

class sipAccountPage extends React.Component{
    state={
        
        data: [],
        page: 1,
        loading: true
    }
    constructor(){
        super();
        this.fetchData=this.fetchData.bind(this);        
    }
    
    fetchData=async(state, instance)=>{
        this.setState({ loading: true });
        const {filtered,pageSize,sorted,page} = state;
        try{
            const result = await this.props.client.query({
                query: QUERY_sipAccount,
                variables: { filtered,pageSize,sorted,page },
                
            })       
            authCheck(result);
            this.setState({data:result.data.sipAccounts.data,pages:result.data.sipAccounts.pages,page:result.data.sipAccounts.page,loading:false});
        }catch(err){
           
           authCheck(err);
        }
    }
    
    
    onDelete=(row,mutation)=>{
        confirm(`Bạn có chắc chắn muốn xóa đầu số '${row.number}'?`,{title:"Xóa bản ghi"}).then(
            (result)=>{
                mutation();
            },            
            (result) => {
                // `cancel` callback
                
            }
        )
    }
    
    render=()=>{
        const {data,loading,pages}=this.state;
  
        return(
        <Page title="Tài khoản SIP"
                breadcrumbs={[{ name: 'Danh sách tài khoản SIP', active: true }]}            
                className="sipAccount"> 
            <Card>
                <CardHeader className="bg-green">                        
                    <Button color="primary" onClick={e=>{this.props.history.push("/sip-account-edit")}}><MdAddCircle/> Thêm tài khoản SIP</Button>
                </CardHeader>
                <CardBody>
                <div style={{height:10}}></div>
            
                    <ReactTable 
                        noDataText="Không có dữ liệu"                        
                        columns={[    
                            {
                                Header:"#",
                                width:50,
                                style:{textAlign:"center"},
                                Cell: (row) => {
                                    return <div>{row.index+1}</div>;
                                    }
                            },                             
                            {
                                Header:"Id",
                                accessor:"_id"
                            },
                            {
                                Header:"UserName",
                                accessor:"userName"
                            },
                            {
                                Header:"Số nội bộ",
                                accessor:"shortExten"
                            },
                            {
                                Header:"Mật khẩu",
                                accessor:"password",
                            }, 
                            {
                                Header:"Tên hiển thị",
                                accessor:"displayName",
                            },  
                            {
                                Header:"Kỹ thuật",
                                accessor:"tech",
                                width:80
                            },                                    
                            {
                                Header:"Công ty",
                                width:140,
                                accessor:"company",
                                Cell:row=>(
                                    <div>
                                        {row.value?row.value.name:"Chưa xác định"}                              
                                    </div>
                                )
                            },
                            {
                                Header:"Mô tả",
                                accessor:"description"
                            },
                            {
                                Header:"Tác vụ",
                                maxWidth:100,
                                filterable:false,
                                sortable:false,
                                Cell: row=>(
                                    <ButtonGroup>
                                        <Button onClick={()=>{this.props.history.push("/sip-account-edit/"+row.original._id)}}><MdEdit/></Button>
                                        {" "}
                                        <Mutation mutation={DELTE_QUERY}
                                                variables={{_id:row.original._id}}
                                                onCompleted={(result)=>{
                                                    window.location.reload();
                                                }}
                                            >
                                                {mutation=>
                                                    <Button onClick={()=>this.onDelete(row.original,mutation)}><MdDelete/></Button>
                                                }
                                            </Mutation>

                                    </ButtonGroup>
                                )
                            }
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={10}
                        className="-striped -highlight"
                    />                                      
                            
                </CardBody>
            </Card>
            
        </Page>)
    }
}

export default withApollo(sipAccountPage);