import React from "react";
import logo from 'styles/images/logo_phusan.png'
import { ValidFormDate } from "utils/dateHelper";
import Moment from "moment";


const TD=({className,colspan,rowspan,children})=><td className={className} colspan={colspan} rowspan={rowspan} style={{padding:"5px 10px",fontSize:17}}>
    {children}
</td>

const PrintTraKQXN = ({
    mabn,
    ten_xet_nghiem,
    ma_xet_nghiem,
    ho_va_ten,
    ngay_sinh,
    khoa_xet_nghiem,
    ngay_lay_mau,
    ngay_tra_kq,
    css,
    objRef
}) => (

        <div className="body" ref={objRef}>

            <div className="content" id="tra_kqxn">
            <div class="img_logo" style={{ width: 120, marginRight: 20 }}> <img src={logo} alt="logo hospital" style={{ width: 120 }} /> </div>
                <div style={{ flex: 1 }}>
                    <p style={{marginBottom:0}}><b style={{ fontSize: 24, color: "#478F20" }}>BỆNH VIỆN PHỤ SẢN HÀ NỘI</b><br />
                        <b>
                            TRUNG TÂM SÀNG LỌC, CHUẨN ĐOÁN TRƯỚC SINH VÀ SƠ SINH
                        </b> 
                    </p>
                    <p> Địa chỉ: Số 929 - Đường La Thành - Ngọc Khánh - Ba Đình - Hà Nội<br />
                        Hotline: 0243 7757 346
                    </p>
                </div>
                <div style={{ width: "100%", margin: "0px 0px 10px 0px" }}>
                    <p style={{ textAlign: "center", fontSize: 22, color: "#478F20", fontWeight: "bold" }}>PHIẾU HẸN TRẢ KẾT QUẢ XÉT NGHIỆM</p>
                </div>
                <div class="form_info">                    
                    <table cellpadding="0" cellspacing="0" style={{borderTop:"none"}}>
                        <tbody>
                        <tr style={{borderTop:"none"}}>
                            <th style={{width:"50%"}}></th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <TD>
                                <span style={{fontWeight:"bold"}}>Họ và tên</span>: {ho_va_ten}
                            </TD>
                            <TD>
                                <span style={{fontWeight:"bold"}}>Năm sinh:</span> {ValidFormDate(ngay_sinh)}
                            </TD>
                            
                        </tr>
                        <tr>
                            <TD>
                            <span style={{fontWeight:"bold"}}>Mã bệnh nhân:</span> {mabn}
                            </TD>
                            <TD>
                            <span style={{fontWeight:"bold"}}>Mã xét nghiệm:</span> {ma_xet_nghiem}
                            </TD>
                        </tr>                        
                        <tr>
                            <TD colspan="2">
                            <span style={{fontWeight:"bold"}}>Tên xét nghiệm:</span> {ten_xet_nghiem}
                            </TD>
                        </tr>
                        <tr>
                            <TD colspan="2">
                            <span style={{fontWeight:"bold"}}>Thời gian lấy mẫu:</span> {Moment(ngay_lay_mau).format("HH:mm DD/MM/YYYY")}
                            </TD>
                        </tr>
                        <tr>
                            <TD colspan="2">
                            <span style={{fontWeight:"bold"}}>Thời gian trả kết quả:</span> {Moment(ngay_tra_kq).format("HH:mm DD/MM/YYYY")}
                            </TD>
                        </tr>
                        <tr>
                            <TD colspan="2">
                                <table className="table-inline">
                                    <tbody>
                                    <tr>
                                        <TD>
                                        <span style={{fontWeight:"bold"}}>Địa điểm lấy kết quả:</span>
                                        </TD>
                                        <TD>                                            
                                            <p>- {khoa_xet_nghiem}</p>
                                        </TD>
                                    </tr>
                                    </tbody>
                                </table>
                                
                            </TD>
                            
                        </tr>
                        </tbody>
                    </table>
                    <div style={{fontSize:16}}>
                        <div style={{fontStyle:"italic",marginTop:20}}>
                            <span style={{fontWeight:"bold",textDecoration:"underline"}}>Lưu ý: </span> Mang theo giấy hẹn này khi đến lấy kết quả.
                        </div>
                        <div style={{fontWeight:"bold"}}>
                            Hướng dẫn xem kết quả trên website Bệnh viện
                        </div>
                        <div style={{marginLeft:10}}>
                            <div>
                            - Vào trang web của bệnh viện tại địa chỉ http://www.benhvienphusanhanoi.vn/
                            </div>
                            <div>
                            - Chọn Tab “<span style={{fontWeight:"bold"}}>Kết quả xét nghiệm</span>”
                            </div>
                            <div>
                            - Chọn “<span style={{fontWeight:"bold"}}>Menu tìm nhanh</span>”
                            </div>
                            <div>
                            - Nhập mã số bệnh nhân
                            </div>
                            <div>
                            - Nhập mã số xét nghiệm
                            </div>  
                        </div>
                        <p>
                        Khi cần hỗ trợ vui lòng liên hệ: <span style={{fontWeight:"bold"}}>Phòng Xét nghiệm:  0243 7757 346 </span> (Trong giờ hành chính từ thứ 2 đến thứ 6)
                        </p>      
                    </div>            
                </div>
            </div>
            
        </div>

    )
export default PrintTraKQXN;